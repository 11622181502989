import "../../../css/GlobalStyles.css";
import { useCallback, useContext, useMemo, useState } from "react";
import { Event } from "@markit/common.types";
import { foundUserGenericLinks } from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { getEventState } from "../../../redux/slices/eventSlice";
import { hasSubscription } from "@markit/common.utils";
import EmptyStateButton from "../../../components/Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../../utils/colors";
import { CreatorPanelTabs } from "../../../components/CreatorDashboard/CreatorPanelTabs";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import { useNavigate } from "../../../hooks/useNavigate";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import { MixpanelContext } from "../../../context/AnalyticsService";
import { FeaturedEventCardMobile } from "../../../components/LandingPage/RenderedEvents";

const LinksPanel = () => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const mixpanel = useContext(MixpanelContext);
  const { events: eventList } = useSelector(getEventState);
  const { events } = eventList;
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const activeGenericLinks = useMemo(
    () => foundUserGenericLinks(events, accountData.uid, "active"),
    [accountData.uid, events]
  );

  const inactiveGenericLinks = useMemo(
    () => foundUserGenericLinks(events, accountData.uid, "inactive"),
    [accountData.uid, events]
  );

  const navigateCreateLinkOnPress = useCallback(() => {
    mixpanel.track("Pressed Create External Event Link", {
      distinct_id: accountData.uid,
      platform: "webapp",
      type: "generic_link",
      button_location: "links_panel",
    });
    navigate("/createLink");
  }, [accountData.uid, mixpanel, navigate]);

  const renderSubPanel = useMemo(() => {
    const renderLinks = (links: Event[]) => {
      return links.length > 0 ? (
        links.map((link, index) => (
          <div style={{ paddingRight: 14, paddingTop: index === 0 ? 24 : 0 }}>
            <FeaturedEventCardMobile
              event={link}
              altNavigationPath={
                link.isDraft ? `/create` : `/e/${link.id}/dashboard`
              }
              myEvent
            />
          </div>
        ))
      ) : (
        <EmptyStateButton
          title={"Grow with Links"}
          description={
            "Collect audience data and grow your audience when you create and share a link through Markit."
          }
          icon={
            <Icon icon={"mdi:link-variant"} height={63} color={Colors.GRAY1} />
          }
          iconBackgroundColor={Colors.WHITE}
          iconBox={80}
          btnText="Create a Link"
          onPress={navigateCreateLinkOnPress}
          containerStyles={{ paddingTop: 200 }}
        />
      );
    };

    return (
      <div
        className="ColumnNormal"
        style={{
          gap: "14px",
          height: "calc(100vh - 180px)",
          overflowY: "scroll",
        }}
      >
        {value === 0 && renderLinks(activeGenericLinks)}
        {value === 1 && renderLinks(inactiveGenericLinks)}
      </div>
    );
  }, [
    value,
    activeGenericLinks,
    inactiveGenericLinks,
    navigateCreateLinkOnPress,
  ]);

  return (
    <div>
      <div className="CreatorPanelContainer">
        <div className="AlignedRowSpaced">
          <h2>Links</h2>
          <div className="AlignedRow" style={{ gap: 14 }}>
            {!hasSubscription(accountData) ? <MarkitPlusActionButton /> : null}
            <RectangleButtonCreatorPanel
              title="Create Link"
              iconName="ion:add-circle"
              onPress={navigateCreateLinkOnPress}
            />
          </div>
        </div>
        <CreatorPanelTabs
          tabValue={value}
          setTabValue={setValue}
          tabNames={[
            `Active (${activeGenericLinks.length})`,
            `Inactive (${inactiveGenericLinks.length})`,
          ]}
        />
      </div>
      <div
        className="ColumnNormal HideScrollbar"
        style={{
          height: "80vh",
          backgroundColor: Colors.WHITE1,
          padding: "0px 14px 24px 48px",
        }}
      >
        {renderSubPanel}
      </div>
    </div>
  );
};

export default LinksPanel;
