import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import "../../../../css/MassTexts.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AccountData,
  Event,
  MassText,
  MassTextAttachment,
  MassTextAttachmentType,
  SubSelectionType,
} from "@markit/common.types";
import {
  LessThanDate,
  getCategoryLabelName,
  getTimezone,
  isEventExternalLink,
  massTextMessageConverter,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  getMassTextAttachments,
  getMassTextDeliveryAttemptedRate,
  getMassTextSendRate,
} from "../../../../utils/textingUtils";
import { GetLongDate } from "../../../../utils/GetLongDate";
import { GetTime } from "../../../../utils/GetTime";
import { MassTextMessagingScreenType } from "../MassTextsPopupPanel";
import ProgressBar from "@ramonak/react-progress-bar";
import CustomLinkify from "../../../Links/CustomLinkify";
import { onSnapshot } from "../../../../firebase";
import { useOnMount } from "../../../../utils/useOnMount";
import {
  getUserData,
  getUserMassTextRef,
} from "../../../../utils/FirebaseUtils";
import { CircularProgress } from "@mui/material";
import { MassTextMessage } from "../Message/MassTextMessage";
import { MassTextsTextSummaryProps } from "./MassTextsTextSummary";
import { getEventState } from "../../../../redux/slices/eventSlice";
import MassTextsLinkAnalytics from "./MassTextLinkAnalytics";
import { API } from "../../../../API";
import { MassTextCatchUp } from "../Message/MassTextCatchUp";
import MassTextsSendDetails from "./MassTextsSendDetails";
import IntermediaryModalContainer from "../../../Containers/IntermediaryModalContainer";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import TextCreditsTagModal from "../Message/TextCreditsTagModal";

type MassTextsSendSummaryProps = MassTextsTextSummaryProps & {
  screen: MassTextMessagingScreenType;
};

const MassTextsSendSummary = (props: MassTextsSendSummaryProps) => {
  const {
    finalRecipients,
    netIndividualRecipients,
    massText,
    updateMassTextSettings,
    setMainScreen,
    screen,
    setScreen,
    isEditing,
    theme,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, savedQuestions } = account;
  const { events } = useSelector(getEventState);
  const { events: userEvents } = events;
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingLive, setLoadingLive] = useState<boolean>(true);
  const [liveMassText, setLiveMassText] = useState<MassText>(massText);
  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[]
  >([]);
  const [deliveryAttemptedRate, setDeliveryAttemptedRate] = useState<number>(0);
  const [sendRate, setSendRate] = useState<number>(100);
  const [numOpensMap, setNumOpensMap] = useState<Map<string, number>>(
    new Map<string, number>()
  );
  const [numConversionsMap, setNumConversionsMap] = useState<
    Map<string, number>
  >(new Map<string, number>());
  const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
  const [selectedAttachment, setSelectedAttachment] =
    useState<MassTextAttachment>();

  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    if (liveMassText.mediaUrl) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
      img.src = liveMassText.mediaUrl;
    }
  }, [liveMassText.mediaUrl]);

  const styles = {
    coverImage: {
      width: 260,
      borderRadius: 12,
    },

    sectionContainer: {
      paddingBlock: 14,
      backgroundColor: Colors.GRAY6,
      borderRadius: 8,
    },

    boldText: {
      fontSize: 16,
      fontWeight: 600,
    },

    subText: {
      fontSize: 14,
      color: Colors.GRAY1,
    },
  };

  const foundMassTextEvent = useMemo(
    () => userEvents.find((event) => event.id === massText.eventRefId),
    [massText.eventRefId, userEvents]
  );

  const hasCategories = useMemo(
    () => massText.subSelectionIds.length > 0,
    [massText.subSelectionIds.length]
  );

  // Able to put !isEditing in conditional because the summary screen will show up right after sending immediate texts
  // Without it, it will think that the text hasn't sent yet for the immediate case
  const hasTextSent = useMemo(
    () =>
      !isEditing || LessThanDate(liveMassText.sentAt, new Date().toISOString()),
    [isEditing, liveMassText.sentAt]
  );

  const isTextFinishedSending = useMemo(
    () =>
      (liveMassText.queued && deliveryAttemptedRate === 100) ||
      liveMassText.queued === undefined ||
      (liveMassText.queued && finalRecipients.length === 0),
    [deliveryAttemptedRate, finalRecipients.length, liveMassText.queued]
  );

  const massTextAttachmentsToShow = useMemo(
    () =>
      massTextAttachments.filter(
        (attachment) =>
          attachment.massTextType !== MassTextAttachmentType.TICKET_LINK
      ),
    [massTextAttachments]
  );

  const categoryLabel = useMemo(() => {
    const tickets = foundMassTextEvent ? foundMassTextEvent.customTickets : [];
    return massText.subSelectionIds.length > 0
      ? massText.subSelectionIds.length > 1 ||
        (massText.subSelectionIds.length === 1 &&
          massText.subSelectionIds[0].type === SubSelectionType.FORM_QUESTION)
        ? "Custom Selection"
        : getCategoryLabelName(
            massText.subSelectionIds[0],
            tickets,
            savedQuestions
          )
      : "No Categories";
  }, [foundMassTextEvent, massText.subSelectionIds, savedQuestions]);

  useOnMount(() => {
    (async () => {
      let finalUserData: AccountData = accountData;
      // for viewing other user's texting analytics
      if (massText.sentBy !== accountData.uid) {
        const userData = await getUserData(massText.sentBy);
        if (userData) {
          finalUserData = userData;
        }
      }
      const unsubscribe = onSnapshot(
        getUserMassTextRef(accountData.uid, massText.id),
        async (doc) => {
          setLoadingLive(true);
          const currMassText = doc.data();
          if (currMassText) {
            setLiveMassText(currMassText);
            const massTextAttachments = await getMassTextAttachments(
              finalUserData.uid,
              currMassText
            );
            setMassTextAttachments(massTextAttachments);

            for (let i = 0; i < massTextAttachments.length; i++) {
              const response = await Promise.all([
                API.tracking.getAttachmentNumOpens({
                  userId: finalUserData.uid,
                  massTextId: currMassText.id,
                  attachmentId: massTextAttachments[i].id,
                }),
                API.tracking.getAttachmentNumConversions({
                  userId: finalUserData.uid,
                  massTextId: currMassText.id,
                  attachmentId: massTextAttachments[i].id,
                }),
              ]);

              setNumOpensMap(
                numOpensMap.set(massTextAttachments[i].id, response[0].totalNum)
              );
              setNumConversionsMap(
                numConversionsMap.set(
                  massTextAttachments[i].id,
                  response[1].totalNum
                )
              );
            }
          }
          setLoadingLive(false);
        }
      );
      return unsubscribe;
    })();
  });

  useEffect(() => {
    (async () => {
      // can use massText and not liveMassText since id and sentAt are the only fields used
      const massTextDeliveryAttemptedRate =
        await getMassTextDeliveryAttemptedRate(massText);

      setDeliveryAttemptedRate(massTextDeliveryAttemptedRate);
      if (
        (massTextDeliveryAttemptedRate === 100 && massText.queued) ||
        massText.queued === undefined
      ) {
        const massTextSendRate = await getMassTextSendRate(massText);
        setSendRate(massTextSendRate);
        setLoading(false);
      } else {
        setLoading(false);
        if (!intervalId) {
          const interval = setInterval(async () => {
            const massTextDeliveryAttemptedRate =
              await getMassTextDeliveryAttemptedRate(massText);
            setDeliveryAttemptedRate(massTextDeliveryAttemptedRate);
            if (
              (massTextDeliveryAttemptedRate === 100 && massText.queued) ||
              massText.queued === undefined
            ) {
              const massTextSendRate = await getMassTextSendRate(massText);
              setSendRate(massTextSendRate);
              clearInterval(intervalId);
            }
          }, 5000);
          setIntervalId(interval);
          return () => clearInterval(interval);
        }
      }
    })();
  }, [accountData.isAdmin, intervalId, massText]);

  const renderEventItemPreview = useCallback(
    (attachment: MassTextAttachment, foundEvent: Event | undefined) => {
      return (
        <div className="AlignedRowSelect" style={{ gap: 8 }}>
          <img
            src={foundEvent?.photoURL ?? ""}
            alt={"LinkAnalyticsEventPic"}
            style={{
              width: 40,
              height: 40,
              borderRadius: 8,
            }}
          />
          <div className="ColumnNormalSelect">
            <span style={{ fontSize: 14, fontWeight: "500" }}>
              {attachment.massTextType === MassTextAttachmentType.LINK
                ? "Event Link"
                : attachment.massTextType}
            </span>
            <span style={styles.subText}>
              {foundEvent?.title ?? "Markit Event"}
            </span>
          </div>
        </div>
      );
    },
    [styles.subText]
  );

  // If the mass text has been sent:
  // 1. Sent - Show the sent and send rate details
  // 2. In Progress - Show the progress bar while the messages finish getting delivered
  const renderNetTotalRecipients = useMemo(
    () => (
      <>
        {isTextFinishedSending ? <HorizontalDivider altMargin={14} /> : null}
        <div
          className="AlignedRowSpacedSelect"
          style={
            isTextFinishedSending
              ? { paddingInline: 14 }
              : { ...styles.sectionContainer, padding: 14, marginTop: 14 }
          }
          onClick={() => {
            setScreen(MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY);
          }}
        >
          <div className="AlignedRowSelect" style={{ gap: 14 }}>
            {!hasCategories ? (
              <span style={styles.boldText}>
                {massText.subSelectionIds.length > 0
                  ? massText.subSelectionIds.length
                  : finalRecipients.length}
              </span>
            ) : null}
            <span style={styles.subText}>
              {hasCategories
                ? categoryLabel
                : `Recipient${finalRecipients.length === 1 ? "" : "s"}`}
            </span>
          </div>
          <div className="AlignedRowSelect" style={{ gap: 2 }}>
            <span style={{ fontSize: 14 }}>See All</span>
            <Icon icon="ion:chevron-forward" height={18} />
          </div>
        </div>
      </>
    ),
    [
      categoryLabel,
      finalRecipients.length,
      hasCategories,
      isTextFinishedSending,
      massText.subSelectionIds.length,
      setScreen,
      styles.boldText,
      styles.sectionContainer,
      styles.subText,
    ]
  );

  const renderHasSentText = useMemo(
    () =>
      isTextFinishedSending ? (
        <div
          className="ColumnNormal"
          style={{ ...styles.sectionContainer, gap: 7, paddingBlock: 21 }}
        >
          <div className="AlignedRow" style={{ gap: 7, paddingInline: 14 }}>
            <Icon icon="ion:paper-plane" height={16} />
            <span style={styles.boldText}>Sent</span>
            <span style={{ ...styles.subText, paddingTop: 2 }}>
              {`${GetLongDate(
                liveMassText.sentAt,
                true,
                true,
                true
              )}, ${GetTime(
                liveMassText.sentAt
              ).toUpperCase()} ${getTimezone()}`}
            </span>
          </div>
          {massText.mediaUrl ? (
            <div style={{ paddingInline: 14 }}>
              <TextCreditsTagModal
                message={massText.message}
                mediaUrl={massText.mediaUrl}
              />
            </div>
          ) : null}

          <HorizontalDivider altMargin={14} />
          <div className="AlignedRow" style={{ gap: 7, paddingInline: 14 }}>
            <span style={styles.boldText}>{sendRate}%</span>
            <span style={styles.subText}>Send Rate</span>
          </div>
          {renderNetTotalRecipients}
        </div>
      ) : (
        <>
          <div
            className="ColumnNormal"
            style={{
              ...styles.sectionContainer,
              paddingInline: 14,
            }}
          >
            <div className="AlignedRowSpaced" style={{ width: "100%" }}>
              <span style={{ fontSize: 13, color: Colors.GRAY1 }}>
                {!liveMassText.queued ? "Preparing to send..." : "Sending..."}
              </span>
              {/* <Icon
      icon="mdi:information-outline"
      height={16}
      style={{ cursor: "pointer" }}
      onClick={() => {
        alert("To implement: info popup modal");
      }}
    /> */}
            </div>
            <div style={{ paddingTop: 7, width: "100%" }}>
              <ProgressBar
                completed={!liveMassText.queued ? 0 : deliveryAttemptedRate}
                maxCompleted={100}
                isLabelVisible={false}
                bgColor={Colors.BLUE5}
                baseBgColor={Colors.WHITE}
                height="14px"
                width="100%"
                borderRadius="4px"
              />
            </div>
          </div>
          {renderNetTotalRecipients}
        </>
      ),
    [
      deliveryAttemptedRate,
      isTextFinishedSending,
      liveMassText.queued,
      liveMassText.sentAt,
      massText.mediaUrl,
      renderNetTotalRecipients,
      sendRate,
      styles.boldText,
      styles.sectionContainer,
      styles.subText,
    ]
  );

  // If the mass text has not been sent yet
  const renderNotSentText = useMemo(
    () => (
      <MassTextsSendDetails
        massText={massText}
        setMainScreen={setMainScreen}
        setScreen={setScreen}
        screen={screen}
        netRecipients={finalRecipients.length}
        isEditing={isEditing}
        suggestedAction={undefined}
        event={foundMassTextEvent}
      />
    ),
    [
      massText,
      setMainScreen,
      setScreen,
      screen,
      finalRecipients.length,
      isEditing,
      foundMassTextEvent,
    ]
  );

  return (
    <>
      {!selectedAttachment ||
      screen === MassTextMessagingScreenType.SEND_SUMMARY ? (
        <IntermediaryModalContainer
          body={
            <div className="ColumnNormal" style={{ height: "100%" }}>
              {!loadingLive && !loading ? (
                <>
                  {!hasTextSent ? renderNotSentText : renderHasSentText}
                  {/* If schedule send, make items editable */}
                  {!hasTextSent ? (
                    <MassTextMessage
                      massText={massText}
                      updateMassTextSettings={updateMassTextSettings}
                      finalRecipients={finalRecipients}
                      disableEventLinks={netIndividualRecipients.length > 0}
                      massTextAttachments={massTextAttachments}
                      event={foundMassTextEvent}
                      theme={theme}
                    />
                  ) : (
                    <>
                      {isEditing &&
                      (finalRecipients.length > 0 ||
                        massText.subSelectionIds.length > 0) &&
                      !hasTextSent ? (
                        <div
                          className="AlignedRowSpacedSelect"
                          style={{ ...styles.sectionContainer, marginTop: 14 }}
                          onClick={() => {
                            setScreen(
                              MassTextMessagingScreenType.RECIPIENTS_SEND_SUMMARY
                            );
                          }}
                        >
                          <div className="AlignedRowSelect" style={{ gap: 14 }}>
                            {!hasCategories ? (
                              <span style={styles.boldText}>
                                {massText.subSelectionIds.length > 0
                                  ? massText.subSelectionIds.length
                                  : finalRecipients.length}
                              </span>
                            ) : null}
                            <span style={styles.subText}>
                              {hasCategories
                                ? categoryLabel
                                : `Recipient${
                                    finalRecipients.length === 1 ? "" : "s"
                                  }`}
                            </span>
                          </div>
                          <div className="AlignedRowSelect" style={{ gap: 2 }}>
                            <span style={{ fontSize: 13 }}>See all</span>
                            <Icon icon="mdi:chevron-right" height={24} />
                          </div>
                        </div>
                      ) : null}
                      {isTextFinishedSending &&
                      massTextAttachmentsToShow.length > 0 ? (
                        <div
                          className="ColumnNormal"
                          style={{ marginTop: 14, gap: 14 }}
                        >
                          <span className="AboutSubtitle">Link Analytics</span>
                          {massTextAttachmentsToShow.map((attachment) => {
                            const foundEvent = userEvents.find(
                              (event) => event.id === attachment.eventId
                            );
                            const hideConversions =
                              foundEvent &&
                              isEventExternalLink(foundEvent.eventType);
                            return (
                              <div
                                onClick={() => {
                                  setSelectedAttachment(attachment);
                                  setScreen(
                                    MassTextMessagingScreenType.LINK_ANALYTICS
                                  );
                                }}
                                className="MassTextsSectionContainer"
                                style={{
                                  paddingBlock: 14,
                                  paddingInline: 0,
                                }}
                              >
                                <div
                                  className="AlignedRowSpacedSelect"
                                  style={{ paddingInline: 14 }}
                                >
                                  {renderEventItemPreview(
                                    attachment,
                                    foundEvent
                                  )}
                                  <Icon
                                    icon="ion:chevron-forward-outline"
                                    height={20}
                                    color={Colors.GRAY1}
                                  />
                                </div>
                                <HorizontalDivider altMargin={14} />
                                <div
                                  className="AlignedRowSelect"
                                  style={{ gap: 24, paddingInline: 14 }}
                                >
                                  <div className="ColumnNormalSelect">
                                    <span style={styles.boldText}>
                                      {numOpensMap.get(attachment.id) || 0}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: Colors.GRAY1,
                                      }}
                                    >
                                      Open
                                      {numOpensMap.get(attachment.id) === 1
                                        ? ""
                                        : "s"}
                                    </span>
                                  </div>
                                  {!hideConversions ? (
                                    <div className="ColumnNormalSelect">
                                      <span style={styles.boldText}>
                                        {numConversionsMap.get(attachment.id) ||
                                          0}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: 12,
                                          color: Colors.GRAY1,
                                        }}
                                      >
                                        Conversion
                                        {numConversionsMap.get(
                                          attachment.id
                                        ) === 1
                                          ? ""
                                          : "s"}
                                      </span>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                      {hasCategories ? (
                        <MassTextCatchUp
                          massText={liveMassText}
                          catchUpChecked={liveMassText.catchUpEnabled}
                          setCatchUpChecked={(catchUp: boolean) =>
                            updateMassTextSettings({ catchUpEnabled: catchUp })
                          }
                          theme={theme}
                        />
                      ) : null}
                      <div
                        className="ColumnCenter"
                        style={{ marginTop: 14, gap: 7, paddingBottom: 100 }}
                      >
                        <span
                          className="AboutSubtitle"
                          style={theme?.PrimaryText}
                        >
                          Message
                        </span>
                        <CustomLinkify>
                          <span
                            style={{
                              ...styles.subText,
                              fontWeight: 400,
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {massTextMessageConverter(
                              liveMassText.message,
                              massTextAttachments
                            )}
                          </span>
                        </CustomLinkify>
                        {massText.mediaUrl ? (
                          <img
                            src={massText.mediaUrl}
                            alt={"text media"}
                            style={{
                              ...styles.coverImage,
                              ...{ aspectRatio: aspectRatio },
                            }}
                          />
                        ) : null}
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="Centering">
                  <CircularProgress
                    style={{ color: "#929292", marginTop: 14 }}
                    size={24}
                  />
                </div>
              )}
            </div>
          }
        />
      ) : (
        <MassTextsLinkAnalytics
          massText={liveMassText}
          massTextAttachment={selectedAttachment}
        />
      )}
    </>
  );
};

export default MassTextsSendSummary;
