import { Colors } from "../../../../utils/colors";
import {
  Event,
  MassText,
  SubSelectionItem,
  SubSelectionType,
} from "@markit/common.types";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  MassTextMessagingScreenType,
  MassTextScreenType,
} from "../MassTextsPopupPanel";
import { useMemo } from "react";
import { Icon } from "@iconify/react";
import {
  GetTime,
  LessThanDate,
  formatDate,
  getCategoryLabelName,
} from "@markit/common.utils";
import { Dropdown, MenuProps } from "antd";
import DropdownMenuLabel from "../../../DropdownMenuLabel";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";

type MassTextsSendDetailsProps = {
  massText: MassText;
  netRecipients: number;
  setMainScreen: (mainScreen: MassTextScreenType) => void;
  screen: MassTextMessagingScreenType;
  setScreen: (screen: MassTextMessagingScreenType) => void;
  suggestedAction: SubSelectionItem | undefined;
  event: Event | undefined;
  isEditing?: boolean;
};

const MassTextsSendDetails = (props: MassTextsSendDetailsProps) => {
  const {
    massText,
    netRecipients,
    isEditing,
    setMainScreen,
    screen,
    setScreen,
    suggestedAction,
    event,
  } = props;
  const { account } = useSelector(getAccountState);
  const { savedQuestions } = account;

  const styles = {
    selectRecipientTitle: { marginLeft: 10, fontWeight: "500", fontSize: 14 },
    selectRecipientTitlePrompt: { color: Colors.GRAY1, fontSize: 14 },
  };

  const textSendTime = useMemo(
    () => (massText.sentAt ? new Date(massText.sentAt) : new Date()),
    [massText.sentAt]
  );

  // Disable the ability to edit recipients if:
  // 1. Editing and there are no categories, meaning we don't allow being able to edit individual recipients (for now)
  //    OR if categories exist and includes the All Followers category
  // 2. On edit summary screen and not currently editing (ie. after sending an immediate mass text)
  const disableRecipients = useMemo(
    () =>
      (isEditing &&
        (massText.subSelectionIds.length === 0 ||
          massText.subSelectionIds.some(
            (category) => category.type === SubSelectionType.ALL_FOLLOWERS
          ))) ||
      (screen === MassTextMessagingScreenType.SEND_SUMMARY && !isEditing),
    [isEditing, massText.subSelectionIds, screen]
  );

  /**
   * Either display:
   * 1. The suggested action item label if found
   * 2. Custom Selection if there are more than one category or if it's a form category
   * 3. The category name if there is only one category
   * 4. No Categories fall through case
   */
  const categoryLabel = useMemo(() => {
    const tickets = event ? event.customTickets : [];
    return suggestedAction
      ? getCategoryLabelName(suggestedAction, tickets, savedQuestions)
      : massText.subSelectionIds.length > 0
      ? massText.subSelectionIds.length > 1 ||
        (massText.subSelectionIds.length === 1 &&
          massText.subSelectionIds[0].type === SubSelectionType.FORM_QUESTION)
        ? "Custom Selection"
        : getCategoryLabelName(
            massText.subSelectionIds[0],
            tickets,
            savedQuestions
          )
      : "No Categories";
  }, [event, savedQuestions, massText.subSelectionIds, suggestedAction]);

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        label: (
          <DropdownMenuLabel
            label="Keep Suggested"
            icon="ion:checkmark-circle"
          />
        ),
        key: "keep_suggested",
      },
      {
        label: (
          <DropdownMenuLabel
            label="Custom Selection"
            icon="ion:chevron-right"
          />
        ),
        key: "custom_selection",
      },
    ],
    []
  );

  const onClick: MenuProps["onClick"] = ({ key }) => {
    switch (key) {
      case "keep_suggested":
        return;
      case "custom_selection":
        setMainScreen(MassTextScreenType.SELECT_RECIPIENTS);
        return;
    }
  };

  const renderRecipientsRow = useMemo(
    () => (
      <div
        className="ColumnNormalSelect"
        onClick={() =>
          !suggestedAction
            ? setMainScreen(MassTextScreenType.SELECT_RECIPIENTS)
            : undefined
        }
        style={{
          pointerEvents: disableRecipients ? "none" : "all",
          gap: 14,
        }}
      >
        <div className="AlignedRowSpacedSelect">
          <div className="ColumnNormalSelect" style={{ gap: 3 }}>
            <div className="AlignedRowSelect">
              <span style={styles.selectRecipientTitlePrompt}>To</span>
              <span style={styles.selectRecipientTitle}>
                {suggestedAction ? (
                  <span style={{ color: Colors.BLUE5 }}>
                    {categoryLabel} (Suggested)
                  </span>
                ) : massText.subSelectionIds.length > 0 ? (
                  <span style={{ color: Colors.BLUE5 }}>{categoryLabel}</span>
                ) : netRecipients > 0 ? (
                  <span
                    style={{
                      color: disableRecipients ? Colors.BLACK : Colors.BLUE5,
                    }}
                  >
                    {`${netRecipients} Recipient${
                      netRecipients !== 1 ? "s" : ""
                    }`}
                  </span>
                ) : event &&
                  screen === MassTextMessagingScreenType.SEND_SUMMARY &&
                  LessThanDate(
                    event.createdAt,
                    new Date("2024-04-16T00:00:00-04:00").toISOString()
                  ) ? (
                  <span style={{ color: Colors.BLUE5 }}>All Attendees</span> // backsupport case for any event text created before custom selection went live. Will just send to all attendees (handled in backend)
                ) : (
                  <span>Select Recipients</span>
                )}
              </span>
            </div>
            {(massText.subSelectionIds.length > 0 || suggestedAction) &&
            !massText.scheduled &&
            netRecipients > 0 ? (
              <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
                {netRecipients} Recipient
                {netRecipients !== 1 ? "s" : ""}
              </span>
            ) : null}
          </div>
          <Icon
            icon="ion:chevron-right"
            height={16}
            color={disableRecipients ? "transparent" : Colors.GRAY2}
          />
        </div>
        <HorizontalDivider altMargin={0} />
      </div>
    ),
    [
      massText.subSelectionIds.length,
      disableRecipients,
      styles.selectRecipientTitlePrompt,
      styles.selectRecipientTitle,
      suggestedAction,
      categoryLabel,
      netRecipients,
      event,
      screen,
      massText.scheduled,
      setMainScreen,
    ]
  );

  return (
    <div className="ColumnNormal">
      {suggestedAction ? (
        <Dropdown
          menu={{ items, onClick }}
          trigger={["click"]}
          placement="bottom"
          overlayStyle={{ paddingTop: 7, paddingInline: 120 }}
        >
          {renderRecipientsRow}
        </Dropdown>
      ) : (
        renderRecipientsRow
      )}
      <div
        onClick={() => setScreen(MassTextMessagingScreenType.SEND_SCHEDULE)}
        className="ColumnNormalSelect"
        style={{ gap: 14 }}
      >
        <div className="AlignedRowSpacedSelect" style={{ paddingTop: 14 }}>
          <div className="ColumnNormalSelect" style={{ gap: 3 }}>
            <div className="AlignedRowSelect">
              <span style={styles.selectRecipientTitlePrompt}>When</span>
              <span style={styles.selectRecipientTitle}>
                {massText.scheduled
                  ? `${GetTime(textSendTime)}, ${formatDate(
                      textSendTime,
                      true
                    )}`
                  : "Sending Now"}
              </span>
            </div>
            {massText.catchUpEnabled ? (
              <div className="AlignedRowSelect" style={{ gap: 5 }}>
                <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
                  Catchup sending enabled
                </span>
                <Icon
                  icon="ion:play-forward"
                  height={14}
                  color={Colors.GRAY2}
                />
              </div>
            ) : null}
          </div>
          {screen === MassTextMessagingScreenType.SUMMARY ||
          (isEditing && screen === MassTextMessagingScreenType.SEND_SUMMARY) ? (
            <Icon icon="ion:chevron-right" height={16} color={Colors.GRAY2} />
          ) : null}
        </div>
        <HorizontalDivider altMargin={0} />
      </div>
    </div>
  );
};

export default MassTextsSendDetails;
