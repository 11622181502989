import "../../css/GlobalStyles.css";
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Event,
  AccountData,
  SavedFormQuestion,
  VerificationState,
  FormAnswersV2,
  FollowSourceType,
} from "@markit/common.types";
import { useTheme } from "../../hooks/useTheme";
import { NameAndPhoneNumberInput } from "../../components/UserInfoContainers/NameAndPhoneNumberInput";
import { FormQuestionsInput } from "../../components/UserInfoContainers/FormQuestionsInput";
import {
  createEmptyFormAnswersV2,
  getTimezone,
  isEventExternalLink,
  isExternalGenericLink,
  isMultipleDays,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import TopHeader from "../../components/TopHeader";
import { GetLongDate } from "../../utils/GetLongDate";
import { GetTime } from "../../utils/GetTime";
import { Colors } from "../../utils/colors";
import Phone from "../../utils/Phone";
import { API } from "../../API";
import { isDesktop } from "react-device-detect";
import { MixpanelContext } from "../../context/AnalyticsService";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { getUserTicket } from "../../utils/eventUtils/userTicketUtils";
import {
  getUsersFormResponses,
  validateEventForm,
} from "../../utils/eventUtils/formUtils";
import MarkitHeaderLogo from "../../components/MarkitHeaderLogo";

type FullEventExternalProps = {
  event: Event;
  host: AccountData;
  address: string;
  eventFormQuestions: SavedFormQuestion[];
};

const FullEventExternal = memo(function FullEventExternalFn(
  props: FullEventExternalProps
) {
  const { event, host, address, eventFormQuestions } = props;
  const { theme } = useTheme();
  const { accountData, loggedIn } = useSelector(getAccountState).account;
  const mixpanel = useContext(MixpanelContext);

  const [verificationState, setVerificationState] = useState(
    VerificationState.UNVERIFIED
  );
  const [tempPhoneNumber, setTempPhoneNumber] = useState("");
  const [tempFullName, setTempFullName] = useState("");
  const [guestUid, setGuestUid] = useState("");
  const [processing, setProcessing] = useState(false);
  const [answers, setAnswers] = useState<FormAnswersV2>(
    createEmptyFormAnswersV2(eventFormQuestions, [])
  );
  const [formErrorIds, setFormErrorIds] = useState<string[]>([]);

  const styles = {
    subtext: {
      fontSize: 14,
      color: Colors.GRAY1,
    },
  };

  const isEmailBasedAccount = useMemo(
    () => accountData.email !== "",
    [accountData.email]
  );

  const isDisabledInputSection = useMemo(
    () =>
      verificationState !== VerificationState.VERIFIED ||
      processing ||
      isEmailBasedAccount,
    [isEmailBasedAccount, processing, verificationState]
  );

  // NOTE: For the useEffects, these are the same as in FullEventInputForm. Can consider making a hook to put the logic in there and call in both files

  // set existing user and follower data if you're logged in
  useEffect(() => {
    (async () => {
      if (loggedIn === LoginState.LOGGED_IN) {
        setTempFullName(accountData.fullName);
        setGuestUid(accountData.uid);
        setVerificationState(VerificationState.VERIFIED);
        if (accountData.phoneNumber !== "") {
          setTempPhoneNumber(accountData.phoneNumber);
        }
        const userFormResponses = await getUsersFormResponses(
          event.createdBy,
          event.formQuestions,
          accountData.uid
        );
        setAnswers(
          createEmptyFormAnswersV2(eventFormQuestions, userFormResponses)
        );
      }
    })();
  }, [
    accountData.fullName,
    accountData.phoneNumber,
    accountData.uid,
    event,
    eventFormQuestions,
    loggedIn,
  ]);

  useEffect(() => {
    (async () => {
      // Autofill any form inputs if user is not logged in and has verified their number
      // The logged in case is handled by the useEffect above
      if (
        loggedIn !== LoginState.LOGGED_IN &&
        verificationState === VerificationState.VERIFIED
      ) {
        const userFormResponses = await getUsersFormResponses(
          event.createdBy,
          event.formQuestions,
          guestUid
        );
        setAnswers(
          createEmptyFormAnswersV2(eventFormQuestions, userFormResponses)
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationState]);

  const redirectToExternalLink = useCallback(
    (alreadySubmitted: boolean) => {
      // Redirect to external event link
      setTimeout(() => {
        window.location.href = event.externalLink;
        if (mixpanel) {
          mixpanel.track("Webapp: Redirected To External Event Link", {
            event_id: event.id,
            external_link: event.externalLink,
            event_type: event.eventType,
            phone_number: tempPhoneNumber,
            already_submitted: alreadySubmitted,
          });
        }
      }, 200);
    },
    [event.eventType, event.externalLink, event.id, mixpanel, tempPhoneNumber]
  );

  const rsvpToExternalEvent = useCallback(async () => {
    // prevent host from getting ticket
    if (event.createdBy === guestUid) {
      alert("You are the host of this event and cannot get a ticket.");
      return;
    }
    setProcessing(true);

    // Check if the user had already submitted for this external event, and if so, redirect immediately
    const foundTicket = await getUserTicket(event.id, guestUid);
    if (foundTicket) {
      redirectToExternalLink(true);
      return;
    }

    // Check if required form questions are answered
    const errorIds = validateEventForm(answers, eventFormQuestions);
    if (errorIds.length > 0) {
      setFormErrorIds(errorIds);
      alert("Please answer the required questions.");
      setProcessing(false);
      return;
    }

    if (mixpanel) {
      mixpanel.track("Webapp: Attempting RSVP To Event", {
        event_id: event.id,
        customer_phone: tempPhoneNumber,
        form_responses: answers,
        event_type: event.eventType,
      });
    }

    if (mixpanel) {
      mixpanel.track("Webapp: Calling Enqueue RSVP To Event", {
        event_id: event.id,
        customer_phone: tempPhoneNumber,
        form_responses: answers,
        event_type: event.eventType,
      });
    }

    await API.rsvp
      .enqueueRSVPToEvent({
        toPhoneNumber: Phone.fromInternationalCode(tempPhoneNumber),
        fullName: tempFullName,
        eventId: event.id,
        followSourceType: isDesktop
          ? FollowSourceType.DESKTOP_WEB
          : FollowSourceType.MOBILE_WEB,
        answers: eventFormQuestions.length !== 0 ? answers : undefined,
        customTicketId: event.customTickets[0].id,
        numberOfTickets: 1,
        redeemTicketId: "",
        promoCodeId: "",
        promoterId: "",
        linkId: "",
        userReferrer: "",
        amount: 0,
        chargeId: "",
        customerId: "",
        uid: "",
      })
      .then(async (response) => {
        const { success } = response;
        if (!success) {
          if (mixpanel) {
            mixpanel.track("Webapp: Failed RSVP To Event", {
              event_id: event.id,
              customer_phone: tempPhoneNumber,
              error_message: "success returned false",
              form_responses: answers,
              event_type: event.eventType,
            });
          }
        }
      })
      .catch((e: any) => {
        if (mixpanel) {
          mixpanel.track("Webapp: Failed RSVP To Event", {
            event_id: event.id,
            customer_phone: tempPhoneNumber,
            error_message: e.message,
            form_responses: answers,
            event_type: event.eventType,
          });
        }
      })
      .finally(() => {
        redirectToExternalLink(false);
      });
  }, [
    event.id,
    event.createdBy,
    event.customTickets,
    event.eventType,
    guestUid,
    answers,
    eventFormQuestions,
    mixpanel,
    tempPhoneNumber,
    tempFullName,
    redirectToExternalLink,
  ]);

  return (
    <div
      className="ColumnNormal"
      style={{ ...theme.GradientBG, minHeight: "100vh" }}
    >
      <TopHeader />
      <div
        style={{
          paddingInline: 14,
          alignSelf: "center",
          maxWidth: isDesktop ? 362 : undefined,
        }}
      >
        <div
          className="AlignedColumn"
          style={{
            padding: 24,
            gap: isDesktop ? 30 : 14,
            paddingInline: 20,
          }}
        >
          <img
            alt="ExternalEventPhoto"
            src={event.photoURL}
            width={isDesktop ? 150 : 100}
            height={isDesktop ? 150 : 100}
            style={{ borderRadius: 10 }}
          />
          <div className="AlignedColumn" style={{ gap: isDesktop ? 14 : 7 }}>
            <span
              style={{
                paddingInline: 20,
                fontSize: 20,
                fontWeight: "500",
                textAlign: "center",
                ...theme.PrimaryText,
              }}
            >
              {event.title}
            </span>
            {!isExternalGenericLink(event.eventType) ? (
              <div className="AlignedColumn" style={{ gap: isDesktop ? 7 : 4 }}>
                <span style={{ ...styles.subtext, textAlign: "center" }}>
                  {GetLongDate(event.start, true, true, true, false)},{" "}
                  {GetTime(event.start)} to{" "}
                  {isMultipleDays(event.start, event.end) &&
                    GetLongDate(event.end, false, false, false, false)}{" "}
                  {GetTime(event.end)} {getTimezone()}
                </span>
                <span style={{ ...styles.subtext, textAlign: "center" }}>
                  {event.isVirtual ? "Virtual Location" : address}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className="ColumnNormal">
          <NameAndPhoneNumberInput
            host={host}
            verificationState={verificationState}
            setVerificationState={setVerificationState}
            tempFullName={tempFullName}
            setTempFullName={setTempFullName}
            tempPhoneNumber={tempPhoneNumber}
            setTempPhoneNumber={setTempPhoneNumber}
            setUid={setGuestUid}
            processing={processing}
            event={event}
            callbackOnVerify={
              event &&
              isEventExternalLink(event.eventType) &&
              eventFormQuestions.length === 0
                ? rsvpToExternalEvent
                : undefined
            }
          />
        </div>
        {eventFormQuestions.length > 0 ? (
          <div
            className={
              isDesktop
                ? "HostQuestionsContainerDesktop"
                : "HostQuestionsContainer"
            }
            style={theme.SecondaryBG}
          >
            <FormQuestionsInput
              formQuestions={eventFormQuestions}
              answers={answers}
              setAnswers={setAnswers}
              formErrorIds={formErrorIds}
              setFormErrorIds={setFormErrorIds}
              disabled={isDisabledInputSection}
            />
            <RectangleButton
              buttonLabel={<span>Continue</span>}
              onPress={rsvpToExternalEvent}
              theme={theme}
              altPaddingVert={12}
              loading={processing}
              disabled={isDisabledInputSection || processing}
              containerStyles={{ marginTop: 20 }}
            />
          </div>
        ) : null}
        <div className="Centering" style={{ paddingBlock: 70 }}>
          <MarkitHeaderLogo theme={theme} fullLogo disabled />
        </div>
      </div>
    </div>
  );
});

export default FullEventExternal;
