import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountState,
  updateAccountData,
} from "../../../../redux/slices/accountSlice";
import { useNavigate } from "react-router-dom";
import ConfirmActionPopup, {
  BinaryConfirmActions,
} from "../../../Containers/ConfirmPopups/ConfirmActionPopup";
import {
  AccountData,
  IntegrationPhoneContactState,
} from "@markit/common.types";
import { useOnMount } from "../../../../utils/useOnMount";
import { fetchNumEventbriteFollowersQuery } from "../../../../utils/userUtils";
import { API } from "../../../../API";
import ConfirmActionModal from "../../../Containers/ConfirmPopups/ConfirmActionModal";
import AlertContainer from "../../../Containers/AlertContainer";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import RectangleButton from "../../../Buttons/RectangleButton";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import { GetTime } from "@markit/common.utils";
import { GetNumericDate } from "../../../../utils/GetTime";
import { CircularProgress } from "@mui/material";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import EventbriteIcon from "../../../IntegrationIcons/EventbriteIcon";
import { onSnapshot } from "../../../../firebase";

enum EventbriteConfirmType {
  SYNC = "sync",
  DISCONNECT = "disconnect",
  NONE = "none",
}

type EventbritePanelSectionProps = {};

export const EventbritePanelSection = (props: EventbritePanelSectionProps) => {
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [numEventbriteContacts, setNumEventbriteContacts] = useState(0);
  const [syncAlert, setSyncAlert] = useState(false);
  const [syncConfirmAlert, setSyncConfirmAlert] =
    useState<BinaryConfirmActions>(BinaryConfirmActions.NONE);
  const [loadingSync, setLoadingSync] = useState(false);
  const [confirmModal, setConfirmModal] = useState<EventbriteConfirmType>(
    EventbriteConfirmType.NONE
  );

  const styles = {
    overallContainerView: {
      flex: 1,
      width: "100%",
      borderRadius: 20,
      height: 225,
    },
  };

  const eventbriteDescription =
    "Seamlessly connect all your attendees and contacts to sync with your Markit contacts and start texting.";

  useOnMount(() => {
    (async () => {
      // Get a snapshot of the total eventbrite contacts
      const eventbriteContactsQuery = await fetchNumEventbriteFollowersQuery(
        accountData.uid
      );
      const unsubscribe = onSnapshot(eventbriteContactsQuery, (snapshot) => {
        setNumEventbriteContacts(snapshot.size);
      });
      return () => unsubscribe();
    })();
  });

  const integratedEventbrite = useMemo(
    () => accountData.eventbriteInfo.syncedOn !== "",
    [accountData.eventbriteInfo.syncedOn]
  );

  const eventbriteSyncedDate = useMemo(
    () =>
      accountData.eventbriteInfo.syncedOn
        ? new Date(accountData.eventbriteInfo.syncedOn)
        : undefined,
    [accountData.eventbriteInfo.syncedOn]
  );

  const connectEventbriteOnPress = useCallback(async () => {
    const response = await API.eventScraper.authorizeEventbriteUser({
      userId: accountData.uid,
    });
    if (response) {
      window.open(response.url);
    }
  }, [accountData.uid]);

  const syncEventbriteOnPress = useCallback(async () => {
    const lastSyncDate = new Date(accountData.eventbriteInfo.syncedOn);
    const diffInMilliseconds = Math.abs(
      new Date().getTime() - lastSyncDate.getTime()
    );
    const oneHourInMilliseconds = 3600000;
    const lastSyncWithinHour = diffInMilliseconds <= oneHourInMilliseconds;
    if (lastSyncWithinHour) {
      setSyncAlert(true);
      return;
    }
    setLoadingSync(true);
    const { phoneContacts, success } =
      await API.eventScraper.fetchEventbritePhoneContacts({
        eventbriteInfo: accountData.eventbriteInfo,
        userId: accountData.uid,
        isSyncing: true,
      });
    if (!success) {
      setConfirmModal(EventbriteConfirmType.SYNC);
      return;
    }
    const newValidContacts = phoneContacts.filter(
      (contact) => contact.state === IntegrationPhoneContactState.NEW
    );
    await API.eventScraper
      .createNewPhoneFollowers({
        userId: accountData.uid,
        phoneContacts: newValidContacts,
      })
      .then((response) => {
        setLoadingSync(false);
        setSyncConfirmAlert(
          response.success
            ? BinaryConfirmActions.AFFIRMATIVE
            : BinaryConfirmActions.NEGATIVE
        );
      });
  }, [accountData.eventbriteInfo, accountData.uid]);

  const disconnectEventbriteOnPress = useCallback(() => {
    const newAccountData: AccountData = {
      ...accountData,
      eventbriteInfo: {
        token: "",
        userId: "",
        connectedOn: "",
        syncedOn: "",
      },
    };
    dispatch(updateAccountData(newAccountData));
  }, [accountData, dispatch]);

  const activateEventbriteOnPress = useCallback(() => {
    navigate("/integrations");
  }, [navigate]);

  return (
    <>
      {accountData.eventbriteInfo.token ? (
        <StandardBorderedContainer
          containerStyles={{
            ...styles.overallContainerView,
            paddingBlock: 14,
            backgroundColor: Colors.WHITE,
          }}
        >
          <div className="ColumnNormal" style={{ height: "100%" }}>
            <div className="AlignedRow" style={{ gap: 14, paddingInline: 14 }}>
              <EventbriteIcon size={34} padding={10} />
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <div className="AlignedRow" style={{ gap: 10 }}>
                  <span className="sectionTitle">Eventbrite</span>
                  <span
                    className="bodySubtext"
                    style={{
                      color: integratedEventbrite ? Colors.GRAY1 : Colors.RED3,
                    }}
                  >
                    {integratedEventbrite ? "Connected" : "Inactive"}
                  </span>
                </div>
                <span className="smallBodySubtext" style={{ width: "80%" }}>
                  {eventbriteDescription}
                </span>
              </div>
            </div>
            <HorizontalDivider altMargin={14} />
            <div
              className="ColumnNormal"
              style={{
                paddingInline: 14,
                flexGrow: 1,
                justifyContent: "space-between",
              }}
            >
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span className="smallBodySubtext">
                  Total Valid Synced Contacts: {numEventbriteContacts}
                </span>
                <span className="smallBodySubtext">
                  Connected On:{" "}
                  {GetNumericDate(accountData.eventbriteInfo.connectedOn)}
                </span>
              </div>
              <div
                className="AlignedRow"
                style={{ alignContent: "flex-end", gap: 14 }}
              >
                <div className="AlignedRow" style={{ gap: 10 }}>
                  <RectangleButton
                    buttonLabel={
                      <span style={{ fontSize: 14, fontWeight: 400 }}>
                        Disconnect
                      </span>
                    }
                    onPress={() =>
                      setConfirmModal(EventbriteConfirmType.DISCONNECT)
                    }
                    altColor={Colors.WHITE1}
                    altTextColor={Colors.RED3}
                    altPaddingHorz={14}
                    altPaddingVert={10}
                    altBorderRadius={8}
                    width={106}
                    containerStyles={{ border: "0.5px solid #EDEDED" }}
                  />
                  {!integratedEventbrite ? (
                    <div>
                      <RectangleButton
                        buttonLabel={
                          <span style={{ fontSize: 14, fontWeight: 400 }}>
                            Activate Integration
                          </span>
                        }
                        onPress={activateEventbriteOnPress}
                        altColor={Colors.WHITE1}
                        altTextColor={Colors.BLACK}
                        altPaddingHorz={14}
                        altPaddingVert={10}
                        altBorderRadius={8}
                        containerStyles={{ border: "0.5px solid #EDEDED" }}
                      />
                    </div>
                  ) : (
                    <div
                      className="AlignedRowSelect"
                      style={{ paddingInline: 9, paddingBlock: 10, gap: 10 }}
                      onClick={syncEventbriteOnPress}
                    >
                      {loadingSync ? (
                        <CircularProgress
                          style={{
                            color: Colors.BLACK,
                            alignSelf: "center",
                            paddingInline: 2,
                          }}
                          size={20}
                        />
                      ) : (
                        <Icon icon="ion:sync-sharp" height={24} />
                      )}
                      <div className="ColumnNormalSelect">
                        <span style={{ fontSize: 12, fontWeight: 500 }}>
                          Refresh Data
                        </span>
                        <span style={{ fontSize: 11, color: Colors.GRAY1 }}>
                          Last Synced:{" "}
                          {GetNumericDate(accountData.eventbriteInfo.syncedOn)}{" "}
                          {eventbriteSyncedDate
                            ? GetTime(eventbriteSyncedDate)
                            : null}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </StandardBorderedContainer>
      ) : (
        <div
          className="AlignedRow"
          style={{
            ...styles.overallContainerView,
            backgroundColor: Colors.WHITE,
            paddingInline: 24,
            paddingBlock: 14,
            gap: 24,
            border: `1.5px solid ${Colors.GRAY11}`,
          }}
        >
          <div className="ColumnNormal" style={{ gap: 24 }}>
            <div className="ColumnNormal" style={{ gap: 14 }}>
              <span style={{ fontSize: 20, fontWeight: 500 }}>
                Eventbrite Integration
              </span>
              <span className="bodySubtext">{eventbriteDescription}</span>
              <span className="bodyMedium">
                Before pressing connect, make sure you{" "}
                <span
                  onClick={connectEventbriteOnPress}
                  style={{
                    cursor: "pointer",
                    color: Colors.BLUE5,
                    textDecorationLine: "underline",
                  }}
                >
                  login
                </span>{" "}
                to your Eventbrite account first.
              </span>
            </div>
            <RectangleButton
              buttonLabel="Connect"
              onPress={connectEventbriteOnPress}
              altColor={Colors.BLACK}
              altTextColor={Colors.WHITE}
              altPaddingHorz={24}
              altPaddingVert={14}
              width={106}
            />
          </div>
          <div
            className="Centering"
            style={{
              borderRadius: 20,
              padding: 24,
              backgroundColor: "#FA6345",
            }}
          >
            <Icon
              icon="simple-icons:eventbrite"
              height={84}
              color={Colors.WHITE}
            />
          </div>
        </div>
      )}
      <ConfirmActionModal
        heading={
          confirmModal === EventbriteConfirmType.SYNC
            ? "Unable to re-sync with Eventbrite at this time."
            : "Are you sure you want to disconnect your Eventbrite integration with Markit?"
        }
        subtext={
          confirmModal === EventbriteConfirmType.SYNC
            ? "We weren't able to sync your latest Eventbrite data. Please try again later or contact support."
            : "This cannot be undone."
        }
        confirmButtonText={
          confirmModal === EventbriteConfirmType.SYNC
            ? "Try Again"
            : "Disconnect"
        }
        icon={
          confirmModal === EventbriteConfirmType.SYNC ? (
            <Icon icon="fa-solid:plug" height={35} />
          ) : undefined
        }
        hideModal={confirmModal === EventbriteConfirmType.NONE}
        setIsVisible={() => setConfirmModal(EventbriteConfirmType.NONE)}
        confirmOnPress={
          confirmModal === EventbriteConfirmType.SYNC
            ? syncEventbriteOnPress
            : disconnectEventbriteOnPress
        }
      />
      {syncConfirmAlert !== BinaryConfirmActions.NONE ? (
        <ConfirmActionPopup
          title={"Synced!"}
          negativeTitle={"Failed Sync"}
          isNegative={syncConfirmAlert === BinaryConfirmActions.NEGATIVE}
          onDisappear={() => setSyncConfirmAlert(BinaryConfirmActions.NONE)}
        />
      ) : null}
      <AlertContainer
        headerComp={
          "You have last synced your Eventbrite data within the last hour."
        }
        subHeaderComp={
          "Please wait until an hour later than your latest sync to try again."
        }
        closeModal={() => setSyncAlert(false)}
        hideModal={!syncAlert}
      />
    </>
  );
};
