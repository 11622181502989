import {
  AccountData,
  AudienceList,
  AudienceListVisibility,
  Event,
  SavedFormResponse,
  SavedFormQuestion,
  Follower,
  FollowType,
} from "@markit/common.types";
import {
  getEventData,
  getUserAudienceListMembersRef,
  getUserAudienceListMembersSnap,
  getUserAudienceListsRef,
  getUserAudienceListsSnap,
  getUserData,
  getUserEventsRef,
  getUserFollowersRef,
  getUserFollowersSnap,
  getUserFollowingsRef,
  getUserSavedFormQuestionsRef,
  getUserWishlistRef,
  getUsersRef,
} from "./FirebaseUtils";
import {
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "../firebase";
import {
  FAVORITES_LIST_NAME,
  deepCopy,
  filterUndefinedValues,
  isEventExternalLink,
} from "@markit/common.utils";
import { userCurrentNumberOfTickets } from "./eventUtils/userTicketUtils";
import { API } from "../API";
import { RecentEventAnalytics } from "../components/FollowerProfile/FollowerProfileHeader";
import { Query } from "firebase/firestore";

// fetches the user's public audience lists
export const fetchUserPublicAudienceLists = async (
  userId: string
): Promise<AudienceList[]> => {
  const audienceListsRef = getUserAudienceListsRef(userId);
  const audienceListsQuery = query(
    audienceListsRef,
    where("visibility", "==", AudienceListVisibility.PUBLIC)
  );
  const audienceListsSnapshot = await getDocs(audienceListsQuery);
  const audienceLists = await Promise.all(
    audienceListsSnapshot.docs.map((snapshot) => {
      const audienceListData = snapshot.data();
      return audienceListData;
    })
  );
  return audienceLists;
};

export const fetchUserAudienceListMembers = async (
  userId: string,
  audienceListId: string
): Promise<string[]> => {
  const audienceListMembersSnap = await getUserAudienceListMembersSnap(
    userId,
    audienceListId
  );
  const audienceListMembersId: string[] = await Promise.all(
    audienceListMembersSnap.docs.map((snapshot) => {
      const audienceListDataId = snapshot.data().uid;
      return audienceListDataId;
    })
  );
  return audienceListMembersId;
};

// checks if a user exists in an audience list
export const checkAudienceListMembership = async (
  userId: string,
  audienceListId: string,
  creatorId: string
): Promise<boolean> => {
  if (audienceListId === "") {
    return false;
  }
  const audienceListRef = getUserAudienceListMembersRef(
    creatorId,
    audienceListId
  );
  const query_ = query(audienceListRef, where("uid", "==", userId));
  const querySnapshot = await getDocs(query_);
  if (querySnapshot.docs.length === 0) {
    return false;
  }
  return true;
};

// Check to see if the user has a favorites list
// This can be removed once we create favorite list on account creation
export const checkUserFavoritesListExists = async (userId: string) => {
  const audienceListsRef = getUserAudienceListsRef(userId);
  const query_ = query(
    audienceListsRef,
    where("name", "==", FAVORITES_LIST_NAME),
    limit(1)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count > 0;
};

// Sorts the audience lists so that the favorites list is shown on the top
export const sortAudienceListsDisplay = (audienceLists: AudienceList[]) => {
  const foundIndex = audienceLists.findIndex(
    (list) => list.name === FAVORITES_LIST_NAME
  );
  if (foundIndex !== -1) {
    const audienceListsCopy = deepCopy(audienceLists);
    const [element] = audienceListsCopy.splice(foundIndex, 1);
    audienceListsCopy.unshift(element);
    return audienceListsCopy;
  }
  return audienceLists;
};

export const getFollowingAccountData = async (
  uid: string
): Promise<AccountData[]> => {
  const followersSnap = await getUserFollowersSnap(uid);
  const followers = await Promise.all(
    followersSnap.docs.map(async (doc) => {
      const followingData = await getUserData(uid);
      if (followingData) {
        return followingData;
      }
    })
  );
  const definedFollowers = filterUndefinedValues(followers);

  return definedFollowers;
};

export const checkIsUserFollowing = async (
  myUserId: string,
  otherUserId: string
) => {
  const followingsRef = getUserFollowingsRef(myUserId);
  const followingsQuery = query(followingsRef, where("uid", "==", otherUserId));
  const followingExists = !(await getDocs(followingsQuery)).empty;
  return followingExists;
};

export const fetchUserFollowerData = async (
  creatorUid: string,
  userId: string
): Promise<{ follower: Follower | undefined; event: Event | undefined }> => {
  const followersRef = getUserFollowersRef(creatorUid);
  const followersQuery = query(
    followersRef,
    where("uid", "==", userId),
    limit(1)
  );
  const snapshot = await getDocs(followersQuery);
  if (snapshot.empty) {
    console.log("No follower user found: " + userId);
    return { follower: undefined, event: undefined };
  }
  const follower = snapshot.docs.map((doc) => doc.data())[0];

  if (follower.eventId !== "") {
    const eventData = await getEventData(follower.eventId);
    return { follower: follower, event: eventData };
  } else {
    return { follower: follower, event: undefined };
  }
};

export const fetchMostRecentUserAttendedEvents = async (
  creatorUid: string,
  userId: string,
  numTicketsToDisplay?: number
) => {
  const userWishlistRef = getUserWishlistRef(userId);
  const userWishlistQuery = query(
    userWishlistRef,
    orderBy("createdAt", "desc")
  );
  const snapshot = await getDocs(userWishlistQuery);
  const eventAnalytics: ({ event: Event; numTickets: number } | undefined)[] =
    await Promise.all(
      snapshot.docs.map(async (doc) => {
        const wishMark = doc.data();
        if (!wishMark.eventId) {
          return undefined;
        }
        const eventsRef = getUserEventsRef(creatorUid);
        const eventsQuery = query(
          eventsRef,
          where("id", "==", wishMark.eventId),
          limit(1)
        );
        const eventSnapshot = await getDocs(eventsQuery);
        if (eventSnapshot.empty) {
          return undefined;
        }
        const event = eventSnapshot.docs.map((doc) => doc.data())[0];
        // return undefined if a link instead of event
        if (isEventExternalLink(event.eventType)) {
          return undefined;
        }
        const numTickets = await userCurrentNumberOfTickets(
          wishMark.eventId,
          userId
        );
        return { event: event, numTickets: numTickets };
      })
    );
  const definedEventAnalytics: RecentEventAnalytics[] =
    filterUndefinedValues(eventAnalytics);
  const sortedDefinedEventAnalytics = definedEventAnalytics.sort((x, y) => {
    return new Date(y.event.end).getTime() - new Date(x.event.end).getTime();
  });
  return numTicketsToDisplay
    ? sortedDefinedEventAnalytics.slice(0, numTicketsToDisplay)
    : sortedDefinedEventAnalytics;
};

export const fetchUserJoinedAudienceLists = async (
  creatorUid: string,
  userId: string,
  numListsToDisplay?: number
) => {
  const creatorAudienceListsSnap = await getUserAudienceListsSnap(creatorUid);
  const audienceLists = await Promise.all(
    creatorAudienceListsSnap.docs.map(async (doc) => {
      const audienceList = doc.data();
      const membersRef = getUserAudienceListMembersRef(
        creatorUid,
        audienceList.id
      );
      const membersQuery = query(membersRef, where("uid", "==", userId));
      const snapshot = await getDocs(membersQuery);
      if (!snapshot.empty) {
        return audienceList;
      }
      return undefined;
    })
  );
  const definedAudienceLists: AudienceList[] =
    filterUndefinedValues(audienceLists);

  return numListsToDisplay
    ? definedAudienceLists.slice(0, numListsToDisplay)
    : definedAudienceLists;
};

// fetch all of a user's followers and return the user data of all of them
// This is used alternatively to the redux function in accountSlice because this function
// returns the array of AccountData as opposed to simply storing into redux
export const fetchAllUserFollowersData = async (
  uid: string
): Promise<AccountData[]> => {
  const response = await API.user.followerUsers({ uid });
  if (response) {
    return response.followingAccounts;
  }
  return [];
};

// Gets the live count of followers of a user
export const fetchLiveUserNumFollowers = async (
  userId: string,
  setNumFollowers: (numFollowers: number) => void
) => {
  const followersRef = getUserFollowersRef(userId);
  const unsub = onSnapshot(followersRef, (snapshot) => {
    const documentCount = snapshot.size;
    setNumFollowers(documentCount);
  });
  return unsub;
};

// Fetches for the specified user's essential question
export const fetchUserEssentialQuestion = async (
  uid: string
): Promise<SavedFormQuestion | undefined> => {
  const savedFormQuestionsRef = getUserSavedFormQuestionsRef(uid);

  const essentialQuestionQuery = query(
    savedFormQuestionsRef,
    where("isEssential", "==", true)
  );

  const essentialQuestionSnap = await getDocs(essentialQuestionQuery);
  if (essentialQuestionSnap.empty) {
    return undefined;
  }
  return essentialQuestionSnap.docs.map((question) => question.data())[0];
};

// Fetches for the specified user's essential form response
export const fetchUserEssentialFormResponse = async (
  userId: string,
  followerId: string,
  essentialQuestionId: string
): Promise<SavedFormResponse> => {
  const { formResponse } = await API.user.fetchUserEssentialFormResponse({
    uid: userId,
    followerId: followerId,
    essentialFormQuestionId: essentialQuestionId,
  });
  return formResponse;
};

// Fetches for the specified user's email form response, if applicable
export const fetchUserEmailFormResponse = async (
  userId: string,
  followerId: string
): Promise<SavedFormResponse> => {
  const { formResponse } = await API.user.fetchUserEmailFormResponse({
    uid: userId,
    followerId: followerId,
  });
  return formResponse;
};

// Fetches for the specified user's essential form response
export const fetchMultipleUserFormResponses = async (
  userId: string,
  followerId: string,
  questionIds: string[]
): Promise<(SavedFormResponse | undefined)[]> => {
  const { formResponses } = await API.user.fetchMultipleUserFormResponses({
    uid: userId,
    followerId: followerId,
    questionIds: questionIds,
  });
  return formResponses;
};

// Fetches the user's followers only from the specified event
export const fetchNumFollowersFromEvent = async (
  userId: string,
  eventId: string
) => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(followersRef, where("eventId", "==", eventId));
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

// Fetches the number of selected recipients with complianceMessageSent as false
export const fetchNumFollowersNoComplianceSent = async (
  userId: string,
  recipients: string[]
) => {
  const followersSnap = await getUserFollowersSnap(userId);
  const numFollowersNotCompliant = followersSnap.docs.reduce(
    (count, snapshot) => {
      const follower = snapshot.data();
      if (
        recipients.includes(follower.uid) &&
        !follower.complianceMessageSent
      ) {
        return count + 1;
      }
      return count;
    },
    0
  );
  return numFollowersNotCompliant;
};

// Checks if a specified phoneNumber is following a designated user (is a part of userToFollow's followers collection)
export const checkIfPhoneUserFollower = async (
  phoneNumber: string,
  userToFollowUid: string
): Promise<string | undefined> => {
  const usersRef = getUsersRef();
  const usersQuery = query(
    usersRef,
    where("phoneNumber", "==", phoneNumber),
    limit(1)
  );
  const snapshot = await getDocs(usersQuery);
  if (snapshot.empty) {
    return undefined;
  }
  const userId = snapshot.docs.map((doc) => doc.data().uid)[0];
  const followersRef = getUserFollowersRef(userToFollowUid);
  const followersQuery = query(followersRef, where("uid", "==", userId));
  const notFollowingYet = (await getDocs(followersQuery)).empty;
  return notFollowingYet ? undefined : userId;
};

// Returns the number of followers imported from eventbrite
export const fetchNumEventbriteFollowersQuery = async (
  userId: string
): Promise<Query<Follower>> => {
  const followersRef = getUserFollowersRef(userId);
  const query_ = query(
    followersRef,
    where("followType", "==", FollowType.EVENTBRITE)
  );

  return query_;
};
