import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  Follower,
  Following,
  SpreadsheetStatus,
} from "@markit/common.types";
import {
  getUserFollowerData,
  getUserFollowersRef,
  getUserFollowingData,
  getUserFollowingsRef,
} from "../utils/FirebaseUtils";
import { fetchSingleUser } from "../utils/FetchSingleUser";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";

type useLoadUserFollowListProps = {
  userId: string;
  followerAccountData: AccountData[];
  followListType: "Followers" | "Following";
  windowSize: number;
  spreadsheetStatus?: SpreadsheetStatus;
};
export const useLoadUserFollowList = (props: useLoadUserFollowListProps) => {
  const {
    userId,
    followerAccountData,
    followListType,
    windowSize,
    spreadsheetStatus,
  } = props;

  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const [fetchedFollowerData, setFetchedFollowerData] = useState<Follower[]>(
    []
  );
  const [fetchedFollowingData, setFetchedFollowingData] = useState<Following[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const removeUserData = useCallback((newUserDataUid: string) => {
    setFetchedUserData((fetchedUserData) => {
      const index = fetchedUserData.findIndex(
        (fetched) => fetched.uid === newUserDataUid
      );
      if (index !== -1) {
        fetchedUserData.splice(index, 1);
      }
      return uniqueVals(
        fetchedUserData,
        (fetchedUserData) => fetchedUserData.uid
      );
    });
  }, []);

  const addFollowerData = useCallback((newFollower: Follower) => {
    setFetchedFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollower.uid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1, newFollower);
      } else {
        fetchedFollowerData = fetchedFollowerData.concat(newFollower);
      }
      return uniqueVals(
        fetchedFollowerData,
        (fetchedFollowerData) => fetchedFollowerData.uid
      );
    });
  }, []);

  const removeFollowerData = useCallback((newFollowerUid: string) => {
    setFetchedFollowerData((fetchedFollowerData) => {
      const index = fetchedFollowerData.findIndex(
        (fetched) => fetched.uid === newFollowerUid
      );
      if (index !== -1) {
        fetchedFollowerData.splice(index, 1);
      }
      return uniqueVals(
        fetchedFollowerData,
        (fetchedFollowerData) => fetchedFollowerData.uid
      );
    });
  }, []);

  const addFollowingData = useCallback((newFollowing: Following) => {
    setFetchedFollowingData((fetchedFollowingData) => {
      const index = fetchedFollowingData.findIndex(
        (fetched) => fetched.uid === newFollowing.uid
      );
      if (index !== -1) {
        fetchedFollowingData.splice(index, 1, newFollowing);
      } else {
        fetchedFollowingData = fetchedFollowingData.concat(newFollowing);
      }
      return uniqueVals(
        fetchedFollowingData,
        (fetchedFollowingData) => fetchedFollowingData.uid
      );
    });
  }, []);

  const removeFollowingData = useCallback((newFollowingUid: string) => {
    setFetchedFollowingData((fetchedFollowingData) => {
      const index = fetchedFollowingData.findIndex(
        (fetched) => fetched.uid === newFollowingUid
      );
      if (index !== -1) {
        fetchedFollowingData.splice(index, 1);
      }
      return uniqueVals(
        fetchedFollowingData,
        (fetchedFollowingData) => fetchedFollowingData.uid
      );
    });
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const loadUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef =
        followListType === "Followers"
          ? getUserFollowersRef(userId)
          : getUserFollowingsRef(userId);
      const followMembersQuery = spreadsheetStatus
        ? query(
            followMembersRef,
            where("spreadsheetData.status", "==", spreadsheetStatus),
            orderBy("createdAt", "desc"),
            limit(windowSize)
          )
        : query(
            followMembersRef,
            orderBy("createdAt", "desc"),
            limit(windowSize)
          );
      const uploadedMembers = (await getDocs(followMembersQuery)).docs.map(
        (document) => document.data().createdAt
      );

      setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberUserData = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            if (followListType === "Followers") {
              addFollowerData(memberUserData as Follower);
            } else {
              addFollowingData(memberUserData);
            }
            fetchSingleUser(
              memberUserData.uid,
              followerAccountData,
              (userData) => addUserData([userData])
            );
          } else if (message.type === "removed") {
            if (followListType === "Followers") {
              removeFollowerData(memberUserData.uid);
            } else {
              removeFollowingData(memberUserData.uid);
            }
            removeUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return unsubscribe;
    }
  }, [
    addFollowerData,
    addFollowingData,
    addUserData,
    followListType,
    followerAccountData,
    isFinished,
    isLoading,
    removeFollowerData,
    removeFollowingData,
    removeUserData,
    spreadsheetStatus,
    userId,
    windowSize,
  ]);

  const loadMoreUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const followMembersRef =
        followListType === "Followers"
          ? getUserFollowersRef(userId)
          : getUserFollowingsRef(userId);
      const followMembersQuery = spreadsheetStatus
        ? query(
            followMembersRef,
            where("spreadsheetData.status", "==", spreadsheetStatus),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          )
        : query(
            followMembersRef,
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          );

      const uploadedMembers = (await getDocs(followMembersQuery)).docs.map(
        (document) => document.data().createdAt
      );

      setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(followMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          const memberUserData = message.doc.data();
          if (message.type === "added" || message.type === "modified") {
            if (followListType === "Followers") {
              addFollowerData(memberUserData as Follower);
            } else {
              addFollowingData(memberUserData);
            }
            fetchSingleUser(
              memberUserData.uid,
              followerAccountData,
              (userData) => addUserData([userData])
            );
          } else if (message.type === "removed") {
            if (followListType === "Followers") {
              removeFollowerData(memberUserData.uid);
            } else {
              removeFollowingData(memberUserData.uid);
            }
            removeUserData(memberUserData.uid);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
      return unsubscribe;
    }
  }, [
    addFollowerData,
    addFollowingData,
    addUserData,
    followListType,
    followerAccountData,
    isFinished,
    isLoading,
    lastVisible,
    removeFollowerData,
    removeFollowingData,
    removeUserData,
    spreadsheetStatus,
    userId,
    windowSize,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchFollowers = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            await fetchSingleUser(uid, followerAccountData, (userData) =>
              addUserData([userData])
            );
            if (followListType === "Followers") {
              const followerData = await getUserFollowerData(userId, uid);
              if (followerData) {
                addFollowerData(followerData);
              }
            } else {
              const followingData = await getUserFollowingData(userId, uid);
              if (followingData) {
                addFollowingData(followingData);
              }
            }
          })
        );
        setIsLoading(false);
      };
      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchFollowers(userIds)
      );
    },
    [
      addFollowerData,
      addFollowingData,
      addUserData,
      followListType,
      followerAccountData,
      userId,
    ]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedFollowerData,
    fetchedFollowingData,
    loadUsers,
    loadMoreUsers,
    loadSearchResultsThrottled,
  };
};
