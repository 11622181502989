import { Colors } from "../../../../utils/colors";
import CustomCheckbox from "../../../CustomCheckbox";

type MassTextsActionItemProps = {
  title: string;
  icon: JSX.Element;
  onPress?: () => void;
  altIconBackgroundColor?: string;
  isCheckSelected?: boolean;
  checkColor?: string;
  containerStyles?: React.CSSProperties;
  subtext?: string;
  disabled?: boolean;
};

export const MassTextsActionItem = (props: MassTextsActionItemProps) => {
  const {
    title,
    icon,
    onPress,
    altIconBackgroundColor,
    isCheckSelected,
    checkColor,
    containerStyles,
    subtext,
    disabled,
  } = props;
  const styles = {
    actionItemIcon: {
      height: 48,
      width: 48,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderRadius: 8,
      cursor: "pointer",
    },
  };

  return (
    <div
      onClick={onPress}
      className="AlignedRowSpacedSelect"
      style={{
        ...containerStyles,
        pointerEvents: disabled || !onPress ? "none" : "all",
        opacity: disabled ? 0.5 : 1,
      }}
    >
      <div className="AlignedRowSelect">
        <div
          style={{
            ...styles.actionItemIcon,
            backgroundColor: altIconBackgroundColor ?? Colors.GRAY6,
          }}
        >
          {icon}
        </div>
        <div style={{ marginLeft: 10, gap: 2 }} className="ColumnNormalSelect">
          <span style={{ fontWeight: "500", fontSize: 14 }}>{title}</span>
          {subtext ? (
            <span style={{ fontSize: 12, color: Colors.GRAY1 }}>{subtext}</span>
          ) : null}
        </div>
      </div>
      {checkColor ? (
        <CustomCheckbox
          checked={isCheckSelected}
          onChange={onPress}
          sx={{ padding: 0, opacity: onPress ? 1 : 0.7 }}
          altColor={checkColor}
        />
      ) : null}
    </div>
  );
};
