import { AccountData, AudienceList } from "@markit/common.types";
import { useLoadUserAudienceListMembers } from "../../../../hooks/useLoadAudienceListMembers";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { filter } from "lodash";
import { Colors } from "../../../../utils/colors";
import FlatList from "flatlist-react/lib";
import { CircularProgress } from "@mui/material";
import { MassTextsPersonItem } from "../Items/MassTextsPersonItem";
import { EmptySearchState } from "../../../EmptyStates/EmptySearchState";
import { useOnMount } from "../../../../utils/useOnMount";
import { fetchUserAudienceListMembers } from "../../../../utils/userUtils";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import { MassTextsActionItem } from "../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { SelectRecipientsSharedProps } from "./SelectRecipientsPanel";
import { addOrFilterRecipients } from "@markit/common.utils";

type SelectRecipientsAudienceListProps = SelectRecipientsSharedProps & {
  selectedList: AudienceList;
};

const SelectRecipientsAudienceList = (
  props: SelectRecipientsAudienceListProps
) => {
  const {
    selectedList,
    allRecipients,
    unsavedRecipients,
    setUnsavedRecipients,
    excludingMode,
    setProfileSelected,
  } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [peopleSearchTerm, setPeopleSearchTerm] = useState<string>("");
  const [allAttendees, setAllAttendees] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const {
    fetchedMemberUserData,
    loadAudienceListUsers,
    loadMoreAudienceListUsers,
    isLoading: isAudienceListMembersLoading,
    isFinished: isAudienceListMembersFinished,
  } = useLoadUserAudienceListMembers({
    userId: accountData.uid,
    audienceListId: selectedList?.id ?? "",
    windowSize: 20,
  });

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  useOnMount(() => {
    (async () => {
      setUnsavedRecipients(allRecipients as string[]);
      const listAttendees = await fetchUserAudienceListMembers(
        accountData.uid,
        selectedList.id
      );
      setAllAttendees(listAttendees);
      setLoading(false);
    })();
  });

  useEffect(() => {
    (async () => {
      if (
        loadingUsers &&
        fetchedMemberUserData.length === 0 &&
        !isAudienceListMembersFinished
      ) {
        loadAudienceListUsers();
        setLoadingUsers(false);
      }
    })();
  }, [
    fetchedMemberUserData.length,
    isAudienceListMembersFinished,
    loadAudienceListUsers,
    loadingUsers,
  ]);

  const containsUser = useCallback((item: AccountData, query: string) => {
    return item.fullName.toLowerCase().includes(query);
  }, []);

  const audienceListMembersToShow: AccountData[] = useMemo(() => {
    if (peopleSearchTerm === "") {
      return fetchedMemberUserData;
    }
    let userList: AccountData[] = fetchedMemberUserData;
    userList = filter(userList, (user: AccountData) => {
      return containsUser(user, peopleSearchTerm.toLowerCase());
    });
    return userList;
  }, [fetchedMemberUserData, peopleSearchTerm, containsUser]);

  const isAllSelected = useCallback(
    (totalList: string[], subList: string[]) =>
      subList.length > 0
        ? totalList.every((sel) => subList.includes(sel))
        : false,
    []
  );

  return (
    <div>
      <div
        className="ColumnCenter"
        style={{
          gap: 4,
          backgroundColor: Colors.GRAY6,
          padding: 10,
          borderRadius: 12,
        }}
      >
        <span style={{ fontWeight: 500, fontSize: 14 }}>
          {selectedList.name}
        </span>
        <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
          {selectedList.numberMembers} Members
        </span>
        <span style={{ fontSize: 12, color: Colors.GRAY1 }}>
          {selectedList.visibility === 0 ? "Public List" : "Private List"}
        </span>
      </div>
      <SearchBoxContainer
        placeholder="Search People"
        onChange={(e) => setPeopleSearchTerm(e.target.value)}
        value={peopleSearchTerm}
        containerStyles={{ marginBottom: 10 }}
      />
      <div
        className="HideScrollbar"
        style={{
          height: "calc(100vh - 250px)",
          overflow: "scroll",
          border: "1px transparent solid",
        }}
      >
        {audienceListMembersToShow.length > 0 ? (
          <div>
            <MassTextsActionItem
              title={"Select All"}
              icon={<Icon icon="ion:people" height={36} color={iconColor} />}
              onPress={() =>
                addOrFilterRecipients(
                  unsavedRecipients,
                  setUnsavedRecipients,
                  allAttendees
                )
              }
              subtext={
                loading
                  ? ""
                  : `${allAttendees.length} ${
                      allAttendees.length !== 1 ? "People" : "Person"
                    }`
              }
              isCheckSelected={isAllSelected(allAttendees, unsavedRecipients)}
              checkColor={iconColor}
            />
            <hr style={{ marginBlock: 10 }} />
          </div>
        ) : null}
        <FlatList
          list={audienceListMembersToShow}
          renderItem={(user) => {
            return (
              <MassTextsPersonItem
                user={user}
                unsavedPeople={unsavedRecipients}
                setUnsavedPeople={setUnsavedRecipients}
                excludingMode={excludingMode}
                setProfileSelected={setProfileSelected}
              />
            );
          }}
          renderWhenEmpty={() =>
            isAudienceListMembersLoading ? (
              <div>Loading audience list...</div>
            ) : (
              <EmptySearchState mainText="No people to show" />
            )
          }
          hasMoreItems={!isAudienceListMembersFinished}
          loadMoreItems={() => {
            loadMoreAudienceListUsers();
          }}
          paginationLoadingIndicator={() =>
            isAudienceListMembersLoading && !isAudienceListMembersFinished ? (
              <CircularProgress
                style={{ color: "#929292", alignSelf: "center" }}
                size={20}
              />
            ) : null
          }
        />
      </div>
    </div>
  );
};

export default SelectRecipientsAudienceList;
