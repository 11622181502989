import {
  MassTextScreenType,
  MassTextsSharedProps,
} from "../MassTextsPopupPanel";
import { useOnMount } from "../../../../utils/useOnMount";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  accountActions,
  getAccountState,
} from "../../../../redux/slices/accountSlice";
import {
  AccountData,
  AudienceList,
  Event,
  MassText,
  SavedFormQuestion,
  SelectRecipientType,
  SubSelectionItem,
} from "@markit/common.types";
import { fetchAllUserFollowersData } from "../../../../utils/userUtils";
import { useDispatch } from "react-redux";
import SelectRecipientsEvent from "./SelectRecipientsEvent";
import SelectRecipientsAudienceList from "./SelectRecipientsAudienceList";
import SelectRecipientsCollectedData from "./SelectRecipientsCollectedData";
import SelectRecipientHeader from "./SelectRecipientsHeader";
import SelectRecipientsOverview from "./SelectRecipients";
import { getEventState } from "../../../../redux/slices/eventSlice";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";

export type SelectRecipientGroupType = {
  type: SelectRecipientType;
  selectedItem: Event | AudienceList | undefined;
  eventTabValue: number;
};

export type SelectRecipientsSharedProps = {
  allRecipients: string[] | SubSelectionItem[];
  unsavedRecipients: string[];
  setUnsavedRecipients: (unsavedRecipients: string[]) => void;
  unsavedCategories: SubSelectionItem[];
  setUnsavedCategories: (unsavedRecipients: SubSelectionItem[]) => void;
  excludingMode: boolean;
  setProfileSelected: (profileSelected: AccountData) => void;
  showCategories: boolean;
};

// for the header and the main file
export type SelectRecipientsMainSharedProps = {
  excludingMode: boolean;
  setExcludingMode: (excludingMode: boolean) => void;
  massText?: MassText;
  updateMassTextSettings?: (massText: Partial<MassText>) => void;
  showCategories?: boolean;
  setSuggestedAction?: (suggestedAction: SubSelectionItem) => void;
  showDoneButton: boolean;
  setShowDoneButton: (showDoneButton: boolean) => void;
};

type SelectRecipientsPanelProps = MassTextsSharedProps & {
  allSelectedPeople: string[];
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  allExcludedPeople: string[];
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  numEventAttendees: number;
  setSuggestedAction: (suggestedAction: SubSelectionItem | undefined) => void;
};

const SelectRecipientsPanel = (props: SelectRecipientsPanelProps) => {
  const {
    setMainScreen,
    setProfileSelected,
    massTextSettings,
    updateMassTextSettings,
    allSelectedPeople,
    setAllSelectedPeople,
    allExcludedPeople,
    setAllExcludedPeople,
    numEventAttendees,
    setSuggestedAction,
  } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const { events } = useSelector(getEventState).events;

  const [loadingAllFollowers, setLoadingAllFollowers] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState<SavedFormQuestion>();
  const [unsavedRecipients, setUnsavedRecipients] = useState<string[]>([]);
  const [unsavedCategories, setUnsavedCategories] = useState<
    SubSelectionItem[]
  >([]);

  const [selectedType, setSelectedType] = useState<SelectRecipientGroupType>({
    type: SelectRecipientType.NONE,
    selectedItem: undefined,
    eventTabValue: 0,
  });
  const [excludingMode, setExcludingMode] = useState<boolean>(false);
  const [focusKeyboard, setFocusKeyboard] = useState(false);
  const [showDoneButton, setShowDoneButton] = useState(false);

  const foundEvent = useMemo(
    () => events.find((event) => event.id === massTextSettings.eventRefId),
    [events, massTextSettings.eventRefId]
  );

  const isHost = useMemo(
    () => foundEvent && accountData.uid === foundEvent.createdBy,
    [accountData.uid, foundEvent]
  );

  // Show categories if ALL are satisfied:
  // 1. Coming from event dashboard
  // 2. Only if they are the host of the event
  // 3. Selected recipients is equal to 0
  // 4. Not scheduled AND more than 0 attendees OR if scheduled
  const showCategories = useMemo(() => {
    return (
      (allSelectedPeople.length === 0 &&
        isHost &&
        ((!massTextSettings.scheduled && numEventAttendees > 0) ||
          massTextSettings.scheduled) &&
        (selectedType.type === SelectRecipientType.NONE ||
          (selectedType.type === SelectRecipientType.EVENTS &&
            (selectedType.selectedItem as Event).id ===
              massTextSettings.eventRefId))) ??
      false
    );
  }, [
    allSelectedPeople.length,
    isHost,
    massTextSettings.eventRefId,
    massTextSettings.scheduled,
    numEventAttendees,
    selectedType.selectedItem,
    selectedType.type,
  ]);

  useOnMount(() => {
    (async () => {
      // Need to fetch and save follower data to redux
      const followersData = await fetchAllUserFollowersData(accountData.uid);
      dispatch(accountActions.addMultipleFollowerAccountData(followersData));
      setLoadingAllFollowers(false);
      setFocusKeyboard(false);
    })();
  });

  const updateSelectType = useCallback(
    (newSelectedType: Partial<SelectRecipientGroupType>) => {
      setSelectedType((prevSelectedType) => {
        return { ...prevSelectedType, ...newSelectedType };
      });
    },
    []
  );

  const resetSelectType = useCallback(() => {
    setSelectedType({
      type: SelectRecipientType.NONE,
      selectedItem: undefined,
      eventTabValue: 0,
    });
    setFocusKeyboard(!showCategories);
  }, [setFocusKeyboard, showCategories]);

  const backToOverview = useCallback(() => {
    if (
      massTextSettings.subSelectionIds.length > 0 ||
      massTextSettings.excludedSubSelectionIds.length > 0 ||
      allSelectedPeople.length > 0 ||
      allExcludedPeople.length > 0
    ) {
      setSuggestedAction(undefined);
    }
    setMainScreen(MassTextScreenType.OVERVIEW);
  }, [
    allExcludedPeople.length,
    allSelectedPeople.length,
    massTextSettings.excludedSubSelectionIds.length,
    massTextSettings.subSelectionIds.length,
    setMainScreen,
    setSuggestedAction,
  ]);

  const sharedPropsPack = {
    allRecipients: excludingMode
      ? showCategories
        ? massTextSettings.excludedSubSelectionIds
        : allExcludedPeople
      : showCategories
      ? massTextSettings.subSelectionIds
      : allSelectedPeople,
    unsavedRecipients: unsavedRecipients,
    setUnsavedRecipients: setUnsavedRecipients,
    unsavedCategories: unsavedCategories,
    setUnsavedCategories: setUnsavedCategories,
    excludingMode: excludingMode,
    setProfileSelected: setProfileSelected,
    showCategories: showCategories,
  };

  const mainSharedPropsPack = {
    excludingMode: excludingMode,
    setExcludingMode: setExcludingMode,
    massText: massTextSettings,
    updateMassTextSettings: updateMassTextSettings,
    showCategories: showCategories,
    setSuggestedAction: setSuggestedAction,
    showDoneButton: showDoneButton,
    setShowDoneButton: setShowDoneButton,
  };

  return (
    <div>
      <div style={{ paddingInline: 14 }}>
        <SelectRecipientHeader
          {...mainSharedPropsPack}
          backToOverview={backToOverview}
          screenType={selectedType.type}
          setScreenType={(type: SelectRecipientType) =>
            updateSelectType({ type: type })
          }
          unsavedRecipients={unsavedRecipients}
          setUnsavedRecipients={setUnsavedRecipients}
          unsavedCategories={unsavedCategories}
          setUnsavedCategories={setUnsavedCategories}
          setAllExcludedPeople={setAllExcludedPeople}
          setAllSelectedPeople={setAllSelectedPeople}
          setFocusKeyboard={setFocusKeyboard}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          resetState={resetSelectType}
        />
      </div>
      <HorizontalDivider />
      <div style={{ padding: 14 }}>
        {selectedType.type === SelectRecipientType.NONE ? (
          <SelectRecipientsOverview
            {...mainSharedPropsPack}
            allSelectedPeople={allSelectedPeople}
            setAllSelectedPeople={setAllSelectedPeople}
            allExcludedPeople={allExcludedPeople}
            setAllExcludedPeople={setAllExcludedPeople}
            focusKeyboard={focusKeyboard}
            updateSelectType={updateSelectType}
            setProfileSelected={setProfileSelected}
            loadingAllFollowers={loadingAllFollowers}
          />
        ) : null}
        {/* Select by Event Screen */}
        {selectedType.type === SelectRecipientType.EVENTS &&
        selectedType.selectedItem ? (
          <SelectRecipientsEvent
            selectedType={selectedType}
            {...sharedPropsPack}
          />
        ) : null}
        {/* Select by Audience List Screen */}
        {selectedType.type === SelectRecipientType.LISTS &&
        selectedType.selectedItem ? (
          <SelectRecipientsAudienceList
            selectedList={selectedType.selectedItem as AudienceList}
            {...sharedPropsPack}
          />
        ) : null}
        {/* Select by Collected Data Screen */}
        {selectedType.type === SelectRecipientType.COLLECTED_DATA ? (
          <SelectRecipientsCollectedData
            {...sharedPropsPack}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SelectRecipientsPanel;
