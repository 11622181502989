import { useOnMount } from "../../../../utils/useOnMount";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
  accountActions,
  getAccountState,
} from "../../../../redux/slices/accountSlice";
import {
  AccountData,
  AudienceList,
  SavedFormQuestion,
  SelectRecipientType,
} from "@markit/common.types";
import { fetchAllUserFollowersData } from "../../../../utils/userUtils";
import { useDispatch } from "react-redux";
import SelectRecipientsEvent from "./SelectRecipientsEvent";
import SelectRecipientsAudienceList from "./SelectRecipientsAudienceList";
import SelectRecipientsCollectedData from "./SelectRecipientsCollectedData";
import SelectRecipientsOverview from "./SelectRecipients";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { SelectRecipientGroupType } from "./SelectRecipientsPanel";
import FullProfilePreviewModal from "../../../FollowerProfile/FullProfilePreviewModal";
import SelectRecipientHeader from "./SelectRecipientsHeader";

export type SimpleSelectRecipientsMainSharedProps = {
  excludingMode: boolean;
  setExcludingMode: (excludingMode: boolean) => void;
  showDoneButton: boolean;
  setShowDoneButton: (showDoneButton: boolean) => void;
};

type SimpleSelectRecipientsPanelProps = {
  allSelectedPeople: string[];
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  allExcludedPeople: string[];
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  setIsVisible: (isVisible: boolean) => void;
};

// Used for if we just want the select recipients part of the flow, ie. audience list members selection
const SimpleSelectRecipientsPanel = (
  props: SimpleSelectRecipientsPanelProps
) => {
  const {
    allSelectedPeople,
    setAllSelectedPeople,
    allExcludedPeople,
    setAllExcludedPeople,
    setIsVisible,
  } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;

  const [loadingAllFollowers, setLoadingAllFollowers] = useState(true);
  const [selectedQuestion, setSelectedQuestion] = useState<SavedFormQuestion>();
  const [unsavedRecipients, setUnsavedRecipients] = useState<string[]>([]);
  const [profileSelected, setProfileSelected] = useState<AccountData>();

  const [selectedType, setSelectedType] = useState<SelectRecipientGroupType>({
    type: SelectRecipientType.NONE,
    selectedItem: undefined,
    eventTabValue: 0,
  });
  const [excludingMode, setExcludingMode] = useState<boolean>(false);
  const [focusKeyboard, setFocusKeyboard] = useState(false);
  const [showDoneButton, setShowDoneButton] = useState(false);

  useOnMount(() => {
    (async () => {
      // Need to fetch and save follower data to redux
      const followersData = await fetchAllUserFollowersData(accountData.uid);
      dispatch(accountActions.addMultipleFollowerAccountData(followersData));
      setLoadingAllFollowers(false);
      setFocusKeyboard(false);
    })();
  });

  const updateSelectType = useCallback(
    (newSelectedType: Partial<SelectRecipientGroupType>) => {
      setSelectedType((prevSelectedType) => {
        return { ...prevSelectedType, ...newSelectedType };
      });
    },
    []
  );

  const sharedPropsPack = {
    allRecipients: excludingMode ? allExcludedPeople : allSelectedPeople,
    unsavedRecipients: unsavedRecipients,
    setUnsavedRecipients: setUnsavedRecipients,
    unsavedCategories: [],
    setUnsavedCategories: () => {},
    excludingMode: excludingMode,
    showCategories: false,
    setProfileSelected: setProfileSelected,
  };

  const mainSharedPropsPack: SimpleSelectRecipientsMainSharedProps = {
    excludingMode: excludingMode,
    setExcludingMode: setExcludingMode,
    showDoneButton: showDoneButton,
    setShowDoneButton: setShowDoneButton,
  };

  const backOnPress = useCallback(() => {
    if (selectedType.type === SelectRecipientType.NONE) {
      setIsVisible(false);
    } else {
      setSelectedType({
        type: SelectRecipientType.NONE,
        selectedItem: undefined,
        eventTabValue: 0,
      });
      setFocusKeyboard(true);
    }
  }, [selectedType.type, setIsVisible]);

  return (
    <div>
      <div style={{ paddingInline: 14 }}>
        <SelectRecipientHeader
          {...mainSharedPropsPack}
          backToOverview={backOnPress}
          screenType={selectedType.type}
          setScreenType={(type: SelectRecipientType) =>
            updateSelectType({ type: type })
          }
          unsavedRecipients={unsavedRecipients}
          setUnsavedRecipients={setUnsavedRecipients}
          unsavedCategories={[]}
          setUnsavedCategories={() => {}}
          setAllExcludedPeople={setAllExcludedPeople}
          setAllSelectedPeople={setAllSelectedPeople}
          setFocusKeyboard={setFocusKeyboard}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          resetState={backOnPress}
          alternateTitle="People"
        />
      </div>
      <HorizontalDivider />
      <div style={{ padding: 14 }}>
        {selectedType.type === SelectRecipientType.NONE ? (
          <SelectRecipientsOverview
            {...mainSharedPropsPack}
            allSelectedPeople={allSelectedPeople}
            setAllSelectedPeople={setAllSelectedPeople}
            allExcludedPeople={allExcludedPeople}
            setAllExcludedPeople={setAllExcludedPeople}
            focusKeyboard={focusKeyboard}
            updateSelectType={updateSelectType}
            setProfileSelected={setProfileSelected}
            loadingAllFollowers={loadingAllFollowers}
          />
        ) : null}
        {/* Select by Event Screen */}
        {selectedType.type === SelectRecipientType.EVENTS &&
        selectedType.selectedItem ? (
          <SelectRecipientsEvent
            selectedType={selectedType}
            {...sharedPropsPack}
          />
        ) : null}
        {/* Select by Audience List Screen */}
        {selectedType.type === SelectRecipientType.LISTS &&
        selectedType.selectedItem ? (
          <SelectRecipientsAudienceList
            selectedList={selectedType.selectedItem as AudienceList}
            {...sharedPropsPack}
          />
        ) : null}
        {/* Select by Collected Data Screen */}
        {selectedType.type === SelectRecipientType.COLLECTED_DATA ? (
          <SelectRecipientsCollectedData
            {...sharedPropsPack}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
          />
        ) : null}
      </div>
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
    </div>
  );
};

export default SimpleSelectRecipientsPanel;
