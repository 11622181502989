import { AccountData } from "@markit/common.types";
import { InitialsPic } from "./InitialsPic";
import { Colors } from "../utils/colors";

type ProfilePicProps = {
  user: AccountData | undefined;
  size: number;
  onPress?: () => void;
  forcePointer?: boolean;
  borderStyle?: string;
};

const ProfilePic = (props: ProfilePicProps) => {
  const { user, size, onPress, borderStyle, forcePointer } = props;

  if (user && !!user.profilePicURL) {
    return (
      <div
        onClick={onPress}
        style={{
          cursor: onPress || forcePointer ? "pointer" : "auto",
          width: size,
          height: size,
          borderRadius: size / 2,
          border: borderStyle,
        }}
      >
        <img
          src={user.profilePicURL}
          alt="ProfilePicURL"
          style={{
            borderRadius: size / 2,
            objectFit: "cover",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        onClick={onPress}
        style={{
          cursor: onPress ? "pointer" : "auto",
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor: Colors.BLUE2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <InitialsPic fullName={user?.fullName} size={size / 3} />
      </div>
    );
  }
};

export default ProfilePic;
