import { useSelector } from "react-redux";
import "../css/Banner.css";
import { useAppRedirects } from "../hooks";
import { WhiteLabelSocial } from "../macros/WhiteLabelUsers";
import { getAccountState } from "../redux/slices/accountSlice";
import { Colors } from "../utils/colors";
import { formatPhoneNumber } from "../utils/FormatPhoneNumber";
import {
  MARKIT_ATTENDEE_FAQ,
  MARKIT_FREE_CREATOR_NUMBER,
  MARKIT_HOTLINE_NUMBER,
} from "@markit/common.utils";
import { useMemo, useState } from "react";
import { useTheme } from "../hooks/useTheme";
import RectangleButton from "./Buttons/RectangleButton";
import AlertButtonWrapper from "./Containers/AlertButtonWrapper";

export enum BannerType {
  HOTLINE,
  TICKETISSUE,
  TEXTHOST,
  TEXTHOST_FREE,
  DOWNLOAD_ORGANIZER_NOAPP,
  DOWNLOAD_ORGANIZER_APP,
  DOWNLOAD,
  RCA,
  DEFAULT,
}

type BannerProps = {
  type?: BannerType;
  hostPhone?: string;
  bannerStyles?: React.CSSProperties;
};

const Banner = (props: BannerProps) => {
  const { type, hostPhone, bannerStyles } = props;
  const { account } = useSelector(getAccountState);
  const { isRCA } = account;
  const { redirectToApp: onClick, redirectToAppStore: onAppStoreClick } =
    useAppRedirects();

  const { theme } = useTheme();

  const [alertText, setAlertText] = useState("");

  const openAttendeeFAQ = () => {
    window.open(MARKIT_ATTENDEE_FAQ);
  };

  const hostNumber = useMemo(
    () => hostPhone ?? MARKIT_FREE_CREATOR_NUMBER,
    [hostPhone]
  );

  switch (type) {
    case BannerType.HOTLINE:
      return (
        <a
          href={`tel:${MARKIT_HOTLINE_NUMBER}`}
          style={{ textDecoration: "none", cursor: "pointer" }}
        >
          <div
            className="Banner"
            style={{
              ...theme.TertiaryBG,
              ...bannerStyles,
              marginTop: "0.5em",
              justifyContent: "center",
              padding: 12,
            }}
          >
            <div className="LeftContainer" style={{ width: "50%" }}>
              <div className="BannerFullName" style={theme.PrimaryText}>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: 14,
                    fontWeight: "600",
                  }}
                >
                  Contact Our Hotline
                </p>
              </div>
            </div>
            <div
              onClick={onClick}
              style={{
                margin: 0,
                color: "#009ff9",
                fontWeight: 600,
                cursor: "pointer",
              }}
            >
              <span
                style={{
                  color: Colors.GRAY1,
                  textDecoration: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
              </span>
            </div>
          </div>
        </a>
      );
    case BannerType.TICKETISSUE:
      return (
        <div
          className="Banner"
          style={{ ...bannerStyles, backgroundColor: "#f4f5f6" }}
        >
          <div className="LeftContainer" style={{ paddingLeft: 10 }}>
            <div className="BannerFullName" style={{ color: "#000" }}>
              <p style={{ padding: 0, margin: 0 }}>Ticket Issue?</p>
            </div>
            <div className="Username" style={{ color: "#929292" }}>
              Visit our website
            </div>
          </div>
          <div
            className="OpenButton"
            style={{
              marginRight: 7,
              backgroundColor: isRCA ? "#FE296C" : "#11b8ff",
            }}
            onClick={openAttendeeFAQ}
          >
            <p className="OpenButtonText">Visit website</p>
          </div>
        </div>
      );

    case BannerType.TEXTHOST:
      return (
        <RectangleButton
          buttonLabel={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "14px",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  ...theme.PrimaryText,
                  fontSize: 14,
                  fontWeight: "600",
                  cursor: "pointer",
                }}
              >
                Contact
              </div>
              <span
                style={{
                  color: Colors.GRAY1,
                  textDecoration: "none",
                  fontWeight: "400",
                  fontSize: 14,
                }}
              >
                {formatPhoneNumber(hostNumber)}
              </span>
            </div>
          }
          onPress={() => (window.location.href = "tel:" + hostNumber)}
          altColor={theme.TertiaryBG.backgroundColor}
          altPaddingVert={bannerStyles?.paddingBlock || 10}
          altPaddingHorz={bannerStyles?.paddingInline || 15}
          altBorderRadius={bannerStyles?.borderRadius || "0.5em"}
          containerStyles={{
            marginTop: bannerStyles?.marginTop || "0.7em",
            marginBottom: bannerStyles?.marginBottom || 0,
          }}
        />
      );

    /* TODO: IF HOST HAS NO NUMBER */
    case BannerType.TEXTHOST_FREE:
      return (
        <AlertButtonWrapper
          buttonComp={
            <RectangleButton
              buttonLabel="Contact Host"
              onPress={() =>
                setAlertText(
                  "The host does not have a subscription with Markit, please contact them via a different channel"
                )
              }
              altColor={theme.TertiaryBG.backgroundColor}
              altTextColor={theme.PrimaryText.color}
              altPaddingVert={bannerStyles?.paddingBlock || 12}
              altPaddingHorz={bannerStyles?.paddingInline || 12}
              altBorderRadius={bannerStyles?.borderRadius || "0.5em"}
              containerStyles={{
                marginTop: bannerStyles?.marginTop || "0.7em",
                marginBottom: bannerStyles?.marginBottom || 0,
              }}
            />
          }
          alertTextHeader={alertText}
          clearAlert={() => setAlertText("")}
        />
      );

    case BannerType.RCA:
      return (
        <div
          className="Banner"
          style={{
            ...bannerStyles,
            backgroundColor: "#f4f5f6",
            marginTop: "0.5em",
          }}
        >
          <div
            className="LeftContainer"
            style={{ paddingLeft: 10, width: "55%" }}
          >
            <div className="BannerFullName" style={{ color: "#000" }}>
              <p style={{ padding: 0, margin: 0 }}>Red Curtain Addict</p>
            </div>
            <div className="Username" style={{ color: "#929292" }}>
              Available on the web
            </div>
          </div>
          <div
            className="OpenButton"
            style={{
              marginRight: 7,
              backgroundColor: "#FE296C",
            }}
            onClick={() => {
              window.open(WhiteLabelSocial.RCA_WEBSITE);
            }}
          >
            <p className="OpenButtonText">Visit our page</p>
          </div>
        </div>
      );

    case BannerType.DOWNLOAD:
      return (
        <div
          className="Banner"
          style={{
            ...bannerStyles,
            backgroundColor: "#f4f5f6",
            marginTop: "0.5em",
          }}
        >
          <div
            className="LeftContainer"
            style={{ paddingLeft: 10, width: "55%" }}
          >
            <div className="BannerFullName" style={{ color: "#000" }}>
              <p style={{ padding: 0, margin: 0 }}>Markit Social</p>
            </div>
            <div className="Username" style={{ color: "#929292" }}>
              Available on IOS and Android
            </div>
          </div>
          <div
            className="OpenButton"
            style={{ marginRight: 7 }}
            onClick={() => onAppStoreClick()}
          >
            <p className="OpenButtonText">Open</p>
          </div>
        </div>
      );
    case BannerType.DOWNLOAD_ORGANIZER_NOAPP:
      return (
        <div
          style={{
            ...bannerStyles,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="OpenButton"
            style={{
              padding: "10px 10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            onClick={onClick}
          >
            <p className="OpenButtonText">Download Markit</p>
          </div>
        </div>
      );

    case BannerType.DOWNLOAD_ORGANIZER_APP:
      return (
        <div
          style={{
            ...bannerStyles,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            className="OpenButton"
            style={{
              padding: "10px 10px",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            onClick={onClick}
          >
            <p className="OpenButtonText">Open Markit</p>
          </div>
        </div>
      );
    default:
      return (
        <div
          className="Banner"
          style={{
            ...bannerStyles,
            backgroundColor: "#f4f5f6",
          }}
        >
          <div
            className="LeftContainer"
            style={{ paddingLeft: 10, width: "55%" }}
          >
            <div className="BannerFullName" style={{ color: "#000" }}>
              <p style={{ padding: 0, margin: 0 }}>Non-US Number?</p>
            </div>
            <div className="Username" style={{ color: "#929292" }}>
              Get the app to get a ticket
            </div>
          </div>
          <div
            className="OpenButton"
            style={{
              marginRight: 7,
              backgroundColor: isRCA ? "#FE296C" : "#11b8ff",
            }}
            onClick={() => onAppStoreClick()}
          >
            <p className="OpenButtonText">Get the app</p>
          </div>
        </div>
      );
  }
};

export default Banner;
