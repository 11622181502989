import { collection, getDocs } from "../firebase";
import { getUserEventRef } from "./FirebaseUtils";

export const FetchAllDiscussionPosts = async (uid: string, eventId: string) => {
  var discussionPosts: any[] = [];
  const discussionPostsRef = collection(
    getUserEventRef(uid, eventId),
    "discussion"
  );
  const discussionPostsSnap = await getDocs(discussionPostsRef)
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        discussionPosts = discussionPosts.concat(doc.data());
      });
      return discussionPosts;
    })
    .catch((e) => {
      console.error("Error fetching discussion posts: ", e.message);
      return discussionPosts;
    });

  return discussionPostsSnap.sort(
    (a, b) => Date.parse(b.postedAt) - Date.parse(a.postedAt)
  );
};
