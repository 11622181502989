class Phone {
  static isValid(phone: string): boolean {
    const re = /^(\+1|1)?[(]?[0-9]{3}[)]?[ ,-]?[0-9]{3}[ ,-]?[0-9]{4}$/;
    return re.test(phone);
  }

  static fromInternationalCode(phone: string): string {
    const cleaned = ("" + phone).replace(/\D/g, "");
    return cleaned
      .replace(/[^\d]+/g, "")
      .replace(/(?:\+1|1)?(\d{3})(\d{3})(\d{4})/, "$1$2$3");
  }
  static toInternationalCode(phone: string): string {
    return phone !== "" ? `+1${phone}` : "";
  }
}

export default Phone;
