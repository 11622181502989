import "../../css/GlobalStyles.css";
import { useTheme } from "../../hooks/useTheme";
import { useCallback, useContext, useState } from "react";
import { MixpanelContext } from "../../context/AnalyticsService";
import PopupModalContainer from "../Containers/PopupModalContainer";
import RectangleButton from "../Buttons/RectangleButton";
import { Colors } from "../../utils/colors";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { useOnMount } from "../../utils/useOnMount";
import { useNavigate } from "../../hooks/useNavigate";
import { Icon } from "@iconify/react";
import HelpModal from "../Containers/HelpModal";

type MarkitPlusModalProps = {
  closeModal: () => void;
};

export const MarkitPlusModal = (props: MarkitPlusModalProps) => {
  const { closeModal } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const navigate = useNavigate();
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);
  const [helpModal, setHelpModal] = useState(false);

  const styles = {
    bodyMedium: {
      fontSize: 14,
      color: Colors.GRAY1,
    },
  };

  useOnMount(() => {
    mixpanel.track("Pressed Markit+ Subscription Prompt", {
      distinct_id: accountData.uid,
      from: "webapp",
    });
  });

  const onSubscriptionPress = useCallback(async () => {
    navigate("/subscription");
  }, [navigate]);

  const renderItemRow = useCallback(
    (title: string, subtext: string, iconName: string) => (
      <div className="AlignedRow">
        <div>
          <Icon icon={iconName} height={28} color={Colors.BLUE5} />
        </div>
        <div className="ColumnNormal" style={{ gap: 5, paddingLeft: 24 }}>
          <span style={{ fontWeight: 500 }}>{title}</span>
          <span style={{ fontSize: 14, color: Colors.GRAY2, width: 280 }}>
            {subtext}
          </span>
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      {!helpModal ? (
        <PopupModalContainer
          headerComp=""
          subHeaderComp=""
          noDivider
          valueComp={
            <div className="ColumnCenter" style={{ paddingBlock: 40, gap: 40 }}>
              <div
                className="ColumnCentering"
                style={{ gap: 14, paddingInline: 50 }}
              >
                <span style={{ fontSize: 32, fontWeight: 500 }}>
                  Markit<span style={{ color: Colors.BLUE5 }}>+</span>
                </span>
                <span style={{ ...styles.bodyMedium, textAlign: "center" }}>
                  Share your unique Markit number. When people text your number
                  they can join your audience.
                </span>
              </div>
              <div className="ColumnCentering" style={{ gap: 30 }}>
                {renderItemRow(
                  "Unlimited Texts",
                  "Get more free texts and pay as you go",
                  "ion:chatbubble-ellipses"
                )}
                {renderItemRow(
                  "Dedicated Phone Number",
                  "Get your own phone number, with a contact card, that people can text directly",
                  "ion:call"
                )}
                {renderItemRow(
                  "Two-Way Texting",
                  "Answer questions, chat, and engage your people with two-way texting conversations",
                  "ion:chatbubbles"
                )}
                {renderItemRow(
                  "Unlimited Lists",
                  "Create unlimited lists with Markit+",
                  "ion:list-circle"
                )}
                {renderItemRow(
                  "Unlimited Tracking Links",
                  "Create unlimited tracking links to monitor your different promotional channels",
                  "ion:trending-up"
                )}
              </div>
              <div
                className="ColumnCentering"
                style={{ paddingInline: 50, gap: 14 }}
              >
                <RectangleButton
                  buttonLabel={
                    <span style={{ color: Colors.WHITE, fontSize: 14 }}>
                      Get Markit<span style={{ color: Colors.BLUE5 }}>+</span>
                    </span>
                  }
                  onPress={onSubscriptionPress}
                  altColor={Colors.BLACK}
                  altPaddingVert={14}
                  altPaddingHorz={14}
                />
                <span
                  style={{ ...styles.bodyMedium, cursor: "pointer" }}
                  onClick={() => setHelpModal(true)}
                >
                  Contact Sales
                </span>
              </div>
            </div>
          }
          theme={theme}
          closeModal={closeModal}
          closeOnOutsidePress
          noExit
        />
      ) : (
        <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
      )}
    </>
  );
};
