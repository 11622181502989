import { useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { AccountData, Follower } from "@markit/common.types";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import AlertContainer from "../Containers/AlertContainer";
import { exportIndividualFollowerData } from "../../utils/eventUtils/formUtils";
import { AppState } from "../../redux/store";
import { useSelector } from "react-redux";
import { getUserFollowerData } from "../../utils/FirebaseUtils";
import { Colors } from "../../utils/colors";
import { useOnMount } from "../../utils/useOnMount";
import ConfirmActionPopup from "../Containers/ConfirmPopups/ConfirmActionPopup";

type FullFollowerProfilePopupPanelHeaderProps = {
  userData: AccountData;
  goBackToProfilePreview: () => void;
};

const FullFollowerProfilePopupPanelHeader = (
  props: FullFollowerProfilePopupPanelHeaderProps
) => {
  const { userData, goBackToProfilePreview } = props;
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const [exportIsLoading, setExportIsLoading] = useState<boolean>(false);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const [userFollowerData, setUserFollowerData] = useState<
    Follower | undefined
  >();
  const [confirmExportAlert, setConfirmExportAlert] = useState<boolean>(false);
  const accountData = useSelector(
    (state: AppState) => state.account.accountData
  );

  useOnMount(async () => {
    const follower = await getUserFollowerData(accountData.uid, userData.uid);
    setUserFollowerData(follower);
  });

  const navigateProfileOnPress = useCallback(async () => {
    window.open(
      `https://markitai.com/u/${userData.username}`,
      "_blank",
      "noreferrer"
    );
    setOpenDropdown(false);
  }, [userData.username]);

  const onExportFinish = useCallback(
    (success: boolean) => {
      if (success) {
        if (alertText.heading !== "Export Failed") {
          setConfirmExportAlert(true);
        }
      } else {
        setAlertText({
          heading: "Export Failed",
          subHeading: "Please try again or contact our hotline.",
        });
      }
    },
    [alertText.heading]
  );

  const exportSpreadsheetOnPress = useCallback(async () => {
    if (userFollowerData === undefined) {
      setOpenDropdown(false);
      setAlertText({
        heading: "This person is not currently following you.",
        subHeading: "",
      });
      return;
    } else if (exportIsLoading) {
      return;
    }
    setExportIsLoading(true);

    // can also add a return operation to check status?
    if (userFollowerData === undefined) {
      setAlertText({
        heading: "This person is not following you",
        subHeading: "",
      });
    } else {
      await exportIndividualFollowerData(
        accountData.uid,
        userData,
        onExportFinish
      );
    }
    setExportIsLoading(false);
    setOpenDropdown(false);
  }, [
    accountData.uid,
    exportIsLoading,
    onExportFinish,
    userData,
    userFollowerData,
  ]);

  const blockUserOnPress = useCallback(() => {
    setAlertText({ heading: "Coming soon...", subHeading: "" });
  }, []);

  const handleOpenChange = useCallback(
    (nextOpen: boolean, info?: { source: "trigger" | "menu" }) => {
      if (info?.source === "trigger" || nextOpen) {
        setOpenDropdown(nextOpen);
      }
    },
    []
  );

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: "View Attendee Profile",
        icon: "uis:arrow-up-right",
        key: "view",
        onPress: navigateProfileOnPress,
      },
      {
        title: "Export Spreadsheet",
        icon: "ion:download-outline",
        key: "export",
        onPress: exportSpreadsheetOnPress,
        loading: exportIsLoading,
      },
      {
        title: `Block ${userData.fullName}`,
        icon: "mdi:cancel",
        key: "block",
        onPress: blockUserOnPress,
        isDestructive: true,
      },
    ],
    [
      blockUserOnPress,
      exportSpreadsheetOnPress,
      navigateProfileOnPress,
      userData.fullName,
      exportIsLoading,
    ]
  );

  return (
    <>
      <div className="AlignedRowSpaced" style={{ position: "relative" }}>
        <div className="LargePopupPanelClose" onClick={goBackToProfilePreview}>
          <Icon icon={"mdi:close"} height={24} />
        </div>
        <DropdownMenu
          dropdownItems={dropdownItems}
          trigger={["click"]}
          placement="bottomLeft"
          altWidth={200}
          isOpen={openDropdown}
          onOpenChange={handleOpenChange}
        >
          <Icon
            icon="ion:ellipsis-horizontal"
            height={20}
            color={Colors.BLACK}
          />
        </DropdownMenu>
      </div>
      <AlertContainer
        headerComp={
          <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
            {alertText.heading === "Export Failed" ? (
              <Icon icon={"ion:close-circle"} color={Colors.RED3} height={30} />
            ) : null}
            {alertText.heading}
          </div>
        }
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
        altButtonLabel={
          alertText.heading === "Export Failed" ? "Got it" : undefined
        }
      />
      {confirmExportAlert ? (
        <ConfirmActionPopup
          title="Data Exported"
          onDisappear={() => setConfirmExportAlert(false)}
        />
      ) : null}
    </>
  );
};

export default FullFollowerProfilePopupPanelHeader;
