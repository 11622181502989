import React, { useState } from "react";
import { TabPanel } from "../FullEventSubComponents/TabPanel";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import HelpModal from "../Containers/HelpModal";
import { EventbritePanelSection } from "../CreatorDashboard/CreatorProfile/Integration/EventbritePanelSection";

type IntegrationsPanelProps = { tabValue: number };

export const IntegrationsPanel = (props: IntegrationsPanelProps) => {
  const { tabValue } = props;
  const [helpModal, setHelpModal] = useState(false);

  const styles = {
    overallContainerView: {
      flex: 1,
      width: "100%",
      borderRadius: 20,
      height: 225,
    },
  };

  return (
    <TabPanel value={tabValue} index={3}>
      <div className="RowNormal" style={{ gap: 30 }}>
        <EventbritePanelSection />
        <StandardBorderedContainer
          containerStyles={{
            ...styles.overallContainerView,
            paddingBlock: 14,
            backgroundColor: Colors.WHITE,
          }}
        >
          <div className="ColumnNormal">
            <div className="AlignedRowSpaced" style={{ paddingInline: 14 }}>
              <span className="bodyMedium">About Integrations</span>
              <div
                className="AlignedRowSelect"
                style={{ gap: 2 }}
                onClick={() => setHelpModal(true)}
              >
                <span style={{ fontSize: 12, fontWeight: 500 }}>
                  Don&apos;t see an integration you want?
                </span>
                <Icon
                  icon="ion:arrow-forward"
                  height={15}
                  style={{
                    transform: "rotateY(0deg) rotate(-45deg)",
                    marginBottom: -1,
                  }}
                />
              </div>
            </div>
            <HorizontalDivider altMargin={14} />
            <div
              className="ColumnNormal"
              style={{ gap: 14, paddingInline: 14 }}
            >
              <div className="AlignedRow" style={{ gap: 14, paddingRight: 14 }}>
                <div>
                  <Icon icon="fa-solid:plug" height={25} width={25} />
                </div>
                <span className="bodyMedium" style={{ color: Colors.GRAY1 }}>
                  Integrations allow you to sync your contacts and data from
                  other platforms, such as Eventbrite, with Markit.
                </span>
              </div>
              <div className="AlignedRow" style={{ gap: 14, paddingRight: 14 }}>
                <div>
                  <Icon icon="ion:sync-sharp" height={25} width={25} />
                </div>
                <span className="bodyMedium" style={{ color: Colors.GRAY1 }}>
                  Once synced, you will be able to automate your text campaigns
                  and more directly through Markit, with the ability to re-sync
                  your data every hour.
                </span>
              </div>
              <div className="AlignedRow" style={{ gap: 14, paddingRight: 14 }}>
                <div>
                  <Icon icon="ion:close-circle" height={25} width={25} />
                </div>
                <span className="bodyMedium" style={{ color: Colors.GRAY1 }}>
                  Disconnect your integrations at any time.
                </span>
              </div>
            </div>
          </div>
        </StandardBorderedContainer>
      </div>
      <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
    </TabPanel>
  );
};
