import React, { memo, useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import {
  AccountData,
  FormInputType,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { Divider } from "@mui/material";
import SingleCustomData from "./SingleCustomData";
import EssentialDataResponse from "./EssentialDataResponse";

type SingleCustomDataItemProps = {
  savedQuestion: SavedFormQuestion;
  formResponse: SavedFormResponse | null | undefined;
  userData: AccountData;
  pressable?: boolean;
  lastItem?: boolean;
  detailView?: boolean;
};

const SingleCustomDataItem = memo(function SingleCustomDataItemFn(
  props: SingleCustomDataItemProps
) {
  const {
    savedQuestion,
    formResponse,
    userData,
    pressable,
    lastItem,
    detailView,
  } = props;

  const { theme } = useTheme();
  const [singleCustomDataOpen, setSingleCustomDataOpen] = useState(false);

  const styles = {
    alignedRow: {
      alignItems: "center",
      display: "flex",
    },

    alignedRowSpaced: {
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
    },

    divider: {
      height: 1,
      marginVertical: 14,
      borderBottomWidth: 1,
      borderBottomColor: Colors.BLACK1,
    },
  };

  const singleCustomDataOnPress = useCallback(() => {
    setSingleCustomDataOpen(true);
  }, []);

  const closeSingleCustomData = useCallback(() => {
    setSingleCustomDataOpen(false);
  }, []);

  const isCollectPhoneFormQuestion = useMemo(
    () =>
      savedQuestion.formInput.inputType === FormInputType.PHONE &&
      userData &&
      userData.phoneNumber !== "",
    [savedQuestion.formInput.inputType, userData]
  );

  return (
    <>
      {singleCustomDataOpen ? (
        <SingleCustomData
          savedQuestion={savedQuestion}
          formResponse={formResponse ?? undefined}
          userData={userData}
          closeSingleCustomData={closeSingleCustomData}
        />
      ) : null}
      <div>
        <div style={{ ...styles.alignedRowSpaced, flexDirection: "row" }}>
          <div style={{ flex: 1 }}>
            <div
              style={{
                ...styles.alignedRow,
                flexDirection: "row",
                marginBottom: 4,
              }}
            >
              {savedQuestion.formInput.inputType === FormInputType.EMAIL ||
              savedQuestion.formInput.inputType === FormInputType.PHONE ? (
                <Icon
                  icon={
                    savedQuestion.formInput.inputType === FormInputType.EMAIL
                      ? "ion:mail-open"
                      : "ion:call"
                  }
                  height={15}
                  color={theme.LabelText.color}
                  style={{ marginRight: 10 }}
                />
              ) : null}
              <div>
                <span
                  className={!detailView ? "TextOverflowEllipsis" : ""}
                  style={{ ...theme.LabelText, fontSize: 14, width: "360px" }}
                >
                  {isCollectPhoneFormQuestion
                    ? "Phone Number"
                    : savedQuestion.formInput.label}
                </span>
              </div>
            </div>
            <EssentialDataResponse
              savedQuestion={savedQuestion}
              formResponse={formResponse}
              userData={userData}
              copyable
            />
          </div>
          {pressable ? (
            <Icon
              icon="mdi:chevron-right"
              height={26}
              color={theme.SubText.color}
              onClick={pressable ? singleCustomDataOnPress : () => {}}
            />
          ) : null}
        </div>
        {!lastItem ? <Divider style={{ marginBlock: 14 }} /> : null}
      </div>
    </>
  );
});

export default SingleCustomDataItem;
