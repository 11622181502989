import { useDispatch } from "react-redux";
import { useTheme } from "../hooks/useTheme";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AccountData } from "@markit/common.types";
import { InitialsPic } from "./InitialsPic";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { logoutUser, toggleInCreatorMode } from "../redux/slices/accountSlice";
import { Colors } from "../utils/colors";
import { MixpanelContext } from "../context/AnalyticsService";
import { Icon } from "@iconify/react";
import { isDesktop } from "react-device-detect";
import { useLocation } from "react-router-dom";
import { DataLoaders } from "../redux/slices/dataSlice";
import ProfilePopupPanel from "./CreatorDashboard/CreatorProfile/ProfilePopupPanel";
import { useNavigate } from "../hooks/useNavigate";
import { MARKITAI } from "@markit/common.utils";

type HeaderProfilePicProps = {
  accountData: AccountData;
  isOnProfilePage?: boolean;
  isOnCreatorDashboard?: boolean;
};

const HeaderProfilePic = (props: HeaderProfilePicProps) => {
  const { accountData, isOnProfilePage, isOnCreatorDashboard } = props;
  const mixpanel = useContext(MixpanelContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { theme } = useTheme(0, true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [profilePanelVisible, setProfilePanelVisible] = useState(false);
  const { pathname } = useLocation();

  // Determines if the user is currently on their own profile page or not
  const isOnOwnProfilePage = useMemo(() => {
    const pathnameParts = pathname.split("/");
    return pathnameParts.some((part) => part === accountData.username);
  }, [accountData.username, pathname]);

  const logout = useCallback(() => {
    try {
      mixpanel.track("Webapp: User Logout", {
        distinct_id: accountData.uid,
      });
      dispatch(logoutUser());
      navigate("/welcome");
    } catch (e: any) {
      alert("There was an error logging out. Please try again.");
      console.log(e.message);
    }
  }, [accountData.uid, dispatch, mixpanel, navigate]);

  const ref = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (
        dropdownVisible &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdownVisible]);

  const viewProfileOnPress = useCallback(() => {
    if (accountData.inCreatorMode) {
      navigate("/home");
    } else {
      // Needed this case because the states in profile page were not updating when navigating while on a profile page
      if (isOnProfilePage) {
        window.location.href = MARKITAI + `/u/${accountData.username}`;
      } else {
        dispatch(DataLoaders.user(accountData.uid));
        navigate(`/u/${accountData.username}`, {
          state: { username: accountData.username },
        });
      }
    }
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    accountData.username,
    dispatch,
    isOnProfilePage,
    navigate,
  ]);

  const switchModeOnPress = useCallback(() => {
    dispatch(toggleInCreatorMode(accountData.uid, accountData.inCreatorMode));
    if (!accountData.inCreatorMode) {
      navigate("/home");
    } else {
      if (isOnProfilePage) {
        window.location.href = MARKITAI + `/u/${accountData.username}`;
      } else {
        dispatch(DataLoaders.user(accountData.uid));
        navigate(`/u/${accountData.username}`, {
          state: { username: accountData.username },
        });
      }
    }
  }, [
    accountData.inCreatorMode,
    accountData.uid,
    accountData.username,
    dispatch,
    isOnProfilePage,
    navigate,
  ]);

  const profileOnPress = useCallback(() => {
    if (accountData.inCreatorMode && isDesktop) {
      setProfilePanelVisible(true);
    } else {
      setDropdownVisible(!dropdownVisible);
    }
  }, [accountData.inCreatorMode, dropdownVisible]);

  return (
    <>
      <div ref={ref}>
        <div
          aria-expanded={dropdownVisible ? "true" : "false"}
          onClick={profileOnPress}
          style={{ cursor: "pointer" }}
        >
          {accountData.profilePicURL && accountData.profilePicURL !== "" ? (
            <img
              style={{
                height: 35,
                width: 35,
                borderRadius: 35 / 2,
                objectFit: "cover",
              }}
              src={accountData.profilePicURL}
              alt="ProfilePagePic"
            />
          ) : (
            <div
              style={{
                height: 35,
                width: 35,
                borderRadius: 35 / 2,
                backgroundColor: "rgb(17, 184, 255, 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 16,
              }}
            >
              <InitialsPic fullName={accountData.fullName} />
            </div>
          )}
        </div>
        <AnimatePresence>
          {dropdownVisible ? (
            <LazyMotion features={domAnimation}>
              <m.div
                key="profilePicDropdown"
                className="HeaderProfilePicDropdown HideScrollbar"
                style={{ right: isDesktop ? "24px" : "14px" }}
                initial={{
                  y: "-20px",
                  opacity: 0,
                  filter: "blur(2px)",
                }}
                animate={{ y: "0px", opacity: 1, filter: "blur(0px)" }}
                exit={{ y: "-20px", opacity: 0, filter: "blur(2px)" }}
                transition={{ duration: 0.2, ease: "easeOut" }}
              >
                <div className="AlignedRow" style={{ gap: "10px" }}>
                  {accountData.profilePicURL &&
                  accountData.profilePicURL !== "" ? (
                    <img
                      style={{
                        height: 35,
                        width: 35,
                        borderRadius: 35 / 2,
                      }}
                      src={accountData.profilePicURL}
                      alt="ProfilePagePic"
                    />
                  ) : (
                    <div
                      style={{
                        height: 35,
                        width: 35,
                        borderRadius: 35 / 2,
                        backgroundColor: "rgb(17, 184, 255, 0.5)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        fontWeight: 500,
                        fontSize: 16,
                      }}
                    >
                      <InitialsPic fullName={accountData.fullName} />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        ...theme.PrimaryText,
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      {accountData.fullName}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: Colors.GRAY1,
                      }}
                    >
                      @{accountData.username}
                    </p>
                  </div>
                </div>
                {/* VIEW PROFILE ITEM */}
                {!isOnOwnProfilePage && !isOnCreatorDashboard && (
                  <>
                    <hr style={theme.DividerColor} />
                    <p
                      style={{
                        ...theme.PrimaryText,
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onClick={viewProfileOnPress}
                    >
                      View Profile
                    </p>
                  </>
                )}
                {/* SWITCH CREATOR / ATTENDEE MODE ITEM */}
                <hr style={theme.DividerColor} />
                <div
                  className="AlignedRowSpaced"
                  onClick={switchModeOnPress}
                  style={{ cursor: "pointer" }}
                >
                  <p
                    style={{
                      ...theme.PrimaryText,
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {accountData.inCreatorMode
                      ? "Switch to Attendee"
                      : "Switch to Hosting"}
                  </p>
                  <Icon icon={"mdi:swap-horizontal-variant"} height={15} />
                </div>
                {/* SIGN OUT ITEM */}
                <hr style={theme.DividerColor} />
                <p
                  style={{
                    ...theme.PrimaryText,
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                  onClick={logout}
                >
                  Sign Out
                </p>
              </m.div>
            </LazyMotion>
          ) : null}
        </AnimatePresence>
      </div>
      <ProfilePopupPanel
        isVisible={profilePanelVisible}
        setIsVisible={setProfilePanelVisible}
      />
    </>
  );
};

export default HeaderProfilePic;
