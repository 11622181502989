import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";

type EventbriteIconProps = { size: number; padding: number };

const EventbriteIcon = (props: EventbriteIconProps) => {
  const { size, padding } = props;

  return (
    <div
      className="Centering"
      style={{
        borderRadius: 10,
        padding: padding,
        backgroundColor: "#FA6345",
      }}
    >
      <Icon icon="simple-icons:eventbrite" height={size} color={Colors.WHITE} />
    </div>
  );
};

export default EventbriteIcon;
