import React, { useCallback } from "react";
import PopupModalContainer from "./PopupModalContainer";
import RectangleButton from "../Buttons/RectangleButton";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";

export interface ProgressActionStatus {
  modalVisible: boolean;
  state: "completed" | "in progress" | "failed";
  numUsers?: number;
}

type ProgressActionModalProps = {
  actionStatus: ProgressActionStatus;
  setActionStatus: (importStatus: ProgressActionStatus) => void;
  failureMessage: string;
  inProgressMessage: string;
  successMessage: string;
  addOnCompletion?: () => void;
};

// Displays the status of an action currently in progress
const ProgressActionModal = (props: ProgressActionModalProps) => {
  const {
    actionStatus,
    setActionStatus,
    failureMessage,
    inProgressMessage,
    successMessage,
    addOnCompletion,
  } = props;

  const closeModal = useCallback(() => {
    setActionStatus({
      modalVisible: false,
      state: "completed",
      numUsers: 0,
    });
    if (actionStatus.state === "completed" && addOnCompletion) {
      addOnCompletion();
    }
  }, [actionStatus.state, addOnCompletion, setActionStatus]);

  return (
    <PopupModalContainer
      headerComp={
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <Icon
            icon={
              actionStatus.state === "in progress"
                ? "mdi:dots-circle"
                : actionStatus.state === "failed"
                ? "ion:close-circle"
                : "ion:checkmark-circle"
            }
            height={35}
            color={
              actionStatus.state === "in progress"
                ? Colors.BLACK
                : actionStatus.state === "failed"
                ? Colors.RED2
                : Colors.GREEN2
            }
          />
          <span>
            Import{" "}
            {actionStatus.state === "in progress"
              ? "in Progress"
              : actionStatus.state === "failed"
              ? "Failed"
              : "Complete"}
          </span>
        </div>
      }
      valueComp={
        <RectangleButton
          buttonLabel="Done"
          onPress={closeModal}
          disabled={actionStatus.state === "in progress"}
          loading={actionStatus.state === "in progress"}
          altTextColor={Colors.WHITE}
          altColor={Colors.BLACK}
          altPaddingVert={14}
        />
      }
      subHeaderComp={
        <div style={{ marginTop: 7, fontSize: 14, color: Colors.GRAY1 }}>
          {actionStatus.state === "in progress"
            ? inProgressMessage
            : actionStatus.state === "failed"
            ? failureMessage
            : successMessage}
        </div>
      }
      closeModal={closeModal}
      noExit
      hideModal={!actionStatus.modalVisible}
    />
  );
};

export default ProgressActionModal;
