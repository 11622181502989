import { useCallback, useMemo, useState } from "react";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import HelpModal from "../../../components/Containers/HelpModal";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";
import SubscriptionDetails from "../../../components/Subscription/SubscriptionDetails";
import { Colors } from "../../../utils/colors";
import StandardBorderedContainer from "../../../components/Containers/StandardBorderedContainer";
import {
  MARKITAI,
  MARKIT_HOTLINE_NUMBER,
  isBusinessSubscription,
  mostRecentSubscriptionPlan,
} from "@markit/common.utils";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { RWebShare } from "react-web-share";
import { onShareLink } from "../../../utils/shareLink";
import { Icon } from "@iconify/react";
import SubscriptionPlanCard from "../../../components/Subscription/SubscriptionPlanCard";
import RectangleButton from "../../../components/Buttons/RectangleButton";
import { formatPhoneNumber } from "../../../utils/FormatPhoneNumber";
import { PlanTypeV4 } from "@markit/common.types";
import { FormatCurrency } from "../../../utils/FormatCurrency";

const BillingPanel = () => {
  const { accountData } = useSelector(getAccountState).account;
  const [helpModal, setHelpModal] = useState(false);

  const styles = {
    bodyMedium: {
      fontSize: 14,
      color: Colors.GRAY1,
    },
    bodyMediumBold: {
      fontSize: 14,
      fontWeight: 500,
      color: Colors.BLACK,
    },
  };

  const subscriptionPlan = useMemo(
    () => mostRecentSubscriptionPlan(accountData) ?? PlanTypeV4.FREE,
    [accountData]
  );

  const shareMarkit = useCallback(async () => {
    await onShareLink("Share Markit with your friends!", MARKITAI);
  }, []);

  return (
    <div className="ColumnNormal">
      <div className="CreatorPanelContainer">
        <div className="AlignedRowSpaced">
          <h2>Billing</h2>
          <div className="AlignedRow" style={{ gap: 14 }}>
            <RectangleButtonCreatorPanel
              title="Contact Sales"
              onPress={() => setHelpModal(true)}
              iconName="ic:round-arrow-outward"
              addHover
            />
            <MarkitPlusActionButton mainAction />
          </div>
        </div>
        <StandardBorderedContainer
          containerStyles={{ marginBlock: 28, padding: 14, borderRadius: 12 }}
        >
          <span style={styles.bodyMedium}>
            You are currently on the{" "}
            <span style={styles.bodyMediumBold}>{subscriptionPlan.name}</span>{" "}
            Monthly plan for{" "}
            <span style={styles.bodyMediumBold}>
              {FormatCurrency(subscriptionPlan.price / 100)} per month
            </span>
            .{" "}
            {isBusinessSubscription(accountData) ? "" : "Upgrade at any time."}
          </span>
        </StandardBorderedContainer>
      </div>
      <div
        className="ColumnNormal HideScrollbar"
        style={{
          backgroundColor: Colors.WHITE1,
          padding: "30px 48px 80px 48px",
        }}
      >
        <div className="RowNormal" style={{ gap: 24 }}>
          <div className="ColumnNormal" style={{ gap: 24, flex: 1 }}>
            <SubscriptionDetails />
            <StandardBorderedContainer
              containerStyles={{
                backgroundColor: Colors.WHITE,
                borderRadius: 20,
                padding: 14,
              }}
            >
              <RWebShare data={{ url: MARKITAI }}>
                <div className="AlignedRowSpacedSelect" onClick={shareMarkit}>
                  <span style={styles.bodyMediumBold}>Share Markit</span>
                  <Icon
                    icon="mdi:chevron-right"
                    height={24}
                    color={Colors.GRAY1}
                  />
                </div>
              </RWebShare>
            </StandardBorderedContainer>
          </div>
          <div className="ColumnNormal" style={{ flex: 1, gap: 24 }}>
            <SubscriptionPlanCard
              cardButton={
                <RectangleButton
                  buttonLabel="Current Plan"
                  onPress={() => {}}
                  altColor={Colors.GRAY6}
                  disabled
                  altPaddingHorz={14}
                  altPaddingVert={14}
                  containerStyles={{ marginTop: 10 }}
                />
              }
              subscriptionPlan={subscriptionPlan}
            />
            <span style={{ ...styles.bodyMedium, textAlign: "center" }}>
              For enterprise or other questions contact sales at{" "}
              <span style={{ color: Colors.BLACK }}>
                {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
              </span>
            </span>
          </div>
        </div>
      </div>
      <HelpModal showModal={helpModal} setShowModal={setHelpModal} />
    </div>
  );
};

export default BillingPanel;
