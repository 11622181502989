import CustomCheckbox from "../../../CustomCheckbox";
import React, { useMemo } from "react";
import { Colors } from "../../../../utils/colors";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";

type RecipientsAddAllItemProps = {
  label: string;
  sublabel: string | React.ReactNode;
  selectRecipients: string[];
  isAllSelected: boolean;
  onPress: () => void;
  excludingMode: boolean;
  isCategories?: boolean;
  disabled?: boolean;
  noDivider?: boolean;
};

export const RecipientsAddAllItem = (props: RecipientsAddAllItemProps) => {
  const {
    label,
    sublabel,
    selectRecipients,
    isAllSelected,
    onPress,
    excludingMode,
    isCategories,
    disabled,
    noDivider,
  } = props;

  const iconColor = useMemo(
    () =>
      excludingMode
        ? Colors.RED3
        : isCategories
        ? Colors.PURPLE7
        : Colors.BLUE5,
    [excludingMode, isCategories]
  );

  const isDisabled = useMemo(
    () => (selectRecipients.length === 0 && !isCategories) || disabled,
    [disabled, isCategories, selectRecipients.length]
  );

  const markAsSelected = useMemo(
    () => isAllSelected && (selectRecipients.length > 0 || isCategories),
    [isAllSelected, isCategories, selectRecipients.length]
  );

  return (
    <div>
      <div
        className="AlignedRowSpacedSelect"
        onClick={onPress}
        style={{
          paddingBlock: 14,
          opacity: isDisabled ? 0.5 : 1,
          pointerEvents: isDisabled ? "none" : "all",
          gap: 10,
        }}
      >
        <div className="ColumnNormal" style={{ gap: 4 }}>
          <div style={{ fontSize: 14, fontWeight: 600 }}>{label}</div>
          {typeof sublabel === "string" ? (
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
              }}
            >
              {selectRecipients.length} {sublabel}
            </span>
          ) : (sublabel as React.ReactNode) ? (
            sublabel
          ) : null}
        </div>
        <CustomCheckbox
          checked={markAsSelected}
          sx={{ padding: 0 }}
          style={{
            opacity: disabled ? 0.4 : 1,
            pointerEvents: disabled ? "none" : "all",
          }}
          altColor={iconColor}
        />
      </div>
      {!noDivider ? <HorizontalDivider altMargin={5} /> : null}
    </div>
  );
};
