import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "../../css/GlobalStyles.css";
import "../../css/ProfilePreviewModal.css";
import { Colors } from "../../utils/colors";
import PopupModalContainer from "../Containers/PopupModalContainer";
import {
  AccountData,
  Event,
  FormResponseV2,
  TicketV2,
} from "@markit/common.types";
import { Divider } from "@mui/material";
import RectangleButton from "../Buttons/RectangleButton";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { getUserFormResponseData } from "../../utils/FirebaseUtils";
import { FollowerProfileHeader } from "./FollowerProfileHeader";
import FullFollowerProfilePopupPanel from "./FullFollowerProfilePopupPanel";
import { MixpanelContext } from "../../context/AnalyticsService";
import { MarkitPlusModal } from "../Subscription/MarkitPlusModal";
import AddToListModal from "../CreatorDashboard/CreatorProfile/AudienceLists/AddToListModal";

interface ProfilePreviewModalProps {
  userData: AccountData;
  event?: Event;
  userTicket?: TicketV2 | undefined;
  messageOnPress: (item: AccountData | undefined) => void;
  setProfileSelected: (userData: AccountData | undefined) => void;
  hideProfilePreview: boolean;
  setHideProfilePreview: (hideProfilePreview: boolean) => void;
  fullProfileOpen: boolean;
  setFullProfileOpen: (fullProfileOpen: boolean) => void;
  setProfileResponseModalOpen?: (profileResponseModalOpen: boolean) => void;
}

const ProfilePreviewModal = (props: ProfilePreviewModalProps) => {
  const {
    userData,
    event,
    userTicket,
    messageOnPress,
    setProfileSelected,
    hideProfilePreview,
    setHideProfilePreview,
    fullProfileOpen,
    setFullProfileOpen,
    setProfileResponseModalOpen,
  } = props;
  const mixpanel = useContext(MixpanelContext);
  const { accountData } = useSelector((state: AppState) => state.account);
  const [markitPlusModalVisible, setMarkitPlusModalVisible] = useState(false);
  const [listModalVisible, setListModalVisible] = useState(false);

  const [currFormResponse, setCurrFormResponse] = useState<
    FormResponseV2 | undefined | null
  >(null);

  const creatorUid = useMemo(
    () =>
      event && accountData.uid !== event.createdBy
        ? event.createdBy
        : accountData.uid,
    [accountData.uid, event]
  );

  useEffect(() => {
    (async () => {
      if (userData) {
        const formResponse = event
          ? await getUserFormResponseData(event.id, userData.uid)
          : undefined;
        setCurrFormResponse(formResponse);
      } else {
        setCurrFormResponse(null);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const noSubscriptionPlan = useMemo(
    () => accountData.customer.phone === "" || !userData,
    [accountData.customer.phone, userData]
  );

  const notHost = useMemo(
    () => creatorUid !== accountData.uid,
    [accountData.uid, creatorUid]
  );

  const navigateFormResponse = useCallback(() => {
    if (setProfileResponseModalOpen) {
      setProfileResponseModalOpen(true);
    }
  }, [setProfileResponseModalOpen]);

  const conversationOnPress = useCallback(() => {
    if (noSubscriptionPlan) {
      mixpanel.track("Pressed Get Markit+ Lead Button", {
        button_location: "message on profile view",
      });

      setMarkitPlusModalVisible(true);
      setHideProfilePreview(true);
    } else {
      setHideProfilePreview(true);
      messageOnPress(userData);
    }
  }, [
    messageOnPress,
    mixpanel,
    noSubscriptionPlan,
    setHideProfilePreview,
    userData,
  ]);

  const fullProfileOnPress = useCallback(() => {
    setFullProfileOpen(true);
    setHideProfilePreview(true);
  }, [setFullProfileOpen, setHideProfilePreview]);

  const goBackToProfilePreview = useCallback(() => {
    setFullProfileOpen(false);
    setListModalVisible(false);
    setHideProfilePreview(false);
  }, [setFullProfileOpen, setHideProfilePreview]);

  const addToListOnPress = useCallback(() => {
    setListModalVisible(true);
    setHideProfilePreview(true);
  }, [setHideProfilePreview]);

  const closeAddToListOnPress = useCallback(() => {
    if (fullProfileOpen) {
      setListModalVisible(false);
    } else {
      goBackToProfilePreview();
    }
  }, [fullProfileOpen, goBackToProfilePreview]);

  const closeModal = useCallback(() => {
    setMarkitPlusModalVisible(false);
    if (hideProfilePreview && !fullProfileOpen) {
      setHideProfilePreview(false);
    }
  }, [fullProfileOpen, hideProfilePreview, setHideProfilePreview]);

  return (
    <>
      {markitPlusModalVisible ? (
        <MarkitPlusModal closeModal={closeModal} />
      ) : null}
      <FullFollowerProfilePopupPanel
        userData={userData}
        fullFollowerProfilePopupPanelVisible={fullProfileOpen}
        goBackToProfilePreview={goBackToProfilePreview}
        conversationOnPress={conversationOnPress}
        addToListOnPress={addToListOnPress}
      />
      {listModalVisible ? (
        <AddToListModal
          isVisible={listModalVisible}
          closeOnPress={closeAddToListOnPress}
          userId={userData.uid}
        />
      ) : null}
      <PopupModalContainer
        headerComp={<span className="AboutSubtitle">Preview </span>}
        valueComp={
          <>
            <FollowerProfileHeader
              fullProfile={false}
              creatorUid={creatorUid}
              userData={userData}
              fullProfileOnPress={fullProfileOnPress}
              addToListOnPress={addToListOnPress}
              conversationOnPress={() => {
                conversationOnPress();
              }}
              userTicket={userTicket}
            />
            <Divider style={{ marginBlock: 14 }} />
            <div className="AlignedRow" style={{ gap: 10 }}>
              {event ? (
                <button
                  onClick={navigateFormResponse}
                  disabled={!currFormResponse}
                  className="RectangleButton"
                  style={{
                    opacity: !currFormResponse ? 0.4 : 1,
                    paddingBlock: 10,
                    backgroundColor: notHost ? Colors.BLACK : Colors.GRAY6,
                    cursor: !currFormResponse ? "auto" : "pointer",
                  }}
                >
                  <p
                    style={{
                      color: notHost ? Colors.WHITE : Colors.BLACK,
                      fontWeight: "600",
                      fontSize: 14,
                      cursor: !currFormResponse ? "auto" : "pointer",
                    }}
                  >
                    {currFormResponse === undefined
                      ? "No Form Responses"
                      : currFormResponse === null
                      ? "Loading..."
                      : "Form Responses"}
                  </p>
                </button>
              ) : null}
              {!notHost ? (
                <RectangleButton
                  buttonLabel="Message"
                  onPress={conversationOnPress}
                  altColor={Colors.BLACK}
                  altTextColor={Colors.WHITE}
                />
              ) : null}
            </div>
          </>
        }
        noExit
        closeOnOutsidePress
        closeModal={() => {
          setProfileSelected(undefined);
          setCurrFormResponse(undefined);
        }}
        hideModal={hideProfilePreview}
      />
    </>
  );
};

export default ProfilePreviewModal;
