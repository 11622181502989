import { useCallback, useMemo, useState } from "react";
import { Colors } from "../../../utils/colors";
import { AccountData, Conversation } from "@markit/common.types";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMoreConversations,
  loadConversationSearchResultsThrottled,
} from "../../../redux/slices/conversationSlice";
import FlatList from "flatlist-react/lib";
import { CircularProgress } from "@mui/material";
import ConversationsSidebarPreview from "./ConversationsSidebarPreview";
import ConversationsMainView from "./ConversationsMainView";
import filter from "lodash.filter";
import { Icon } from "@iconify/react";
import {
  hasSubscription,
  isSubscriptionPaymentFailed,
} from "@markit/common.utils";
import ProfilePreviewModal from "../../FollowerProfile/ProfilePreviewModal";
import { ConversationsMarkitPlusPrompt } from "../../Containers/ConversationsMarkitPlusPrompt";
import BlurredConversationPreview from "../../../assets/FreeTierConversationBlurred.png";
import EmptyStateButton from "../../Buttons/EmptyStateButton";
import ShareProfilePopupPanel from "../CreatorProfile/ShareProfilePopupPanel";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import SearchBoxContainer from "../../Containers/SearchBoxContainer";
import { MarkitPlusModal } from "../../Subscription/MarkitPlusModal";
import { EmptyStateFlatlist } from "../../EmptyStates/EmptyStateFlatlist";

export enum ConversationOption {
  ALL = "all",
  UNREAD = "unread",
}

type ConversationsPanelProps = {
  conversationMainUser: AccountData | undefined;
  setConversationMainUser: (
    conversationMainUser: AccountData | undefined
  ) => void;
};

const ConversationsPanel = (props: ConversationsPanelProps) => {
  const { conversationMainUser, setConversationMainUser } = props;
  const dispatch = useDispatch();
  const { account, conversations } = useSelector(getAccountState);
  const { accountData, followingAccountData } = account;
  const [searchTerm, setSearchTerm] = useState("");
  const [newUserSearchTerm, setNewUserSearchTerm] = useState("");
  const [newConversation, setNewConversation] = useState(false);
  const [profilePreviewSelected, setProfilePreviewSelected] =
    useState<AccountData>();
  const [hideProfilePreview, setHideProfilePreview] = useState(false);
  const [fullProfileOpen, setFullProfileOpen] = useState(false);
  const [shareLinkModal, setShareLinkModal] = useState(false);
  const [markitPlusModalVisible, setMarkitPlusModalVisible] = useState(false);

  const {
    userConversations,
    lastVisibleConversation,
    isConversationsLoading,
    loadedAllConversations,
  } = conversations;

  const styles = {
    newConversationButton: {
      padding: 12,
      borderRadius: 8,
      backgroundColor: Colors.GRAY11,
      cursor: "pointer",
    },
  };

  const handleSearch = (text: string) => {
    if (text !== "") {
      dispatch(
        loadConversationSearchResultsThrottled(
          text.toLowerCase(),
          accountData.uid
        )
      );
    }
    setSearchTerm(text.toLowerCase());
  };

  const containsUser = useCallback(
    (item: Conversation, query: string) => {
      const foundUser = followingAccountData.find(
        (user) => user.uid === item.participantUids[0]
      );
      if (foundUser) {
        return foundUser.fullName.toLowerCase().includes(query);
      }
      return false;
    },
    [followingAccountData]
  );

  const conversationsToShow = useMemo(() => {
    if (searchTerm === "") {
      return userConversations;
    }
    let conversationList: Conversation[] = userConversations;
    conversationList = filter(conversationList, (convo: Conversation) => {
      return containsUser(convo, searchTerm);
    });

    return conversationList;
  }, [searchTerm, userConversations, containsUser]);

  const newConversationOnPress = useCallback(() => {
    if (hasSubscription(accountData)) {
      setNewConversation(true);
      setNewUserSearchTerm("");
      setConversationMainUser(undefined);
    } else {
      setMarkitPlusModalVisible(true);
    }
  }, [accountData, setConversationMainUser]);

  const closeNewConversationOnPress = useCallback(() => {
    const foundUser = followingAccountData.find(
      (user) => user.uid === userConversations[0].participantUids[0]
    );
    setNewConversation(false);
    setNewUserSearchTerm("");
    setConversationMainUser(foundUser);
  }, [followingAccountData, setConversationMainUser, userConversations]);

  const profilePreviewOnPress = useCallback((item: AccountData) => {
    setProfilePreviewSelected(item);
  }, []);

  // set chat user to display on conversationsmainview
  const displayConversationsMainView = useCallback(
    async (item: Conversation) => {
      if (isSubscriptionPaymentFailed(accountData.customer.state)) {
        // setPaymentInfoVisible(true);
        // @TODO: Show modal about payment info
      } else {
        setNewConversation(false);
        const mainUser = followingAccountData.find(
          (user) => user.uid === item.participantUids[0]
        );
        setConversationMainUser(mainUser);
      }
    },
    [accountData.customer.state, followingAccountData, setConversationMainUser]
  );

  const closeMarkitPlusModal = useCallback(() => {
    setMarkitPlusModalVisible(false);
  }, []);

  const renderFreeTierMessageTitle = useMemo(() => {
    if (accountData.numFreeTierReplies > 1) {
      // 2 or more people have tried to message free tier user
      return `${accountData.numFreeTierReplies} people tried to message you`;
    } else if (accountData.numFreeTierReplies === 1) {
      return "Someone tried to message you"; // 1 person tried to message
    }
    // 0 people have tried to message free tier user
    return "When someone tries to message you";
  }, [accountData.numFreeTierReplies]);

  const renderEmptyFree = useMemo(() => {
    return (
      <div>
        <div style={{ paddingInline: 14, marginBottom: 4, marginTop: -10 }}>
          <ConversationsMarkitPlusPrompt
            title={renderFreeTierMessageTitle}
            subtext={
              "You can chat with and answer people's questions by upgrading to Markit+"
            }
            containerStyles={{ marginTop: 10 }}
            onPress={() => {
              setMarkitPlusModalVisible(true);
            }}
          />
        </div>
        {accountData.numFreeTierReplies > 0 ? (
          <img
            src={BlurredConversationPreview}
            alt="Blurred conversation list preview for free tier"
            style={{ width: "100%" }}
          />
        ) : null}
      </div>
    );
  }, [accountData.numFreeTierReplies, renderFreeTierMessageTitle]);

  const renderEmptySubView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isConversationsLoading}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title="Start a Conversation"
            description="You currently have no conversations"
            icon={<Icon icon="ion:people" height={50} color={Colors.GRAY1} />}
            iconBox={70}
            containerStyles={{ paddingTop: 120 }}
          />
        }
      />
    );
  }, [isConversationsLoading, searchTerm]);

  return (
    <StandardBorderedContainer
      containerStyles={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 20,
      }}
    >
      {/* Conversations Sidebar */}
      <div className="ConversationsPanelLeftContainer">
        <div
          className="AlignedRowSpaced"
          style={{
            padding: 14,
            paddingBlock: 18,
            borderBottom: `${
              hasSubscription(accountData) && conversationsToShow.length === 0
                ? "1.5px"
                : "0px"
            } solid ${Colors.GRAY11}`,
            gap: 10,
          }}
        >
          <SearchBoxContainer
            placeholder={"Search Conversations"}
            onChange={(e) => handleSearch(e.target.value)}
            containerStyles={{ marginBlock: 0 }}
          />
          <div
            onClick={newConversationOnPress}
            style={styles.newConversationButton}
            className="AlignedRow"
          >
            <Icon
              icon={"feather:edit"}
              height={18}
              style={{ color: Colors.BLACK }}
            />
          </div>
        </div>
        {newConversation ? (
          <div
            className="AlignedRowSpaced ConversationSidebarItem"
            style={{ backgroundColor: Colors.GRAY6 }}
          >
            <div className="AlignedRow" style={{ gap: 10 }}>
              <Icon
                icon={"mdi:person-circle-outline"}
                height={45}
                style={{ color: Colors.GRAY2 }}
              />
              <span className="AboutSubtitle">New Conversation</span>
            </div>
            <Icon
              onClick={closeNewConversationOnPress}
              icon={"mdi:close"}
              height={20}
              style={{
                color: Colors.GRAY2,
                marginRight: 14,
                pointerEvents: userConversations.length > 0 ? "all" : "none",
              }}
            />
          </div>
        ) : null}
        <div
          className="HideScrollbar ColumnNormal"
          style={{
            overflow: "scroll",
            height: newConversation
              ? "calc(100vh - 330px"
              : "calc(100vh - 260px)",
          }}
        >
          <FlatList
            list={conversationsToShow}
            keyExtractor={(item: Conversation) => item.conversationSid}
            renderItem={(item) => {
              return (
                <div key={item.conversationSid}>
                  <ConversationsSidebarPreview
                    accountData={accountData}
                    followingAccountData={followingAccountData}
                    conversationItem={item}
                    conversationsMainViewChatUser={conversationMainUser}
                    selectConversationMainView={displayConversationsMainView}
                  />
                </div>
              );
            }}
            renderWhenEmpty={() =>
              !hasSubscription(accountData)
                ? renderEmptyFree
                : renderEmptySubView
            }
            hasMoreItems={!loadedAllConversations}
            loadMoreItems={() => {
              if (!loadedAllConversations && !isConversationsLoading) {
                dispatch(
                  fetchMoreConversations(
                    accountData.uid,
                    loadedAllConversations,
                    isConversationsLoading,
                    lastVisibleConversation
                  )
                );
              }
            }}
            paginationLoadingIndicator={() =>
              isConversationsLoading ? (
                <CircularProgress style={{ color: "#929292" }} size={30} />
              ) : null
            }
            paginationLoadingIndicatorPosition="center"
          />
        </div>
      </div>
      {/* Conversations Main View */}
      <div className="ConversationsPanelRightContainer">
        {conversationMainUser !== undefined || newConversation ? (
          <div key={conversationMainUser?.uid}>
            <ConversationsMainView
              conversationsMainUser={conversationMainUser}
              setConversationsMainUser={setConversationMainUser}
              isNewConversation={newConversation}
              setIsNewConversation={setNewConversation}
              profilePreviewOnPress={(item: AccountData) =>
                profilePreviewOnPress(item)
              }
              profilePreviewVisible={profilePreviewSelected !== undefined}
              newUserSearchTerm={newUserSearchTerm}
              setNewUserSearchTerm={setNewUserSearchTerm}
            />
          </div>
        ) : (
          <div>
            {/* No conversation selected state render */}
            <div
              style={{
                padding: 14,
                paddingBlock: 18,
                borderBottom: "solid",
                borderColor: Colors.GRAY11,
              }}
            >
              <div className="AlignedRow">
                <Icon
                  icon="ion:person-circle"
                  height={42}
                  style={{ color: Colors.GRAY1, marginRight: 8 }}
                />
                <span style={{ color: Colors.GRAY1, fontWeight: 400 }}>
                  {conversationsToShow.length === 0 ? "No Conversations" : null}
                </span>
              </div>
            </div>
            <div style={{ marginTop: 120 }}>
              {account.numFollowers === 0 ? (
                <EmptyStateButton
                  title={"No Followers"}
                  description="You have no conversations. Conversations are created when your followers text you back."
                  btnText="Build Following"
                  onPress={() => {
                    setShareLinkModal(true);
                  }}
                  icon={
                    <Icon
                      icon="ion:people"
                      height={50}
                      style={{ color: Colors.GRAY1 }}
                    />
                  }
                  iconBox={70}
                  iconBackgroundColor={Colors.GRAY6}
                />
              ) : accountData.numFreeTierReplies >= 0 &&
                !hasSubscription(accountData) ? (
                <EmptyStateButton
                  title={"You don't have access to two-way texting"}
                  description="Upgrade to Markit+ to chat back and forth with attendees"
                  btnText="Learn More"
                  onPress={() => setMarkitPlusModalVisible(true)}
                  icon={
                    <Icon
                      icon="ion:chatbubble-ellipses"
                      height={50}
                      style={{ color: Colors.GRAY1 }}
                    />
                  }
                  iconBox={70}
                  iconBackgroundColor={Colors.GRAY6}
                />
              ) : conversationsToShow.length === 0 ? (
                <EmptyStateButton
                  title={"No Conversations"}
                  description="Conversations are created when people respond to your texts"
                  btnText="New Text"
                  onPress={newConversationOnPress}
                  icon={
                    <Icon
                      icon="ion:chatbubble-ellipses"
                      height={50}
                      style={{ color: Colors.GRAY1 }}
                    />
                  }
                  iconBox={70}
                  iconBackgroundColor={Colors.GRAY6}
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
      <ShareProfilePopupPanel
        isVisible={shareLinkModal}
        setIsVisible={setShareLinkModal}
      />
      {profilePreviewSelected ? (
        <ProfilePreviewModal
          userData={profilePreviewSelected}
          setProfileSelected={setProfilePreviewSelected}
          messageOnPress={() => {}}
          hideProfilePreview={hideProfilePreview}
          setHideProfilePreview={setHideProfilePreview}
          fullProfileOpen={fullProfileOpen}
          setFullProfileOpen={setFullProfileOpen}
        />
      ) : null}
      {markitPlusModalVisible ? (
        <MarkitPlusModal closeModal={closeMarkitPlusModal} />
      ) : null}
    </StandardBorderedContainer>
  );
};

export default ConversationsPanel;
