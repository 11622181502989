import "../css/GlobalStyles.css";
import { LightTheme, ThemeStyle } from "../hooks/useTheme";
import { Colors } from "../utils/colors";
import IOSSwitch from "./IOSSwitch";

type SwitchToggleItemProps = {
  title: string;
  description: string;
  toggleValue: boolean;
  onChange: () => void;
  theme?: ThemeStyle;
  disabled?: boolean;
  hideToggle?: boolean;
};

const SwitchToggleItem = (props: SwitchToggleItemProps) => {
  const {
    title,
    description,
    toggleValue,
    onChange,
    theme,
    disabled,
    hideToggle,
  } = props;

  return (
    <div
      className="AlignedRowSpaced"
      style={{ gap: 10, cursor: disabled ? "auto" : "pointer" }}
    >
      <div>
        <div
          style={{
            color:
              theme === undefined || theme === LightTheme
                ? Colors.BLACK
                : Colors.WHITE,
            opacity: disabled ? 0.4 : 1,
            fontWeight: "500",
            fontSize: 14,
          }}
        >
          {title}
        </div>
        <div
          style={{
            paddingTop: 4,
            fontSize: 12,
            fontWeight: "400",
            color: Colors.GRAY1,
            opacity: disabled ? 0.4 : 1,
            width: "100%",
          }}
        >
          {description}
        </div>
      </div>
      {!hideToggle ? (
        <IOSSwitch
          sx={{ m: 1 }}
          checked={toggleValue}
          onChange={onChange}
          disabled={disabled}
        />
      ) : null}
    </div>
  );
};

export default SwitchToggleItem;
