import { useState } from "react";
import {
  MassText,
  MassTextAttachment,
  TextTimelineItem,
} from "@markit/common.types";
import { onSnapshot, query, where } from "firebase/firestore";
import {
  textTimelineEndItem,
  textTimelineStartItem,
} from "@markit/common.utils";
import { generate } from "shortid";
import { useOnMount } from "../utils/useOnMount";
import { getEventData, getUserMassTextsRef } from "../utils/FirebaseUtils";
import { getMassTextAttachments } from "../utils/textingUtils";

type useLiveUpdatingEventTextsProps = {
  eventId: string;
};

export const useLiveUpdatingEventTexts = (
  props: useLiveUpdatingEventTextsProps
) => {
  const { eventId } = props;
  const [eventTextsLoading, setEventTextsLoading] = useState(true);
  const [regularTextsLoading, setRegularTextsLoading] = useState(true);
  const [eventMassTexts, setEventMassTexts] = useState<MassText[]>([]);
  const [textTimelineToShow, setTextTimelineToShow] = useState<
    (MassText | TextTimelineItem)[]
  >([]);

  const [regularMassTextsInRange, setRegularMassTextsInRange] = useState<
    MassText[]
  >([]);

  const [massTextAttachments, setMassTextAttachments] = useState<
    MassTextAttachment[]
  >([]);

  useOnMount(() => {
    (async () => {
      const eventData = await getEventData(eventId);

      if (eventData) {
        const userIdToShow = eventData.createdBy; // TODO (jonathan): maybe change in future if we want cohosts and others to have their own text timelines

        const eventStart: TextTimelineItem = textTimelineStartItem(
          eventData.start,
          generate()
        );

        const eventEnd: TextTimelineItem = textTimelineEndItem(
          eventData.end,
          generate()
        );

        // get all event texts
        const massTextsRefs = getUserMassTextsRef(userIdToShow);
        const eventTextsQuery = query(
          massTextsRefs,
          where("eventRefId", "==", eventData.id)
        );

        onSnapshot(eventTextsQuery, async (snapshot) => {
          if (snapshot.docs.length === 0) {
            setEventMassTexts([]);
            setTextTimelineToShow(
              [eventStart, eventEnd].sort((x, y) => {
                return (
                  new Date(x.sentAt).getTime() - new Date(y.sentAt).getTime()
                );
              })
            );
            setEventTextsLoading(false);
          } else {
            const currEventTexts = snapshot.docs.map((doc) => doc.data());
            setEventMassTexts(currEventTexts);
            setTextTimelineToShow(
              [...currEventTexts, eventStart, eventEnd].sort((x, y) => {
                return (
                  new Date(x.sentAt).getTime() - new Date(y.sentAt).getTime()
                );
              })
            );
            setEventTextsLoading(false);
          }
        });

        // get all mass texts sent after event
        const massTextsQuery = query(
          massTextsRefs,
          where("createdAt", ">=", eventData.createdAt)
        );

        onSnapshot(massTextsQuery, async (snapshot) => {
          if (snapshot.docs.length === 0) {
            setRegularMassTextsInRange([]);
            setRegularTextsLoading(false);
          } else {
            const currMassTexts = snapshot.docs.map((doc) => doc.data());
            setRegularMassTextsInRange(currMassTexts);

            // get the relevant mass text attachments from all event texts
            const massTextAttachments = await Promise.all(
              currMassTexts.map(async (massText) => {
                const attachments = await getMassTextAttachments(
                  userIdToShow,
                  massText
                );
                return attachments;
              })
            );
            const flatAttachments = massTextAttachments.reduce(
              (acc, attachments) => {
                return acc.concat(attachments);
              },
              []
            );
            setMassTextAttachments(flatAttachments);
            setRegularTextsLoading(false);
          }
        });
      }
    })();
  });

  return {
    eventTextsLoading,
    regularTextsLoading,
    eventMassTexts,
    textTimelineToShow,
    regularMassTextsInRange,
    massTextAttachments,
  };
};
