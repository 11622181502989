import "./css/App.css";
import { useContext, useEffect, useLayoutEffect } from "react";
import { useOnMount } from "./utils/useOnMount";
import { MixpanelContext } from "./context/AnalyticsService";
import { Route, Routes, useLocation } from "react-router-dom";
import EventRoute from "./routes/EventRoute";
import ProfileRoute from "./routes/ProfileRoute";
import SubscriptionRoute from "./routes/SubscriptionRoute";
import LoginRoute from "./routes/LoginRoute";
import ExploreRoute from "./routes/ExploreRoute";
import CommunityPage from "./screens/CommunityPage";
import LoadingScreen from "./screens/LoadingScreen";
import AdminDashboardRoute from "./routes/AdminDashboardRoute";
import { CreateEvent } from "./screens/CreateEvent/CreateEvent";
import { NavigationId } from "./navigation/AppParamList";
import { useDispatch, useSelector } from "react-redux";
import { accountActions, getAccountState } from "./redux/slices/accountSlice";
import { LightTheme, useTheme } from "./hooks/useTheme";
import DefaultLandingPage from "./screens/DefaultLandingPage";
import HomeRoute from "./routes/HomeRoute";
import CreateExternalLink from "./screens/CreateEvent/CreateExternalLink";
import { Helmet } from "react-helmet";
import ScrollToTop from "./utils/ScrollToTop";
import ImportContacts from "./screens/Import/ImportContacts";
import { useInitialAppLoad } from "./hooks/useInitialAppLoad";
import EventbriteIntegration from "./screens/Integrations/EventbriteIntegration";

export const WEBSITE = "https://markitsocial.net";

/**
 * Documentation of the different webapp URLs
 * https://docs.google.com/document/d/1E_lDN3HcWl1hQFY-UujdHVw2VtRTXMEjihSoSBm9Mwg
 *
 */
const App = () => {
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const { account } = useSelector(getAccountState);
  const { currentEvent, currentRoleTicket, currentEventFormQuestions } =
    account;
  const { pathname } = useLocation();
  // If we need to put app under maintenance
  const underMaintenance = false;
  const { theme } = useTheme();

  useInitialAppLoad();
  useOnMount(() => {
    mixpanel.track("Viewed Webapp Link");
  });

  // called on unmount when leaving welcome route and clears the temporary event if it exists,
  // unless the user presses a certain button on the screen
  useEffect(() => {
    if (currentRoleTicket && !pathname.includes("/welcome")) {
      // for clearing accepting event role data
      dispatch(accountActions.setCurrentRoleTicket(undefined));
    } else if (
      currentEvent &&
      !currentRoleTicket &&
      !pathname.includes("/welcome")
    ) {
      // for clearing publishing event data
      dispatch(accountActions.setCurrentEvent(undefined));
    }
    if (
      currentEventFormQuestions.length > 0 &&
      !pathname.includes("/create") &&
      !pathname.includes("/edit") &&
      !pathname.includes("/createLink") &&
      !pathname.includes("/editLink") &&
      !pathname.includes("/welcome")
    ) {
      // for clearing the event selected saved form questions on event creation
      dispatch(accountActions.resetCurrentEventFormQuestions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // set the background color beyond the set window
  useLayoutEffect(() => {
    document.body.style.backgroundColor =
      theme === LightTheme ? "white" : "black";
  }, [theme, pathname]);

  // TODO (jasonx): turn this into something more typesafe
  if (underMaintenance) {
    return <LoadingScreen underMaintenance={underMaintenance} />;
  } else {
    return (
      <>
        <Helmet>
          <title>{"Markit"}</title>
          <meta name="og:title" content={"Markit"} />
          <meta name="og:description" content={""} />
        </Helmet>
        <ScrollToTop>
          <Routes>
            <Route path={NavigationId.HOME} element={<HomeRoute />} />
            <Route path={NavigationId.LOGIN} element={<LoginRoute />} />
            <Route
              path={NavigationId.SUBSCRIPTION_PLAN}
              element={<SubscriptionRoute />}
            />
            <Route path="/e/:eventId/*" element={<EventRoute />} />
            <Route path={"/create"} element={<CreateEvent />} />
            <Route path={"/createLink"} element={<CreateExternalLink />} />
            <Route path={NavigationId.IMPORT} element={<ImportContacts />} />
            <Route
              path={NavigationId.INTEGRATIONS}
              element={<EventbriteIntegration />}
            />
            <Route path="/u/:username/*" element={<ProfileRoute />} />
            <Route path="/explore/*" element={<ExploreRoute />} />
            <Route
              path={NavigationId.ADMIN_DASHBOARD}
              element={<AdminDashboardRoute />}
            />
            <Route path="/c/:communityId" element={<CommunityPage />} />
            <Route path="*" element={<DefaultLandingPage />} />
          </Routes>
        </ScrollToTop>
      </>
    );
  }
};

export default App;
