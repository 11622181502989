import React, { useCallback, useState } from "react";
import "../../css/GlobalStyles.css";
import PopupModalContainer from "../Containers/PopupModalContainer";
import {
  AccountData,
  Event,
  SavedFormQuestion,
  SavedFormResponse,
} from "@markit/common.types";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { getEventData } from "../../utils/FirebaseUtils";
import { useOnMount } from "../../utils/useOnMount";
import { useTheme } from "../../hooks/useTheme";
import SingleCustomDataItem from "./SingleCustomDataItem";
import { GetDateWithTime, isExternalGenericLink } from "@markit/common.utils";
import { Icon } from "@iconify/react";

interface SingleCustomDataProps {
  savedQuestion: SavedFormQuestion;
  formResponse: SavedFormResponse | undefined;
  userData: AccountData;
  closeSingleCustomData: () => void;
}

const SingleCustomData = (props: SingleCustomDataProps) => {
  const { savedQuestion, formResponse, userData, closeSingleCustomData } =
    props;
  const { accountData } = useSelector((state: AppState) => state.account);
  const { theme } = useTheme();

  const [sourceEvent, setSourceEvent] = useState<Event>();

  const editOnPress = useCallback(async () => {
    alert("Edit coming soon...");
  }, []);

  const styles = {
    sectionContainer: {
      marginTop: 4,
      paddingInline: 14,
      paddingBlock: 14,
      borderRadius: 12,
    },
  };

  useOnMount(() => {
    (async () => {
      if (formResponse && formResponse.eventId) {
        const event = await getEventData(formResponse.eventId);
        setSourceEvent(event);
      }
    })();
  });

  return (
    <PopupModalContainer
      headerComp={
        <span
          style={{
            color: theme.PrimaryText,
            fontSize: 16,
            fontWeight: "600",
          }}
        >
          Details
        </span>
      }
      headerLeftComp={
        <div className="LargePopupPanelClose" onClick={closeSingleCustomData}>
          <Icon icon={"mdi:close"} height={24} />
        </div>
      }
      headerRightComp={
        <div className="LargePopupPanelGrayButton" onClick={editOnPress}>
          <span
            style={{
              color: theme.PrimaryText,
              fontSize: 16,
              fontWeight: "600",
            }}
          >
            Edit
          </span>
        </div>
      }
      valueComp={
        <div
          style={{
            ...styles.sectionContainer,
            backgroundColor: theme.PrimaryBG.backgroundColor,
          }}
        >
          {savedQuestion && userData ? (
            <SingleCustomDataItem
              savedQuestion={savedQuestion}
              formResponse={formResponse}
              userData={userData}
              detailView
            />
          ) : null}
          <div style={{ marginBottom: 4, fontSize: 14 }}>
            <span style={{ ...theme.LabelText }}>Collected On</span>
          </div>
          <div>
            <span
              style={{
                color: formResponse
                  ? theme.PrimaryText.color
                  : theme.SubText.color,
                fontSize: 14,
              }}
            >
              {formResponse
                ? GetDateWithTime(formResponse.lastResponded, true)
                : "N/A"}
            </span>
          </div>
          <Divider style={{ marginBlock: 14 }} />
          <div style={{ marginBottom: 4 }}>
            <span style={{ ...theme.LabelText, fontSize: 14 }}>Source</span>
          </div>
          <div>
            <span
              style={{
                color: formResponse
                  ? theme.PrimaryText.color
                  : theme.SubText.color,
                fontSize: 14,
              }}
            >
              {formResponse
                ? sourceEvent
                  ? `${
                      isExternalGenericLink(sourceEvent.eventType)
                        ? "Submitted to Link:"
                        : "Went to Event:"
                    } ${sourceEvent.title}`
                  : "Followed Profile"
                : "N/A"}
            </span>
          </div>
        </div>
      }
      noExit
      closeOnOutsidePress
      closeModal={closeSingleCustomData}
      positionOnTop
    />
  );
};

export default SingleCustomData;
