import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  where,
  startAfter,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import { AccountData } from "@markit/common.types";
import {
  getUserAudienceListMembersRef,
  getUserData,
} from "../utils/FirebaseUtils";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";

type useLoadUserAudienceListMembersProps = {
  userId: string;
  audienceListId: string;
  windowSize: number;
};
export const useLoadUserAudienceListMembers = (
  props: useLoadUserAudienceListMembersProps
) => {
  const { userId, audienceListId, windowSize } = props;

  const [fetchedMemberUserData, setFetchedMemberUserData] = useState<
    AccountData[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedMemberUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const loadAudienceListUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const audienceListMembersRef = getUserAudienceListMembersRef(
        userId,
        audienceListId
      );

      const audienceListMembersQuery = query(
        audienceListMembersRef,
        orderBy("uid", "desc"),
        limit(windowSize)
      );

      const uploadedMembers = (
        await getDocs(audienceListMembersQuery)
      ).docs.map((document) => document.data().uid);

      setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(audienceListMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const memberUserData = message.doc.data();

            const userData = await getUserData(memberUserData.uid);
            if (userData) {
              addUserData([userData]);
            }
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [addUserData, audienceListId, isFinished, isLoading, userId, windowSize]);

  const loadMoreAudienceListUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const audienceListMembersRef = getUserAudienceListMembersRef(
        userId,
        audienceListId
      );
      const audienceListMembersQuery = query(
        audienceListMembersRef,
        orderBy("uid", "desc"),
        startAfter(lastVisible),
        limit(windowSize)
      );

      const uploadedMembers = (
        await getDocs(audienceListMembersQuery)
      ).docs.map((document) => document.data().uid);

      setLastVisible(uploadedMembers[uploadedMembers.length - 1]);
      if (uploadedMembers.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(audienceListMembersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const memberUserData = message.doc.data();

            const userData = await getUserData(memberUserData.uid);
            if (userData) {
              addUserData([userData]);
            }
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [
    addUserData,
    audienceListId,
    isFinished,
    isLoading,
    lastVisible,
    userId,
    windowSize,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchListMembers = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            const audienceListMembersRef = getUserAudienceListMembersRef(
              userId,
              audienceListId
            );
            const query_ = query(
              audienceListMembersRef,
              where("uid", "==", uid)
            );

            const unsubscribe = onSnapshot(query_, (snapshot) => {
              snapshot.docChanges().forEach(async (message) => {
                if (message.type === "added" || message.type === "modified") {
                  const memberUserData = message.doc.data();

                  const userData = await getUserData(memberUserData.uid);
                  if (userData) {
                    addUserData([userData]);
                  }
                }
              });
            });
            return unsubscribe;
          })
        );
        setIsLoading(false);
      };

      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchListMembers(userIds)
      );
    },
    [addUserData, audienceListId, userId]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedMemberUserData,
    loadAudienceListUsers,
    loadMoreAudienceListUsers,
    loadSearchResultsThrottled,
  };
};
