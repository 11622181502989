import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  query,
  startAfter,
  where,
  orderBy,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import { AccountData, TextRecipient } from "@markit/common.types";
import {
  getMassTextRecipientData,
  getMassTextRecipientsRef,
  getUserData,
} from "../utils/FirebaseUtils";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";

type useLoadTextRecipientsProps = {
  userId: string;
  massTextId: string;
  attachmentId?: string;
  windowSize: number;
};
export const useLoadTextRecipients = (props: useLoadTextRecipientsProps) => {
  const { userId, massTextId, attachmentId, windowSize } = props;

  const [fetchedTextRecipients, setFetchedTextRecipients] = useState<
    TextRecipient[]
  >([]);
  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const addTextRecipientData = useCallback(
    (newTextRecipient: TextRecipient[]) => {
      setFetchedTextRecipients((fetchedRecipient) =>
        uniqueVals(
          fetchedRecipient.concat(newTextRecipient),
          (recipient) => recipient.uid
        )
      );
    },
    []
  );

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  const loadTextRecipients = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const textRecipientsRef = getMassTextRecipientsRef(userId, massTextId);
      const textRecipientsQuery = attachmentId
        ? query(
            textRecipientsRef,
            orderBy(`trackingOverviews.${attachmentId}.numOpens`, "desc"),
            limit(windowSize)
          )
        : query(textRecipientsRef, orderBy("uid", "desc"), limit(windowSize));
      const uploadedItems = (await getDocs(textRecipientsQuery)).docs.map(
        (document) => document.data().uid
      );

      setLastVisible(uploadedItems[uploadedItems.length - 1]);
      if (uploadedItems.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(textRecipientsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const textRecipient = message.doc.data();

            const textRecipientData = await getMassTextRecipientData(
              userId,
              massTextId,
              textRecipient.uid
            );
            if (textRecipientData) {
              addTextRecipientData([textRecipientData]);
            }

            const userData = await getUserData(textRecipient.uid);
            if (userData) {
              addUserData([userData]);
            }
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [
    addTextRecipientData,
    addUserData,
    attachmentId,
    isFinished,
    isLoading,
    massTextId,
    userId,
    windowSize,
  ]);

  const loadMoreTextRecipients = useCallback(async () => {
    if (!isFinished && !isLoading) {
      setIsLoading(true);
      const textRecipientsRef = getMassTextRecipientsRef(userId, massTextId);
      const textRecipientsQuery = attachmentId
        ? query(
            textRecipientsRef,
            orderBy(`trackingOverviews.${attachmentId}.numOpens`, "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          )
        : query(
            textRecipientsRef,
            orderBy("uid", "desc"),
            startAfter(lastVisible),
            limit(windowSize)
          );
      const uploadedItems = (await getDocs(textRecipientsQuery)).docs.map(
        (document) => document.data().uid
      );

      setLastVisible(uploadedItems[uploadedItems.length - 1]);
      if (uploadedItems.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(textRecipientsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const textRecipient = message.doc.data();

            const textRecipientData = await getMassTextRecipientData(
              userId,
              massTextId,
              textRecipient.uid
            );
            if (textRecipientData) {
              addTextRecipientData([textRecipientData]);
            }

            const userData = await getUserData(textRecipient.uid);
            if (userData) {
              addUserData([userData]);
            }
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [
    addTextRecipientData,
    addUserData,
    attachmentId,
    isFinished,
    isLoading,
    lastVisible,
    massTextId,
    userId,
    windowSize,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      const fetchTextRecipients = async (userIds: string[]) => {
        setIsLoading(true);
        await Promise.all(
          userIds.map(async (uid) => {
            const textRecipientsRef = getMassTextRecipientsRef(
              userId,
              massTextId
            );
            const query_ = query(textRecipientsRef, where("uid", "==", uid));

            const unsubscribe = onSnapshot(query_, (snapshot) => {
              snapshot.docChanges().forEach(async (message) => {
                if (message.type === "added" || message.type === "modified") {
                  const textRecipient = message.doc.data();

                  addTextRecipientData([textRecipient]);
                  const userData = await getUserData(textRecipient.uid);
                  if (userData) {
                    addUserData([userData]);
                  }
                }
              });
            });
          })
        ).then(() => {
          setIsLoading(false);
        });
      };

      await loadAlgoliaUsersSearchResults(
        searchTerm,
        `following:${userId}`,
        (userIds: string[]) => fetchTextRecipients(userIds)
      );
    },
    [addTextRecipientData, addUserData, massTextId, userId]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedTextRecipients,
    fetchedUserData,
    loadTextRecipients,
    loadMoreTextRecipients,
    loadSearchResultsThrottled,
  };
};
