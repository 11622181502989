import { API } from "../../API";
import Phone from "../Phone";
import { Mixpanel } from "../../context/AnalyticsService";
import { VerificationState } from "@markit/common.types";

// TODO: Refactor FullEventInputForm to call these util functions instead of
// the functions being in that file (PETER)

export const showError = (
  verificationState: VerificationState,
  tempPhoneNumber: string,
  tempFullName: string,
  setShowPhoneNumberError: Function,
  setShowFullNameError: Function,
  setShowCodeInputError: Function
) => {
  let error = false;
  if (!Phone.isValid(tempPhoneNumber)) {
    setShowPhoneNumberError(true);
    error = true;
  }

  if (tempFullName === "") {
    setShowFullNameError(true);
    error = true;
  }

  if (verificationState === VerificationState.VERIFYING) {
    setShowCodeInputError(true);
  }

  return error;
};

export const sendVerificationCode = async (
  verificationState: VerificationState,
  tempPhoneNumber: string,
  tempFullName: string,
  setShowPhoneNumberError: Function,
  setShowFullNameError: Function,
  setShowCodeInputError: Function,
  setSendingVerificationCode: Function,
  setVerificationState: Function
) => {
  setSendingVerificationCode(true);
  if (
    !showError(
      verificationState,
      tempFullName,
      tempPhoneNumber,
      setShowPhoneNumberError,
      setShowFullNameError,
      setShowCodeInputError
    )
  ) {
    API.text
      .sendVerificationCode({
        toPhoneNumber: Phone.fromInternationalCode(tempPhoneNumber),
      })
      .then(() => setVerificationState(VerificationState.VERIFYING))
      .catch(() =>
        showError(
          verificationState,
          tempFullName,
          tempPhoneNumber,
          setShowPhoneNumberError,
          setShowFullNameError,
          setShowCodeInputError
        )
      )
      .then(() => setSendingVerificationCode(false));
  }
  setSendingVerificationCode(false);
};

export const verifyPhoneNumber = async (
  tempPhoneNumber: string,
  mixpanel: Mixpanel,
  verificationCode: (string | undefined)[],
  setLoading: Function,
  setUid: Function,
  setVerificationState: Function,
  setVerificationCode: Function
) => {
  if (verificationCode.filter((el) => el === undefined).length === 6) {
    return;
  }

  setLoading(true);
  API.text
    .verifyCode({
      phoneNumber: Phone.fromInternationalCode(tempPhoneNumber),
      code: verificationCode.join(""),
    })
    .then((response) => {
      const { uid } = response;
      if (uid !== undefined) {
        setUid(uid);
      }
      setVerificationState(VerificationState.VERIFIED);
      mixpanel.track("Webapp: Successful Verify");
    })
    .catch(() => {
      alert("Verification Code is Incorrect");
      setVerificationCode(Array(6).fill(undefined));
    })
    .then(() => setLoading(false));
};

export const paymentTimeoutHandler = async (
  eventId: string,
  toPhoneNumber: string,
  uid: string,
  amount: number,
  numberOfTickets: number,
  event_type: string,
  customTicketId: string,
  customer_phone: string,
  mixpanel: Mixpanel
) => {
  // trigger Slack bot notification
  await API.monitor
    .paymentTimeout({
      eventId: eventId,
      toPhoneNumber: toPhoneNumber,
      uid: uid,
      amount: amount,
      numberOfTickets: numberOfTickets,
    })
    .then(async (response: any) => {
      console.log("Triggered Slack bot for payment processing timeout");
    })
    .catch((e: any) => {
      console.error(
        "Unable to trigger Slack bot for payment processing timeout"
      );
    });

  // send Mixpanel event
  mixpanel.track("Webapp: Stripe Payment Processing Timeout", {
    event_id: eventId,
    event_type: event_type,
    error_message: "Stripe payment processing timed out.",
    customTicketId: customTicketId,
    numberOfTickets: numberOfTickets,
    customer_phone: customer_phone,
  });
};
