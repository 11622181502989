import { useContext } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { useSelector } from "react-redux";
import { WEBSITE } from "../App";
import { MixpanelContext } from "../context/AnalyticsService";
import { getUrlParamState } from "../redux/slices/urlParamSlice";
import { APPSTORE, GOOGLEPLAY } from "@markit/common.utils";

export const useAppRedirects = () => {
  const mixpanel = useContext(MixpanelContext);

  const urlParams = useSelector(getUrlParamState);
  const {
    e: eventId,
    ticketId: tixId,
    promoCodeId: pCodeId,
    promoter: promoterId,
    userReferrer,
    communityId,
  } = urlParams;

  const redirectToApp = () => {
    if (isAndroid) {
      // const url = "intent://APP_HOST/#Intent;scheme=APP_NAME;package=APP_PACKAGE;end";

      if (userReferrer !== "") {
        window.location.replace(
          "intent://?userReferrer=" +
            userReferrer +
            "/#Intent;scheme=markit:;package=com.markit.markitapp;end"
        );
      } else if (tixId !== "") {
        window.location.replace(
          "intent://?screen=FullEvent&id=" +
            eventId +
            "&ticketId=" +
            tixId +
            "/#Intent;scheme=markit:;package=com.markit.markitapp;end"
        );
      } else if (pCodeId !== "") {
        window.location.replace(
          "intent://?screen=FullEvent&id=" +
            eventId +
            "&promoCodeId=" +
            pCodeId +
            "/#Intent;scheme=markit:;package=com.markit.markitapp;end"
        );
      } else if (promoterId !== "") {
        window.location.replace(
          "intent://?screen=FullEvent&id=" +
            eventId +
            "&promoter=" +
            promoterId +
            "/#Intent;scheme=markit:;package=com.markit.markitapp;end"
        );
      } else if (communityId !== "") {
        window.location.replace(
          "intent://?screen=FullCommunity&id=" +
            communityId +
            "/#Intent;scheme=markit:;package=com.markit.markitapp;end"
        );
      } else {
        window.location.replace(
          "intent://?screen=FullEvent&id=" +
            eventId +
            "/#Intent;scheme=markit:;package=com.markit.markitapp;end"
        );
      }
    } else if (isIOS) {
      if (userReferrer !== "") {
        window.location.replace("markit://?userReferrer=" + userReferrer);
      } else if (tixId !== "") {
        window.location.replace(
          "markit://?screen=FullEvent&id=" + eventId + "&ticketId=" + tixId
        );
      } else if (pCodeId !== "") {
        window.location.replace(
          "markit://?screen=FullEvent&id=" + eventId + "&promoCodeId=" + pCodeId
        );
      } else if (promoterId !== "") {
        window.location.replace(
          "markit://?screen=FullEvent&id=" + eventId + "&promoter=" + promoterId
        );
      } else if (communityId !== "") {
        window.location.replace(
          "markit://?screen=FullCommunity&id=" + communityId
        );
      } else {
        window.location.replace("markit://?screen=FullEvent&id=" + eventId);
      }
    } else {
      // Webview
      window.location.replace(WEBSITE);
    }
  };

  const redirectToAppStore = (isGooglePlay?: boolean) => {
    if (isAndroid || isGooglePlay) {
      window.open(GOOGLEPLAY);
      mixpanel.track("Webapp: Opened App Store Link", {
        device: "android",
      });
    } else {
      window.open(APPSTORE);
      mixpanel.track("Webapp: Opened App Store Link", {
        device: "iOS",
      });
    }
  };

  return { redirectToApp, redirectToAppStore };
};
