import "../../css/Containers/ContainerStyles.css";
import React, { useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { ThemeStyle } from "../../hooks/useTheme";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { isDesktop } from "react-device-detect";

export enum PopupModalContainerWidth {
  NORMAL,
  SMALL,
  WIDE,
}

type PopupModalContainerProps = {
  headerComp: string | React.ReactNode;
  headerLeftComp?: React.ReactNode;
  headerRightComp?: React.ReactNode;
  subHeaderComp?: string | React.ReactNode;
  icon?: React.ReactNode;
  valueComp: React.ReactNode;
  theme?: ThemeStyle;
  headerLeftIcon?: React.ReactNode;
  altPadding?: number;
  noExit?: boolean;
  noDivider?: boolean;
  closeModal: () => void;
  closeOnOutsidePress?: boolean;
  modalContentWidth?: PopupModalContainerWidth;
  fixedBottomContent?: React.ReactNode;
  hideModal?: boolean;
  positionOnTop?: boolean;
};

const PopupModalContainer = (props: PopupModalContainerProps) => {
  const {
    headerComp,
    headerLeftComp,
    headerRightComp,
    subHeaderComp,
    valueComp,
    icon,
    theme,
    headerLeftIcon,
    altPadding,
    noExit,
    noDivider,
    closeModal,
    closeOnOutsidePress,
    modalContentWidth,
    fixedBottomContent,
    hideModal,
    positionOnTop,
  } = props;

  const ref = useRef<any>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal();
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [closeModal]);

  return (
    <>
      {!hideModal ? (
        <div className="modalBackground">
          <div
            ref={closeOnOutsidePress ? ref : null}
            className={
              (modalContentWidth
                ? modalContentWidth === PopupModalContainerWidth.WIDE
                  ? "wideModalContent"
                  : modalContentWidth === PopupModalContainerWidth.SMALL
                  ? "smallModalContent"
                  : "modalContent"
                : "modalContent") + " HideScrollbar"
            }
            style={{
              ...theme?.SecondaryBG,
              position: positionOnTop ? "absolute" : "relative",
              top: positionOnTop ? 79 : 0,
              margin: isDesktop ? 0 : 14,
            }}
          >
            <div>
              {headerLeftIcon}
              <div
                className="AlignedRowSpaced"
                style={{ paddingInline: "14px" }}
              >
                <div className="AlignedRowSpaced" style={{ width: "100%" }}>
                  {headerLeftComp}
                  <div className="AlignedRow" style={{ gap: 5 }}>
                    <h4 className="AboutSubtitle" style={theme?.PrimaryText}>
                      {headerComp}
                    </h4>
                    {icon}
                  </div>
                  {headerRightComp}
                </div>
                {!noExit ? (
                  <Icon
                    icon={"ion:close-circle"}
                    height={24}
                    onClick={closeModal}
                    style={{ color: Colors.GRAY1, cursor: "pointer" }}
                  />
                ) : null}
              </div>
              <div
                style={{
                  color: "#929292",
                  fontSize: 12,
                  paddingInline: "14px",
                }}
              >
                {subHeaderComp}
              </div>
              {!noDivider ? <HorizontalDivider altMargin={14} /> : null}
              <div
                className="HideScrollbar"
                style={{
                  maxHeight: "80vh",
                  overflowY: "scroll",
                  paddingInline: altPadding ?? 14,
                }}
              >
                {valueComp}
              </div>
              {fixedBottomContent ? <div>{fixedBottomContent}</div> : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PopupModalContainer;
