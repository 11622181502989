import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import ListTabs from "../ListTabs";
import { Colors } from "../../utils/colors";
import { getUserFollowersRef } from "../../utils/FirebaseUtils";
import { getCountFromServer, query, where } from "../../firebase";
import {
  GraphTimelineTabs,
  TimelineLabels,
  getGraphTimelineDate,
  isTimestampWithinRange,
  timelineStartDate,
  toFixedNumber,
} from "@markit/common.utils";
import { CircularProgress } from "@mui/material";
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from "recharts";

export const DataGraphContainer = () => {
  const { accountData, numFollowers, appInitialized } =
    useSelector(getAccountState).account;
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(1);
  const [loadingData, setLoadingData] = useState(false);
  const [followersData, setFollowersData] = useState<Map<string, number[]>>(
    new Map<TimelineLabels, number[]>()
  );

  const styles = {
    smallText: { fontSize: 12, color: Colors.GRAY2 },
    timelineBorder: {
      borderLeft: "0.5px solid #929292",
      borderBottom: "0.5px solid #929292",
      borderRight: "0.5px solid #929292",
    },
  };

  const selectedTab = useMemo(
    () => GraphTimelineTabs[selectedTabIndex],
    [selectedTabIndex]
  );

  const startDate = useMemo(
    () =>
      timelineStartDate(
        selectedTab,
        accountData.completedCreatorSetupTimestamp
      ),
    [accountData.completedCreatorSetupTimestamp, selectedTab]
  );

  const followersToShow = useMemo(() => {
    if (loadingData) {
      return undefined;
    }
    const numFollowersData = followersData.get(selectedTab);
    if (numFollowersData) {
      // Need to format the data with a name and value for the ListChart to take in
      const timelineFollowersData = numFollowersData.map((number, index) => ({
        name: index.toString(),
        value: number,
      }));
      return timelineFollowersData;
    }
    return [];
  }, [followersData, loadingData, selectedTab]);

  const newFollowersPerData = useMemo(() => {
    const numFollowersToShow = followersData.get(selectedTab);
    if (numFollowersToShow) {
      return numFollowersToShow.reduce((prev, curr) => prev + curr, 0);
    } else {
      return 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followersData, selectedTab, followersToShow]);

  const queryFollowersForHour = useCallback(
    async (startDate: Date) => {
      const startOfHour = new Date(startDate);
      startOfHour.setMinutes(0, 0, 0);

      const endOfHour = new Date(startDate);
      endOfHour.setMinutes(59, 59, 999);

      const followersRef = getUserFollowersRef(accountData.uid);
      const query_ = query(
        followersRef,
        where("createdAt", ">=", startOfHour.toISOString()),
        where("createdAt", "<=", endOfHour.toISOString())
      );
      const snapshot = await getCountFromServer(query_);
      return snapshot.data().count;
    },
    [accountData.uid]
  );

  const queryFollowersForDay = useCallback(
    async (startDate: Date) => {
      const startOfDay = startDate.toISOString();
      const endOfDay = new Date(startDate);
      endOfDay.setHours(23, 59, 59, 999);

      const followersRef = getUserFollowersRef(accountData.uid);
      const query_ = query(
        followersRef,
        where("createdAt", ">=", startOfDay),
        where("createdAt", "<", endOfDay.toISOString())
      );
      const snapshot = await getCountFromServer(query_);
      return snapshot.data().count;
    },
    [accountData.uid]
  );

  const queryFollowersForMonth = useCallback(
    async (startDate: Date) => {
      const startOfMonth = new Date(startDate);
      startOfMonth.setDate(1);
      startOfMonth.setHours(0, 0, 0, 0);

      const endOfMonth = new Date(startDate);
      endOfMonth.setMonth(endOfMonth.getMonth() + 1);
      endOfMonth.setDate(0);
      endOfMonth.setHours(23, 59, 59, 999);

      const followersRef = getUserFollowersRef(accountData.uid);
      const query_ = query(
        followersRef,
        where("createdAt", ">=", startOfMonth.toISOString()),
        where("createdAt", "<=", endOfMonth.toISOString())
      );
      const snapshot = await getCountFromServer(query_);
      return snapshot.data().count;
    },
    [accountData.uid]
  );

  const countFollowersInRange = useCallback(async () => {
    const tempFollowersData: number[] = [];
    const currentDate = new Date(startDate);
    if (!followersData.has(selectedTab)) {
      while (currentDate <= new Date()) {
        try {
          // to factor out the refactor to new followers data
          const specifiedDate = new Date("2023-10-18");
          const startOfDay = new Date(specifiedDate);
          startOfDay.setHours(0, 0, 0, 0);
          const endOfDay = new Date(specifiedDate);
          endOfDay.setHours(23, 59, 59, 999);
          if (
            !isTimestampWithinRange(
              currentDate.toISOString(),
              startOfDay.toISOString(),
              endOfDay.toISOString()
            )
          ) {
            let count = 0;
            if (selectedTab === TimelineLabels.ONE_DAY) {
              count = await queryFollowersForHour(currentDate);
              tempFollowersData.push(count);
            } else if (
              selectedTab === TimelineLabels.YR_TO_DAY ||
              selectedTab === TimelineLabels.ALL
            ) {
              count = await queryFollowersForMonth(currentDate);
              tempFollowersData.push(count);
            } else {
              count = await queryFollowersForDay(currentDate);
              tempFollowersData.push(count);
            }
          }
        } catch (error) {
          console.error("Error fetching followers:", error);
        }
        if (selectedTab === TimelineLabels.ONE_DAY) {
          currentDate.setHours(currentDate.getHours() + 1);
        } else if (
          selectedTab === TimelineLabels.YR_TO_DAY ||
          selectedTab === TimelineLabels.ALL
        ) {
          currentDate.setMonth(currentDate.getMonth() + 1);
        } else {
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
      followersData.set(selectedTab, tempFollowersData);
      setFollowersData(followersData);
    }
  }, [
    followersData,
    queryFollowersForDay,
    queryFollowersForHour,
    queryFollowersForMonth,
    selectedTab,
    startDate,
  ]);

  useEffect(() => {
    (async () => {
      if (numFollowers === 0) {
        return;
      }
      setLoadingData(true);
      await countFollowersInRange();
      setLoadingData(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabIndex]);

  return (
    <div className="ColumnNormal" style={{ paddingInline: 14, gap: 10 }}>
      <ListTabs
        tabLabels={GraphTimelineTabs}
        tabNumbers={[-1, -1, -1, -1, -1]}
        selectedValue={selectedTabIndex}
        onChange={(index: number) => setSelectedTabIndex(index)}
        containerStyles={{ justifyContent: "space-between" }}
        tabStyles={{
          borderRadius: 100,
          width: "20%",
          justifyContent: "center",
        }}
        altColor={Colors.GRAY6}
        newTextColor={Colors.GRAY3}
        newSelectedTextColor={Colors.BLACK}
      />
      {loadingData || !appInitialized ? (
        <div className="Centering" style={{ paddingTop: 50 }}>
          <CircularProgress
            style={{ color: Colors.GRAY1, alignSelf: "center" }}
            size={18}
          />
        </div>
      ) : (
        <div className="ColumnNormal" style={{ gap: 10 }}>
          <div className="ColumnNormal">
            {loadingData || !appInitialized ? (
              <div style={{ paddingBlock: 4 }}>
                <CircularProgress
                  style={{ color: Colors.GRAY1, alignSelf: "center" }}
                  size={17}
                />
              </div>
            ) : (
              <div>
                <span style={{ fontSize: 24 }}>
                  {numFollowers === 0 ? "0" : newFollowersPerData}
                </span>
                {numFollowers !== 0 && selectedTab !== TimelineLabels.ALL ? (
                  <span
                    style={{
                      ...styles.smallText,
                      color: Colors.GREEN2,
                      marginLeft: 4,
                    }}
                  >
                    +
                    {toFixedNumber(
                      (newFollowersPerData /
                        Math.max(numFollowers - newFollowersPerData, 1)) *
                        100,
                      1
                    )}
                    %
                  </span>
                ) : null}
              </div>
            )}
            <div className="AlignedRowSpaced">
              <span style={styles.smallText}>
                {numFollowers === 0
                  ? "Followers"
                  : `New Follower${newFollowersPerData !== 1 ? "s" : ""}`}
              </span>
              <span style={styles.smallText}>
                {getGraphTimelineDate(startDate)} - Today
              </span>
            </div>
          </div>
          {numFollowers > 0 ? (
            followersToShow && newFollowersPerData > 0 ? (
              <ResponsiveContainer style={styles.timelineBorder} height={80}>
                <LineChart
                  data={followersToShow}
                  margin={{ left: 0, right: 0, bottom: 0, top: 4 }}
                >
                  <XAxis hide />
                  <YAxis hide />
                  <Line
                    dot={false}
                    dataKey="value"
                    stroke={Colors.BLUE5}
                    strokeWidth={1.5}
                  />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <div
                className="Centering"
                style={{ ...styles.timelineBorder, height: 80 }}
              >
                <span style={styles.smallText}>
                  No followers found within the time range
                </span>
              </div>
            )
          ) : null}
        </div>
      )}
    </div>
  );
};
