import React, { memo } from "react";
import { useTheme } from "../../hooks/useTheme";

type VerticalDividerProps = {
  height: number;
  altThickness?: number;
  altMargin?: number;
};

export const VerticalDivider = memo(function VerticalDividerFn(
  props: VerticalDividerProps
) {
  const { height, altThickness, altMargin } = props;
  const { theme } = useTheme();

  return (
    <div
      style={{
        // width: altThickness ?? 1,
        // backgroundColor: theme.DividerColor.borderColor,
        width: 0,
        border: `1px solid ${theme.DividerColor.borderColor}`,
        height: height,
        margin: `${altMargin !== undefined ? altMargin : 0} ${
          altMargin !== undefined ? altMargin : 0
        }`,
      }}
    />
  );
});
