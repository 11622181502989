import { useCallback, useMemo } from "react";
import {
  AccountData,
  CustomTicketV2,
  Event,
  PromoCode,
  SavedFormQuestion,
  TicketV2,
} from "@markit/common.types";
import {
  getVisibleCustomTickets,
  isRequestTickets,
} from "@markit/common.utils";
import { isDesktop } from "react-device-detect";
import { Checkout } from "./Checkout";
import "../../css/FullEvent/Checkout.css";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { m, LazyMotion, domAnimation } from "framer-motion";
import { useTheme } from "../../hooks/useTheme";

type CheckoutContainerProps = {
  event: Event;
  host: AccountData;
  setSubmitted: (submitted: boolean) => void;
  tixId: string;
  ticketData?: TicketV2;
  ticketToRedeem: CustomTicketV2 | undefined;
  promoterId: string;
  promoCode?: PromoCode;
  userReferrer: string;
  submitted: boolean;
  selectedCustomTicket: CustomTicketV2;
  setSelectedCustomTicket: (selectedTicket: CustomTicketV2 | undefined) => void;
  eventFormQuestions: SavedFormQuestion[];
  isEventAtCapacity: boolean;
  setShowCheckoutForm: (showCheckoutForm: boolean) => void;
};

const CheckoutContainer = ({
  event,
  host,
  tixId, // redeem
  ticketData,
  ticketToRedeem,
  promoterId, // promoterId
  promoCode, // promoCode
  userReferrer,
  selectedCustomTicket,
  setSelectedCustomTicket,
  eventFormQuestions,
  submitted,
  setSubmitted,
  isEventAtCapacity,
  setShowCheckoutForm,
}: CheckoutContainerProps) => {
  const exitPressed = useCallback(async () => {
    setShowCheckoutForm(false);
  }, [setShowCheckoutForm]);

  const { theme } = useTheme();

  const visibleTickets = useMemo(
    () => getVisibleCustomTickets(event.customTickets, ticketToRedeem?.id),
    [event.customTickets, ticketToRedeem?.id]
  );

  const headerTitle = useMemo(() => {
    if (visibleTickets.some((ticket: CustomTicketV2) => ticket.price !== 0)) {
      if (tixId) {
        return "Redeem Ticket";
      } else {
        return "Get Ticket";
      }
    } else if (isRequestTickets(event)) {
      return "Request Ticket";
    } else {
      return "RSVP";
    }
  }, [event, tixId, visibleTickets]);

  return (
    <div
      className={isDesktop ? "CheckoutFormDesktop" : "CheckoutFormMobile"}
      style={
        isDesktop
          ? { ...theme.TransparentBG, minHeight: "100vh" }
          : { ...theme.SubGradientBG, minHeight: "100vh" }
      }
    >
      <div className={isDesktop ? "CheckoutHeaderDesktop" : "CheckoutHeader"}>
        <p
          className={isDesktop ? "CheckoutTitleDesktop" : "CheckoutTitle"}
          style={theme.PrimaryText}
        >
          {headerTitle}
        </p>
        <Icon
          icon="gridicons:cross-circle"
          height={30}
          color={Colors.GRAY1}
          onClick={exitPressed}
          style={{ cursor: "pointer", paddingRight: isDesktop ? "" : "14px" }}
        />
      </div>
      {isDesktop ? (
        <LazyMotion features={domAnimation}>
          <m.div
            initial={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
            animate={{ y: "0px", opacity: 1, filter: "blur(0px)" }}
            exit={{ y: "20px", opacity: 0, filter: "blur(2px)" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            style={{ overflowY: "auto" }}
            className="HideScrollbar"
          >
            <Checkout
              event={event}
              host={host}
              tixId={tixId}
              ticketData={ticketData}
              ticketToRedeem={ticketToRedeem}
              promoterId={promoterId}
              promoCode={promoCode}
              userReferrer={userReferrer}
              submitted={submitted}
              setSubmitted={setSubmitted}
              selectedCustomTicket={selectedCustomTicket ?? visibleTickets[0]}
              setSelectedCustomTicket={setSelectedCustomTicket}
              isEventAtCapacity={isEventAtCapacity}
              eventFormQuestions={eventFormQuestions}
            />
          </m.div>
        </LazyMotion>
      ) : (
        <Checkout
          event={event}
          host={host}
          tixId={tixId}
          ticketData={ticketData}
          ticketToRedeem={ticketToRedeem}
          promoterId={promoterId}
          promoCode={promoCode}
          userReferrer={userReferrer}
          submitted={submitted}
          setSubmitted={setSubmitted}
          selectedCustomTicket={selectedCustomTicket ?? visibleTickets[0]}
          setSelectedCustomTicket={setSelectedCustomTicket}
          isEventAtCapacity={isEventAtCapacity}
          eventFormQuestions={eventFormQuestions}
        />
      )}
    </div>
  );
};
export default CheckoutContainer;
