import { useCallback, useMemo, useState } from "react";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import {
  SavedFormQuestion,
  SelectRecipientType,
  SubSelectionItem,
} from "@markit/common.types";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import { SelectRecipientsMainSharedProps } from "./SelectRecipientsPanel";

type SelectRecipientsHeaderProps = SelectRecipientsMainSharedProps & {
  backToOverview: () => void;
  screenType: SelectRecipientType;
  setScreenType: (screenType: SelectRecipientType) => void;
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  unsavedRecipients: string[];
  setUnsavedRecipients: (unsavedRecipients: string[]) => void;
  unsavedCategories: SubSelectionItem[];
  setUnsavedCategories: (unsavedCategories: SubSelectionItem[]) => void;
  setFocusKeyboard: (focusKeyboard: boolean) => void;
  selectedQuestion: SavedFormQuestion | undefined;
  setSelectedQuestion: (
    selectedQuestion: SavedFormQuestion | undefined
  ) => void;
  resetState: () => void;
  alternateTitle?: string;
};

const SelectRecipientsHeader = (props: SelectRecipientsHeaderProps) => {
  const {
    backToOverview,
    screenType,
    setScreenType,
    updateMassTextSettings,
    setAllSelectedPeople,
    setAllExcludedPeople,
    unsavedRecipients,
    setUnsavedRecipients,
    unsavedCategories,
    setUnsavedCategories,
    excludingMode,
    setExcludingMode,
    setFocusKeyboard,
    setShowDoneButton,
    selectedQuestion,
    setSelectedQuestion,
    showCategories,
    resetState,
    alternateTitle,
  } = props;
  const [showConfirmBackModal, setShowConfirmBackModal] =
    useState<boolean>(false);

  const styles = {
    headerTitle: { fontSize: 14, fontWeight: "500" },
    headerPosition: {
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      zIndex: -1,
    },
  };

  const titleToDisplay = useMemo(
    () =>
      alternateTitle ??
      "Recipient" + (unsavedRecipients.length === 1 ? "" : "s"),
    [alternateTitle, unsavedRecipients.length]
  );

  const madeChanges = useMemo(() => {
    if (screenType !== SelectRecipientType.NONE) {
      return unsavedRecipients.length > 0 || unsavedCategories.length > 0;
    }
    return false;
  }, [screenType, unsavedCategories.length, unsavedRecipients.length]);

  const saveSelection = useCallback(() => {
    if (excludingMode) {
      if (showCategories && updateMassTextSettings) {
        updateMassTextSettings({
          excludedSubSelectionIds: unsavedCategories,
        });
      } else {
        setAllExcludedPeople(unsavedRecipients);
      }
    } else {
      if (showCategories && updateMassTextSettings) {
        updateMassTextSettings({
          subSelectionIds: unsavedCategories,
        });
      } else {
        setAllSelectedPeople(unsavedRecipients);
      }
    }
    setUnsavedRecipients([]);
    setUnsavedCategories([]);
    setSelectedQuestion(undefined);
    if (screenType === SelectRecipientType.COLLECTED_DATA) {
      setSelectedQuestion(undefined);
    } else {
      setScreenType(SelectRecipientType.NONE);
    }
    resetState();
  }, [
    excludingMode,
    setUnsavedRecipients,
    setUnsavedCategories,
    setSelectedQuestion,
    screenType,
    resetState,
    showCategories,
    updateMassTextSettings,
    unsavedCategories,
    setAllExcludedPeople,
    unsavedRecipients,
    setAllSelectedPeople,
    setScreenType,
  ]);

  const handleBackNavigation = useCallback(() => {
    if (screenType !== SelectRecipientType.NONE) {
      if (selectedQuestion) {
        setSelectedQuestion(undefined);
        return;
      }
      setFocusKeyboard(true);
      setScreenType(SelectRecipientType.NONE);
    } else {
      if (excludingMode) {
        setShowDoneButton(false);
        setExcludingMode(false);
      } else {
        backToOverview();
      }
    }
  }, [
    screenType,
    selectedQuestion,
    setFocusKeyboard,
    setScreenType,
    setSelectedQuestion,
    excludingMode,
    setShowDoneButton,
    setExcludingMode,
    backToOverview,
  ]);

  const backOnPress = useCallback(() => {
    if (!madeChanges) {
      handleBackNavigation();
    } else {
      setShowConfirmBackModal(true);
    }
  }, [madeChanges, handleBackNavigation]);

  const showTopRightButton = useMemo(
    () => screenType !== SelectRecipientType.NONE,
    [screenType]
  );

  return (
    <>
      <div
        className="AlignedRowSpaced"
        style={{ position: "relative", paddingBlock: 14 }}
      >
        <div className="LargePopupPanelClose" onClick={backOnPress}>
          <Icon icon={"ion:chevron-back"} height={24} color={Colors.GRAY3} />
        </div>
        {showCategories && screenType !== SelectRecipientType.NONE ? (
          <div
            className="SelectedRecipientsBanner AlignedRow"
            style={{ gap: 4 }}
          >
            <span
              style={{
                ...styles.headerTitle,
                color: excludingMode ? Colors.RED3 : Colors.PURPLE7,
              }}
            >
              {unsavedCategories.length > 0
                ? `${unsavedCategories.length} ${
                    unsavedCategories.length !== 1 ? "Categories" : "Category"
                  } ${excludingMode ? "Excluded" : "Selected"}`
                : `${excludingMode ? "Excluding" : "Selecting"} by Category`}
            </span>
          </div>
        ) : unsavedRecipients.length > 0 &&
          screenType !== SelectRecipientType.NONE ? (
          <div
            className="SelectedRecipientsBanner AlignedRow"
            style={{ gap: 4 }}
          >
            <h4 style={{ fontWeight: 600, fontSize: 14 }}>
              {unsavedRecipients.length}
            </h4>
            <h4 style={{ fontWeight: 400, fontSize: 14 }}>
              {titleToDisplay} {excludingMode ? "Excluded" : "Selected"}
            </h4>
          </div>
        ) : (
          <div style={{ ...styles.headerPosition, position: "absolute" }}>
            <span style={styles.headerTitle}>
              {excludingMode ? "Exclude " : ""}
              {titleToDisplay}
            </span>
          </div>
        )}
        <div
          className="LargePopupPanelBlackButton AlignedRowSelect"
          onClick={saveSelection}
          style={{
            visibility: showTopRightButton ? undefined : "hidden",
            pointerEvents: !madeChanges ? "none" : "all",
            opacity: !madeChanges ? 0.2 : 1,
            gap: 5,
          }}
        >
          <span style={{ ...styles.headerTitle, color: Colors.WHITE }}>
            Save
          </span>
        </div>
      </div>
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your edits won't be saved. This cannot be undone."
        deleteButtonText="Exit"
        hideModal={!showConfirmBackModal}
        setIsVisible={setShowConfirmBackModal}
        deleteOnPress={() => {
          setShowConfirmBackModal(false);
          handleBackNavigation();
        }}
        icon={<Icon icon="ion:exit-outline" height={40} />}
      />
    </>
  );
};

export default SelectRecipientsHeader;
