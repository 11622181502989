import { AccountData, Event } from "@markit/common.types";
import StandardBorderedContainer from "../../Containers/StandardBorderedContainer";
import {
  GetTime,
  formatDate,
  isEventExternalLink,
  isExternalGenericLink,
} from "@markit/common.utils";
import { Colors } from "../../../utils/colors";
import ProfileEventTimelineSubItem from "./ProfileEventTimelineSubItem";
import EventOrganizerSubItem from "../EventItem/EventOrganizerSubItem";

type ProfileEventTimelineCardItemProps = {
  event: Event;
  organizers: AccountData[];
  url: string;
  isMyAccount: boolean;
};

// To display the event details on the profile page event timeline for the card view
const ProfileEventTimelineCardItem = (
  props: ProfileEventTimelineCardItemProps
) => {
  const { event, organizers, url, isMyAccount } = props;

  const styles = {
    title: {
      color: Colors.BLACK,
      fontSize: 16,
      fontWeight: "500",
    },
    description: {
      fontSize: 12,
      color: Colors.GRAY1,
    },
  };

  return (
    <StandardBorderedContainer
      containerStyles={{
        backgroundColor: Colors.WHITE,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <a
        className="AlignedRowSpaced"
        style={{
          flexGrow: 1,
          gap: 5,
          alignItems: "flex-start",
          padding: 10,
          borderRadius: 12,
        }}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="ColumnNormalSelect"
          style={{
            gap: 7,
            alignSelf: isExternalGenericLink(event.eventType)
              ? "center"
              : undefined,
          }}
        >
          <span className="TwoLineText" style={styles.title}>
            {event.title}
          </span>
          {!isExternalGenericLink(event.eventType) ? (
            <div className="ColumnNormalSelect" style={{ gap: 4 }}>
              <span style={styles.description}>
                {GetTime(new Date(event.start))},{" "}
                {formatDate(new Date(event.start), true, true)}
              </span>
              <span style={styles.description}>
                {event.isVirtual
                  ? "Virtual Location"
                  : event.privateLocation && !isMyAccount
                  ? "Hidden Location"
                  : event.googleDescription}
              </span>
              {organizers.length > 0 &&
              !isEventExternalLink(event.eventType) ? (
                <EventOrganizerSubItem organizers={organizers} />
              ) : null}
            </div>
          ) : null}
          <ProfileEventTimelineSubItem event={event} />
        </div>
        <img
          alt="EventTimelineCardItem"
          src={event.photoURL}
          width={90}
          height={90}
          style={{ borderRadius: 8 }}
        />
      </a>
    </StandardBorderedContainer>
  );
};

export default ProfileEventTimelineCardItem;
