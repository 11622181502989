import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Event,
  WishMark,
  Visibility,
  TicketV2,
  MassText,
  CloneEventDetails,
} from "@markit/common.types";
import {
  arrayRemove,
  arrayUnion,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { AppState, AppThunk } from "../store";
import { createWishMarkData, rsvpToWishMark } from "./wishMarkSlice";
import {
  Batch,
  getEventData,
  getEventRef,
  getEventsRef,
  getUserEventRef,
  getUserRef,
} from "../../utils/FirebaseUtils";
import { API } from "../../API";
import { getAllUsersTickets } from "../../utils/eventUtils/userTicketUtils";
import { Mixpanel } from "../../context/AnalyticsService";
import { isPaidTickets } from "@markit/common.utils";
import { accountActions, toggleInCreatorMode } from "./accountSlice";
import { uploadBlobToFirebase } from "../../utils/makeEvent";
import { saveMediaToFirestore } from "../../utils/photoUtils";
import isEqual from "lodash.isequal";

export type EventState = {
  events: Event[];
  tickets: TicketV2[];
};

export const initialEventState: EventState = {
  events: [],
  tickets: [],
};

export const eventSlice = createSlice({
  name: "event",
  initialState: initialEventState,
  reducers: {
    updateEvent: (state, action: PayloadAction<Partial<Event>>) => {
      state.events = state.events.map((event) =>
        event.id === action.payload.id ? { ...event, ...action.payload } : event
      );
    },
    initializeTickets: (state, action: PayloadAction<TicketV2[]>) => {
      state.tickets = state.tickets
        .concat(action.payload)
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
      state.tickets.sort((x: TicketV2, y: TicketV2) => {
        return (
          new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime()
        );
      });
    },
    initialize: (
      state,
      action: PayloadAction<{ events: Event[]; feed: boolean }>
    ) => {
      state.events = state.events
        .concat(action.payload.events)
        .filter(
          (v, i, a) =>
            v.customTickets !== undefined &&
            a.findIndex((t) => t.id === v.id) === i
        );
      state.events.sort((x: Event, y: Event) => {
        return new Date(x.start).getTime() - new Date(y.start).getTime();
      });
    },
    removeEvent: (state, action: PayloadAction<string>) => {
      state.events = state.events.filter(
        (event) => event.id !== action.payload
      );
    },
    removeAllEvents: (state) => {
      state.events = [];
    },
    addToUserTickets: (state, action: PayloadAction<TicketV2>) => {
      if (!state.tickets.some((event) => event.id === action.payload.id)) {
        state.tickets = state.tickets.concat(action.payload);
      } else {
        state.tickets = state.tickets.filter(
          (event) => event.id !== action.payload.id
        );
        state.tickets = state.tickets.concat(action.payload);
      }
      state.tickets.sort((x: TicketV2, y: TicketV2) => {
        return (
          new Date(x.createdAt).getTime() - new Date(y.createdAt).getTime()
        );
      });
    },
    addToUserEvents: (state, action: PayloadAction<Event>) => {
      if (
        action.payload.customTickets !== undefined &&
        !state.events.some((event) => event.id === action.payload.id)
      ) {
        state.events = state.events.concat(action.payload);
      } else {
        state.events = state.events.filter(
          (event) => event.id !== action.payload.id
        );
        if (action.payload.customTickets !== undefined) {
          state.events = state.events.concat(action.payload);
        }
      }
      state.events.sort((x: Event, y: Event) => {
        return new Date(x.start).getTime() - new Date(y.start).getTime();
      });
    },
    addToEvents: (state, action: PayloadAction<Event>) => {
      if (
        action.payload.customTickets !== undefined &&
        !state.events.some((event) => event.id === action.payload.id)
      ) {
        state.events = state.events.concat(action.payload);
      } else {
        state.events = state.events.filter(
          (event) => event.id !== action.payload.id
        );
        if (action.payload.customTickets !== undefined) {
          state.events = state.events.concat(action.payload);
        }
      }
      state.events.sort((x: Event, y: Event) => {
        return new Date(x.start).getTime() - new Date(y.start).getTime();
      });
    },
  },
});

export const eventActions = eventSlice.actions;
export const eventReducer = eventSlice.reducer;

export const refreshEventData =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    const eventData = await getEventData(eventId);
    if (eventData) {
      dispatch(eventActions.addToEvents(eventData));
    }
  };

export const refreshEventTicketData =
  (eventId: string, userId: string): AppThunk =>
  async (dispatch) => {
    const eventData = await getEventData(eventId);
    if (eventData) {
      dispatch(eventActions.addToEvents(eventData));

      const ticketsData: TicketV2[] = await getAllUsersTickets(eventId, userId);

      for (let i = 0; i < ticketsData.length; i++) {
        dispatch(eventActions.addToUserTickets(ticketsData[i]));
      }
    }
  };

export const getFullEventData = (state: EventState, eventId: string) => {
  const foundEvent = state.events.find((event) => event.id === eventId);
  return foundEvent;
};

export const createEventData =
  (
    event: Event,
    mixpanel: Mixpanel,
    eventTexts?: MassText[],
    cloneEventDetails?: CloneEventDetails
  ): AppThunk =>
  async (dispatch, getState) => {
    const { accountData, currentEventFormQuestions } = getState().account;

    const finalEvent = await uploadBlobToFirebase(event, true);
    try {
      const response = await API.events.postEvent({
        event: finalEvent,
        savedFormQuestions: currentEventFormQuestions,
      });
      const { hostTicket, updatedEvent } = response;
      dispatch(eventActions.addToUserTickets(hostTicket));
      dispatch(eventActions.addToEvents(updatedEvent));
      dispatch(accountActions.addSavedFormQuestions(currentEventFormQuestions));

      const promises = [];
      if (cloneEventDetails) {
        promises.push(
          API.events.cloneEventDetails({
            eventId: finalEvent.id,
            cloneDetails: cloneEventDetails,
          })
        );
      }
      if (eventTexts) {
        promises.push(
          API.events.generateEventMassTexts({
            eventId: finalEvent.id,
            userId: finalEvent.createdBy,
            eventTexts: eventTexts,
          })
        );
      }
      await Promise.all(promises);

      // Switch user to creator mode on event creation if currently in attendee mode
      if (!accountData.inCreatorMode) {
        dispatch(toggleInCreatorMode(accountData.uid, false));
      }
      mixpanel.track("Event Created", {
        distinct_id: accountData.uid,
        event_id: finalEvent.id,
        event_location: finalEvent.googleDescription,
        event_is_paid: isPaidTickets(finalEvent),
        event_is_crowdfunded: finalEvent.crowdfundingGoal !== 0,
        event_is_university: finalEvent.universityId,
        event_host_name: accountData.fullName,
        event_host_username: accountData.username,
        event_host_phone_name: accountData.phoneNumber,
        event_host_email: accountData.email,
        event_partner: accountData.customer.phone !== "",
        creation_source: "webapp",
        cloned_event: cloneEventDetails !== undefined,
      });
      mixpanel.people_increment({ Events: 1 });
    } catch (err: any) {
      console.log("Error creating event: " + err.message);
    }
  };

export const updateEventData =
  (event: Event, eventTexts?: MassText[]): AppThunk =>
  async (dispatch, getState) => {
    const { currentEventFormQuestions } = getState().account;

    try {
      const finalEvent = await uploadBlobToFirebase(event, false);
      const { updatedEvent } = await API.events.updateEvent({
        event: finalEvent,
        savedFormQuestions: currentEventFormQuestions,
      });
      dispatch(eventActions.addToEvents(updatedEvent));
      dispatch(accountActions.addSavedFormQuestions(currentEventFormQuestions));
      if (eventTexts) {
        await API.events.generateEventMassTexts({
          eventId: event.id,
          userId: event.createdBy,
          eventTexts: eventTexts,
        });
      }
    } catch (err: any) {
      console.log("Error updating event: " + err.message);
    }
  };

export const deleteEventData =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    await API.events.deleteEvent({ eventId: eventId });
    dispatch(eventActions.removeEvent(eventId));
  };

export const wishedToEvent =
  (
    eventId: string,
    uid: string,
    wishMark: WishMark,
    wishMarkExists: boolean
  ): AppThunk =>
  async (dispatch) => {
    const batch = new Batch("Error wishing to event");
    const eventRef = getEventRef(eventId);
    const eventData = (await getDoc(eventRef)).data();
    if (eventData !== undefined) {
      const userEventRef = getUserEventRef(eventData.createdBy, eventData.id);

      batch.update(eventRef, {
        wished: arrayUnion(uid),
      });
      batch.update(userEventRef, {
        wished: arrayUnion(uid),
      });

      if (eventData.notGoing.includes(uid)) {
        if (wishMarkExists) {
          dispatch(rsvpToWishMark(wishMark, uid));
        } else {
          dispatch(createWishMarkData(wishMark, uid, true));
        }
        batch.update(eventRef, {
          notGoing: arrayRemove(uid),
        });
        batch.update(userEventRef, {
          notGoing: arrayRemove(uid),
        });
      } else {
        dispatch(createWishMarkData(wishMark, uid, true));
      }

      try {
        await batch.commit();
      } catch (error: any) {
        alert("Error sending wished in event");
        console.error("Error sending wished in event: " + error.message);
      }
    }
  };

export const fetchOneEvent = async (
  event: Event,
  uid: string,
  universityId: number | undefined,
  userUniversity: number,
  addEvent: (event: Event) => void
) => {
  if (event !== undefined) {
    // get the event creator's account data
    const eventCreatorRef = getUserRef(event.createdBy);
    const eventCreatorData = (await getDoc(eventCreatorRef)).data();

    let communityMembers: string[] = [];

    // ON EVENT FEED, DON'T ADD
    // (Visibility) events that user is not on specified list of event user
    let showEvent: boolean = false;
    switch (event.visibility) {
      case Visibility.Public: {
        showEvent = true;
        break;
      }
      case Visibility.Private: {
        showEvent = false;
        break;
      }
      case Visibility.Custom: {
        showEvent =
          eventCreatorData?.customList !== undefined &&
          (eventCreatorData.customList.individualUsersList.includes(uid) ||
            communityMembers.includes(uid));
        break;
      }
      // deprecated not used
      case Visibility.Followers: {
        showEvent =
          eventCreatorData?.followers !== undefined &&
          eventCreatorData.followers.includes(uid);
        break;
      }
      case Visibility.University: {
        showEvent = event.universityId === (universityId || userUniversity);
        break;
      }
      // community visible events are fetched separately once a user's communities are fetched
    }

    if (showEvent) {
      addEvent(event);
    }
  }
};

export const setFeaturedEvent =
  (event: Event, featured: boolean): AppThunk =>
  async (dispatch) => {
    const eventRef = getEventRef(event.id);
    const batch = new Batch("setting featured events");
    await batch.update(eventRef, { isFeatured: featured });
    await batch
      .commit()
      .then(() =>
        dispatch(eventActions.addToEvents({ ...event, isFeatured: featured }))
      );
  };

export const fetchAllEventsQuick =
  (uid: string, userUniversity: number): AppThunk =>
  async (dispatch) => {
    const startTime = new Date().getTime();
    const response = await API.feed.getFeed({ uid, userUniversity });
    if (response) {
      const { events } = response;

      if (events.length === 0) {
        console.error("Quick Fetch Failed");
        await dispatch(fetchAllEvents(uid, userUniversity));
      } else {
        await Promise.all([
          dispatch(eventActions.initialize({ events: events, feed: true })),
        ]);
        console.log(
          (new Date().getTime() - startTime) / 1000,
          "done with quick",
          events.length
        );
      }
    } else {
      console.error("Quick Fetch Failed");
      await dispatch(fetchAllEvents(uid, userUniversity));
    }
  };
export const fetchAllEvents =
  (uid: string, userUniversity: number, universityId?: number): AppThunk =>
  async (dispatch) => {
    const eventList: Event[] = [];
    const date = new Date();

    const eventsQuery = query(
      getEventsRef(),
      orderBy("end", "desc"),
      where("end", ">=", date.toISOString())
    );
    const events = await getDocs(eventsQuery);

    const addEvent = (event: Event) => eventList.push(event);
    await Promise.all(
      events.docs.map((event) =>
        fetchOneEvent(event.data(), uid, universityId, userUniversity, addEvent)
      )
    );

    await Promise.all([
      dispatch(eventActions.initialize({ events: eventList, feed: true })),
    ]);
    console.log("done with old", new Date(), eventList.length);
  };

export const fetchCreatorEvents =
  (uid: string): AppThunk =>
  async (dispatch) => {
    await API.user
      .creatorEvents({ uid })
      .then((resp) => {
        const { events, errorFetching } = resp;
        if (errorFetching) {
          console.error("Error fetching events on initial app load");
          return;
        }
        dispatch(eventActions.initialize({ events: events, feed: false }));
      })
      .catch((error) => console.error(error.message));
  };

export const updateTicketPurchaseMessage =
  (
    event: Event,
    ticketPurchaseMessage: string,
    customImage: string
  ): AppThunk =>
  async (dispatch) => {
    const batch = new Batch("Error updating ticket purchase message");
    const eventRef = getEventRef(event.id);
    const userEventRef = getUserEventRef(event.createdBy, event.id);

    batch.update(eventRef, {
      creatorTicketPurchaseMessage: ticketPurchaseMessage,
    });
    batch.update(userEventRef, {
      creatorTicketPurchaseMessage: ticketPurchaseMessage,
    });

    // change or remove media if it exists
    if (customImage === "") {
      batch.update(eventRef, {
        creatorTicketPurchaseMediaUrl: "",
      });
      batch.update(userEventRef, {
        creatorTicketPurchaseMediaUrl: "",
      });
    } else if (!isEqual(event.creatorTicketPurchaseMediaUrl, customImage)) {
      const url = await saveMediaToFirestore(
        customImage,
        event.createdBy + "/textMedia/events/" + event.id
      );
      batch.update(eventRef, {
        creatorTicketPurchaseMediaUrl: url,
      });
      batch.update(userEventRef, {
        creatorTicketPurchaseMediaUrl: url,
      });
    }

    try {
      await batch.commit();
      dispatch(eventActions.addToEvents(event));
    } catch (e: any) {
      console.error("Error updating ticket purchase message: " + e.message);
    }
  };

export const getEventState = (state: AppState) => state;
