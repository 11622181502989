import { AccountData } from "@markit/common.types";
import CustomCheckbox from "../CustomCheckbox";
import ProfileItem from "../ProfileItem";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";

type SelectRecipientItemProps = {
  item: AccountData;
  isSelected: boolean;
  iconColor: string;
  selectOnPress: () => void;
  selectPreviewOnPress: () => void;
  disabled?: boolean;
};

export const SelectRecipientItem = (props: SelectRecipientItemProps) => {
  const {
    item,
    isSelected,
    disabled,
    selectOnPress,
    selectPreviewOnPress,
    iconColor,
  } = props;

  return (
    <div>
      <ProfileItem
        profileItem={item}
        onItemPress={selectPreviewOnPress}
        rightIcon={
          <>
            {!disabled ? (
              <CustomCheckbox
                checked={isSelected}
                onChange={selectOnPress}
                sx={{ padding: 0 }}
                altColor={iconColor}
              />
            ) : null}
          </>
        }
      />
      <HorizontalDivider altMargin={10} />
    </div>
  );
};
