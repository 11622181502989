import { ReactNode, useState } from "react";
import { getEventData } from "../utils/FirebaseUtils";
import { useOnMount } from "../utils/useOnMount";
import { isExternalLinkNoData } from "@markit/common.utils";
import {
  updateLinkTrackerOpens,
  updateTotalEventLinkViews,
} from "../utils/trackingUtils";
import { Colors } from "../utils/colors";

interface Props {
  children: ReactNode;
}

export const RedirectWrapper: ReactNode = (props: Props) => {
  const { children } = props;
  const [isCheckingRedirect, setIsCheckingRedirect] = useState(true);

  useOnMount(() => {
    (async () => {
      // Handle redirecting user
      const pathname = window.location.pathname;
      const regex = /\/e\//;
      if (regex.test(pathname)) {
        const subparts = pathname.split("/").filter((path) => path.length > 0);
        // Enter redirect case if url contains 'e' for event route
        if (subparts.length === 2 && subparts[0] === "e") {
          const event = await getEventData(subparts[1]);
          if (event && isExternalLinkNoData(event.eventType)) {
            const linkId =
              new URLSearchParams(window.location.search).get("linkId") ?? "";
            Promise.all([
              updateLinkTrackerOpens(event.id, event.createdBy, linkId),
              updateTotalEventLinkViews(event.id, event.createdBy),
            ]).then(() => {
              window.location.replace(event.externalLink);
            });
            return null;
          }
        }
      }
      setIsCheckingRedirect(false);
    })();
  });

  // Conditionally render children only if redirect logic is done
  if (isCheckingRedirect) {
    return (
      <div style={{ backgroundColor: Colors.GRAY6, height: "100vh" }}></div>
    );
  }
  return children;
};
