import React, { useMemo, useState } from "react";
import {
  AccountData,
  MassText,
  TrackingData,
  TrackingOverview,
  TrackingType,
} from "@markit/common.types";
import { Icon } from "@iconify/react";
import { Colors } from "../../../../utils/colors";
import { InitialsPic } from "../../../InitialsPic";
import { Box } from "@mui/material";
import { AntTab, AntTabs } from "../../../AntTabs";
import { useOnMount } from "../../../../utils/useOnMount";
import FlatList from "flatlist-react/lib";
import { GetLongDate } from "../../../../utils/GetLongDate";
import { GetTime } from "@markit/common.utils";
import { API } from "../../../../API";

type MassTextLinkAnalyticsItemProps = {
  item: AccountData;
  trackingOverview: TrackingOverview;
  massText: MassText;
  attachmentId: string;
  isSelected: boolean;
  onPress: () => void;
  hideConversions?: boolean;
};

export const MassTextLinkAnalyticsItem = ({
  item,
  trackingOverview,
  isSelected,
  massText,
  attachmentId,
  onPress,
  hideConversions,
}: MassTextLinkAnalyticsItemProps) => {
  const [tabValue, setTabValue] = useState(0);
  const [trackingData, setTrackingData] = useState<TrackingData[]>([]);

  const linkListToShow = useMemo(
    () =>
      tabValue === 0
        ? trackingData.filter((tracker) => tracker.type === TrackingType.OPEN)
        : trackingData.filter(
            (tracker) => tracker.type === TrackingType.CONVERSION
          ),
    [tabValue, trackingData]
  );

  useOnMount(() => {
    (async () => {
      const { trackingData } = await API.tracking.getUserTrackingData({
        userId: massText.sentBy,
        recipientId: item.uid,
        massTextId: massText.id,
        attachmentId: attachmentId,
      });
      setTrackingData(trackingData);
    })();
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <div className="ColumnNormal">
      <div
        onClick={onPress}
        className="AlignedRowSpaced"
        style={{
          cursor: "pointer",
          pointerEvents: trackingOverview.numOpens === 0 ? "none" : "all",
        }}
      >
        <div className="GuestInfo" style={{ cursor: "pointer" }}>
          <div
            className="GuestInfo"
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: Colors.BLACK,
            }}
          >
            {item.profilePicURL && item.profilePicURL !== "" ? (
              <img
                className="GuestPic"
                src={item.profilePicURL}
                alt="guest profile pic"
                style={{ cursor: "pointer" }}
              />
            ) : (
              <div
                className="GuestPic InitialsPic"
                style={{ cursor: "pointer" }}
              >
                <InitialsPic fullName={item.fullName} isLink />
              </div>
            )}
            <span style={{ fontSize: 14, fontWeight: 500 }}>
              {item.fullName}
            </span>
          </div>
        </div>
        <div className="AlignedRowSelect" style={{ gap: 10 }}>
          <div className="AlignedRow" style={{ gap: 2 }}>
            <span style={{ fontSize: 12 }}>{trackingOverview.numOpens}</span>
            <Icon icon="ion:eye-outline" height={14} />
          </div>
          {!hideConversions ? (
            <div className="AlignedRow" style={{ gap: 2 }}>
              <span style={{ fontSize: 12 }}>
                {trackingOverview.numConversions}
              </span>
              <Icon
                icon="ion:arrow-forward-circle-outline"
                height={14}
                style={{ marginTop: 2 }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {isSelected ? (
        <div
          className="MassTextsSectionContainer"
          style={{ marginTop: 14, cursor: "auto", paddingTop: 0 }}
        >
          <Box>
            <AntTabs value={tabValue} onChange={handleChange}>
              <AntTab
                label={`Opens (${trackingOverview.numOpens})`}
                sx={{ padding: 0, fontSize: 13 }}
              />
              {!hideConversions ? (
                <AntTab
                  label={`Conversions (${trackingOverview.numConversions})`}
                  isDisabled={trackingOverview.numConversions === 0}
                  sx={{ fontSize: 13 }}
                />
              ) : null}
            </AntTabs>
          </Box>
          <FlatList
            list={linkListToShow}
            renderItem={(item) => (
              <div className="AlignedRow" style={{ marginTop: 14 }}>
                <Icon
                  icon={
                    tabValue === 0
                      ? "ion:eye-outline"
                      : "ion:arrow-forward-circle-outline"
                  }
                  height={14}
                  style={{ marginRight: 5 }}
                />
                <span style={{ marginRight: 15, fontSize: 14 }}>
                  {tabValue === 0 ? "Open" : "Conversion"}
                </span>
                <span style={{ fontSize: 14 }}>
                  {GetLongDate(item.createdAt, true, true, false)},{" "}
                  {GetTime(new Date(item.createdAt))}
                </span>
              </div>
            )}
          />
        </div>
      ) : null}
      <hr />
    </div>
  );
};
