import React, { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import {
  GuestInfo,
  SpreadsheetFileDisplay,
  SpreadsheetInfo,
} from "@markit/common.types";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import {
  formatPhoneNumber,
  formatPhoneNumberRemoveDashes,
} from "../../../../utils/FormatPhoneNumber";
import ValidContactsPopupPanel from "./ValidContactsPopupPanel";
import RectangleButton from "../../../Buttons/RectangleButton";
import {
  MARKIT_HOTLINE_NUMBER,
  MARKIT_UPLOAD_NOTICE,
  detectedCensored,
  filterUndefinedValues,
  isValidPhoneNumber,
} from "@markit/common.utils";
import CustomCheckbox from "../../../CustomCheckbox";
import {
  parseSpreadsheetFile,
  removeEmptyAssignedColumns,
} from "../../../../utils/spreadsheetUtils";
import { CircularProgress } from "@mui/material";
import { checkIfPhoneUserFollower } from "../../../../utils/userUtils";
import ConfirmActionModal from "../../../Containers/ConfirmPopups/ConfirmActionModal";
import AlertContainer from "../../../Containers/AlertContainer";

type UploadSpreadsheetFileProps = {
  spreadsheet: SpreadsheetInfo | undefined;
  setSpreadsheet: (spreadsheet: SpreadsheetInfo | undefined) => void;
  currNumImported: number;
  displayFile: SpreadsheetFileDisplay;
  setDisplayFile?: (displayFile: SpreadsheetFileDisplay) => void;
  permissionsChecked?: boolean;
  setPermissionsChecked?: (permissionsChecked: boolean) => void;
};

const UploadSpreadsheetFile = (props: UploadSpreadsheetFileProps) => {
  const {
    spreadsheet,
    setSpreadsheet,
    currNumImported,
    displayFile,
    setDisplayFile,
    permissionsChecked,
    setPermissionsChecked,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [tempSpreadsheet, setTempSpreadsheet] = useState<SpreadsheetInfo>();
  const [contactsVisible, setContactsVisible] = useState(0); // 1 = valid contacts, 2 = invalid contacts, 3 = duplicate, 4 = existing
  const [alertText, setAlertText] = useState<{
    heading: string;
    subHeading: string;
    btnType: "Help" | "Got It" | "";
  }>({ heading: "", subHeading: "", btnType: "" });
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [reuploadVisible, setReuploadVisible] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
    reuploadBtn: {
      border: "0.5px solid #B9B9B9",
      paddingInline: 10,
      paddingBlock: 7,
      borderRadius: 8,
      cursor: "pointer",
      whiteSpace: "nowrap",
    },
  };

  const numAvailableImports = useMemo(
    () => 500 - currNumImported,
    [currNumImported]
  );

  const contactsToShow = useMemo(() => {
    if (!spreadsheet) {
      return [];
    }
    const contacts: { name: string; phoneNumber: string }[] = (
      contactsVisible === 1
        ? spreadsheet.validRows
        : contactsVisible === 2
        ? spreadsheet.invalidRows
        : contactsVisible === 3
        ? spreadsheet.duplicateRows
        : spreadsheet.existingContactRows
    ).map((row) => {
      return { name: row.fullName, phoneNumber: row.phoneNumber };
    });
    return contacts;
  }, [contactsVisible, spreadsheet]);

  const poorlyFormattedFile = useCallback(() => {
    setAlertText({
      heading: "Poorly Formatted File",
      subHeading:
        "Match the sample spreadsheet format and remove all other data or additional columns beyond name and phone number.",
      btnType: "Got It",
    });
    setUploadLoading(false);
  }, []);

  const onUploadSpreadsheet = useCallback(
    (event) => {
      setReuploadVisible(false);
      if (setPermissionsChecked) {
        setPermissionsChecked(false);
      }
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();
      let csv: string = "";
      reader.onload = async (e) => {
        setUploadLoading(true);
        const fileData = e.target?.result;
        csv = parseSpreadsheetFile(fileData, file);
        if (!csv.length) {
          poorlyFormattedFile();
          return;
        }

        let data: {
          fullName: string;
          phoneNumber: string;
          dataColumns: string[];
        }[] = [];
        data = csv
          .split("\n")
          .filter((row) => row.length > 0)
          .map((row) => {
            // Split by commas excluding those found within double quotes
            // Then, when returning data, remove the double quotes found for cleaned data
            const splitData = row.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
            return {
              fullName: splitData[0].replace(/"/g, ""),
              phoneNumber: splitData[1],
              dataColumns: splitData
                .slice(2)
                .map((column) => column.replace(/"/g, "")),
            };
          });

        // Check if first or second column is all empty, if so, throw alert
        if (
          data.every((row) => row.fullName === "" || row.phoneNumber === "")
        ) {
          poorlyFormattedFile();
          return;
        }

        const validRows: GuestInfo[] = [];
        const invalidRows: GuestInfo[] = [];
        const duplicateRows: GuestInfo[] = [];

        for (let i = 0; i < data.length; i++) {
          // Keep the raw row data to pass into duplicate and failed rows
          const row = data[i];
          const cleanedRow = {
            ...data[i],
            phoneNumber: formatPhoneNumberRemoveDashes(data[i].phoneNumber),
          };
          const foundDuplicateNumber = validRows.some(
            (currRow) =>
              formatPhoneNumberRemoveDashes(currRow.phoneNumber) ===
              formatPhoneNumberRemoveDashes(row.phoneNumber)
          );

          // If censored term detected, immediately push to invalidRow
          if (detectedCensored(row.fullName)) {
            invalidRows.push(row);
          } else if (
            cleanedRow.fullName &&
            isValidPhoneNumber(cleanedRow.phoneNumber) &&
            cleanedRow.phoneNumber !== accountData.phoneNumber
          ) {
            if (!foundDuplicateNumber) {
              validRows.push(cleanedRow);
            } else {
              duplicateRows.push(row);
            }
          } else {
            invalidRows.push(row);
          }
        }

        // get the existing contacts out of the validRows
        const existingContacts = await Promise.all(
          validRows.map(async (validRow) => {
            const followerUserId = await checkIfPhoneUserFollower(
              validRow.phoneNumber,
              accountData.uid
            );
            if (followerUserId) {
              const finalValidRow: GuestInfo = {
                ...validRow,
                uid: followerUserId,
              };
              return finalValidRow;
            }
            return undefined;
          })
        );
        const definedExistingContacts = filterUndefinedValues(existingContacts);

        // Check if a valid data column for all rows is empty. If so, remove that column for all users
        const dataValidColumns = filterUndefinedValues(
          validRows.map((row) => row.dataColumns)
        );
        const cleanedDataValidColumns =
          removeEmptyAssignedColumns(dataValidColumns);
        for (let i = 0; i < cleanedDataValidColumns.length; i++) {
          validRows[i] = {
            ...validRows[i],
            dataColumns: cleanedDataValidColumns[i],
          };
        }

        const finalValidRows = validRows.filter(
          (row) =>
            !definedExistingContacts.some(
              (existingRow) => existingRow.phoneNumber === row.phoneNumber
            )
        );

        // If spreadsheet has over 500 contacts and user has not gotten approval
        if (
          finalValidRows.length >= numAvailableImports &&
          !accountData.importContactsApproval
        ) {
          setAlertText({
            heading: "Need permission to import 500+ contacts",
            subHeading:
              (currNumImported > 0
                ? `You have already imported ${currNumImported}/500 total contacts. To import`
                : "For imports") +
              " over 500 contacts, please contact our customer hotline to request permission to import.",
            btnType: "Help",
          });
        } else if (currNumImported === 0) {
          // If this is the first spreadsheet the user is uploading
          setTempSpreadsheet({
            fileName: file.name,
            validRows: finalValidRows,
            invalidRows,
            duplicateRows,
            existingContactRows: definedExistingContacts,
          });
          setConfirmVisible(true);
        } else {
          setSpreadsheet({
            fileName: file.name,
            validRows: finalValidRows,
            invalidRows,
            duplicateRows,
            existingContactRows: definedExistingContacts,
          });
          if (setDisplayFile) {
            setDisplayFile({ ...displayFile, name: file.name });
          }
        }
        setUploadLoading(false);
      };
      if (file.name.endsWith(".xlsx")) {
        reader.readAsArrayBuffer(file);
      } else if (file.name.endsWith(".csv")) {
        reader.readAsText(file);
      } else {
        setAlertText({
          heading: "Invalid File",
          subHeading:
            "Sorry, the uploaded file type is not supported. Please upload a .csv or .xlsx file.",
          btnType: "Got It",
        });
      }
      event.target.value = null;
    },
    [
      accountData.importContactsApproval,
      accountData.phoneNumber,
      accountData.uid,
      currNumImported,
      displayFile,
      numAvailableImports,
      poorlyFormattedFile,
      setDisplayFile,
      setPermissionsChecked,
      setSpreadsheet,
    ]
  );

  const handleFirstUpload = useCallback(() => {
    if (tempSpreadsheet) {
      setSpreadsheet(tempSpreadsheet);
      if (setDisplayFile) {
        setDisplayFile({ ...displayFile, name: tempSpreadsheet.fileName });
      }
    }
  }, [displayFile, setDisplayFile, setSpreadsheet, tempSpreadsheet]);

  const cancelFirstUpload = useCallback(() => {
    setTempSpreadsheet(undefined);
    if (setPermissionsChecked) {
      setPermissionsChecked(false);
    }
  }, [setPermissionsChecked]);

  const previewContactsOnPress = useCallback((contactRow: number) => {
    setContactsVisible(contactRow);
  }, []);

  // Show the agree to terms checkbox if the user has not imported any contacts yet
  const acknowledgeTermsSubtext = useMemo(() => {
    return (
      <div className="AlignedRow" style={{ paddingBlock: 14, gap: 14 }}>
        <CustomCheckbox
          checked={permissionsChecked}
          onChange={() =>
            setPermissionsChecked
              ? setPermissionsChecked(!permissionsChecked)
              : undefined
          }
          sx={{ padding: 0 }}
          altColor={Colors.BLUE5}
        />
        <span style={{ ...styles.subtext, fontSize: 12 }}>
          You acknowledge and agree you have obtained explicit consent from all
          the individuals whose phone numbers you have uploaded, are uploading,
          or will upload, and also agree to the following{" "}
          <a
            href={MARKIT_UPLOAD_NOTICE}
            target="_blank"
            rel="noreferrer"
            style={{ textDecorationLine: "underline", color: Colors.BLACK }}
          >
            terms
          </a>
          .
        </span>
      </div>
    );
  }, [permissionsChecked, setPermissionsChecked, styles.subtext]);

  const loadingState = useMemo(
    () => (
      <div>
        <CircularProgress size={16} style={{ color: Colors.GRAY1 }} />
      </div>
    ),
    []
  );

  const contactsInfoRow = useCallback(
    (contactRow: number, rowValue: number) => {
      const rowName =
        contactRow === 1
          ? "New Valid Contacts"
          : contactRow === 2
          ? "Failed Imports"
          : contactRow === 3
          ? "Duplicates Found"
          : "Already Existing Contacts";
      return (
        <div
          onClick={() => previewContactsOnPress(contactRow)}
          className="AlignedRowSpacedSelect"
          style={{
            paddingInline: 14,
            pointerEvents: rowValue === 0 ? "none" : "all",
          }}
        >
          <span
            style={{
              ...styles.subtext,
              fontWeight: 500,
              color: contactRow === 1 ? Colors.GREEN2 : Colors.BLACK,
            }}
          >
            {rowValue} {rowName}
          </span>
          <Icon icon="ion:chevron-right" height={14} color={Colors.GRAY1} />
        </div>
      );
    },
    [previewContactsOnPress, styles.subtext]
  );

  return (
    <>
      {!spreadsheet ? (
        <div className="ColumnNormal" style={{ paddingTop: 80, gap: 40 }}>
          <StandardBorderedContainer
            containerStyles={{
              border: "1.5px dashed #b9b9b9",
              paddingInline: 40,
              paddingBlock: 60,
              width: 547,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="ColumnNormal Centering"
              style={{
                gap: 7,
                paddingBottom: 40,
                textAlign: "center",
                paddingInline: 40,
              }}
            >
              <span style={{ fontSize: 20 }}>
                Upload a csv or xlsx file to get started
              </span>
              <span style={styles.subtext}>
                Format your spreadsheet with contact name in the first column
                and phone number in the second column.
              </span>
            </div>
            <label
              className="AlignedRowSelect Centering"
              style={{
                backgroundColor: Colors.BLACK,
                color: Colors.WHITE,
                width: 140,
                paddingBlock: 14,
                borderRadius: 12,
                pointerEvents: !uploadLoading ? "all" : "none",
              }}
            >
              <input
                type="file"
                accept=".csv, .xlsx"
                onChange={onUploadSpreadsheet}
                disabled={uploadLoading}
              />
              {!uploadLoading ? (
                <span style={styles.bodyMedium}>Browse</span>
              ) : (
                <CircularProgress
                  style={{ color: Colors.WHITE, alignSelf: "center" }}
                  size={17}
                />
              )}
            </label>
          </StandardBorderedContainer>
        </div>
      ) : (
        <StandardBorderedContainer
          containerStyles={{
            backgroundColor: Colors.WHITE1,
            paddingBlock: 14,
            width: 547,
          }}
        >
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <div
              className="AlignedRowSpaced"
              style={{ paddingInline: 14, gap: 14 }}
            >
              {setDisplayFile ? (
                <div className="AlignedRow" style={{ gap: 7 }}>
                  <div>
                    <Icon
                      icon="ion:checkmark-circle"
                      height={18}
                      color={Colors.GREEN2}
                      style={{ marginBottom: -2 }}
                    />
                  </div>
                  <span
                    className="OneLineText"
                    style={{
                      ...styles.bodyMedium,
                      wordBreak: "break-all",
                    }}
                  >
                    {spreadsheet.fileName}
                  </span>
                </div>
              ) : (
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <span
                    className="OneLineText"
                    style={{ ...styles.bodyMedium, wordBreak: "break-all" }}
                  >
                    {displayFile.name}
                  </span>
                  <span
                    className="OneLineText"
                    style={{ ...styles.subtext, wordBreak: "break-all" }}
                  >
                    {spreadsheet.fileName}
                  </span>
                </div>
              )}
              {permissionsChecked !== undefined ? (
                <div>
                  {uploadLoading ? (
                    loadingState
                  ) : (
                    <div
                      onClick={() => setReuploadVisible(true)}
                      className="Centering"
                      style={styles.reuploadBtn}
                    >
                      <span style={{ fontSize: 12 }}>Re-Upload</span>
                    </div>
                  )}
                </div>
              ) : null}
            </div>
            <HorizontalDivider />
            {contactsInfoRow(1, spreadsheet.validRows.length)}
            <HorizontalDivider />
            {contactsInfoRow(2, spreadsheet.invalidRows.length)}
            {spreadsheet.duplicateRows.length > 0 ? (
              <>
                <HorizontalDivider />
                {contactsInfoRow(3, spreadsheet.duplicateRows.length)}
              </>
            ) : null}
            {spreadsheet.existingContactRows.length > 0 ? (
              <>
                <HorizontalDivider />
                {contactsInfoRow(4, spreadsheet.existingContactRows.length)}
              </>
            ) : null}
          </div>
        </StandardBorderedContainer>
      )}
      {spreadsheet && contactsVisible > 0 ? (
        <ValidContactsPopupPanel
          contactsToShow={contactsToShow}
          contactsView={contactsVisible}
          setContactsView={setContactsVisible}
        />
      ) : null}
      <AlertContainer
        headerComp={
          <div className="ColumnNormal" style={{ gap: 14 }}>
            <Icon icon="ion:cloud-upload" height={35} />
            <span>{alertText.heading}</span>
          </div>
        }
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        alternateValueComp={
          <RectangleButton
            buttonLabel={
              alertText.btnType === "Got It" ? (
                <span>Got It</span>
              ) : (
                <a
                  href={`tel:${MARKIT_HOTLINE_NUMBER}`}
                  className="AlignedRow"
                  style={{ gap: 10, fontWeight: 600 }}
                >
                  <span style={{ color: Colors.BLACK }}>Contact Hotline</span>
                  <span style={{ color: Colors.GRAY1 }}>
                    {formatPhoneNumber(MARKIT_HOTLINE_NUMBER)}
                  </span>
                </a>
              )
            }
            onPress={() =>
              alertText.btnType === "Got It"
                ? setAlertText({ heading: "", subHeading: "", btnType: "" })
                : {}
            }
            altColor={
              alertText.btnType === "Got It" ? Colors.BLACK : Colors.GRAY6
            }
          />
        }
        closeOnOutsidePress={alertText.btnType === "Help"}
        closeModal={() =>
          setAlertText({ heading: "", subHeading: "", btnType: "" })
        }
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
      <ConfirmActionModal
        heading={"Before you continue"}
        subtext={acknowledgeTermsSubtext}
        confirmButtonText={"Continue"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!confirmVisible}
        setIsVisible={setConfirmVisible}
        confirmOnPress={handleFirstUpload}
        cancelOnPress={cancelFirstUpload}
        disableConfirm={!permissionsChecked}
      />
      <ConfirmActionModal
        heading={"Upload a new file?"}
        subtext={"Your existing file will be discarded."}
        confirmButtonText={"New Upload"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!reuploadVisible}
        setIsVisible={setReuploadVisible}
        confirmOnPress={() => {}}
        alternateConfirmBtn={
          <label
            className="RectangleButton"
            style={{
              backgroundColor: Colors.BLACK,
              cursor: "pointer",
              paddingBlock: 14,
            }}
          >
            <input
              type="file"
              accept=".csv, .xlsx"
              onChange={onUploadSpreadsheet}
            />
            <span
              style={{ color: Colors.WHITE, fontSize: 14, fontWeight: 500 }}
            >
              New Upload
            </span>
          </label>
        }
      />
    </>
  );
};

export default UploadSpreadsheetFile;
