import { Colors } from "../../utils/colors";
import { useCallback, useMemo } from "react";
import { Icon } from "@iconify/react";
import { DropdownMenu, DropdownMenuItem } from "../DropdownMenu";
import { unfollowUser } from "../../utils/FollowUser";
import { AccountData } from "@markit/common.types";
import { BinaryConfirmActions } from "../Containers/ConfirmPopups/ConfirmActionPopup";

type ProfileFollowingActionsButtonProps = {
  userId: string;
  creatorData: AccountData;
  setAlreadyFollowing: (alreadyFollowing: boolean) => void;
  setShowForm: (showForm: boolean) => void;

  setProcessing: (processing: boolean) => void;
  setConfirmPopupSubscribeAction: (action: BinaryConfirmActions) => void;
};

export const ProfileFollowingActionsButton = (
  props: ProfileFollowingActionsButtonProps
) => {
  const {
    userId,
    creatorData,
    setAlreadyFollowing,
    setShowForm,
    setProcessing,
    setConfirmPopupSubscribeAction,
  } = props;

  const onUnfollowClick = useCallback(async () => {
    setProcessing(true);
    setAlreadyFollowing(false);
    await unfollowUser(userId, creatorData.uid);
    setProcessing(false);
    setShowForm(false);
    setConfirmPopupSubscribeAction(BinaryConfirmActions.NEGATIVE);
  }, [
    creatorData.uid,
    setAlreadyFollowing,
    setConfirmPopupSubscribeAction,
    setProcessing,
    setShowForm,
    userId,
  ]);

  const dropdownItems: DropdownMenuItem[] = useMemo(
    () => [
      {
        title: `Unsubscribe from ${creatorData.fullName}`,
        icon: "ion:exit-outline",
        key: "unsubscribe",
        onPress: onUnfollowClick,
      },
    ],
    [creatorData.fullName, onUnfollowClick]
  );

  return (
    <DropdownMenu
      dropdownItems={dropdownItems}
      trigger={["click"]}
      placement="bottom"
    >
      <div
        className="AlignedRowSelect"
        style={{
          width: "100%",
          justifyContent: "center",
          backgroundColor: Colors.WHITE,
          borderRadius: 8,
          fontWeight: 600,
          fontSize: 14,
          gap: 7,
        }}
      >
        <span>Subscribed</span>
        <Icon icon={"mdi:chevron-down"} height={18} color={Colors.BLACK} />
      </div>
    </DropdownMenu>
  );
};
