import Phone from "./Phone";

export const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = ("" + phoneNumber).replace(/\D[^.]/g, "");
  return (
    cleaned.slice(0, 3) + "-" + cleaned.slice(3, 6) + "-" + cleaned.slice(6)
  );
};

export const formatPhoneNumberRemoveDashes = (phoneNumber: string): string => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  return cleaned
    .replace(/[^\d]+/g, "")
    .replace(/(?:\+1|1)?(\d{3})(\d{3})(\d{4})/, "$1$2$3");
};

// for checking formatting of verification code
export const formattedVerificationCode = /[!@#$%^&*()_+\-=[\]{};':"|,.<>\\/?]+/;

// For formatting the phone number for verification by filtering out all unnecessary
export const formattedVerificationPhoneNumber = (phoneNumber: string) =>
  Phone.fromInternationalCode(
    phoneNumber
      .split("")
      .filter((char) => !formattedVerificationCode.test(char))
      .join()
  );
