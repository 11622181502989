import { useCallback, useMemo } from "react";
import "../../css/FullEvent/BuyBanner.css";
import {
  CustomTicketV2,
  Event,
  PromoCode,
  TicketV2,
} from "@markit/common.types";
import { Button } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  LessThanDate,
  isCustomTicketsAllFree,
  isRequestTickets,
} from "@markit/common.utils";
import { isDesktop } from "react-device-detect";
import { useTheme } from "../../hooks/useTheme";
import { Colors } from "../../utils/colors";
import { themeMui } from "../FullEvent/FullEventInputForm";

type BuyBannerProps = {
  event: Event;
  tixId: string | undefined;
  ticketData: TicketV2 | undefined;
  promoCode: PromoCode | undefined;
  lowestTicketPrice: number;
  highestTicketPrice: number;
  isEventAtCapacity: boolean;
  setShowCheckoutForm: (showCheckoutForm: boolean) => void;
  visibleTickets: CustomTicketV2[];
};

const BuyBanner = ({
  event,
  tixId,
  ticketData,
  promoCode,
  lowestTicketPrice,
  highestTicketPrice,
  isEventAtCapacity,
  setShowCheckoutForm,
  visibleTickets,
}: BuyBannerProps) => {
  const { theme } = useTheme();

  const alreadyRedeemed = useMemo(
    () =>
      ticketData &&
      (ticketData.redeemedBy !== "" ||
        ticketData.scanned ||
        // need the redeemeedBy field as well for the case where
        // the user shares their mass text ticket with someone else;
        // without it, the receiver won't be able to redeem it
        (ticketData.redeemedBy !== "" && ticketData.uid !== "")),
    [ticketData]
  );

  // Checks if the buy banner should be disabled, if the event is set to manually sold out,
  // and if the ticket is not a redeem ticket.
  const isEventSoldOut = useMemo(
    () => (isEventAtCapacity || event.isManualSoldOut) && !ticketData,
    [event.isManualSoldOut, isEventAtCapacity, ticketData]
  );

  const notYetRedeemed = useMemo(
    () => ticketData && ticketData.redeemedBy === "" && !ticketData.scanned,
    [ticketData]
  );

  const ticketPriceRange = useMemo(() => {
    if (lowestTicketPrice === highestTicketPrice) {
      return "$" + lowestTicketPrice.toString();
    } else {
      return (
        "$" +
        lowestTicketPrice.toString() +
        " - " +
        highestTicketPrice.toString()
      );
    }
  }, [highestTicketPrice, lowestTicketPrice]);

  const bannerHeaderText = useMemo(() => {
    if (new Date(event.end) < new Date()) {
      return "Event Over";
    } else if (visibleTickets.some((ticket) => ticket.price !== 0)) {
      // paid ticketed
      return ticketPriceRange;
    } else if (isRequestTickets(event)) {
      // request ticketed
      return "Request";
    } else {
      // free ticketed
      return "RSVP";
    }
  }, [event, ticketPriceRange, visibleTickets]);

  const bannerSubText = useMemo(() => {
    if (new Date(event.end) < new Date()) {
      return "This event has ended";
    } else if (alreadyRedeemed) {
      return "Ticket was already redeemed";
    } else if (notYetRedeemed) {
      return "Redeem your free ticket";
    } else if (isEventSoldOut && !event.isManualSoldOut) {
      return "Redeem a Ticket";
    } else if (visibleTickets.some((ticket) => ticket.price !== 0)) {
      if (promoCode) {
        return "Redeem your promo code";
      } else {
        return (
          "Price " +
          (lowestTicketPrice !== highestTicketPrice ? "range" : "") +
          " for this event"
        );
      }
    } else if (isRequestTickets(event)) {
      return "Request for this event";
    } else {
      return "This event is free";
    }
  }, [
    alreadyRedeemed,
    event,
    highestTicketPrice,
    isEventSoldOut,
    lowestTicketPrice,
    notYetRedeemed,
    promoCode,
    visibleTickets,
  ]);

  const btnText = useMemo(() => {
    if (alreadyRedeemed) {
      return "Redeemed";
    } else if (notYetRedeemed) {
      return "Redeem";
    } else if (new Date(event.end) < new Date()) {
      return "Not Available";
    } else if (isEventSoldOut) {
      return "Sold Out";
    } else if (promoCode) {
      return "Redeem Code";
    } else if (isRequestTickets(event)) {
      return "Request";
    } else if (isCustomTicketsAllFree(event)) {
      return "RSVP";
    } else {
      return "Get Tickets";
    }
  }, [alreadyRedeemed, event, isEventSoldOut, notYetRedeemed, promoCode]);

  const disableBuyBtn = useMemo(() => {
    if (alreadyRedeemed || new Date(event.end) < new Date() || isEventSoldOut) {
      return true;
    }
    return false;
  }, [alreadyRedeemed, event.end, isEventSoldOut]);

  const showRedeemTicketSubtext = useMemo(
    () =>
      isEventSoldOut &&
      !event.isManualSoldOut &&
      LessThanDate(new Date().toISOString(), event.end),
    [event.end, event.isManualSoldOut, isEventSoldOut]
  );

  // here, make a modal appear
  const onBuyClick = useCallback(() => {
    if (event.locationBasedRSVP) {
      alert("This event requires you to download the app.");
      return;
    }
    window.scrollTo(0, 0);
    setShowCheckoutForm(true);
  }, [event.locationBasedRSVP, setShowCheckoutForm]);

  return isDesktop ? (
    <div
      className="BuyBannerDesktop"
      style={{ ...theme.SecondaryBG, display: "flex" }}
    >
      <div className="BuyBannerContainer">
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <p
            className="BuyFullName"
            style={isEventSoldOut ? theme.SubText : theme.PrimaryText}
          >
            {tixId ? "$0" : bannerHeaderText}{" "}
            <span className="TicketStrike">
              {tixId ? ticketPriceRange : ""}
            </span>
          </p>
          <p
            className="BuyUsername"
            style={{
              color: showRedeemTicketSubtext ? Colors.BLUE5 : Colors.GRAY1,
              cursor: showRedeemTicketSubtext ? "pointer" : "auto",
              pointerEvents: showRedeemTicketSubtext ? "all" : "none",
            }}
            onClick={onBuyClick}
          >
            {bannerSubText}
          </p>
        </div>
        <ThemeProvider theme={themeMui}>
          <Button
            style={{
              backgroundColor: !disableBuyBtn ? Colors.RED2 : Colors.GRAY6,
              fontWeight: 600,
              color: !disableBuyBtn ? "#FFFFFF" : Colors.BLACK,
            }}
            variant="contained"
            className="BuyOpenButton"
            disableElevation
            disabled={disableBuyBtn}
            sx={{ borderRadius: "8px" }}
            onClick={onBuyClick}
          >
            {btnText}
          </Button>
        </ThemeProvider>
      </div>
    </div>
  ) : (
    <div
      className="BuyBanner"
      style={{
        ...theme.SubComponentBG,
        ...theme.BannerBorder,
        display: "flex",
      }}
    >
      <div className="BuyBannerContainer">
        <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
          <p
            className="BuyFullName"
            style={isEventSoldOut ? theme.SubText : theme.PrimaryText}
          >
            {tixId ? "$0" : bannerHeaderText}{" "}
            <span className="TicketStrike">
              {tixId ? ticketPriceRange : ""}
            </span>
          </p>
          <p
            className="BuyUsername"
            style={{
              color: showRedeemTicketSubtext ? Colors.BLUE5 : Colors.GRAY1,
              cursor: showRedeemTicketSubtext ? "pointer" : "auto",
              pointerEvents: showRedeemTicketSubtext ? "all" : "none",
            }}
            onClick={onBuyClick}
          >
            {bannerSubText}
          </p>
        </div>
        <ThemeProvider theme={themeMui}>
          <Button
            style={{
              backgroundColor: !disableBuyBtn ? Colors.RED2 : Colors.GRAY6,
              color: !disableBuyBtn ? "#FFFFFF" : Colors.BLACK,
              fontWeight: "600",
              borderRadius: "8px",
            }}
            variant="contained"
            className="BuyOpenButton"
            disableElevation
            disabled={disableBuyBtn}
            sx={{ borderRadius: 3 }}
            onClick={onBuyClick}
          >
            {btnText}
          </Button>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default BuyBanner;
