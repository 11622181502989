import "../../css/FullEvent/EventQRCode.css";
import { memo, useRef } from "react";
import { isDesktop } from "react-device-detect";
import QRCode from "react-qr-code";
import { useWindowSize } from "@react-hook/window-size";
import exportAsImage from "../../utils/exportAsImage.js";
import { Icon } from "@iconify/react";
import {
  useEventSelector,
  useParametricSelector,
} from "../../hooks/useParametricSelector";
import { getLoadedEvent } from "../../redux/slices/dataSlice";
import LoadingScreen from "../LoadingScreen";
import { useSelector } from "react-redux";
import { getUrlParamState } from "../../redux/slices/urlParamSlice";
import { useLocation } from "react-router-dom";
import { isExternalGenericLink } from "@markit/common.utils";
import { HorizontalDivider } from "../../components/Dividers/HorizontalDivider";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Colors } from "../../utils/colors";

type EventQRCodeProps = {
  darkMode?: boolean;
};

const EventQRCode = memo(function EventQRCodeFn(props: EventQRCodeProps) {
  const { promoter } = useSelector(getUrlParamState);
  const { linkMappingId } = useLocation().state || {};
  const [height] = useWindowSize();
  const exportRef = useRef(null);

  const eventId = useEventSelector();
  const {
    isLoading: isLoadingEventData,
    data: event,
    isError: isErrorEventData,
  } = useParametricSelector(getLoadedEvent, eventId);

  const value =
    "https://markitai.com/e/" +
    eventId +
    (promoter === ""
      ? linkMappingId
        ? "?linkId=" + linkMappingId
        : ""
      : "?promoter=" +
        promoter +
        (linkMappingId ? "&linkId=" + linkMappingId : ""));

  if (isLoadingEventData) {
    return <LoadingScreen />;
  }
  if (isErrorEventData || !event) {
    return <LoadingScreen error />;
  }
  return (
    <div className={"Centering EventQRContainer"}>
      <div className="EventQRBody">
        <div style={{ paddingInline: 14 }}>
          <span className="sectionTitle">
            {isExternalGenericLink(event.eventType) ? "" : "Event"} QR Code
          </span>
        </div>
        <HorizontalDivider altMargin={14} />
        <div className="EventQRCodeImage" ref={exportRef}>
          <QRCode
            size={isDesktop ? height / 4.4 : height / 1.2}
            value={value}
          />
        </div>
        <HorizontalDivider altMargin={14} />
        <div style={{ paddingInline: 14 }}>
          <RectangleButton
            buttonLabel="Save Image"
            altTextColor={Colors.WHITE}
            altColor={Colors.BLACK}
            onPress={() => exportAsImage(exportRef.current, "EventQR")}
            iconRight={
              <Icon
                icon="ion:download"
                height={17}
                color={Colors.WHITE}
                style={{ marginTop: -2 }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
});

export default EventQRCode;
