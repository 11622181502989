import { MassText } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { IoPaperPlaneSharp, IoTimerSharp } from "react-icons/io5";
import { GetLongDate } from "../../utils/GetLongDate";
import { GetTime, truncateString } from "@markit/common.utils";
import { useState } from "react";

type MassTextPreviewItemProps = {
  massTextItem: MassText;
  isScheduled: boolean;
  setMassTextsPopupPanelVisible: (
    value: boolean | ((prevVar: boolean) => boolean)
  ) => void;
  setEditingMassText: (massText: MassText) => void;
};

/**
 * New MassTextPreviewItem component that displays a condensed view
 * of sent/scheduled texts in Manage Texts header preview in
 * texting page
 */
export const MassTextPreviewItem = ({
  massTextItem,
  isScheduled,
  setMassTextsPopupPanelVisible,
  setEditingMassText,
}: MassTextPreviewItemProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    alignedRow: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },

    alignedRowSpaced: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      cursor: "pointer",
    },

    textContainer: {
      borderRadius: 12,
      padding: 14,
      cursor: "pointer",
    },

    subtitle: {
      fontSize: 12,
      color: Colors.GRAY1,
      cursor: "pointer",
    },
  };

  return (
    <div
      onClick={() => {
        setMassTextsPopupPanelVisible(true);
        setEditingMassText(massTextItem);
      }}
      style={{
        ...styles.textContainer,
        backgroundColor: isHovered ? Colors.GRAY11 : Colors.GRAY6,
        cursor: "pointer",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={styles.alignedRow}>
        <span
          style={{
            color: Colors.BLACK,
            fontSize: 14,
            fontWeight: "500",
            cursor: "pointer",
          }}
        >
          {/* render text label based if it has been sent or not */}
          {isScheduled ? "Scheduled Text" : "Sent Text"}
        </span>
      </div>
      <div style={{ ...styles.alignedRow, marginTop: 7 }}>
        {isScheduled ? (
          <IoTimerSharp size={12} style={{ color: Colors.BLACK }} />
        ) : (
          <IoPaperPlaneSharp size={12} style={{ color: Colors.BLACK }} />
        )}
        <span
          style={{
            ...styles.subtitle,
            paddingLeft: 7,
          }}
        >
          {GetLongDate(massTextItem.sentAt, true, true, true)}{", "}
          {GetTime(new Date(massTextItem.sentAt))}
        </span>
      </div>
      <div
        style={{
          ...styles.subtitle,
          marginTop: 7,
        }}
      >
        {truncateString(massTextItem.message, 40, "...")}
      </div>
    </div>
  );
};
