import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  addSingleAudienceListMember,
  getAccountState,
} from "../../../../redux/slices/accountSlice";
import LargePopupModalContainer from "../../../Containers/LargePopupModalContainer";
import { BackButton } from "../../../Buttons/BackButton";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Colors } from "../../../../utils/colors";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import { AudienceList } from "@markit/common.types";
import filter from "lodash.filter";
import { MassTextsActionItem } from "../../MassTexts/Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import {
  FAVORITES_LIST_NAME,
  filterUndefinedValues,
  uniqueVals,
} from "@markit/common.utils";
import { useDispatch } from "react-redux";
import {
  checkAudienceListMembership,
  sortAudienceListsDisplay,
} from "../../../../utils/userUtils";
import { CircularProgress } from "@mui/material";
import ConfirmActionPopup from "../../../Containers/ConfirmPopups/ConfirmActionPopup";
import { AddNewToListButton } from "../../../Buttons/AddNewToListButton";
import AudienceListPopupPanel from "./AudienceListPopupPanel";
import isEqual from "lodash.isequal";

interface AddToListModalProps {
  isVisible: boolean;
  closeOnPress: () => void;
  addOnPress?: (lists: AudienceList[]) => void;
  preselectedLists?: AudienceList[];
  userId?: string; // If adding specific user to lists
}

const AddToListModal = (props: AddToListModalProps) => {
  const { isVisible, closeOnPress, addOnPress, preselectedLists, userId } =
    props;
  const dispatch = useDispatch();
  const { accountData, audienceLists } = useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLists, setSelectedLists] = useState<AudienceList[]>([]);
  const [availableLists, setAvailableLists] = useState<AudienceList[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [confirmAction, setConfirmAction] = useState(false);
  const [listPopupVisible, setListPopupVisible] = useState(false);

  const styles = {
    headerPosition: {
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      zIndex: -1,
    },
    bodyMedium: {
      fontSize: 14,
      fontWeight: 500,
    },
  };

  useEffect(() => {
    (async () => {
      if (userId) {
        const availableLists = await Promise.all(
          audienceLists.map(async (audienceList): Promise<any> => {
            const alreadyAdded = await checkAudienceListMembership(
              userId,
              audienceList.id,
              accountData.uid
            );
            if (!alreadyAdded) {
              return audienceList;
            }
            return undefined;
          })
        );
        const definedLists: AudienceList[] =
          filterUndefinedValues(availableLists);
        setAvailableLists(definedLists);
      } else {
        if (preselectedLists) {
          setSelectedLists(preselectedLists);
        }
        setAvailableLists(audienceLists);
      }
      setIsLoading(false);
    })();
  }, [accountData.uid, audienceLists, isVisible, preselectedLists, userId]);

  const disabledAudienceList = useCallback(
    (item: AudienceList) => {
      return !availableLists.some((list) => list.id === item.id);
    },
    [availableLists]
  );

  const addListsOnPress = useCallback(async () => {
    if (userId) {
      await Promise.all(
        selectedLists.map((list) => {
          dispatch(addSingleAudienceListMember(accountData.uid, list, userId));
          return true;
        })
      );
    }
    if (addOnPress) {
      addOnPress(selectedLists);
    }
    closeOnPress();
    setConfirmAction(true);
  }, [
    userId,
    addOnPress,
    closeOnPress,
    selectedLists,
    dispatch,
    accountData.uid,
  ]);

  const selectListOnPress = useCallback(
    (item: AudienceList) => {
      const listIds = selectedLists.map((list) => list.id);
      setSelectedLists((selectedLists) => {
        if (listIds.includes(item.id)) {
          selectedLists = selectedLists.filter((list) => list.id !== item.id);
        } else {
          selectedLists = selectedLists.concat(item);
        }
        return uniqueVals(selectedLists, (list: AudienceList) => list.id);
      });
    },
    [selectedLists]
  );

  const addNewList = useCallback(() => {
    setListPopupVisible(true);
  }, []);

  const clearListsOnPress = useCallback(() => {
    setSelectedLists([]);
  }, []);

  const handleSearch = useCallback((text: string) => {
    setSearchTerm(text.toLowerCase());
  }, []);

  const contains = useCallback((list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query);
  }, []);

  const audienceListsToShow = useMemo(() => {
    let lists: AudienceList[] = audienceLists;
    if (searchTerm !== "") {
      lists = filter(lists, (list: AudienceList) => {
        return contains(list, searchTerm);
      });
    }
    const sortedLists = sortAudienceListsDisplay(lists);
    return sortedLists;
  }, [audienceLists, contains, searchTerm]);

  return (
    <>
      <LargePopupModalContainer
        showModal={isVisible}
        headerComp={
          <div className="AlignedRowSpaced">
            <BackButton iconName={"mdi:close"} onPress={closeOnPress} />
            <div style={{ ...styles.headerPosition, position: "absolute" }}>
              <span style={styles.bodyMedium}>Add to Lists</span>
            </div>
            <div className="AlignedRow" style={{ gap: 14 }}>
              {selectedLists.length > 0 ? (
                <span
                  onClick={clearListsOnPress}
                  style={{
                    ...styles.bodyMedium,
                    color: Colors.BLUE5,
                    cursor: "pointer",
                  }}
                >
                  Clear
                </span>
              ) : null}
              <RectangleButton
                buttonLabel={preselectedLists ? "Save" : "Add"}
                onPress={addListsOnPress}
                disabled={
                  (!preselectedLists && selectedLists.length === 0) ||
                  isEqual(preselectedLists, selectedLists)
                }
                altColor={Colors.BLACK}
                altPaddingHorz={14}
                altPaddingVert={10}
                altBorderRadius={100}
              />
            </div>
          </div>
        }
        valueComp={
          <div className="ColumnNormal" style={{ gap: 20 }}>
            <SearchBoxContainer
              placeholder="Search Lists..."
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginTop: 0 }}
            />
            <div>
              {!isLoading ? (
                <div className="ColumNormal">
                  {audienceListsToShow.map((list) => (
                    <div className="ColumnNormal">
                      <MassTextsActionItem
                        title={list.name}
                        altIconBackgroundColor={
                          list.name === FAVORITES_LIST_NAME
                            ? Colors.WHITE
                            : Colors.GRAY6
                        }
                        icon={
                          list.name === FAVORITES_LIST_NAME ? (
                            <Icon icon="mdi:star-circle" height={34} />
                          ) : (
                            <Icon
                              icon="ion:list"
                              height={40}
                              color={Colors.BLUE5}
                            />
                          )
                        }
                        onPress={() => selectListOnPress(list)}
                        subtext={`${list.numberMembers} People`}
                        checkColor={Colors.BLUE5}
                        isCheckSelected={
                          selectedLists.some((item) => item.id === list.id) ||
                          disabledAudienceList(list)
                        }
                        disabled={disabledAudienceList(list)}
                      />
                      <HorizontalDivider altMargin={10} />
                    </div>
                  ))}
                  <AddNewToListButton
                    label="New List..."
                    onPress={addNewList}
                    containerStyles={{ paddingLeft: 7 }}
                  />
                </div>
              ) : (
                <div className="Centering">
                  <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
                </div>
              )}
            </div>
          </div>
        }
      />
      {confirmAction ? (
        <ConfirmActionPopup
          title={"Added to Lists!"}
          onDisappear={() => setConfirmAction(false)}
        />
      ) : null}
      {listPopupVisible ? (
        <AudienceListPopupPanel
          isVisible={listPopupVisible}
          setIsVisible={setListPopupVisible}
          existingList={undefined}
          setExistingList={() => {}}
          handleOnExit={(list: AudienceList) => selectListOnPress(list)}
        />
      ) : null}
    </>
  );
};

export default AddToListModal;
