import { useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import { CreatorPanelTabs } from "../../../components/CreatorDashboard/CreatorPanelTabs";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";

const TransactionsPanel = () => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [value, setValue] = useState(0);

  return (
    <div className="CreatorPanelContainer">
      <div className="AlignedRowSpaced">
        <h2>Transactions</h2>
        <RectangleButtonCreatorPanel
          title="Manual Payout"
          iconName="ion:add-circle"
          onPress={() => {}}
          disabled
        />
      </div>
      <CreatorPanelTabs
        tabValue={value}
        setTabValue={setValue}
        tabNames={["All Transactions", "Payment Disputes", "Stripe Payouts"]}
      />
      <div
        className="EventDashboardContainerSpaceBetween"
        style={{ marginTop: "14px" }}
      >
        <div>
          <div className="EventDashboardContentHeader">Manage in app</div>
          <div className="EventDashboardContentDescription">
            {value === 0
              ? "Manage all your transaction data in the Markit app."
              : value === 1
              ? "Manage all your payout disputes in the Markit app."
              : "Manage all your Stripe payouts in the Markit app."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsPanel;
