import { Event } from "@markit/common.types";
import {
  isEventExternalLink,
  isExternalGenericLink,
  isRequestTickets,
} from "@markit/common.utils";
import { Colors } from "../../../utils/colors";
import { useCallback } from "react";
import { Icon } from "@iconify/react";

type ProfileEventTimelineSubItemProps = {
  event: Event;
  condensedView?: boolean;
};

// To display the event details sub item box at the bottom of the ProfileEventTimelineCardItem or ProfileEventTimelineGridItem
// Displays the state such as "Request Required", "Linked Event"
const ProfileEventTimelineSubItem = (
  props: ProfileEventTimelineSubItemProps
) => {
  const { event, condensedView } = props;

  const renderItemContainer = useCallback(
    (title: string, backgroundColor: string, color: string) =>
      condensedView ? (
        <span style={{ fontSize: 12, color: color }}>{title}</span>
      ) : (
        <div
          className="AlignedRowSelect"
          style={{
            gap: 5,
            paddingBlock: 6,
            paddingInline: 8,
            borderRadius: 8,
            backgroundColor: backgroundColor,
            justifyContent: "center",
            width: "min-content",
          }}
        >
          {isEventExternalLink(event.eventType) ? (
            <Icon
              icon={"mdi:link-variant"}
              style={{ color: Colors.GRAY1 }}
              height={12}
            />
          ) : null}
          <span style={{ fontSize: 12, color: color, whiteSpace: "nowrap" }}>
            {title}
          </span>
        </div>
      ),
    [condensedView, event.eventType]
  );

  return isEventExternalLink(event.eventType)
    ? renderItemContainer(
        isExternalGenericLink(event.eventType) ? "Link" : "Linked Event",
        Colors.GRAY6,
        Colors.GRAY1
      )
    : isRequestTickets(event)
    ? renderItemContainer("Approval Required", Colors.BLUE2, Colors.BLUE5)
    : null;
};

export default ProfileEventTimelineSubItem;
