import "../../css/AdminDashboard.css";
import "../../css/GlobalStyles.css";
import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, ThemeProvider, CircularProgress, Box } from "@mui/material";
import { themeMui } from "../../components/FullEvent/FullEventInputForm";
import { firebaseAuth, signOut } from "../../firebase";
import { GetNumericDate, GetTime } from "../../utils/GetTime";
import { API } from "../../API";
import {
  CustomTab,
  CustomTabs,
  a11yProps,
} from "../../components/FullEventSubComponents/CustomTabs";
import { TabPanel } from "../../components/FullEventSubComponents/TabPanel";
import FlatList from "flatlist-react/lib";
import { useLoadUserList } from "../../hooks/useLoadUserList";
import DatePicker from "react-datepicker";
import { isDesktop } from "react-device-detect";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import {
  LoginState,
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { useNavigate } from "../../hooks/useNavigate";
import AdminDashboardFeatureEvent from "./AdminDashboardFeatureEvent";
import { HorizontalDivider } from "../../components/Dividers/HorizontalDivider";
import { Colors } from "../../utils/colors";

type AdminDashboardProps = {};

const AdminDashboard = memo(function AdminDashboardFn(
  props: AdminDashboardProps
) {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn, followingAccountData } = account;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingSuccessful, setLoadingSuccessful] = useState<boolean>(true);

  const oneWeekAgoDate = new Date();
  oneWeekAgoDate.setDate(oneWeekAgoDate.getDate() - 1);
  const oneWeekAgoDateString = oneWeekAgoDate.toISOString();
  const [startDate, setStartDate] = useState<string>(oneWeekAgoDateString);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const [successfulPayments, setSuccessfulPayments] = useState<any>([]);
  const [successfulPaymentIds, setSuccessfulPaymentIds] = useState<string[]>(
    []
  );
  const [invalidPayments, setInvalidPayments] = useState<any[]>([]);
  const [limit, setLimit] = useState(100);
  const [failedLimit, setFailedLimit] = useState(10);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const {
    isFinished: isSuccessfulPaymentFinished,
    isLoading: isSuccessfulPaymentLoading,
    fetchedUserData: successfulPaymentAccountData,
    loadMoreUsers: loadMoreSuccessfulPaymentUsers,
  } = useLoadUserList({
    userIdList: successfulPaymentIds,
    windowSize: limit,
    followingAccountData,
  });

  useEffect(() => {
    if (
      successfulPaymentAccountData.length === 0 &&
      !isSuccessfulPaymentFinished
    ) {
      loadMoreSuccessfulPaymentUsers();
      setLoadingSuccessful(false);
    }
  }, [
    isSuccessfulPaymentFinished,
    loadMoreSuccessfulPaymentUsers,
    successfulPaymentAccountData.length,
  ]);

  useEffect(() => {
    (async () => {
      // fetch stripe payments
      const { successfulPaymentIntents, invalidPaymentIntents } =
        await API.adminDashboard.fetchDashboardStripePayments({
          startDate: startDate,
          endDate: endDate,
          limit: limit,
        });
      setSuccessfulPayments(successfulPaymentIntents);
      setSuccessfulPaymentIds(
        successfulPaymentIntents.map(
          (payment: any) => payment.paymentIntent.metadata.uid
        )
      );
      setInvalidPayments(invalidPaymentIntents);
      setLoading(false);
    })();
  }, [endDate, startDate, limit]);

  const logoutUser = useCallback(() => {
    dispatch(accountActions.logout());
    signOut(firebaseAuth);
    window.location.reload();
  }, [dispatch]);

  const navigateEventDashboard = useCallback(async () => {
    navigate("/adminDashboard/events");
  }, [navigate]);

  const navigateToStripeLink = useCallback((id: string) => {
    window.open("https://dashboard.stripe.com/payments/" + id);
  }, []);

  const navigateToEventLink = useCallback((id: string) => {
    window.open("https://markitai.com/e/" + id);
  }, []);

  const navigateToGoogleVoice = useCallback(() => {
    window.open("https://voice.google.com/u/4/messages");
  }, []);

  const renderItem = (payment: any, paymentType: string) => {
    return (
      <div
        className={
          paymentType === "Successful" && !isDesktop
            ? "AdminDashboardListItem"
            : "AdminDashboardListItemEvent"
        }
      >
        <div className="AlignedRowSpaced">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: 60,
            }}
          >
            <span className="AdminDashboardSubtext">
              {GetNumericDate(
                new Date(
                  Number(payment.paymentIntent.latest_charge.created) * 1000
                ).toISOString()
              )}
            </span>
            <span className="AdminDashboardSubtext">
              {GetTime(
                new Date(
                  Number(payment.paymentIntent.latest_charge.created) * 1000
                ).toISOString()
              )}
            </span>
          </div>
          {paymentType === "Successful" ? (
            <span
              className="AdminDashboardSubtext"
              style={{ textAlign: "left", width: 100 }}
            >
              {
                successfulPaymentAccountData.find(
                  (user) => user.uid === payment.paymentIntent.metadata.uid
                )?.fullName
              }
            </span>
          ) : null}
          {paymentType === "Failed" ? (
            <span
              className="AdminDashboardSubtext"
              style={{ width: 100, color: "#11B8FF" }}
              onClick={navigateToGoogleVoice}
            >
              {payment.phoneNumber}
            </span>
          ) : null}
          {paymentType === "Successful" ? (
            <span className="AdminDashboardSubtext" style={{ width: 50 }}>
              ${payment.paymentIntent.amount / 100}
            </span>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span className="AdminDashboardSubtext" style={{ width: 50 }}>
                ${payment.paymentIntent.amount / 100}
              </span>
              <span
                className="AdminDashboardSubtext"
                style={{ fontWeight: "bold" }}
              >
                {payment.paymentIntent.latest_charge.amount_refunded > 0
                  ? "Refunded"
                  : ""}
              </span>
            </div>
          )}
          {paymentType === "Successful" ? (
            <>
              <span
                className="AdminDashboardSubtext"
                style={{ width: 100, color: "#11B8FF" }}
                onClick={() =>
                  navigateToEventLink(payment.paymentIntent.metadata.eventId)
                }
              >
                {payment.eventTitle}
              </span>
              <span className="AdminDashboardSubtext" style={{ width: 100 }}>
                {payment.ticketLabel}
              </span>
              <span className="AdminDashboardSubtext" style={{ width: 20 }}>
                {payment.paymentIntent.metadata.numberOfTickets}
              </span>
            </>
          ) : null}
        </div>
        <div className="AlignedRowSpaced">
          <span
            className="AdminDashboardSubtext"
            style={{ color: "#11B8FF" }}
            onClick={() => navigateToStripeLink(payment.paymentIntent.id)}
          >
            Visit Stripe Payment
          </span>
        </div>
      </div>
    );
  };

  if (!loading) {
    return (
      <div className="AdminDashboardContainer">
        <div className="AdminDashboardBody">
          <div className="AlignedRowSpaced" style={{ paddingTop: 14 }}>
            <p className="AdminDashboardHeader">
              Welcome, Admin Master {accountData.fullName}!
            </p>
            {loggedIn === LoginState.LOGGED_IN ? (
              <div style={{ marginTop: 5 }} onClick={logoutUser}>
                <p
                  style={{
                    color: "#11B8FF",
                    fontSize: 14,
                  }}
                >
                  Logout
                </p>
              </div>
            ) : null}
          </div>
          <HorizontalDivider altMargin={24} />
          <div>
            <p className="AdminDashboardSectionTitle">
              Recent Events Dashboard
            </p>
            <ThemeProvider theme={themeMui}>
              <div className="AlignedRow" style={{ marginTop: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="RSVPButton"
                  disableElevation
                  sx={{
                    borderRadius: 2,
                    backgroundColor: Colors.BLUE5,
                    width: 300,
                  }}
                  onClick={navigateEventDashboard}
                >
                  Dashboard
                </Button>
              </div>
            </ThemeProvider>
          </div>
          <HorizontalDivider altMargin={24} />
          <AdminDashboardFeatureEvent />
          <HorizontalDivider altMargin={24} />
          <div className="ColumnNormal">
            <div className="AlignedRow">
              <span className="AdminDashboardSectionTitle">
                Stripe Dashboard
              </span>
              <span style={{ color: "#fff", fontSize: 12, marginLeft: 10 }}>
                (only fetches up to past 100 payments right now)
              </span>
            </div>
            <span className="AdminDashboardSubtext" style={{ marginTop: 10 }}>
              Time Range Filter
            </span>
            <div
              className={isDesktop ? "AlignedRow" : "AlignedRowSpaced"}
              style={{ marginTop: 10 }}
            >
              <DatePicker
                selected={new Date(startDate)}
                onChange={(date) => date && setStartDate(date.toISOString())}
                showTimeSelect
              />
              <DatePicker
                selected={new Date(endDate)}
                onChange={(date) => date && setEndDate(date.toISOString())}
                showTimeSelect
              />
            </div>
            <Box>
              <CustomTabs
                value={value}
                onChange={handleChange}
                sx={{ borderBottom: 0, marginTop: 1 }}
              >
                <CustomTab
                  label={
                    <span
                      className={value === 0 ? "TabNameActive" : "TabName"}
                      style={{ color: "#FFF" }}
                    >
                      Successful
                    </span>
                  }
                  {...a11yProps(0)}
                />
                <CustomTab
                  label={
                    <span
                      className={value === 1 ? "TabNameActive" : "TabName"}
                      style={{ color: "#FFF" }}
                    >
                      Failed
                    </span>
                  }
                  {...a11yProps(1)}
                />
              </CustomTabs>
            </Box>
            <div className="AlignedRowSpaced">
              {value === 0 ? (
                <>
                  <span className="AdminDashboardSubtext">Time</span>
                  <span className="AdminDashboardSubtext">Name</span>
                  <span className="AdminDashboardSubtext">Amount</span>
                  <span className="AdminDashboardSubtext">Event Id</span>
                  <span className="AdminDashboardSubtext">Tix Id</span>
                  <span className="AdminDashboardSubtext"># Tix</span>
                </>
              ) : (
                <>
                  <span className="AdminDashboardSubtext">Time</span>
                  <span className="AdminDashboardSubtext">Phone</span>
                  <span className="AdminDashboardSubtext">Amount</span>
                </>
              )}
            </div>
            {!loadingSuccessful ? (
              <TabPanel value={value} index={0}>
                <div className="AdminDashboardList">
                  <FlatList
                    list={successfulPayments}
                    renderItem={(payment: any) =>
                      renderItem(payment, "Successful")
                    }
                    renderWhenEmpty={() => (
                      <div>
                        <span className="AdminDashboardSubtext">
                          No successful payments to show
                        </span>
                      </div>
                    )}
                    hasMoreItems={!isSuccessfulPaymentFinished}
                    loadMoreItems={() => {
                      setLimit(limit + 30);
                      loadMoreSuccessfulPaymentUsers();
                    }}
                    paginationLoadingIndicator={() =>
                      isSuccessfulPaymentLoading &&
                      !isSuccessfulPaymentFinished ? (
                        <CircularProgress
                          style={{ color: "#fff", alignSelf: "center" }}
                          size={20}
                        />
                      ) : null
                    }
                  />
                </div>
              </TabPanel>
            ) : (
              <div
                className="AdminDashboardContainer"
                style={{ marginTop: 50 }}
              >
                <CircularProgress
                  style={{ color: "#929292", alignSelf: "center" }}
                  size={25}
                />
              </div>
            )}
            <TabPanel value={value} index={1}>
              <div className="AdminDashboardList">
                <FlatList
                  list={invalidPayments}
                  renderItem={(payment) => renderItem(payment, "Failed")}
                  renderWhenEmpty={() => (
                    <div>
                      <span className="AdminDashboardSubtext">
                        No failed payments to show
                      </span>
                    </div>
                  )}
                  loadMoreItems={() => {
                    setFailedLimit(failedLimit + 10);
                  }}
                />
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="AdminDashboardContainer"
        style={{ justifyContent: "center" }}
      >
        <CircularProgress
          style={{ color: "#929292", alignSelf: "center" }}
          size={25}
        />
      </div>
    );
  }
});

export default AdminDashboard;
