import React, { useMemo } from "react";
import {
  isEventExternalLink,
  isExternalGenericLink,
} from "@markit/common.utils";
import { getEventState } from "../../../redux/slices/eventSlice";
import { useSelector } from "react-redux";
import { Event, OrganizerType } from "@markit/common.types";
import { Colors } from "../../../utils/colors";

type EventItemPreviewTypeProps = {
  eventItem: Event;
  altBackgroundColor?: string;
  altFontSize?: number;
};

// The text box that is displayed with the event item preview to denote hosting, co-hosting, or external link
const EventItemPreviewType = ({
  eventItem,
  altBackgroundColor,
  altFontSize,
}: EventItemPreviewTypeProps) => {
  const { tickets } = useSelector(getEventState).events;

  const userTicket = useMemo(
    () =>
      tickets.find((ticket) => {
        return ticket.eventId === eventItem.id;
      }),
    [eventItem.id, tickets]
  );

  const organizerType = useMemo(
    () =>
      userTicket && userTicket.role
        ? userTicket.role.type === OrganizerType.HOST
          ? "Hosting"
          : userTicket.role.type === OrganizerType.COHOST
          ? "Co-Hosting"
          : userTicket.role.type === OrganizerType.SCANNER
          ? "Scanning"
          : userTicket.role.type === OrganizerType.PERFORMER
          ? "Performing"
          : userTicket.role.type === OrganizerType.PROMOTER
          ? "Promoting"
          : "Organizing"
        : "Organizing",
    [userTicket]
  );

  return !isExternalGenericLink(eventItem.eventType) ? (
    <div
      className="RowNormal"
      style={{
        width: "fit-content",
        cursor: "pointer",
        paddingInline: 8,
        paddingBlock: 6,
        borderRadius: 8,
        backgroundColor: altBackgroundColor ?? Colors.WHITE,
        boxShadow: "0px 1px 2px 0.5px #EDEDED",
      }}
    >
      <span style={{ fontSize: altFontSize ?? 12, color: Colors.BLUE5 }}>
        {isEventExternalLink(eventItem.eventType)
          ? "Linked Event"
          : organizerType}
      </span>
    </div>
  ) : null;
};

export default EventItemPreviewType;
