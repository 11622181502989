import { Colors } from "../../utils/colors";
import { MouseEventHandler } from "react";
import { Icon } from "@iconify/react";

type ThreeDotsButtonProps = {
  onPress: MouseEventHandler<HTMLDivElement>;
};

const ThreeDotsButton = (props: ThreeDotsButtonProps) => {
  const { onPress } = props;

  return (
    <div
      className="ColumnNormalSelect"
      style={{
        fontSize: 14,
        fontWeight: 600,
      }}
      onClick={onPress}
    >
      <Icon icon="ion:ellipsis-horizontal" height={20} color={Colors.BLACK} />
    </div>
  );
};

export default ThreeDotsButton;
