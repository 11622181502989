import { useCallback, useEffect, useState } from "react";
import { uniqueVals } from "@markit/common.utils";
import { AccountData } from "@markit/common.types";
import { fetchSingleUser } from "../utils/FetchSingleUser";
import { algoliaClient } from "../utils/algoliaUtils";

type useLoadUserListProps = {
  userIdList: string[];
  windowSize: number;
  followingAccountData: AccountData[];
  callbackFn?: (accountData: AccountData[]) => void;
};
export const useLoadUserList = (props: useLoadUserListProps) => {
  const { userIdList, windowSize, followingAccountData, callbackFn } = props;

  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const [failedUserIds, setFailedUserIds] = useState<string[]>([]);
  const [sortedUserIds, setSortedUserIds] = useState<string[]>(userIdList);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const addUserData = useCallback(
    (newUserData: AccountData[]) => {
      setFetchedUserData((fetchedUserData) =>
        uniqueVals(
          fetchedUserData.concat(newUserData),
          (userData) => userData.uid
        )
      );
      callbackFn && callbackFn(newUserData);
    },
    [callbackFn]
  );

  const addFailedUser = useCallback((failedUsers: string[]) => {
    setFailedUserIds((failedUserIds) =>
      uniqueVals(failedUserIds.concat(failedUsers))
    );
  }, []);

  const loadAndStoreUsers = useCallback(async () => {
    setIsLoading(true);
    const usersToFetch = sortedUserIds
      .slice(0, fetchedUserData.length + failedUserIds.length + windowSize)
      .filter(
        (userId) => !fetchedUserData.some((userData) => userData.uid === userId)
      );
    const newUsers: AccountData[] = [];
    const failedUids: string[] = [];
    await Promise.all(
      usersToFetch.map((userId) =>
        fetchSingleUser(
          userId,
          followingAccountData,
          (userData) => newUsers.push(userData),
          () => failedUids.push(userId)
        )
      )
    );

    if (newUsers.length < windowSize) {
      setIsFinished(true);
    }

    addFailedUser(failedUids);
    addUserData(newUsers);
    setIsLoading(false);
  }, [
    addFailedUser,
    addUserData,
    failedUserIds.length,
    fetchedUserData,
    followingAccountData,
    sortedUserIds,
    windowSize,
  ]);

  const loadMoreUsers = useCallback(async () => {
    if (sortedUserIds.length === 0) return;
    if (!isFinished && !isLoading) {
      loadAndStoreUsers();
    }
  }, [sortedUserIds.length, isFinished, isLoading, loadAndStoreUsers]);

  // When the users displayed need to be manually refetched due to a state change of userIdList
  // Ex: AudienceListPopupPanel when you add/remove new members to the list
  const reloadMoreUsers = useCallback(async () => {
    loadAndStoreUsers();
  }, [loadAndStoreUsers]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      setIsLoading(true);
      if (!isFinished) {
        const index = algoliaClient.initIndex("users");
        const results = (await index.search(searchTerm)).hits;
        const userIds = results
          .map((obj) => obj.objectID)
          .filter(
            (uid) =>
              sortedUserIds.includes(uid) &&
              !fetchedUserData.some((userData) => userData.uid === uid)
          );

        const newUsers: AccountData[] = [];
        await Promise.all(
          userIds.map((userId) =>
            fetchSingleUser(userId, followingAccountData, (userData) =>
              newUsers.push(userData)
            )
          )
        );
        addUserData(newUsers);
      }
      setIsLoading(false);
    },
    [
      addUserData,
      fetchedUserData,
      followingAccountData,
      isFinished,
      sortedUserIds,
    ]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          loadSearchResults(searchTerm);
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  useEffect(() => {
    let newFetchedUserData = [...fetchedUserData];
    newFetchedUserData = newFetchedUserData.filter((user) =>
      userIdList.includes(user.uid)
    );

    const newUserIdList = [...userIdList];
    newUserIdList.sort((uid1, uid2) => {
      const newFetchedUserIds = newFetchedUserData.map((user) => user.uid);
      const followingAccountDataIds = followingAccountData.map(
        (user) => user.uid
      );
      const uid1InFollowers = followingAccountDataIds.includes(uid1);
      const uid2InFollowers = followingAccountDataIds.includes(uid2);
      const uid1InFetched = newFetchedUserIds.includes(uid1);
      const uid2InFetched = newFetchedUserIds.includes(uid2);
      if (uid1InFollowers || uid2InFollowers) {
        if (uid1InFollowers && uid2InFollowers) {
          return 0;
        }
        return uid1InFollowers ? -1 : 1;
      } else if (uid1InFetched || uid2InFetched) {
        if (uid1InFetched && uid2InFetched) {
          return 0;
        }
        return uid1InFetched ? -1 : 1;
      }
      return 0;
    });
    setFetchedUserData(newFetchedUserData);
    setSortedUserIds(newUserIdList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIdList]);

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    reloadMoreUsers,
    loadSearchResultsThrottled,
  };
};
