import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Colors } from "../../../utils/colors";
import { GetNumericDate, GetTime } from "../../../utils/GetTime";
import { GetLongDate } from "../../../utils/GetLongDate";
import CustomLinkify from "../../Links/CustomLinkify";
import { capitalizeFirstLetter } from "@markit/common.utils";
import MessageNotDeliveredAlert from "./MessageNotDeliveredAlert";
import { IMessage, TextTypes, TwilioMessageStatus } from "@markit/common.types";

type ChatMessageProps = {
  message: IMessage;
  userId: string;
  previousMessage?: IMessage;
  finalMessage?: boolean;
  messageBubbleAltWidth?: string;
};

export const ChatMessage = ({
  message,
  userId,
  previousMessage,
  finalMessage,
  messageBubbleAltWidth,
}: ChatMessageProps) => {
  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    if (message.photoURL) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
      img.src = message.photoURL;
    }
  }, [message.photoURL]);

  const hideMessageDetails = useMemo(
    () =>
      finalMessage &&
      (message.status === TwilioMessageStatus.SENT ||
        message.status === TwilioMessageStatus.DELIVERED),
    [finalMessage, message.status]
  );

  const isMessageFromSelf = useMemo(
    () => message.user._id === userId,
    [message.user._id, userId]
  );

  const currentMessageTime = useMemo(
    () =>
      message.createdAt instanceof Date
        ? (message.createdAt as Date).toISOString()
        : message.createdAt.toString(),
    [message.createdAt]
  );

  const nonCreatorMessage =
    message.type && message.type !== TextTypes.CREATORMESSAGE;

  const MessagesDate = useCallback(() => {
    if (
      !previousMessage ||
      (previousMessage &&
        GetNumericDate(message.createdAt.toString()) !==
          GetNumericDate(previousMessage.createdAt.toString()))
    ) {
      let dateToDisplay = "";
      if (message.createdAt instanceof Date) {
        dateToDisplay = (message.createdAt as Date).toISOString();
      } else {
        dateToDisplay = message.createdAt.toString();
      }

      return (
        <div className="MessagesCenteredDate">
          {GetLongDate(dateToDisplay, true, true, false, true)}
        </div>
      );
    } else {
      return null;
    }
  }, [previousMessage, message.createdAt]);

  const styles = {
    media: {
      width: 260,
      borderRadius: 12,
    },

    messageBubble: {
      maxWidth: "40%",
      borderRadius: 16,
      padding: 10,
    },

    messageViewCreator: {
      marginLeft: 100,
      marginTop: 7,
    },

    messageViewParticipant: {
      marginRight: 100,
      marginTop: 7,
    },
  };

  return (
    <>
      <MessagesDate />
      {message.photoURL ? (
        <img
          src={message.photoURL}
          alt={"text media"}
          style={{ ...styles.media, ...{ aspectRatio: aspectRatio } }}
        />
      ) : null}
      {message.text && message.text.trim() !== "" ? (
        <div
          className="ColumnNormal"
          key={message._id}
          style={{
            ...styles.messageBubble,
            maxWidth: messageBubbleAltWidth ?? "40%",
            alignSelf: isMessageFromSelf ? "flex-end" : "flex-start",
            backgroundColor: isMessageFromSelf
              ? nonCreatorMessage
                ? Colors.WHITE
                : Colors.BLUE4
              : Colors.GRAY6,
            border: nonCreatorMessage ? "1px solid #B9B9B9" : "none",
            color: isMessageFromSelf
              ? nonCreatorMessage
                ? Colors.GRAY3
                : Colors.WHITE
              : Colors.BLACK,
            borderBottomLeftRadius: isMessageFromSelf ? 16 : 4,
            borderBottomRightRadius: isMessageFromSelf ? 4 : 16,
          }}
        >
          <CustomLinkify
            altColor={
              nonCreatorMessage || !isMessageFromSelf
                ? Colors.BLUE5
                : Colors.WHITE
            }
          >
            <span
              style={{
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
                fontSize: 14,
              }}
            >
              {message.text}
            </span>
          </CustomLinkify>
          <div
            className="AlignedRow"
            style={{
              gap: 7,
              marginTop: hideMessageDetails && !nonCreatorMessage ? 0 : 5,
            }}
          >
            {nonCreatorMessage ? (
              <span style={{ opacity: 0.7, fontSize: 12 }}>
                {capitalizeFirstLetter(message.type)}
              </span>
            ) : null}
            {!hideMessageDetails ? (
              <span style={{ opacity: 0.7, fontSize: 12 }}>
                {GetTime(currentMessageTime)}
              </span>
            ) : null}
          </div>
        </div>
      ) : null}
      {hideMessageDetails ? (
        <span style={{ fontSize: 12, color: Colors.GRAY3 }}>
          Delivered
          {"  "}
          {GetTime(currentMessageTime)}
        </span>
      ) : message.status === TwilioMessageStatus.UNDELIVERED ||
        message.status === TwilioMessageStatus.FAILED ? (
        <MessageNotDeliveredAlert />
      ) : null}
    </>
  );
};
