import { useMemo } from "react";
import { Colors } from "../../../../utils/colors";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { prettifyPhone } from "@markit/common.utils";
import LargePopupModalContainer from "../../../Containers/LargePopupModalContainer";
import LargePopupModalHeader from "../../../Containers/LargePopupModalHeader";

type ValidContactsPopupPanelProps = {
  contactsToShow: { name: string; phoneNumber: string }[];
  contactsView: number;
  setContactsView: (contactsView: number) => void;
};

const ValidContactsPopupPanel = (props: ValidContactsPopupPanelProps) => {
  const { contactsToShow, contactsView, setContactsView } = props;

  const styles = {
    itemTitle: { fontSize: 14, fontWeight: 500 },
    itemSubtext: { fontSize: 12, color: Colors.GRAY1 },
  };

  const contactsTitle = useMemo(
    () =>
      contactsView === 1
        ? "Valid Contacts"
        : contactsView === 2
        ? "Failed Contacts"
        : contactsView === 3
        ? "Duplicate Contacts"
        : "Already Existing Contacts",
    [contactsView]
  );

  return (
    <LargePopupModalContainer
      showModal={contactsView > 0}
      headerComp={
        <LargePopupModalHeader
          title={contactsTitle}
          backOnPress={() => setContactsView(0)}
        />
      }
      valueComp={
        <div
          className="HideScrollbar"
          style={{ overflow: "auto", height: `calc(100vh - 110px)` }}
        >
          {contactsToShow.map((contact) => (
            <div className="ColumnNormal">
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <span style={styles.itemTitle}>
                  {contact.name ? contact.name : "Empty Name"}
                </span>
                <span style={styles.itemSubtext}>
                  {contact.phoneNumber
                    ? prettifyPhone(contact.phoneNumber)
                    : "Empty Number"}
                </span>
              </div>
              <HorizontalDivider altMargin={14} />
            </div>
          ))}
        </div>
      }
    />
  );
};

export default ValidContactsPopupPanel;
