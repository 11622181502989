import { TextField } from "@mui/material";
import { useTheme } from "../../hooks/useTheme";
import { isDesktop } from "react-device-detect";
import { useState } from "react";

// for checking formatting of verification code
export const formattedVerificationCode = /[!@#$%^&*()_+\-=[\]{};':"|,.<>\\/?]+/;

type VerificationCodeTextFieldProps = {
  index: number;
  elem: number;
  showCodeInputError: boolean;
  setShowCodeInputError: (showCodeInputError: boolean) => void;
  verificationCode: string[];
  setVerificationCode: (verificationCode: string[]) => void;
  refs: React.MutableRefObject<HTMLElement | undefined>[];
};

/**
 * To be used for text fields with the verified text in the right of the text box
 */
const VerificationCodeTextField = (props: VerificationCodeTextFieldProps) => {
  const {
    index,
    elem,
    showCodeInputError,
    setShowCodeInputError,
    verificationCode,
    setVerificationCode,
    refs,
  } = props;

  const { theme } = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <TextField
      key={index}
      error={showCodeInputError}
      autoFocus={index === 0}
      autoComplete="off"
      style={{
        backgroundColor: theme.SecondaryBG.backgroundColor,
        alignSelf: "center",
        padding: isDesktop ? "10px 14px" : "5px 12px",
        borderRadius: 8,
        border: `1px solid`,
        borderColor: showCodeInputError
          ? theme.ErrorBorderColor.borderColor
          : isFocused
          ? theme.FocusedTextFieldBorder.borderColor
          : theme.EditableTextFieldBorder.borderColor,
      }}
      sx={{
        "& input:-webkit-autofill": {
          WebkitBoxShadow: `0 0 0 1000px ${theme.SecondaryBG.backgroundColor} inset`,
          WebkitTextFillColor: theme.PrimaryText.color,
          borderRadius: 8,
          caretColor: theme.PrimaryText.color,
          WebkitBackgroundClip: "text !important",
        },
      }}
      onKeyDown={(e: React.KeyboardEvent) => {
        // if the user clicks delete in an empty input, go back one
        if (
          (e.key === "Delete" || e.key === "Backspace") &&
          verificationCode[elem] === ""
        ) {
          refs[Math.max(Math.min(elem - 1, 5), 0)].current?.focus();
        }
      }}
      inputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        style: { ...theme.PrimaryText, textAlign: "center" },
      }}
      onFocus={handleFocus}
      onBlur={handleBlur}
      variant="standard"
      InputProps={{
        disableUnderline: true,
      }}
      inputRef={refs[elem]}
      value={verificationCode[elem]}
      onChange={(event) => {
        const newValue = event.target.value;
        const isNumber = /^\d*$/.test(newValue);
        if (!isNumber) {
          return; // Early return if input is not a number
        }

        setShowCodeInputError(false);

        if (newValue.length === 0) {
          setVerificationCode(
            verificationCode.map((val, index) => (index === elem ? "" : val))
          );
          refs[Math.max(Math.min(elem - 1, 5), 0)].current?.focus();
        } else if (newValue.length === 1 || newValue.length === 2) {
          setVerificationCode(
            verificationCode.map((val, index) =>
              index === elem ? newValue[newValue.length - 1] : val
            )
          );
          refs[Math.max(Math.min(elem + 1, 5), 0)].current?.focus();
        } else {
          setVerificationCode(
            verificationCode.map((val, index) =>
              index >= elem && index < elem + newValue.length
                ? newValue[index - elem]
                : val
            )
          );
          refs[
            Math.max(Math.min(elem + newValue.length, 5), 0)
          ].current?.focus();
        }
      }}
    />
  );
};

export default VerificationCodeTextField;
