import { useSelector } from "react-redux";
import { AccountData } from "@markit/common.types";
import { getAccountState } from "../../redux/slices/accountSlice";
import { FollowerProfileHeader } from "./FollowerProfileHeader";
import LargePopupModalContainer from "../Containers/LargePopupModalContainer";
import FullFollowerProfilePopupPanelHeader from "./FullFollowerProfilePopupPanelHeader";

type FullFollowerProfilePopupPanelProps = {
  userData: AccountData;
  fullFollowerProfilePopupPanelVisible: boolean;
  goBackToProfilePreview: () => void;
  addToListOnPress: () => void;
  conversationOnPress: () => void;
};

const FullFollowerProfilePopupPanel = (
  props: FullFollowerProfilePopupPanelProps
) => {
  const { account } = useSelector(getAccountState);
  const { accountData } = account;

  const {
    userData,
    fullFollowerProfilePopupPanelVisible,
    goBackToProfilePreview,
    addToListOnPress,
    conversationOnPress,
  } = props;

  // MassTexsPopupPanel main content
  return (
    <LargePopupModalContainer
      showModal={fullFollowerProfilePopupPanelVisible}
      headerComp={
        <FullFollowerProfilePopupPanelHeader
          userData={userData}
          goBackToProfilePreview={goBackToProfilePreview}
        />
      }
      valueComp={
        <FollowerProfileHeader
          fullProfile={true}
          creatorUid={accountData.uid}
          userData={userData}
          conversationOnPress={conversationOnPress}
          addToListOnPress={addToListOnPress}
        />
      }
    />
  );
};

export default FullFollowerProfilePopupPanel;
