import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  createAudienceList,
  getAccountState,
  updateAudienceList,
} from "../../../../redux/slices/accountSlice";
import { Colors } from "../../../../utils/colors";
import LargePopupModalContainer from "../../../Containers/LargePopupModalContainer";
import { BackButton } from "../../../Buttons/BackButton";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import { Icon } from "@iconify/react";
import CustomTextField from "../../../CustomTextField";
import {
  AccountData,
  AudienceList,
  AudienceListVisibility,
} from "@markit/common.types";
import { makeEmptyAudienceList } from "../../../../utils/makeEmptyData";
import SwitchToggleItem from "../../../SwitchToggleItem";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import { useOnMount } from "../../../../utils/useOnMount";
import { fetchUserAudienceListMembers } from "../../../../utils/userUtils";
import filter from "lodash.filter";
import FlatList from "flatlist-react/lib";
import EmptyStateButton from "../../../Buttons/EmptyStateButton";
import { CircularProgress } from "@mui/material";
import RectangleButton from "../../../Buttons/RectangleButton";
import { useDispatch } from "react-redux";
import AudienceListDropdownMenu from "../../../Dropdowns/AudienceListDropdownMenu";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import isEqual from "lodash.isequal";
import SimpleSelectRecipientsPanel from "../../MassTexts/SelectRecipientsFlow/SimpleSelectRecipientsPanel";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { MassTextsPersonItem } from "../../MassTexts/Items/MassTextsPersonItem";
import FullProfilePreviewModal from "../../../FollowerProfile/FullProfilePreviewModal";
import { useLoadUserList } from "../../../../hooks/useLoadUserList";
import { FAVORITES_LIST_NAME } from "@markit/common.utils";
import { EmptyStateFlatlist } from "../../../EmptyStates/EmptyStateFlatlist";

type AudienceListPopupPanelProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  existingList: AudienceList | undefined;
  setExistingList: (existingList: AudienceList | undefined) => void;
  handleOnExit?: (list: AudienceList) => void; // Handles actions outside of popup, ie. AddToListModal
};

const AudienceListPopupPanel = (props: AudienceListPopupPanelProps) => {
  const {
    isVisible,
    setIsVisible,
    existingList,
    setExistingList,
    handleOnExit,
  } = props;
  const dispatch = useDispatch();
  const { accountData, followingAccountData, audienceLists } =
    useSelector(getAccountState).account;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [audienceList, setAudienceList] = useState<AudienceList>(
    existingList ?? makeEmptyAudienceList()
  );
  const [currentMembers, setCurrentMembers] = useState<string[]>([]);
  const [excludedMembers, setExcludedMembers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [listNameError, setListNameError] = useState<{
    isError: boolean;
    message: string;
  }>({ isError: false, message: "" });
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [loadingExistingList, setLoadingExistingList] = useState(true);
  const [savingList, setSavingList] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectRecipientsVisible, setSelectRecipientsVisible] = useState(false);
  const [profileSelected, setProfileSelected] = useState<AccountData>();
  const [initialListMembers, setInitialListMembers] = useState<string[]>([]);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
    bodySmall: { fontSize: 12, color: Colors.GRAY1 },
    sectionTitle: { fontSize: 16, fontWeight: 500 },
    errorText: { fontSize: 12, color: Colors.RED2 },
  };

  const netListMembers = useMemo(
    () =>
      currentMembers
        ? currentMembers.filter((userId) => !excludedMembers?.includes(userId))
        : [],
    [excludedMembers, currentMembers]
  );

  const {
    isFinished,
    isLoading,
    fetchedUserData,
    loadMoreUsers,
    reloadMoreUsers,
    loadSearchResultsThrottled,
  } = useLoadUserList({
    userIdList: !loadingMembers ? netListMembers : [],
    windowSize: 20,
    followingAccountData: followingAccountData,
  });

  useOnMount(() => {
    (async () => {
      // existing audience list
      if (existingList) {
        const listMembers = await fetchUserAudienceListMembers(
          accountData.uid,
          existingList.id
        );
        setCurrentMembers(listMembers);
        setInitialListMembers(listMembers);
      }
      setLoadingExistingList(false);
    })();
  });

  useEffect(() => {
    if (!isLoading && fetchedUserData.length === 0 && !isFinished) {
      loadMoreUsers();
      setLoadingMembers(false);
    }
  }, [fetchedUserData, isFinished, isLoading, loadMoreUsers]);

  const existingListNotEditing = useMemo(
    () => existingList && !isEditing,
    [existingList, isEditing]
  );

  const disableEditingInfo = useMemo(() => {
    const favoritesList = audienceLists.find(
      (list) => list.name === FAVORITES_LIST_NAME
    );
    return favoritesList && favoritesList.id === audienceList.id && isEditing;
  }, [audienceList.id, audienceLists, isEditing]);

  const contains = useCallback((member: AccountData, query: string) => {
    return member.fullName.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
    loadSearchResultsThrottled(text.toLowerCase());
  };

  const netListMembersToShow = useMemo(() => {
    let listMembers: AccountData[] = fetchedUserData;
    if (searchTerm !== "") {
      listMembers = filter(listMembers, (member: AccountData) => {
        return contains(member, searchTerm);
      });
    }
    return listMembers;
  }, [contains, fetchedUserData, searchTerm]);

  const updateAudienceListSettings = useCallback(
    (newListSettings: Partial<AudienceList>) => {
      setAudienceList((prevListSettings) => {
        return { ...prevListSettings, ...newListSettings };
      });
    },
    []
  );

  const backOnPress = useCallback(() => {
    setIsVisible(false);
    setExistingList(undefined);
  }, [setExistingList, setIsVisible]);

  const initialBackOnPress = useCallback(() => {
    const originalList: AudienceList = {
      ...(existingList ?? makeEmptyAudienceList()),
      id: audienceList.id,
    };
    if (
      (!isEqual(originalList, audienceList) ||
        !isEqual(initialListMembers, netListMembers)) &&
      (!existingList || isEditing)
    ) {
      setShowDeleteModal(true);
    } else if (isEditing) {
      setIsEditing(false);
    } else {
      backOnPress();
    }
  }, [
    audienceList,
    backOnPress,
    existingList,
    initialListMembers,
    isEditing,
    netListMembers,
  ]);

  const confirmListOnPress = useCallback(async () => {
    if (existingListNotEditing) {
      setIsEditing(true);
      return;
    }

    if (audienceList.name === "") {
      setListNameError({
        isError: true,
        message: "You must enter a list name.",
      });
      return;
    } else if (
      audienceList.name === FAVORITES_LIST_NAME.toLowerCase() &&
      !existingList
    ) {
      setListNameError({
        isError: true,
        message: 'Can not make list called "Favorites"',
      });
      return;
    } else if (
      audienceLists.find((list) => list.name === audienceList.name) &&
      !existingList
    ) {
      setListNameError({
        isError: true,
        message: "Can not name a list the same as another list",
      });
      return;
    }

    setSavingList(true);
    if (existingList && isEditing) {
      dispatch(
        updateAudienceList(accountData.uid, audienceList, netListMembers)
      );
      setExistingList(audienceList);
      setIsEditing(false);
    } else {
      dispatch(
        createAudienceList(accountData.uid, audienceList, netListMembers)
      );
      if (handleOnExit) {
        setTimeout(() => {
          handleOnExit(audienceList);
        }, 200);
      }
      backOnPress();
    }
    setSavingList(false);
  }, [
    existingListNotEditing,
    audienceList,
    existingList,
    audienceLists,
    isEditing,
    dispatch,
    accountData.uid,
    netListMembers,
    setExistingList,
    handleOnExit,
    backOnPress,
  ]);

  const addPeopleOnPress = useCallback(() => {
    setIsEditing(true);
    setSelectRecipientsVisible(true);
  }, []);

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={loadingExistingList}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <EmptyStateButton
            title="Add People"
            description="People that join or are added to your list will show up here."
            icon={<Icon icon="ion:people" height={64} color={Colors.GRAY1} />}
            iconBox={84}
            btnText="Add People"
            onPress={addPeopleOnPress}
            containerStyles={{ paddingTop: 100 }}
          />
        }
      />
    );
  }, [addPeopleOnPress, loadingExistingList, searchTerm]);

  return (
    <>
      <LargePopupModalContainer
        showModal={isVisible}
        containerStyles={{
          paddingInline: 0,
          height: "97%",
          overflow: "hidden",
        }}
        valueComp={
          !selectRecipientsVisible ? (
            <>
              <div className="AlignedRowSpaced" style={{ padding: 14 }}>
                <BackButton
                  iconName={isEditing ? undefined : "mdi:close"}
                  onPress={initialBackOnPress}
                />
                <div className="AlignedRow" style={{ gap: 14 }}>
                  {existingList && !isEditing ? (
                    <AudienceListDropdownMenu
                      list={existingList}
                      deleteOnPress={initialBackOnPress}
                    />
                  ) : null}
                  <RectangleButton
                    buttonLabel={existingListNotEditing ? "Edit" : "Save"}
                    onPress={confirmListOnPress}
                    loading={savingList}
                    disabled={savingList}
                    altColor={Colors.BLACK}
                    altPaddingHorz={14}
                    altPaddingVert={10}
                    altBorderRadius={100}
                    iconRight={
                      existingListNotEditing ? (
                        <Icon
                          icon="feather:edit"
                          height={14}
                          color={Colors.WHITE}
                          style={{ marginTop: -1 }}
                        />
                      ) : undefined
                    }
                  />
                </div>
              </div>
              <HorizontalDivider />
              <div
                className="ColumnNormal"
                style={{ gap: 24, padding: 14, height: "100%" }}
              >
                <div className="ColumnNormal" style={{ gap: 14 }}>
                  <div className="ColumnNormal" style={{ gap: 7 }}>
                    <span style={styles.headerText}>
                      {existingList
                        ? !isEditing
                          ? existingList.name
                          : "Edit List"
                        : "New List"}
                    </span>
                    {existingList && !isEditing && existingList.description ? (
                      <span style={{ ...styles.bodySmall, fontSize: 14 }}>
                        {existingList.description}
                      </span>
                    ) : null}
                  </div>
                  {isEditing || !existingList ? (
                    <div className="ColumnNormal" style={{ gap: 10 }}>
                      <div className="ColumnNormal" style={{ gap: 7 }}>
                        <CustomTextField
                          value={audienceList.name}
                          placeholder="List Name..."
                          inputMode="text"
                          borderRadius={12}
                          backgroundColor={Colors.WHITE1}
                          onChange={(change: any) => {
                            setListNameError({ isError: false, message: "" });
                            updateAudienceListSettings({
                              name: change.target.value,
                            });
                          }}
                          disabled={disableEditingInfo}
                          error={listNameError.isError}
                          altMarginBottom={0}
                        />
                        {listNameError.isError ? (
                          <span style={styles.errorText}>
                            {listNameError.message}
                          </span>
                        ) : null}
                      </div>
                      <CustomTextField
                        value={audienceList.description}
                        placeholder="List Description..."
                        inputMode="text"
                        borderRadius={12}
                        backgroundColor={Colors.WHITE1}
                        onChange={(change: any) => {
                          updateAudienceListSettings({
                            description: change.target.value,
                          });
                        }}
                        disabled={disableEditingInfo}
                        altMarginBottom={0}
                      />
                    </div>
                  ) : null}
                </div>
                <StandardBorderedContainer
                  containerStyles={{
                    backgroundColor: Colors.GRAY6,
                    padding: 14,
                  }}
                >
                  <SwitchToggleItem
                    title={
                      (audienceList.visibility ===
                        AudienceListVisibility.PRIVATE && existingListNotEditing
                        ? "Private"
                        : "Public") + " List"
                    }
                    description={
                      existingListNotEditing
                        ? audienceList.visibility ===
                          AudienceListVisibility.PRIVATE
                          ? "This list is private."
                          : "This list is public and joinable when people follow your profile."
                        : "Turn on to make your list public. This means anyone can add themselves to your list when following your profile."
                    }
                    toggleValue={
                      audienceList.visibility === AudienceListVisibility.PUBLIC
                    }
                    disabled={disableEditingInfo}
                    onChange={() => {
                      updateAudienceListSettings({
                        visibility:
                          audienceList.visibility ===
                          AudienceListVisibility.PRIVATE
                            ? AudienceListVisibility.PUBLIC
                            : AudienceListVisibility.PRIVATE,
                      });
                    }}
                    hideToggle={existingListNotEditing}
                  />
                </StandardBorderedContainer>
                <div
                  className="ColumnNormal"
                  style={{ gap: 10, height: "100%" }}
                >
                  <span style={styles.sectionTitle}>
                    People ({netListMembers.length})
                  </span>
                  <SearchBoxContainer
                    placeholder="Search People..."
                    onChange={(e) => handleSearch(e.target.value)}
                    containerStyles={{ marginTop: 0 }}
                  />
                  <div className="HideScrollbar" style={{ overflow: "auto" }}>
                    {(isEditing || !existingList) &&
                    currentMembers.length > 0 ? (
                      <>
                        <div
                          onClick={addPeopleOnPress}
                          className="AlignedRowSpacedSelect"
                          style={{ paddingBlock: 7, paddingLeft: 7 }}
                        >
                          <span
                            style={{
                              ...styles.bodyMedium,
                              color: Colors.BLUE5,
                            }}
                          >
                            Add People...
                          </span>
                          <Icon
                            icon={"ion:add-circle"}
                            height={24}
                            style={{ color: Colors.BLUE5 }}
                          />
                        </div>
                        <HorizontalDivider altMargin={10} />
                      </>
                    ) : null}
                    <FlatList
                      list={netListMembersToShow}
                      renderItem={(item) => {
                        return (
                          <MassTextsPersonItem
                            user={item}
                            unsavedPeople={netListMembers}
                            setUnsavedPeople={setCurrentMembers}
                            setProfileSelected={setProfileSelected}
                            disableCheck={!isEditing}
                          />
                        );
                      }}
                      renderWhenEmpty={() => renderEmptyView}
                      hasMoreItems={!isFinished}
                      loadMoreItems={() => {
                        setTimeout(() => {
                          if (searchTerm.trim() === "") {
                            loadMoreUsers();
                          }
                        }, 50); // The set index has a tiny delay
                      }}
                      paginationLoadingIndicator={() =>
                        isLoading && !isFinished ? (
                          <CircularProgress
                            style={{
                              color: "#929292",
                              alignSelf: "center",
                              marginTop: "24px",
                              marginLeft: "14px",
                            }}
                            size={30}
                          />
                        ) : null
                      }
                    />
                    <div style={{ height: 400 }} />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <SimpleSelectRecipientsPanel
              allSelectedPeople={currentMembers}
              setAllSelectedPeople={setCurrentMembers}
              allExcludedPeople={excludedMembers}
              setAllExcludedPeople={setExcludedMembers}
              setIsVisible={(isVisible: boolean) => {
                reloadMoreUsers();
                setSelectRecipientsVisible(isVisible);
              }}
            />
          )
        }
      />
      {profileSelected ? (
        <FullProfilePreviewModal
          profileSelected={profileSelected}
          setProfileSelected={setProfileSelected}
        />
      ) : null}
      <ConfirmDeleteModal
        heading="If you go back, your changes will be discarded."
        subtext="Continue?"
        deleteButtonText="Discard Changes"
        hideModal={!showDeleteModal}
        setIsVisible={setShowDeleteModal}
        deleteOnPress={backOnPress}
      />
    </>
  );
};

export default AudienceListPopupPanel;
