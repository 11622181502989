import { memo } from "react";
import "../css/DefaultLandingPage.css";
import "../css/GlobalStyles.css";
import TopHeader from "../components/TopHeader";
import Footer from "../components/Footer";
import { useOnMount } from "../utils/useOnMount";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../redux/slices/accountSlice";
import landingPageCover from "../assets/LandingPageCover.png";
import BlackHoverButton from "../components/Buttons/BlackHoverButton";
import { Colors } from "../utils/colors";
import MarkitHeaderLogo from "../components/MarkitHeaderLogo";
import { isDesktop } from "react-device-detect";
import { MARKIT_WEBSITE } from "@markit/common.utils";
import { useNavigate } from "../hooks/useNavigate";

const DefaultLandingPage = memo(function DefaultLandingPageFn() {
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn } = account;
  const navigate = useNavigate();

  const styles = {
    landingPageContainer: {
      justifyContent: "center",
      gap: 20,
      marginBottom: 80,
    },
  };

  useOnMount(() => {
    (async () => {
      if (loggedIn === LoginState.LOGGED_IN && accountData.inCreatorMode) {
        navigate("/home");
      }
    })();
  });

  const LearnMoreButton = ({ padding }: { padding: number }) => (
    <a
      href={MARKIT_WEBSITE}
      style={{
        paddingInline: 14,
        paddingBlock: padding,
        borderColor: Colors.GRAY2,
        border: "1px solid #b9b9b9",
        borderRadius: 100,
        color: Colors.BLACK,
        fontSize: 14,
        whiteSpace: "nowrap",
      }}
    >
      Learn More
    </a>
  );

  return (
    <div>
      <div style={{ backgroundColor: Colors.WHITE1 }}>
        <TopHeader />
        {isDesktop ? (
          // Desktop view
          <div className="AlignedRow" style={styles.landingPageContainer}>
            <div style={{ width: "30%", marginLeft: 100 }}>
              <div style={{ marginBottom: 30 }}>
                <MarkitHeaderLogo forceColor="gray" />
              </div>
              <div className="ColumnNormal" style={{ gap: 47 }}>
                <span className="landingPageTitle">
                  Hear from
                  <br />
                  the brands
                  <br />& creators
                  <br />
                  you <span className="gradient-text">love.</span>
                </span>
                <div className="subtitleText">
                  <p>The best nightlife, tech, and more.</p>
                  <p style={{ color: Colors.GRAY1 }}>
                    Exclusive text updates and reminders from your favorite
                    creators. Create your account today.
                  </p>
                </div>
                <div className="AlignedRow" style={{ gap: 16 }}>
                  <div>
                    <BlackHoverButton
                      title="Get Started"
                      onPress={() => {
                        if (loggedIn === LoginState.LOGGED_IN) {
                          navigate(`/u/${accountData.username}`, {
                            state: { username: accountData.username },
                          });
                        } else {
                          navigate("/welcome");
                        }
                      }}
                      altTextColor={Colors.WHITE}
                      altBackgroundColor={Colors.BLACK}
                      containerStyles={{
                        whiteSpace: "nowrap",
                        padding: 14,
                        borderRadius: 100,
                      }}
                      iconName={"mdi:chevron-right"}
                      iconSize={20}
                    />
                  </div>
                  <LearnMoreButton padding={14} />
                </div>
              </div>
            </div>
            <div className="hero-image">
              <img
                src={landingPageCover}
                alt="Landing page cover"
                width={500}
              />
            </div>
          </div>
        ) : (
          // Mobile View
          <div className="ColumnCentering" style={{ overflowX: "hidden" }}>
            <div style={{ marginTop: 40, marginBottom: 14 }}>
              <MarkitHeaderLogo forceColor="gray" fullLogo />
            </div>
            <div
              className="ColumnCentering"
              style={{ textAlign: "center", gap: 24 }}
            >
              <span style={{ fontSize: 36, fontWeight: 600, lineHeight: 0.9 }}>
                Hear from the
                <br />
                brands & creators
                <br />
                you <span className="gradient-text">love.</span>
              </span>
              <div className="ColumnNormal" style={{ fontSize: 16 }}>
                <span>The best nightlife, tech, and more.</span>
                <span style={{ color: Colors.GRAY1, paddingInline: 14 }}>
                  Exclusive text updates and reminders from your favorite
                  creators. Create your account today.
                </span>
              </div>
              <div className="AlignedRow" style={{ gap: 14 }}>
                <div>
                  <BlackHoverButton
                    title="Explore"
                    onPress={() => navigate("/explore")}
                    altTextColor={Colors.WHITE}
                    altBackgroundColor={Colors.BLACK}
                    containerStyles={{
                      whiteSpace: "nowrap",
                      padding: 12,
                      borderRadius: 100,
                    }}
                    iconName={"ion:compass"}
                    iconSize={18}
                  />
                </div>
                <LearnMoreButton padding={10} />
              </div>
              <div style={{ marginBottom: 30 }}>
                <img
                  src={landingPageCover}
                  alt="Landing page cover"
                  height={570}
                  width={"auto"}
                  style={{ marginLeft: "10%" }}
                />
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
});

export default DefaultLandingPage;
