import { Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import { Colors } from "../../utils/colors";
import HeaderProfilePic from "../HeaderProfilePic";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { DarkTheme, ThemeStyle } from "../../hooks/useTheme";
import { useLocation } from "react-router-dom";
import ShareProfilePopupPanel from "./CreatorProfile/ShareProfilePopupPanel";
import MainActionsDropdownMenu from "../Dropdowns/MainActionsDropdownMenu";

type CreatorModeTopHeaderProps = {
  theme?: ThemeStyle;
};

const CreatorModeTopHeader = (props: CreatorModeTopHeaderProps) => {
  const { theme } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const { pathname } = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [shareLinkModal, setShareLinkModal] = useState(false);

  const tipOptionColor = useMemo(
    () =>
      theme && theme === DarkTheme
        ? Colors.GRAY10
        : pathname === "/create" || pathname.includes("/edit")
        ? Colors.WHITE
        : Colors.GRAY6,
    [pathname, theme]
  );

  return (
    <>
      <div className="AlignedRowSpaced CreatorModeRightPanelHeader CreatorPanelContainer">
        <Tooltip title="Share Profile">
          <div
            className="ProfilePageCreatorHeaderButtons"
            style={{ backgroundColor: tipOptionColor }}
            onClick={() => setShareLinkModal(true)}
          >
            <Icon
              icon="ion:share"
              height={18}
              style={{ paddingLeft: 1, paddingTop: 1, ...theme?.PrimaryText }}
            />
          </div>
        </Tooltip>
        <MainActionsDropdownMenu
          sourceLocation="creator_top_header"
          overlayStyles={{ width: 200 }}
        >
          <div
            className="ProfilePageCreatorHeaderButtons"
            style={{ backgroundColor: tipOptionColor }}
            onClick={() => setDropdownVisible(!dropdownVisible)}
          >
            <Icon
              icon="ic:round-add"
              height={22}
              style={{
                paddingLeft: 1,
                paddingTop: 1,
                ...theme?.PrimaryText,
              }}
            />
          </div>
        </MainActionsDropdownMenu>
        <Tooltip
          title="Tips"
          sx={{ backgroundColor: Colors.BLACK, opacity: 1 }}
          onClick={() =>
            window.open("https://www.markitsocial.net/helpcenter", "_blank")
          }
        >
          <div
            className="ProfilePageCreatorHeaderButtons"
            style={{ backgroundColor: tipOptionColor }}
          >
            <Icon
              icon="mdi:question-mark"
              height={18}
              style={{ paddingTop: 1, ...theme?.PrimaryText }}
            />
          </div>
        </Tooltip>
        <HeaderProfilePic
          accountData={accountData}
          isOnCreatorDashboard={true}
        />
      </div>
      {shareLinkModal ? (
        <ShareProfilePopupPanel
          isVisible={shareLinkModal}
          setIsVisible={setShareLinkModal}
        />
      ) : null}
    </>
  );
};

export default CreatorModeTopHeader;
