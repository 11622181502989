import {
  AccountData,
  Conversation,
  MemberIndicator,
} from "@markit/common.types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Query,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { AppState, AppThunk } from "../store";
import { API_BASE } from "../../API";
import {
  Batch,
  getConversationRef,
  getUserConversationRef,
  getUserConversationsRef,
  getUserData,
} from "../../utils/FirebaseUtils";
import { accountActions } from "./accountSlice";
import { loadAlgoliaUsersSearchResults } from "../../utils/algoliaUtils";

export type ConversationState = {
  userConversations: Conversation[];
  lastVisibleConversation: string;
  isConversationsLoading: boolean;
  loadedAllConversations: boolean;
  numUnreadConversations: number;
};

export const initialConversationState: ConversationState = {
  userConversations: [],
  lastVisibleConversation: "",
  isConversationsLoading: true,
  loadedAllConversations: false,
  numUnreadConversations: 0,
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState: initialConversationState,
  reducers: {
    addConversation: (state, action: PayloadAction<Conversation>) => {
      const index = state.userConversations.findIndex(
        (c) => c.conversationSid === action.payload.conversationSid
      );
      if (index === -1) {
        state.userConversations = state.userConversations.concat(
          action.payload
        );
      } else {
        state.userConversations.splice(index, 1, action.payload);
      }
      state.userConversations.sort((x: Conversation, y: Conversation) => {
        return (
          new Date(y.recentTimestamp).getTime() -
          new Date(x.recentTimestamp).getTime()
        );
      });
    },
    removeConversation: (state, action: PayloadAction<string>) => {
      state.userConversations = state.userConversations.filter(
        (c) => c.conversationSid !== action.payload
      );
    },
    removeAllConversations: (state) => {
      state.userConversations = [];
      state.numUnreadConversations = 0;
    },
    setLastVisibleConversation: (state, action: PayloadAction<string>) => {
      state.lastVisibleConversation = action.payload;
    },
    setIsConversationLoading: (state, action: PayloadAction<boolean>) => {
      state.isConversationsLoading = action.payload;
    },
    setLoadedAllConversations: (state, action: PayloadAction<boolean>) => {
      state.loadedAllConversations = action.payload;
    },
    setNumUnreadConversations: (state, action: PayloadAction<number>) => {
      state.numUnreadConversations = action.payload;
    },
    updateReadConversationIndicator: (
      state,
      action: PayloadAction<{
        conversation: Conversation;
        uid: string;
        markRead: boolean;
      }>
    ) => {
      // for all conversations
      const index = state.userConversations.findIndex(
        (c) => c.conversationSid === action.payload.conversation.conversationSid
      );
      if (index !== -1) {
        const newChatIndicator: MemberIndicator[] = state.userConversations[
          index
        ].chatIndicator.map((m: { uid: string; indicator: boolean }) => {
          if (m.uid === action.payload.uid) {
            return { uid: m.uid, indicator: action.payload.markRead };
          } else {
            return m;
          }
        });
        state.userConversations[index].chatIndicator = newChatIndicator;
      }
    },
  },
});

export const conversationActions = conversationSlice.actions;
export const conversationReducer = conversationSlice.reducer;

export const fetchConversations =
  (userId: string): AppThunk =>
  async (dispatch) => {
    const userConversationsRef = getUserConversationsRef(userId);
    const userConversationsQuery = query(
      userConversationsRef,
      where("creatorUid", "==", userId),
      where("recentTimestamp", "!=", ""),
      orderBy("recentTimestamp", "desc"),
      limit(15)
    );

    const uploadedTimes = (await getDocs(userConversationsQuery)).docs.map(
      (document) => document.data().recentTimestamp
    );
    if (uploadedTimes.length < 15) {
      dispatch(conversationActions.setLoadedAllConversations(true));
    } else {
      dispatch(conversationActions.setLoadedAllConversations(false));
    }

    const lastVisibleConversation = uploadedTimes[uploadedTimes.length - 1];
    if (lastVisibleConversation) {
      dispatch(
        conversationActions.setLastVisibleConversation(lastVisibleConversation)
      );
    }

    onSnapshot(userConversationsQuery, (snapshot) => {
      snapshot
        .docChanges()
        .forEach(
          async (change: {
            type: string;
            doc: { data: () => Conversation };
          }) => {
            if (change.type === "added" || change.type === "modified") {
              const conversationData = change.doc.data();
              dispatch(conversationActions.addConversation(conversationData));
              const userData = await getUserData(
                conversationData.participantUids[0]
              );
              if (userData) {
                dispatch(accountActions.addToFollowingAccountData(userData));
              }
            }
          }
        );
      dispatch(conversationActions.setIsConversationLoading(false));
    });
  };

export const fetchNumUnreadConversations =
  (userId: string): AppThunk =>
  async (dispatch) => {
    try {
      const userConversationsRef = getUserConversationsRef(userId);
      const userConversationsQuery = query(
        userConversationsRef,
        where("creatorUid", "==", userId),
        where("recentTimestamp", "!=", ""),
        where("chatIndicator", "array-contains", {
          uid: userId,
          indicator: true,
        })
      );

      const snapshot = await getCountFromServer(userConversationsQuery);
      const numUnreadConversations = snapshot.data().count;
      dispatch(
        conversationActions.setNumUnreadConversations(numUnreadConversations)
      );
    } catch (err) {
      console.log(err);
    }
  };

export const fetchMoreConversations =
  (
    userId: string,
    isFinished: boolean,
    isLoading: boolean,
    lastVisible: string
  ): AppThunk =>
  async (dispatch) => {
    if (!isFinished && !isLoading) {
      dispatch(conversationActions.setIsConversationLoading(true));
      const userConversationsRef = getUserConversationsRef(userId);
      const userConversationsQuery = query(
        userConversationsRef,
        where("creatorUid", "==", userId),
        where("recentTimestamp", "!=", ""),
        orderBy("recentTimestamp", "desc"),
        startAfter(lastVisible),
        limit(15)
      );

      const uploadedTimes = (await getDocs(userConversationsQuery)).docs.map(
        (document) => document.data().recentTimestamp
      );
      dispatch(
        conversationActions.setLastVisibleConversation(
          uploadedTimes[uploadedTimes.length - 1]
        )
      );

      if (uploadedTimes.length < 15) {
        dispatch(conversationActions.setLoadedAllConversations(true));
      } else {
        dispatch(conversationActions.setLoadedAllConversations(false));
      }

      const unsubscribe = onSnapshot(userConversationsQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (change) => {
          if (change.type === "added" || change.type === "modified") {
            const conversationData = change.doc.data();
            dispatch(conversationActions.addConversation(conversationData));
            const userData = await getUserData(
              conversationData.participantUids[0]
            );
            if (userData) {
              dispatch(accountActions.addToFollowingAccountData(userData));
            }
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        dispatch(conversationActions.setIsConversationLoading(false));
      }, 500);
      return unsubscribe;
    }
  };

const loadConversationSearchResults =
  (searchTerm: string, userId: string): AppThunk =>
  async (dispatch) => {
    const fetchConversations = async (userIds: string[]) => {
      dispatch(conversationActions.setIsConversationLoading(true));
      await Promise.all(
        userIds.map(async (uid) => {
          const conversationsRef = getUserConversationsRef(userId);
          const query_ = query(
            conversationsRef,
            where("participantUids", "array-contains", uid),
            where("recentTimestamp", "!=", "")
          );
          const snapshot = await getDocs(query_);
          if (!snapshot.empty) {
            const conversation = snapshot.docs.map((doc) => doc.data())[0];
            dispatch(conversationActions.addConversation(conversation));
            const userData = await getUserData(conversation.participantUids[0]);
            if (userData) {
              dispatch(accountActions.addToFollowingAccountData(userData));
            }
          }
        })
      ).finally(() => {
        dispatch(conversationActions.setIsConversationLoading(false));
      });
    };
    await loadAlgoliaUsersSearchResults(
      searchTerm,
      `following:${userId}`,
      (userIds: string[]) => fetchConversations(userIds)
    );
  };

export const loadConversationSearchResultsThrottled =
  (searchTerm: string, userId: string): AppThunk =>
  async (dispatch) => {
    setTimeout(() => {
      if (searchTerm.length > 1) {
        dispatch(loadConversationSearchResults(searchTerm, userId));
      }
    }, 500);
  };

export const fetchSpecificConversation =
  (userId: string, conversationSid: string): AppThunk =>
  async (dispatch) => {
    const userConversationRef = getUserConversationRef(userId, conversationSid);

    onSnapshot(userConversationRef, (snapshot) => {
      if (snapshot.exists()) {
        const conversationData = snapshot.data();
        if (conversationData) {
          dispatch(conversationActions.addConversation(conversationData));
        }
      } else {
        dispatch(conversationActions.removeConversation(conversationSid));
      }
    });
  };

// Mark the message that was previously unread as read if markRead is true
// Mark the message that was previously read as unread if markRead is false
export const updateChatConversationIndicator =
  (conversationSid: string, uid: string, markRead: boolean): AppThunk =>
  async (dispatch, getState) => {
    const numUnread = getState().conversations.numUnreadConversations;
    // @TODO: look into updating conversation state instead of setting loading
    dispatch(conversationActions.setIsConversationLoading(true));

    const batch = new Batch("Error updating chat conversation indicator");
    const conversationRef = getConversationRef(conversationSid);
    const conversationData = (await getDoc(conversationRef)).data();
    if (conversationData) {
      const newChatIndicator: MemberIndicator[] =
        conversationData.chatIndicator.map(
          (m: { uid: string; indicator: boolean }) => {
            if (m.uid === uid) {
              return { uid: m.uid, indicator: markRead };
            } else {
              return m;
            }
          }
        );

      const userConversationRef = getUserConversationRef(
        conversationData.creatorUid,
        conversationSid
      );
      batch.update(userConversationRef, { chatIndicator: newChatIndicator });
      // Update read indicator in Firebase
      dispatch(
        conversationActions.updateReadConversationIndicator({
          conversation: conversationData,
          uid: uid,
          markRead: markRead,
        })
      );
      dispatch(conversationActions.setNumUnreadConversations(numUnread - 1));

      for (let i = 0; i < conversationData.participantUids.length; i++) {
        const otherUserConversationRef = getUserConversationRef(
          conversationData.participantUids[i],
          conversationSid
        );
        batch.update(otherUserConversationRef, {
          chatIndicator: newChatIndicator,
        });
      }

      batch.update(conversationRef, { chatIndicator: newChatIndicator });
    }

    try {
      await batch.commit();
      dispatch(conversationActions.setIsConversationLoading(false));
    } catch (e: any) {
      alert("Error updating chat indicator: " + e.message);
    }
  };

/**
 * Gets the userData of the participants from all of a user's conversations.
 * This only works while the participantUids field only contains one participant
 */
export const getConversationParticipantsData = async (
  userConversations: Conversation[]
): Promise<AccountData[]> => {
  const participantsData = await Promise.all(
    userConversations.map(async (conversation) => {
      const userData = await getUserData(conversation.participantUids[0]);
      if (userData) {
        return userData;
      }
    })
  );
  const definedParticipants: AccountData[] = [];
  for (let i = 0; i < participantsData.length; i++) {
    if (participantsData[i] as AccountData) {
      definedParticipants.push(participantsData[i] as AccountData);
    }
  }
  return definedParticipants;
};

// set the conversationIdToDelete to the Conversation SID found on Twilio
// Example: CH099996154e3a4a77b9e004a5695bba21
export const adminDeleteConversation =
  (conversationId: string, userId: string): AppThunk =>
  async (dispatch) => {
    console.log("Deleting conversation");
    await fetch(API_BASE + "creatorText/deleteTwilioConversation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        conversationIdToDelete: conversationId,
        userId: userId,
      }),
    }).then(() => {
      console.log("Deleted conversation successfully!");
      dispatch(conversationActions.removeConversation(conversationId));
    });
  };

// Deletes the User from Twilio Conversation (for deleting accounts)
export const deleteTwilioConversationUser = async (userId: string) => {
  await fetch(API_BASE + "creatorText/deleteTwilioConversationUser", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
    }),
  });
};

export const getUnreadConversationsQuery = async (
  userId: string
): Promise<Query<Conversation>> => {
  const userConversationsRef = getUserConversationsRef(userId);
  const query_ = query(
    userConversationsRef,
    where("creatorUid", "==", userId),
    where("chatIndicator", "array-contains", { indicator: true, uid: userId })
  );

  return query_;
};

export const fetchNumTotalConversations = async (
  userId: string
): Promise<number> => {
  const userConversationsRef = getUserConversationsRef(userId);
  const query_ = query(
    userConversationsRef,
    where("recentTimestamp", "!=", "")
  );
  const snapshot = await getCountFromServer(query_);

  return snapshot.data().count;
};

export const getConversationState = (state: AppState) => state;
