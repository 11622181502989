import "../../css/GlobalStyles.css";
import { ReactNode, memo, useRef, useState } from "react";
import TopHeader from "../../components/TopHeader";
import { useSelector } from "react-redux";
import {
  LoginState,
  getAccountState,
  toggleInCreatorMode,
} from "../../redux/slices/accountSlice";
import CreatorModeSidebar from "../../components/CreatorDashboard/CreatorModeSidebar";
import CreatorModeTopHeader from "../../components/CreatorDashboard/CreatorModeTopHeader";
import { Colors } from "../../utils/colors";
import EmptyViewStateMobile from "../../components/EmptyStates/EmptyViewStateMobile";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "../../hooks/useNavigate";
import { useOnMount } from "../../utils/useOnMount";
import { useDispatch } from "react-redux";

type CreatorModeWrapperProps = {
  children: ReactNode;
};

const CreatorModeWrapper = (props: CreatorModeWrapperProps) => {
  const { children } = props;
  const { account } = useSelector(getAccountState);
  const { accountData, sidebarCondensed, loggedIn } = account;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // States for sidebar
  const refContainer = useRef<HTMLDivElement>(null);
  const [morePanelVisible, setMorePanelVisible] = useState(false);

  useOnMount(() => {
    // Handle Redirecting
    if (loggedIn === LoginState.LOGGED_OUT) {
      navigate("/welcome");
    } else if (!accountData.inCreatorMode) {
      dispatch(toggleInCreatorMode(accountData.uid, accountData.inCreatorMode));
    }
  });

  if (!isDesktop) {
    return <EmptyViewStateMobile />;
  }

  return (
    <div
      ref={refContainer}
      className="WebApp"
      style={{
        backgroundColor: Colors.WHITE,
        transition: "0.5s",
        minHeight: "100vh",
        justifyContent: "center",
      }}
    >
      {accountData.inCreatorMode ? (
        <CreatorModeSidebar
          morePanelVisible={morePanelVisible}
          setMorePanelVisible={setMorePanelVisible}
        />
      ) : null}
      <div
        style={
          accountData.inCreatorMode
            ? {
                width: sidebarCondensed ? "calc(100vw - 76px)" : "86vw",
                marginLeft: sidebarCondensed ? "76px" : "14vw",
              }
            : undefined
        }
      >
        {accountData.inCreatorMode ? <CreatorModeTopHeader /> : <TopHeader />}
        {children}
      </div>
    </div>
  );
};

export default memo(CreatorModeWrapper);
