import {
  AccountData,
  AudienceList,
  Event,
  MassTextAttachmentType,
  SelectRecipientType,
  SubSelectionItem,
  SubSelectionStatusId,
  SubSelectionType,
} from "@markit/common.types";
import { CircularProgress } from "@mui/material";
import FlatList from "flatlist-react/lib";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { m } from "framer-motion";
import SearchBoxContainer from "../../../Containers/SearchBoxContainer";
import IntermediaryModalContainer from "../../../Containers/IntermediaryModalContainer";
import EmptyStateButton from "../../../Buttons/EmptyStateButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import filter from "lodash.filter";
import { RenderedEventMassTextsModal } from "../../../LandingPage/RenderedEvents";
import { MassTextsActionItem } from "../Items/MassTextsActionItem";
import { MassTextsPersonItem } from "../Items/MassTextsPersonItem";
import useFocusInput from "../../../../hooks/useFocusInput";
import { useLoadUserFollowList } from "../../../../hooks/useLoadUserFollowList";
import { getEventState } from "../../../../redux/slices/eventSlice";
import {
  SelectRecipientGroupType,
  SelectRecipientsMainSharedProps,
} from "./SelectRecipientsPanel";
import {
  addOrFilterCategories,
  attachmentDetails,
  getEventAttachmentLinks,
} from "@markit/common.utils";
import SelectRecipientsSuggestedCategories from "./SelectRecipientsSuggestedCategories";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import SelectRecipientCategoryItem from "../Items/SelectRecipientCategoryItem";
import { API } from "../../../../API";
import ConfirmActionModal from "../../../Containers/ConfirmPopups/ConfirmActionModal";
import ConfirmDeleteModal from "../../../Containers/ConfirmPopups/ConfirmDeleteModal";
import AlertContainer from "../../../Containers/AlertContainer";
import { EmptyStateFlatlist } from "../../../EmptyStates/EmptyStateFlatlist";
import { useLiveUpdatingEventAnalytics } from "../../../../hooks/useLiveUpdatingEventAnalytics";
import { NavigationId } from "../../../../navigation/AppParamList";
import { useNavigate } from "../../../../hooks/useNavigate";

type SelectRecipientsProps = SelectRecipientsMainSharedProps & {
  allSelectedPeople: string[];
  setAllSelectedPeople: (allSelectedPeople: string[]) => void;
  allExcludedPeople: string[];
  setAllExcludedPeople: (allExcludedPeople: string[]) => void;
  focusKeyboard: boolean;
  showDoneButton: boolean;
  setShowDoneButton: (showDoneButton: boolean) => void;
  updateSelectType: (selectType: Partial<SelectRecipientGroupType>) => void;
  setProfileSelected: (profileSelected: AccountData) => void;
  loadingAllFollowers: boolean;
};

const SelectRecipients = (props: SelectRecipientsProps) => {
  const {
    massText,
    updateMassTextSettings,
    excludingMode,
    setExcludingMode,
    allSelectedPeople,
    setAllSelectedPeople,
    allExcludedPeople,
    setAllExcludedPeople,
    focusKeyboard,
    showCategories,
    showDoneButton,
    setShowDoneButton,
    updateSelectType,
    setProfileSelected,
    loadingAllFollowers,
  } = props;
  const { events } = useSelector(getEventState).events;
  const { accountData, audienceLists, followingAccountData, numFollowers } =
    useSelector(getAccountState).account;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingFollowers, setLoadingFollowers] = useState(true);
  const [netRecipientsWindowSize, setNetRecipientsWindowSize] = useState(20);
  const [focusRef, setFocus] = useFocusInput<HTMLInputElement>();

  const [alertText, setAlertText] = useState("");
  const [resetAllConfirm, setResetAllConfirm] = useState(false);
  const [convertToPeopleConfirm, setConvertToPeopleConfirm] = useState(false);

  const styles = {
    mainText: { fontWeight: "500", fontSize: 14 },
    sectionContainer: {
      padding: 14,
      backgroundColor: Colors.GRAY6,
      borderRadius: 8,
      marginBottom: 14,
    },
  };

  const { liveTotalGuests, liveTotalRequested } = useLiveUpdatingEventAnalytics(
    {
      eventId: massText ? massText.eventRefId : "",
    }
  );

  const {
    fetchedUserData: fetchedFollowerData,
    loadMoreUsers: loadMoreFollowerUsers,
    isLoading: isFetchedFollowersLoading,
    isFinished: isFinishedFollowers,
    loadSearchResultsThrottled,
  } = useLoadUserFollowList({
    userId: accountData.uid,
    followListType: "Followers",
    windowSize: 20,
    followerAccountData: followingAccountData,
  });

  useEffect(() => {
    if (
      loadingFollowers &&
      fetchedFollowerData.length === 0 &&
      !isFinishedFollowers
    ) {
      loadMoreFollowerUsers();
      setLoadingFollowers(false);
    }
  }, [
    fetchedFollowerData.length,
    isFinishedFollowers,
    loadMoreFollowerUsers,
    loadingFollowers,
  ]);

  const foundEvent = useMemo(
    () => events.find((event) => massText && event.id === massText.eventRefId),
    [events, massText]
  );

  const eventsHosting = useMemo(
    () =>
      events
        .filter(
          (event) => event.createdBy === accountData.uid && !event.isDraft
        )
        .sort(
          (x, y) => new Date(y.start).getTime() - new Date(x.start).getTime()
        ),
    [accountData.uid, events]
  );

  const categoriesToShow = useMemo(
    () =>
      massText
        ? excludingMode
          ? massText.excludedSubSelectionIds
          : massText.subSelectionIds
        : [],
    [excludingMode, massText]
  );

  // Shows the selected or excluded people depending on the select type
  const newRecipientsToShow = useMemo(
    () => (excludingMode ? allExcludedPeople : allSelectedPeople),
    [excludingMode, allExcludedPeople, allSelectedPeople]
  );

  const netRecipients = useMemo(
    () =>
      excludingMode
        ? allExcludedPeople
        : allSelectedPeople.filter(
            (userId) => !allExcludedPeople.includes(userId)
          ),
    [allExcludedPeople, allSelectedPeople, excludingMode]
  );

  // Used to display the total net recipients, meaning for selected people view, it will filter out the excluded
  const netRecipientsToShow = useMemo(
    () =>
      showCategories && categoriesToShow.length > 0
        ? categoriesToShow
        : netRecipients.slice(0, netRecipientsWindowSize),
    [showCategories, categoriesToShow, netRecipients, netRecipientsWindowSize]
  );

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.BLUE5),
    [excludingMode]
  );

  const excludedToShowLength = useMemo(
    () =>
      showCategories && massText
        ? massText.excludedSubSelectionIds.length
        : allExcludedPeople.length,
    [allExcludedPeople.length, massText, showCategories]
  );

  const displayCategoriesView = useMemo(
    () => showCategories && categoriesToShow.length > 0,
    [categoriesToShow.length, showCategories]
  );

  const containsUser = (user: AccountData, query: string) => {
    return user.fullName.toLowerCase().includes(query.toLowerCase());
  };

  const containsEvent = (event: Event, query: string) => {
    return event.title.toLowerCase().includes(query.toLowerCase());
  };

  const containsList = (list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query.toLowerCase());
  };

  const itemsToShow = useMemo(() => {
    if (searchTerm === "") {
      return [];
    }

    const finalList: (AccountData | Event | AudienceList)[] = [];
    const userList = filter(fetchedFollowerData, (user: AccountData) => {
      return containsUser(user, searchTerm);
    });
    const eventList = filter(eventsHosting, (event: Event) => {
      return containsEvent(event, searchTerm);
    }).sort(
      (x, y) => new Date(y.start).getTime() - new Date(x.start).getTime()
    );
    const userAudienceList = filter(audienceLists, (list: AudienceList) => {
      return containsList(list, searchTerm);
    });
    finalList.push(...eventList, ...userAudienceList, ...userList);

    return finalList;
  }, [audienceLists, eventsHosting, fetchedFollowerData, searchTerm]);

  const updateSearch = useCallback(
    (text: string) => {
      if (text !== "") {
        loadSearchResultsThrottled(text.toLowerCase());
      }
      setSearchTerm(text);
    },
    [loadSearchResultsThrottled]
  );

  const convertCategoriesToPeople = useCallback(async () => {
    if (!foundEvent || !massText || !updateMassTextSettings) {
      return;
    }

    const { recipients } = await API.text.convertTextCategories({
      selectedCategories: massText.subSelectionIds,
      excludedCategories: massText.excludedSubSelectionIds,
      userId: accountData.uid,
      eventId: foundEvent.id,
    });
    setAllSelectedPeople(recipients);
    updateMassTextSettings({
      subSelectionIds: [],
      excludedSubSelectionIds: [],
    });
  }, [
    accountData.uid,
    foundEvent,
    massText,
    setAllSelectedPeople,
    updateMassTextSettings,
  ]);

  const excludeOnPress = useCallback(() => {
    setExcludingMode(true);
  }, [setExcludingMode]);

  const showCannotSelectNonCategoryAlert = useCallback(() => {
    if (
      showCategories &&
      massText &&
      (massText.subSelectionIds.length > 0 ||
        massText.excludedSubSelectionIds.length > 0)
    ) {
      setAlertText(
        `You can't ${
          excludingMode ? "exclude" : "select"
        } by individual followers once you've ${
          massText.subSelectionIds.length > 0 ? "selected" : "excluded"
        } an event category.`
      );
      return true;
    }
    // Check if event specific links are used in the message, only if from event dashboard text
    if (massText && massText.eventRefId !== "") {
      const foundAttachmentLinks = massText.message
        ? getEventAttachmentLinks(
            massText.message,
            eventsHosting.map((event) => event.id)
          )
        : [];
      const hasEventLink = foundAttachmentLinks.some((link) => {
        const details = attachmentDetails(link);
        return (
          details.type === MassTextAttachmentType.TICKET_LINK ||
          details.type === MassTextAttachmentType.CALENDAR_INVITE
        );
      });
      if (hasEventLink) {
        setAlertText(
          `You can't ${
            excludingMode ? "exclude" : "select"
          } by individual followers after attaching ticket or calendar links.`
        );
        return true;
      }
    }
    return false;
  }, [eventsHosting, excludingMode, massText, showCategories]);

  const navigateCollectedData = useCallback(() => {
    if (showCannotSelectNonCategoryAlert()) {
      return;
    }
    updateSelectType({ type: SelectRecipientType.COLLECTED_DATA });
  }, [showCannotSelectNonCategoryAlert, updateSelectType]);

  const selectAllFollowers = useCallback(async () => {
    if (showCannotSelectNonCategoryAlert()) {
      return;
    }
    setAllSelectedPeople(followingAccountData.map((user) => user.uid));
    setShowDoneButton(false);
  }, [
    followingAccountData,
    setAllSelectedPeople,
    setShowDoneButton,
    showCannotSelectNonCategoryAlert,
  ]);

  const selectCategoryPreviewOnPress = useCallback(
    (tabValue: number, isAllAttendees?: boolean, isAllPeople?: boolean) => {
      if (foundEvent && massText && updateMassTextSettings) {
        if (isAllAttendees) {
          if (
            massText.subSelectionIds.some(
              (category) =>
                category.type === SubSelectionType.STATUS &&
                category.id === SubSelectionStatusId.ATTENDEES
            )
          ) {
            updateMassTextSettings({ subSelectionIds: [] });
          } else {
            updateMassTextSettings({
              subSelectionIds: massText.subSelectionIds.concat({
                type: SubSelectionType.STATUS,
                id: SubSelectionStatusId.ATTENDEES,
                formOption: "",
              }),
            });
          }
        } else if (isAllPeople) {
          if (
            massText.subSelectionIds.some(
              (category) => category.type === SubSelectionType.ALL_PEOPLE
            )
          ) {
            updateMassTextSettings({ subSelectionIds: [] });
          } else {
            updateMassTextSettings({
              subSelectionIds: massText.subSelectionIds.concat({
                type: SubSelectionType.ALL_PEOPLE,
                id: "",
                formOption: "",
              }),
            });
          }
        } else {
          updateSelectType({
            type: SelectRecipientType.EVENTS,
            selectedItem: foundEvent,
            eventTabValue: tabValue,
          });
        }
      }
    },
    [foundEvent, massText, updateMassTextSettings, updateSelectType]
  );

  const renderEventItem = useCallback(
    (item: Event) => {
      return (
        <div
          onClick={() => {
            if (showCannotSelectNonCategoryAlert()) {
              return;
            }
            updateSelectType({
              type: SelectRecipientType.EVENTS,
              selectedItem: item,
            });
          }}
        >
          <RenderedEventMassTextsModal event={item} />
        </div>
      );
    },
    [showCannotSelectNonCategoryAlert, updateSelectType]
  );

  const renderListItem = useCallback(
    (item: AudienceList) => {
      return (
        <MassTextsActionItem
          title={item.name}
          icon={<Icon icon="ion:list" height={40} color={iconColor} />}
          onPress={() => {
            if (showCannotSelectNonCategoryAlert()) {
              return;
            }
            updateSelectType({
              type: SelectRecipientType.LISTS,
              selectedItem: item,
            });
          }}
          subtext={`${item.numberMembers} People`}
        />
      );
    },
    [iconColor, showCannotSelectNonCategoryAlert, updateSelectType]
  );

  const renderRecipientItem = useCallback(
    (item: AccountData) => {
      return (
        <MassTextsPersonItem
          user={item}
          unsavedPeople={newRecipientsToShow}
          setUnsavedPeople={(allPeople: string[]) => {
            if (showCannotSelectNonCategoryAlert()) {
              return;
            }
            if (excludingMode) {
              setAllExcludedPeople(allPeople);
            } else {
              setAllSelectedPeople(allPeople);
            }
          }}
          excludingMode={excludingMode}
          setProfileSelected={setProfileSelected}
        />
      );
    },
    [
      excludingMode,
      newRecipientsToShow,
      setAllExcludedPeople,
      setAllSelectedPeople,
      setProfileSelected,
      showCannotSelectNonCategoryAlert,
    ]
  );

  const renderEmptySuggestedView = useMemo(
    () => (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={isFetchedFollowersLoading}
        btnText={numFollowers === 0 ? "Build Audience" : undefined}
        containerStyles={{ paddingTop: 120 }}
        nonSearchEmptyView={
          <div className="ColumnNormal" style={{ gap: 10 }}>
            {foundEvent && showCategories ? (
              <SelectRecipientsSuggestedCategories
                event={foundEvent}
                excludingMode={excludingMode}
                selectedCategories={massText ? massText.subSelectionIds : []}
                selectOnPress={(
                  tabValue: number,
                  isAllAttendees?: boolean,
                  isAllPeople?: boolean
                ) =>
                  selectCategoryPreviewOnPress(
                    tabValue,
                    isAllAttendees,
                    isAllPeople
                  )
                }
                hideAllAttendees={
                  liveTotalGuests === 0 && liveTotalRequested > 0
                }
              />
            ) : null}
            <span
              style={{ color: Colors.GRAY1, fontSize: 14, fontWeight: "500" }}
            >
              {showCategories ? "More Options" : "Suggested"}
            </span>
            {followingAccountData.length > 0 ? (
              <div>
                {!excludingMode &&
                (allSelectedPeople.length === 0 ||
                  allSelectedPeople.length !== followingAccountData.length) ? (
                  <>
                    <div
                      className="AlignedRow"
                      style={{
                        position: "relative",
                        cursor: loadingAllFollowers ? "auto" : "pointer",
                      }}
                    >
                      <MassTextsActionItem
                        title={"Select All Followers"}
                        icon={
                          <Icon
                            icon="ion:people"
                            height={36}
                            color={Colors.BLUE5}
                          />
                        }
                        onPress={selectAllFollowers}
                        subtext={
                          loadingAllFollowers
                            ? ""
                            : `${followingAccountData.length} People`
                        }
                        disabled={loadingAllFollowers}
                        containerStyles={{ flexGrow: 1 }}
                      />
                      {loadingAllFollowers ? (
                        <CircularProgress
                          style={{
                            color: "#929292",
                            position: "absolute",
                            right: 0,
                            top: 14,
                          }}
                          size={18}
                        />
                      ) : null}
                    </div>
                    <HorizontalDivider altMargin={10} />
                  </>
                ) : null}
                <MassTextsActionItem
                  title={"Collected Data"}
                  icon={
                    <Icon icon="ion:document" height={32} color={iconColor} />
                  }
                  onPress={navigateCollectedData}
                />
                <HorizontalDivider altMargin={10} />
                {eventsHosting
                  .slice(0, Math.min(eventsHosting.length, 2))
                  .map((event) => (
                    <div key={event.id}>
                      {renderEventItem(event)}
                      <HorizontalDivider altMargin={10} />
                    </div>
                  ))}
                {audienceLists
                  .slice(0, Math.min(audienceLists.length, 2))
                  .map((list) => (
                    <div key={list.id}>
                      {renderListItem(list)}
                      <HorizontalDivider altMargin={10} />
                    </div>
                  ))}
                {followingAccountData
                  .slice(0, Math.min(followingAccountData.length, 2))
                  .map((user) => renderRecipientItem(user))}
              </div>
            ) : !loadingAllFollowers ? (
              <div>
                <MassTextsActionItem
                  title={"Build Your Audience"}
                  icon={
                    <Icon icon="ion:people" height={36} color={Colors.BLUE5} />
                  }
                  onPress={() =>
                    setAlertText(
                      "Press your profile pic in the top right header to share your profile!"
                    )
                  }
                  subtext={"You currently have no followers"}
                />
                <HorizontalDivider altMargin={10} />
                <MassTextsActionItem
                  title={"Import Contacts"}
                  icon={
                    <Icon icon="ion:person-add" height={29} color={iconColor} />
                  }
                  onPress={() => navigate(NavigationId.IMPORT)}
                />
              </div>
            ) : (
              <div className="Centering" style={{ paddingTop: 14 }}>
                <CircularProgress style={{ color: "#929292" }} size={20} />
              </div>
            )}
          </div>
        }
      />
    ),
    [
      allSelectedPeople.length,
      audienceLists,
      eventsHosting,
      excludingMode,
      followingAccountData,
      foundEvent,
      iconColor,
      isFetchedFollowersLoading,
      liveTotalGuests,
      liveTotalRequested,
      loadingAllFollowers,
      massText,
      navigate,
      navigateCollectedData,
      numFollowers,
      renderEventItem,
      renderListItem,
      renderRecipientItem,
      searchTerm,
      selectAllFollowers,
      selectCategoryPreviewOnPress,
      showCategories,
    ]
  );

  const renderIndividualListItem = useCallback(
    (item: Event | AudienceList | AccountData) => (
      <>
        {(item as AccountData).uid
          ? renderRecipientItem(item as AccountData)
          : (item as Event).title
          ? renderEventItem(item as Event)
          : renderListItem(item as AudienceList)}
        {!(item as AccountData).uid ? (
          <HorizontalDivider altMargin={10} />
        ) : null}
      </>
    ),
    [renderEventItem, renderListItem, renderRecipientItem]
  );

  const renderListItems = useMemo(
    () => (
      <FlatList
        list={itemsToShow}
        renderItem={(item: Event | AudienceList | AccountData) =>
          renderIndividualListItem(item)
        }
        renderWhenEmpty={() => renderEmptySuggestedView}
        hasMoreItems={!isFinishedFollowers}
        loadMoreItems={() => {
          loadMoreFollowerUsers();
        }}
        paginationLoadingIndicator={() =>
          isFetchedFollowersLoading && !isFinishedFollowers ? (
            <CircularProgress
              style={{ color: "#929292", alignSelf: "center" }}
              size={20}
            />
          ) : null
        }
      />
    ),
    [
      isFetchedFollowersLoading,
      isFinishedFollowers,
      itemsToShow,
      loadMoreFollowerUsers,
      renderEmptySuggestedView,
      renderIndividualListItem,
    ]
  );

  return (
    <IntermediaryModalContainer
      body={
        <>
          <div className="AlignedRow" style={{ gap: 10, marginBottom: 14 }}>
            <SearchBoxContainer
              placeholder="Search Events, People, Lists..."
              value={searchTerm}
              ref={focusRef}
              onChange={(e) => updateSearch(e.target.value)}
              onFocus={() => {
                setShowDoneButton(true);
              }}
              autoFocus={
                (newRecipientsToShow.length === 0 &&
                  categoriesToShow.length === 0) ||
                focusKeyboard
              }
              containerStyles={{ marginTop: 0 }}
            />
            {showDoneButton ? (
              <m.div
                key="selectrecipientsdone"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  ease: "easeIn",
                  duration: 0.2,
                  type: "tween",
                }}
                style={{ cursor: "pointer" }}
              >
                <span
                  onClick={() => {
                    setSearchTerm("");
                    setShowDoneButton(false);
                  }}
                  style={{ color: iconColor, fontWeight: "500" }}
                >
                  Done
                </span>
              </m.div>
            ) : null}
          </div>
          <m.div
            key="selectrecipientselection"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, type: "tween" }}
          >
            {showDoneButton ? (
              <>
                {renderListItems}
                <div style={{ height: 80 }} />
              </>
            ) : (
              <>
                <div style={styles.sectionContainer}>
                  <div style={{ width: "100%" }}>
                    <div className="AlignedRowSpaced">
                      <div className="ColumnNormal">
                        <span
                          style={{
                            color:
                              displayCategoriesView && !excludingMode
                                ? Colors.PURPLE7
                                : iconColor,
                            ...styles.mainText,
                          }}
                        >
                          {displayCategoriesView ? (
                            <>
                              {categoriesToShow.length}{" "}
                              {categoriesToShow.length !== 1
                                ? "Categories "
                                : "Category "}
                              {excludingMode ? "Excluded" : ""}
                            </>
                          ) : (
                            <>
                              {excludingMode
                                ? allExcludedPeople.length
                                : netRecipients.length}{" "}
                              {excludingMode
                                ? "Excluded"
                                : `Recipient${
                                    netRecipients.length !== 1 ? "s" : ""
                                  }`}
                            </>
                          )}
                        </span>
                        {displayCategoriesView ? (
                          <span
                            style={{
                              fontSize: 12,
                              color: Colors.GRAY2,
                              marginRight: 40,
                              marginTop: 7,
                            }}
                          >
                            Will {excludingMode ? "exclude" : "send to"} all
                            recipients in{" "}
                            {excludingMode ? "excluded" : "selected"} categories
                            at time of send.
                          </span>
                        ) : null}
                      </div>
                      {(netRecipients.length > 0 ||
                        categoriesToShow.length > 0) &&
                      !excludingMode ? (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setResetAllConfirm(true)}
                        >
                          <span style={styles.mainText}>Reset</span>
                        </div>
                      ) : null}
                    </div>
                    {!excludingMode ? (
                      <>
                        <HorizontalDivider altMargin={14} />
                        <div
                          onClick={excludeOnPress}
                          className="AlignedRowSpacedSelect"
                        >
                          {excludedToShowLength > 0 ? (
                            <span
                              style={{ ...styles.mainText, color: Colors.RED3 }}
                            >
                              {excludedToShowLength}{" "}
                              {displayCategoriesView
                                ? excludedToShowLength !== 1
                                  ? "Categories"
                                  : "Category"
                                : ""}{" "}
                              Excluded
                            </span>
                          ) : (
                            <span style={styles.mainText}>Exclude</span>
                          )}
                          <Icon
                            icon="ion:chevron-right"
                            height={16}
                            color={Colors.GRAY1}
                          />
                        </div>
                      </>
                    ) : null}
                    {!excludingMode && displayCategoriesView ? (
                      <>
                        <HorizontalDivider altMargin={14} />
                        <div
                          onClick={() => setConvertToPeopleConfirm(true)}
                          className="AlignedRowSpacedSelect"
                        >
                          <span style={styles.mainText}>
                            Convert Categories to People
                          </span>
                          <Icon
                            icon="ion:chevron-right"
                            height={16}
                            color={Colors.GRAY1}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                {showCategories &&
                netRecipientsToShow.length > 0 &&
                massText &&
                updateMassTextSettings ? (
                  (netRecipientsToShow as SubSelectionItem[]).map(
                    (category) => {
                      return (
                        <SelectRecipientCategoryItem
                          item={category}
                          onPress={() =>
                            addOrFilterCategories(
                              excludingMode
                                ? massText.excludedSubSelectionIds
                                : massText.subSelectionIds,
                              (categories: SubSelectionItem[]) =>
                                excludingMode
                                  ? updateMassTextSettings({
                                      excludedSubSelectionIds: categories,
                                    })
                                  : updateMassTextSettings({
                                      subSelectionIds: categories,
                                    }),
                              category
                            )
                          }
                          excludingMode={excludingMode}
                          event={foundEvent}
                        />
                      );
                    }
                  )
                ) : (
                  <div
                    className="HideScrollbar"
                    style={{
                      height: "calc(100vh - 280px)",
                      overflowY: "scroll",
                    }}
                  >
                    <FlatList
                      list={netRecipientsToShow as string[]}
                      renderItem={(userId) => {
                        const userData = followingAccountData.find(
                          (user) => user.uid === userId
                        );
                        return userData ? renderRecipientItem(userData) : null;
                      }}
                      renderWhenEmpty={() => (
                        <EmptyStateButton
                          title={
                            (excludingMode ? "Exclude" : "Select") +
                            " Recipients"
                          }
                          description={
                            excludingMode
                              ? "Excluded people will show up here. Exclusions will override normal selections"
                              : "Your full recipient list will show up here."
                          }
                          icon={
                            <Icon
                              icon="ion:people"
                              height={40}
                              color={excludingMode ? Colors.RED3 : Colors.GRAY1}
                            />
                          }
                          btnText={
                            (excludingMode ? "Exclude" : "Select") +
                            " Recipients"
                          }
                          onPress={setFocus}
                          containerStyles={{ paddingTop: 100 }}
                        />
                      )}
                      hasMoreItems={
                        netRecipientsWindowSize < netRecipients.length
                      }
                      loadMoreItems={() => {
                        setTimeout(() => {
                          setNetRecipientsWindowSize(
                            netRecipientsWindowSize + 20
                          );
                        }, 100);
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </m.div>
          <ConfirmDeleteModal
            heading="Reset All Selection?"
            subtext="You cannot undo this."
            deleteButtonText="Reset"
            hideModal={!resetAllConfirm}
            setIsVisible={setResetAllConfirm}
            deleteOnPress={() => {
              setAllExcludedPeople([]);
              setAllSelectedPeople([]);
              if (updateMassTextSettings) {
                updateMassTextSettings({
                  subSelectionIds: [],
                  excludedSubSelectionIds: [],
                });
              }
            }}
          />
          <ConfirmActionModal
            heading="Convert to People?"
            subtext="This will convert your categories into a flattened list of individual people."
            confirmButtonText="Convert"
            hideModal={!convertToPeopleConfirm}
            setIsVisible={setConvertToPeopleConfirm}
            confirmOnPress={() => convertCategoriesToPeople()}
          />
          <AlertContainer
            headerComp={alertText}
            closeModal={() => setAlertText("")}
            hideModal={alertText === ""}
          />
        </>
      }
    />
  );
};

export default SelectRecipients;
