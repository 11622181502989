import "../../css/Login.css";
import { memo, useCallback, useContext, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import { firebaseAuth } from "../../firebase";
import logo from "../../assets/MarkitLogoBlue.png";
import TopHeader from "../../components/TopHeader";
import { Colors } from "../../utils/colors";
import CustomTextField from "../../components/CustomTextField";
import { isDesktop } from "react-device-detect";
import { MixpanelContext } from "../../context/AnalyticsService";
import Footer from "../../components/Footer";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { ExternalLinkText } from "../../components/Links/ExternalLinkText";
import { friendlyRoleName, isEventExternalLink } from "@markit/common.utils";
import VerificationCodeButton from "../../components/VerificationCodeButton";
import { VerificationState } from "@markit/common.types";
import { formattedVerificationPhoneNumber } from "../../utils/FormatPhoneNumber";
import Phone from "../../utils/Phone";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { useLogin } from "../../hooks/useLogin";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useTheme } from "../../hooks/useTheme";
import MessageDataRates from "../../components/MessageDataRates";
import LoginForgotPassword from "../../components/Login/LoginForgotPassword";
import AlertContainer from "../../components/Containers/AlertContainer";
import { useLocation } from "react-router-dom";
import { BackButton } from "../../components/Buttons/BackButton";
import { useNavigate } from "../../hooks/useNavigate";
import Input from "react-phone-number-input/input";

const LoginWelcome = memo(function LoginWelcomeFn() {
  const { account } = useSelector(getAccountState);
  const { currentEvent, currentRoleTicket } = account;
  const { defaultEmail } = useLocation().state || {}; // defaultEmail = start on email sign up
  const mixpanel = useContext(MixpanelContext);
  const navigate = useNavigate();
  const { theme } = useTheme();

  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });

  const { loginWithCredential } = useLogin();

  const [loading, setLoading] = useState(false);
  const [verificationState, setVerificationState] = useState(
    VerificationState.UNVERIFIED
  );
  const [tempPhoneNumber, setTempPhoneNumber] = useState<string>("");

  const [showCodeInputError, setShowCodeInputError] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] =
    useState<boolean>(false);

  // email login/signup
  const [emailLogin, setEmailLogin] = useState(false);
  const [emailSignup, setEmailSignup] = useState(defaultEmail ?? false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState({
    status: false,
    message: "",
  });
  const [passwordError, setPasswordError] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // email signup
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const styles = {
    subtext: { color: Colors.GRAY2, fontSize: 12 },
  };

  const showError = useCallback(() => {
    let error = false;
    if (!Phone.isValid(formattedVerificationPhoneNumber(tempPhoneNumber))) {
      setShowPhoneNumberError(true);
      error = true;
    }

    if (verificationState === VerificationState.VERIFYING) {
      setShowCodeInputError(true);
    }

    return error;
  }, [tempPhoneNumber, verificationState]);

  const navigateEmailLoginOnPress = useCallback(() => {
    mixpanel.track("Webapp: User Start Login");
    setEmailLogin(true);
  }, [mixpanel]);

  const navigateEmailSignUp = useCallback(() => {
    setEmailSignup(true);
    setEmailLogin(false);
  }, []);

  const navigateForgotPassword = useCallback(() => {
    setForgotPassword(true);
    setEmailLogin(false);
  }, []);

  const backToWelcome = useCallback(async () => {
    setEmailLogin(false);
    setEmail("");
    setPassword("");
    setEmailError({ status: false, message: "" });
    setPasswordError(false);
  }, []);

  const backToEmailSignIn = useCallback(async () => {
    if (defaultEmail) {
      navigate("/welcome/onboarding");
    } else {
      setEmailLogin(true);
      setEmailSignup(false);
      setForgotPassword(false);
      setEmail("");
      setConfirmEmail("");
      setPassword("");
      setConfirmPassword("");
      setEmailError({ status: false, message: "" });
      setPasswordError(false);
    }
  }, [defaultEmail, navigate]);

  const logInWithEmailAndPassword = useCallback(async () => {
    try {
      if (!email || !password) {
        setAlertText({
          heading: "One or more of the fields are empty.",
          subHeading: "You must fill them all to continue.",
        });
        return;
      }
      await signInWithEmailAndPassword(firebaseAuth, email, password).then(
        async (credential) => {
          if (credential.user !== null) {
            setLoading(true);
            setEmailError({ status: false, message: "" });
            setPasswordError(false);
            loginWithCredential(credential);
            setLoading(false);
          }
        }
      );
    } catch (error: any) {
      console.error(error);
      switch (error.message) {
        case "The email address is badly formatted.":
          setEmailError({
            status: true,
            message: "The email address is badly formatted",
          });
          break;
        case "The password is invalid or the user does not have a password.":
          setEmailError({ status: false, message: "" });
          setPasswordError(true);
          break;
        default:
          setEmailError({
            status: true,
            message: "The email or password is invalid. Please try again.",
          });
          setPasswordError(true);
      }
    }
  }, [email, password, loginWithCredential]);

  const registerEmailAndPassword = useCallback(() => {
    const emailLower = email.toLowerCase();
    const confirmEmailLower = confirmEmail.toLowerCase();
    if (!email || !password) {
      setAlertText({
        heading: "One or more of the fields are empty.",
        subHeading: "You must fill them all to continue.",
      });
      return;
    }
    if (emailLower !== confirmEmailLower) {
      setAlertText({
        heading: "Your emails do not match!",
        subHeading: "",
      });
      return;
    }
    if (password !== confirmPassword) {
      setAlertText({
        heading: "Your passwords do not match!",
        subHeading: "",
      });
      return;
    }
    setLoading(true);
    createUserWithEmailAndPassword(firebaseAuth, emailLower, password)
      .then((userCredential) => {
        if (userCredential.user !== null) {
          loginWithCredential(userCredential);
          mixpanel.track("Webapp: Agreed to Terms", {
            customer_email: emailLower,
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.code === "auth/email-already-exists") {
          setEmailError({ status: true, message: "Email already exists" });
        } else if (error.code === "auth/invalid-email") {
          setEmailError({ status: true, message: "Badly formatted email" });
        } else {
          setEmailError({ status: true, message: error.message });
        }
        setLoading(false);
      });
  }, [
    confirmEmail,
    confirmPassword,
    email,
    loginWithCredential,
    mixpanel,
    password,
  ]);

  const emailAccountString = useMemo(
    () => (
      <p style={{ ...styles.subtext }}>
        Email is recommended for organizations where multiple people may need to
        access the same account.
      </p>
    ),
    [styles.subtext]
  );

  return (
    <div>
      <div id="sign-in-button"></div>
      <div
        className="LoginContainer"
        style={{ height: isDesktop ? "120vh" : "100vh" }}
      >
        <TopHeader hideLogin />
        <div
          className="LoginBody"
          style={{
            justifyContent: "center",
            width: isDesktop ? "362px" : "85%",
            marginTop: isDesktop ? 70 : 30,
            paddingTop: 20,
            paddingBottom: 14,
          }}
        >
          {emailLogin ? (
            <div className="ColumnNormal">
              <div style={{ paddingBottom: 24 }}>
                <BackButton onPress={backToWelcome} />
              </div>
              <span
                className="LoginHeaderText"
                style={{ ...theme.PrimaryText }}
              >
                Continue with email
              </span>
              <div style={{ paddingTop: 7, paddingBottom: 20 }}>
                {emailAccountString}
              </div>
              <div style={{ marginBottom: 14 }}>
                <CustomTextField
                  value={email}
                  placeholder="Email"
                  inputMode="text"
                  borderRadius={12}
                  onChange={(change: any) => {
                    setEmailError({
                      status: false,
                      message: "",
                    });
                    setEmail(change.target.value);
                  }}
                  error={emailError.status}
                />
              </div>
              <div style={{ marginBottom: 14 }}>
                <CustomTextField
                  inputMode={showPassword ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  error={passwordError}
                  onChange={(password: any) => {
                    setPasswordError(false);
                    setPassword(password.target.value);
                  }}
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  }
                />
              </div>
              <RectangleButton
                buttonLabel={<span>Sign In</span>}
                onPress={logInWithEmailAndPassword}
                theme={theme}
                altPaddingVert={12}
                disabled={loading}
                loading={loading}
              />
              <div
                style={{
                  fontSize: 12,
                  fontWeight: "600",
                  marginTop: 14,
                  cursor: "pointer",
                }}
                onClick={navigateForgotPassword}
              >
                Forgot Password?
              </div>
              <hr />
              <RectangleButton
                buttonLabel={<span>Sign up with email</span>}
                onPress={navigateEmailSignUp}
                altColor={Colors.GRAY6}
                altTextColor={Colors.GRAY3}
                altPaddingVert={12}
                disabled={loading}
                loading={loading}
              />
            </div>
          ) : emailSignup ? (
            <div className="ColumnNormal">
              <div style={{ paddingBottom: 24 }}>
                <BackButton onPress={backToEmailSignIn} />
              </div>
              <span
                className="LoginHeaderText"
                style={{
                  ...theme.PrimaryText,
                }}
              >
                Email Sign Up {defaultEmail ? "Recommended" : ""}
              </span>
              <div style={{ paddingTop: 7, paddingBottom: 20 }}>
                {emailAccountString}
              </div>
              <div style={{ marginBottom: 14 }}>
                <CustomTextField
                  value={email}
                  placeholder="Email"
                  inputMode="text"
                  borderRadius={12}
                  onChange={(change: any) => {
                    setEmailError({
                      status: false,
                      message: "",
                    });
                    setEmail(change.target.value);
                  }}
                  error={emailError.status}
                />
              </div>
              <div style={{ marginBottom: 14 }}>
                <CustomTextField
                  value={confirmEmail}
                  placeholder="Confirm Email"
                  inputMode="text"
                  borderRadius={12}
                  onChange={(change: any) => {
                    setConfirmEmail(change.target.value);
                  }}
                  error={emailError.status}
                />
              </div>
              <div style={{ marginBottom: 14 }}>
                <CustomTextField
                  inputMode={showPassword ? "text" : "password"}
                  value={password}
                  placeholder="Password"
                  error={passwordError}
                  onChange={(password: any) => {
                    setPasswordError(false);
                    setPassword(password.target.value);
                  }}
                  autocompletePassword
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                    </IconButton>
                  }
                />
              </div>
              <div style={{ marginBottom: 14 }}>
                <CustomTextField
                  inputMode={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  placeholder="Confirm Password"
                  error={passwordError}
                  onChange={(password: any) => {
                    setPasswordError(false);
                    setConfirmPassword(password.target.value);
                  }}
                  autocompletePassword
                  endAdornment={
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                    >
                      {showConfirmPassword ? (
                        <MdVisibility />
                      ) : (
                        <MdVisibilityOff />
                      )}
                    </IconButton>
                  }
                />
              </div>
              <div style={{ marginBottom: 14 }}>
                <RectangleButton
                  buttonLabel={<span>Sign Up</span>}
                  onPress={registerEmailAndPassword}
                  theme={theme}
                  altPaddingVert={12}
                  disabled={loading}
                  loading={loading}
                />
              </div>
              <MessageDataRates />
            </div>
          ) : forgotPassword ? (
            <LoginForgotPassword
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
              backToEmailOnPress={backToEmailSignIn}
            />
          ) : (
            <>
              {verificationState === VerificationState.UNVERIFIED ? (
                <div className="LoginHeaderTextContainer">
                  <div style={{ paddingBlock: 10 }}>
                    <img className="LoginLogo" src={logo} alt={"banner"} />
                  </div>
                  <span className="LoginHeaderText">
                    {currentRoleTicket
                      ? "Accept Organizer Role"
                      : currentEvent
                      ? `Publish your ${
                          isEventExternalLink(currentEvent.eventType)
                            ? "link"
                            : "event"
                        }`
                      : "Welcome to Markit"}
                  </span>
                  {currentRoleTicket && currentEvent ? (
                    <span className="LoginTitleDescription">
                      {`You have been invited to be a  
                        ${friendlyRoleName(currentRoleTicket)} for `}
                      <ExternalLinkText
                        href={`https://markitai.com/e/${currentEvent.id}`}
                      >{`${currentEvent.title}`}</ExternalLinkText>
                    </span>
                  ) : null}
                  <span className="LoginTitleDescription">
                    Sign in or sign up below
                    {currentRoleTicket ? " to accept this role." : ""}
                  </span>
                </div>
              ) : null}
              <div className="LoginBoxContainer">
                {verificationState === VerificationState.UNVERIFIED ? (
                  <div style={{ paddingBottom: 14 }}>
                    <CustomTextField
                      value={tempPhoneNumber}
                      inputMode="tel"
                      placeholder="Your Number"
                      borderRadius={12}
                      onChange={(change: any) => {
                        const parsedPhoneNumber = change.target.value.replace(
                          /-/g,
                          ""
                        );
                        setShowPhoneNumberError(false);
                        setTempPhoneNumber(parsedPhoneNumber);
                      }}
                      error={showPhoneNumberError}
                    />
                  </div>
                ) : null}
                {/* <div style={{ paddingBlock: 14 }}>
                  <Input
                    placeholder="Enter phone number"
                    value={tempPhoneNumber}
                    onChange={(value: any) => {
                      console.log(value);
                      // const parsedPhoneNumber = phone.target.value.replace(/-/g, "");
                      setShowPhoneNumberError(false);
                      setTempPhoneNumber(value as string);
                    }}
                  />
                </div> */}
                <VerificationCodeButton
                  signIn={true}
                  buttonText="Continue with Phone"
                  tempPhoneNumber={tempPhoneNumber}
                  setTempPhoneNumber={setTempPhoneNumber}
                  verificationState={verificationState}
                  setVerificationState={setVerificationState}
                  showCodeInputError={showCodeInputError}
                  setShowCodeInputError={setShowCodeInputError}
                  showError={showError}
                />
                {verificationState === VerificationState.UNVERIFIED ? (
                  <>
                    <hr />
                    <div style={{ marginBottom: 14 }}>
                      <RectangleButton
                        buttonLabel={<span>Continue with Email</span>}
                        onPress={navigateEmailLoginOnPress}
                        altColor={Colors.GRAY6}
                        altTextColor={Colors.GRAY3}
                        altPaddingVert={12}
                      />
                    </div>
                    <div style={{ textAlign: "center", paddingInline: 14 }}>
                      {emailAccountString}
                    </div>
                  </>
                ) : null}
              </div>
            </>
          )}
        </div>
        {isDesktop ? (
          <div style={{ marginTop: "auto" }}>
            <Footer forceLight />
          </div>
        ) : null}
      </div>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        theme={theme}
        closeModal={() => setAlertText({ heading: "", subHeading: "" })}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </div>
  );
});

export default LoginWelcome;
