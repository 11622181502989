import "../css/FullEvent/FullEvent.css";
import { Colors } from "../utils/colors";
import { ThemeType } from "@markit/common.types";
import { useTheme } from "../hooks/useTheme";
import { MARKIT_ATTENDEE_FAQ } from "@markit/common.utils";

type InternationalPhoneNoticeProp = {
  eventTheme?: ThemeType;
};

const InternationalPhoneNotice = (props: InternationalPhoneNoticeProp) => {
  const { eventTheme } = props;
  const { theme } = useTheme(eventTheme);

  return (
    <>
      <div
        className="SupportContainer"
        style={{ ...theme.SecondaryBG, borderRadius: "12px" }}
      >
        <p className="SupportText" style={theme.PrimaryText}>
          Have a non-US phone number?{" "}
          <a
            href={MARKIT_ATTENDEE_FAQ}
            target="_blank"
            rel="noreferrer"
            className="SupportText"
            style={{ color: Colors.BLUE5 }}
          >
            Click here
          </a>
        </p>
      </div>
    </>
  );
};

export default InternationalPhoneNotice;
