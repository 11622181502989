import {
  query,
  where,
  getCountFromServer,
  getDocs,
  orderBy,
  limit,
} from "../../firebase";
import {
  getTicketsRef,
  getTicketsSnap,
  getEventData,
  getUserData,
  getEventsRef,
  getUserEventsRef,
} from "../../utils/FirebaseUtils";
import {
  Event,
  CustomTicketV2,
  PromoCode,
  PromoCodeType,
  AccountData,
  TicketV2,
  RequestStatus,
  OrganizerType,
} from "@markit/common.types";
import { filterUndefinedValues, uniqueVals } from "@markit/common.utils";
import { ErrorCode } from "@markit/common.api";
import { getUserRoleTicket, getUserTicket } from "./userTicketUtils";
import { API } from "../../API";

/**
 * Get total attendee tickets
 */
export const getTotalAttendeeTickets = async (
  eventId: string
): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("uid", "!=", ""),
    where("role.type", "==", OrganizerType.ATTENDEE)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get total unique attendee tickets
 */
export const getTotalUniqueAttendees = async (
  eventId: string
): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("redeemedBy", "!=", ""),
    where("role.type", "==", OrganizerType.ATTENDEE)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get total attendee accepted tickets
 */
export const getTotalAcceptedAttendeeTickets = async (
  eventId: string
): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("uid", "!=", ""),
    where("requestStatus", "==", "accepted"),
    where("role.type", "==", OrganizerType.ATTENDEE)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get total unique accepted attendee tickets
 */
export const getTotalUniqueAcceptedAttendees = async (
  eventId: string
): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("redeemedBy", "!=", ""),
    where("requestStatus", "==", "accepted"),
    where("role.type", "==", OrganizerType.ATTENDEE)
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get the number of event organizers
 */
export const getTotalOrganizers = async (eventId: string) => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("customTicketId", "==", ""));
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get the number of assigned event organizers
 */
export const getTotalAssignedOrganizers = async (eventId: string) => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("customTicketId", "==", ""),
    where("uid", "!=", "")
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get the total number of actual tickets sold (not including host distributed tickets)
 * Useful for tickets involving paid events
 */
export const getTotalTicketsSold = async (eventId: string) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("originalOwner", "!=", eventData.createdBy),
      where("requestStatus", "==", RequestStatus.ACCEPTED)
    );
    const snapshot = await getCountFromServer(query_);

    return snapshot.data().count;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get the total number of actual tickets sold with a certain custom ticket (not including host distributed tickets)
 * Useful for tickets involving paid events
 */
export const getTotalTicketsSoldInGroup = async (
  eventId: string,
  customTicketId: string,
  excludePending?: boolean // true if you do not want to include request pending tickets sold
) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = excludePending
      ? query(
          ticketsRef,
          where("customTicketId", "==", customTicketId),
          where("originalOwner", "!=", eventData.createdBy),
          where("requestStatus", "==", RequestStatus.ACCEPTED)
        )
      : query(
          ticketsRef,
          where("customTicketId", "==", customTicketId),
          where("originalOwner", "!=", eventData.createdBy)
        );
    const snapshot = await getCountFromServer(query_);

    return snapshot.data().count;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get the total number of host distributed tickets
 * Useful for tickets involving paid events
 */
export const getTotalTicketsDistributed = async (eventId: string) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("originalOwner", "==", eventData.createdBy),
      where("customTicketId", "!=", "")
    );
    const snapshot = await getCountFromServer(query_);

    return snapshot.data().count;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get the total number of host distributed tickets with a certain custom ticket
 * Useful for tickets involving paid events
 */
export const getTotalTicketsDistributedInGroup = async (
  eventId: string,
  customTicketId: string
) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("customTicketId", "==", customTicketId),
      where("originalOwner", "==", eventData.createdBy)
    );
    const snapshot = await getCountFromServer(query_);

    return snapshot.data().count;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get the number of redeemed host distributed tickets
 * Useful for tickets involving paid events
 */
export const getRedeemedTicketsDistributed = async (eventId: string) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("originalOwner", "==", eventData.createdBy),
      where("uid", "!=", "")
    );
    const snapshot = await getCountFromServer(query_);

    const organizers = await getTotalAssignedOrganizers(eventId);
    return snapshot.data().count - organizers;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get the number of redeemed host distributed tickets with a certain custom ticket
 * Useful for tickets involving paid events
 */
export const getRedeemedTicketsDistributedInGroup = async (
  eventId: string,
  customTicketId: string
) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("customTicketId", "==", customTicketId),
      where("originalOwner", "==", eventData.createdBy),
      where("uid", "!=", "")
    );
    const snapshot = await getCountFromServer(query_);

    return snapshot.data().count;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get the number of unredeemed host distributed tickets
 * Useful for tickets involving paid events
 */
export const getUnredeemedTicketsDistributed = async (eventId: string) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("originalOwner", "==", eventData.createdBy),
      where("uid", "==", "")
    );
    const snapshot = await getCountFromServer(query_);

    return snapshot.data().count;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get the number of unredeemed host distributed tickets with a certain custom ticket
 * Useful for tickets involving paid events
 */
export const getUnredeemedTicketsDistributedInGroup = async (
  eventId: string,
  customTicketId: string
) => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("customTicketId", "==", customTicketId),
      where("originalOwner", "==", eventData.createdBy),
      where("uid", "==", "")
    );
    const snapshot = await getCountFromServer(query_);

    return snapshot.data().count;
  } else {
    console.log("could not fetch event data");
    return 0;
  }
};

/**
 * Get how many tickets remaining in custom ticket that can be sold
 */
export const getTicketsRemainingInGroup = async (
  customTicket: CustomTicketV2,
  event: Event
) => {
  if (customTicket.quantityAvailable === 0) {
    return -1;
  }
  const ticketsSold = await getTotalTicketsSoldInGroup(
    event.id,
    customTicket.id
  );
  return Math.max(customTicket.quantityAvailable - ticketsSold, 0);
};
/**
 * Check if the current ticket is in stock
 */
export const ticketInStock = async (
  customTicket: CustomTicketV2,
  event: Event
) => {
  if (customTicket.quantityAvailable === 0) {
    return true;
  }
  const ticketsSold = await getTotalTicketsSoldInGroup(
    event.id,
    customTicket.id
  );
  return ticketsSold < customTicket.quantityAvailable;
};

/**
 * Check if the event has no tickets remaining
 */
export const eventAtCapacity = async (
  event: Event,
  visibleTickets: CustomTicketV2[]
) => {
  const ticketAvailabilityPromises = visibleTickets.map((customTicket) =>
    ticketInStock(customTicket, event)
  );
  const ticketAvailabilityResults = await Promise.all(
    ticketAvailabilityPromises
  );

  return ticketAvailabilityResults.every((ticketAvailable) => !ticketAvailable);
};

/**
 * Get the label for the custom ticket
 */
export const getTicketLabels = async (
  ticket: CustomTicketV2,
  ticketsRemaining: number,
  hideResponses: boolean
) => {
  let labels: string[] = [];

  if (!hideResponses) {
    if (ticket.quantityAvailable === 0) {
      return labels;
    }
    if (ticketsRemaining > 0) {
      labels = labels.concat(
        `${ticketsRemaining}/${ticket.quantityAvailable} tickets remaining`
      );
    }
  }

  return labels;
};

export const getTicketDescription = async (
  ticket: CustomTicketV2,
  ticketsRemaining: number,
  hideResponses: boolean
) => {
  const labels = await getTicketLabels(ticket, ticketsRemaining, hideResponses);
  const label = labels.join(".");
  return label;
};

export const applyPromoCode = (
  customTicket: CustomTicketV2,
  promoCode: PromoCode,
  ticketQuantity: number
) => {
  if (!promoCode) {
    return undefined;
  }

  switch (promoCode.type) {
    case PromoCodeType.DOLLARS_OFF: {
      if (promoCode.constants.length === 1) {
        return Math.min(
          customTicket.price * ticketQuantity - promoCode.constants[0],
          0
        );
      }
      return undefined;
    }
    case PromoCodeType.PERCENT_OFF: {
      if (promoCode.constants.length === 1) {
        return (
          (customTicket.price *
            ticketQuantity *
            (100 - promoCode.constants[0])) /
          100
        );
      }
      return undefined;
    }
  }
};

/**
 * Get visible guest list to show on event cards and full event (filters out organizers)
 */
export const getVisibleGuestList = async (
  eventId: string,
  numToDisplay: number
): Promise<AccountData[]> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("redeemedBy", "!=", ""),
    where("requestStatus", "==", RequestStatus.ACCEPTED),
    orderBy("redeemedBy", "desc"),
    limit(numToDisplay)
  );
  const ticketsSnap = await getDocs(query_);
  const guests = await Promise.all(
    ticketsSnap.docs.map(async (doc) => {
      const ticket = doc.data();
      if (ticket.customTicketId === "") {
        return undefined;
      }
      const userData = await getUserData(ticket.uid);
      if (userData) {
        return userData;
      }
      return undefined;
    })
  );
  const definedGuests: AccountData[] = filterUndefinedValues(guests);
  return definedGuests;
};

/**
 * Determines if the user is an organizer (host, cohost, scanner, performer)
 */
export const isUserOrganizer = async (event: Event, uid: string) => {
  let isOrganizer = false;

  if (event.createdBy === uid) {
    isOrganizer = true;
    return isOrganizer;
  }

  const ticket = await getUserTicket(event.id, uid);
  if (ticket) {
    const role = ticket.role.type;
    if (
      role === OrganizerType.COHOST ||
      role === OrganizerType.PROMOTER ||
      role === OrganizerType.SCANNER
    ) {
      isOrganizer = true;
    }
  }

  return isOrganizer;
};

/**
 * Returns object if ticket alias is found
 * using numbers in FullEventTicketOptions
 */
export const findFreeTicketAlias = async (
  eventId: string,
  alias: string
): Promise<[TicketV2 | undefined, ErrorCode]> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("alias", "==", alias));
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    return [undefined, ErrorCode.INVALID_PROMO];
  }
  const userTicket = querySnapshot.docs.map((doc) => doc.data());

  if (userTicket.length > 1) {
    for (let i = 0; i < userTicket.length; i++) {
      if (userTicket[i].uid === "") {
        return [userTicket[i], ErrorCode.OK];
      }
    }
    // return 4;
    return [undefined, ErrorCode.REACHED_MAX_USE];
  }
  return [userTicket[0], ErrorCode.OK];
};

/**
 * Get the unique number of users going to an event
 */
export const getUniqueAttendees = async (eventId: string): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("redeemedBy", "!=", ""));
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    console.log("no tickets found for: ", eventId);
    return 0;
  }
  const userIds = querySnapshot.docs.map((doc) => doc.data().redeemedBy);
  // filter out the manual redeemed tickets
  const filteredUserIds = userIds.filter(
    (user) => user !== "manual redeemed" && user !== "manual redeemed scanned"
  );

  return uniqueVals(filteredUserIds).length;
};

export const fetchAllUpcomingEvents = async () => {
  const date = new Date();

  const eventsQuery = query(
    getEventsRef(),
    orderBy("end", "desc"),
    where("end", ">=", date.toISOString())
  );

  const events = (await getDocs(eventsQuery)).docs.map((snap) => snap.data());
  return events;
};

/**
 * NOT USED CURRENTLY
 * Get the upcoming public events hosted by the specified user
 */
export const fetchUpcomingUserEvents = async (
  uid: string,
  isMyAccount: boolean
) => {
  if (isMyAccount) {
    const eventsQuery = query(
      getUserEventsRef(uid),
      where("end", ">=", new Date().toISOString()),
      orderBy("end", "asc"),
      limit(3)
    );
    const events = (await getDocs(eventsQuery)).docs.map((snap) => snap.data());
    return events;
  } else {
    const eventsQuery = query(
      getUserEventsRef(uid),
      where("end", ">=", new Date().toISOString()),
      orderBy("end", "asc"),
      limit(4)
    );
    const events = (await getDocs(eventsQuery)).docs.map((snap) => snap.data());
    return events;
  }
};

// fetch all of a creators events
export const fetchCreatorEvents = async (uid: string) => {
  const events = await API.user.creatorEvents({ uid }).then((resp) => {
    const { events, errorFetching } = resp;
    if (errorFetching) {
      console.error("Error fetching events on initial app load");
      return [];
    }
    return events;
  });
  return events;
};

/**
 * Get total attendees that have been scanned
 */
export const getTotalScanned = async (eventId: string): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("scanned", "==", true));
  const snapshot = await getCountFromServer(query_);

  return snapshot.data().count;
};

/**
 * Gets the accumulated revenue for the selected event
 */
export const getTotalRevenueForEvent = async (
  eventId: string
): Promise<number> => {
  let totalRevenue: number = 0;
  const ticketsSnap = await getTicketsSnap(eventId);
  ticketsSnap.docs.map((doc) => {
    return (totalRevenue = totalRevenue + doc.data().amountPaid);
  });
  return totalRevenue;
};

/**
 * Get total redeemed by tickets including those that were redeemed by the host
 */
export const getTotalRedeemed = async (eventId: string): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("redeemedBy", "!=", ""),
    where("requestStatus", "==", "accepted")
  );
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get number of tickets sold by a promoter
 */
export const getTotalPromoterTicketsSold = async (
  eventId: string,
  promoterUid: string
): Promise<number> => {
  const promoterTicket = await getUserRoleTicket(eventId, promoterUid);

  if (promoterTicket) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      where("promoter", "==", promoterTicket.id)
    );
    const snapshot = await getCountFromServer(query_);
    return snapshot.data().count;
  } else {
    return 0;
  }
};

// Gets the total number of free ticket promo codes for an event
export const getTotalNumFreePromoTickets = async (
  eventId: string
): Promise<number> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("alias", "!=", ""));
  const snapshot = await getCountFromServer(query_);
  return snapshot.data().count;
};

/**
 * Get the userIds from all the tickets for an Event of a specific ticket type
 * Exclude the manual redeemed and excludedList people
 */
export const getUidsFromEventTicketType = async (
  eventId: string,
  customTicketId: string
): Promise<string[]> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("customTicketId", "==", customTicketId),
    where("uid", "!=", "")
  );
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    return [];
  }
  const tickets = querySnapshot.docs.map((doc) => doc.data());

  return uniqueVals(tickets, (ticket: TicketV2) => ticket.uid).map(
    (ticket) => ticket.uid
  );
};

/**
 * Get the ticketData from all the unique attendee tickets for an Event
 */
export const fetchUniqueEventTickets = async (
  eventId: string
): Promise<TicketV2[]> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(
    ticketsRef,
    where("uid", "!=", ""),
    where("role.type", "==", OrganizerType.ATTENDEE)
  );
  const querySnapshot = await getDocs(query_);

  if (querySnapshot.docs.length === 0) {
    console.log("no tickets found for: ", eventId);
    return [];
  }
  const tickets = querySnapshot.docs.map((doc) => doc.data());
  return uniqueVals(tickets, (ticket: TicketV2) => ticket.uid);
};

/**
 * Get the ticket data from all requested attendees for an event
 */
export const fetchEventAttendeeRequestedTickets = async (
  eventId: string
): Promise<TicketV2[]> => {
  const ticketsRef = getTicketsRef(eventId);
  const query_ = query(ticketsRef, where("requestStatus", "==", "pending"));
  const snapshot = await getDocs(query_);

  if (snapshot.docs.length === 0) {
    console.log("no tickets found for: ", eventId);
    return [];
  }

  const tickets = snapshot.docs.map((doc) => doc.data());
  return tickets;
};

/**
 * Returs ALL Free Tickets with Alias Array
 */
export const fetchAllFreeTicketsWithAlias = async (
  eventId: string
): Promise<TicketV2[]> => {
  const eventData = await getEventData(eventId);
  if (eventData) {
    const ticketsRef = getTicketsRef(eventId);
    const query_ = query(
      ticketsRef,
      // TODO: if we add aliases to organizer ticket's this will also fetch organizer tickets
      // and break the function's purpose :)
      where("originalOwner", "==", eventData.createdBy),
      where("alias", "!=", "")
    );
    const querySnapshot = await getDocs(query_);

    if (querySnapshot.docs.length === 0) {
      console.log("Empty query snapshot");
      return [];
    } else {
      const freeTickets = querySnapshot.docs.map((doc) => doc.data());
      return freeTickets;
    }
  } else {
    console.log("could not fetch event data");
    return [];
  }
};

// Given an array of event organizers, return the array so that the host is the first element
export const getEventDisplayedOrganizers = (
  hostId: string,
  organizers: AccountData[]
) => {
  const finalOrganizers = organizers;
  const foundHost = organizers.find((organizer) => organizer.uid === hostId);
  const foundHostIndex = organizers.findIndex(
    (organizer) => organizer.uid === hostId
  );
  if (foundHostIndex !== -1 && foundHost) {
    finalOrganizers.splice(foundHostIndex, 1);
    finalOrganizers.unshift(foundHost);
  }
  return finalOrganizers;
};

export const fetchEventOrganizers = async (events: Event[]) => {
  const organizersArr = await Promise.all(
    events.map((event) => {
      return event.cohosts.concat(event.createdBy);
    })
  );
  const organizers = uniqueVals(organizersArr.flatMap((subArray) => subArray));
  const organizersData = await Promise.all(
    organizers.map(async (organizer) => {
      const userData = await getUserData(organizer);
      if (userData) {
        return userData;
      }
    })
  );
  const filteredOrganizersData: AccountData[] =
    filterUndefinedValues(organizersData);

  return filteredOrganizersData;
};
