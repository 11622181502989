import React from "react";
import { Colors } from "../../../../utils/colors";
import CustomTextField from "../../../CustomTextField";
import UploadSpreadsheetFile from "./UploadSpreadsheetFile";
import RectangleButton from "../../../Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import { MARKIT_WEBSITE } from "@markit/common.utils";
import { SpreadsheetFileDisplay, SpreadsheetInfo } from "@markit/common.types";

type UploadContactsProps = {
  spreadsheet: SpreadsheetInfo | undefined;
  setSpreadsheet: (spreadsheet: SpreadsheetInfo | undefined) => void;
  currNumImported: number;
  displayFile: SpreadsheetFileDisplay;
  setDisplayFile: (displayFile: SpreadsheetFileDisplay) => void;
  permissionsChecked: boolean;
  setPermissionsChecked: (permissionsChecked: boolean) => void;
};

const UploadContacts = (props: UploadContactsProps) => {
  const {
    spreadsheet,
    setSpreadsheet,
    currNumImported,
    displayFile,
    setDisplayFile,
    permissionsChecked,
    setPermissionsChecked,
  } = props;

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
  };

  return (
    <div className="ColumnNormal Centering" style={{ paddingBottom: 40 }}>
      {spreadsheet ? (
        <div className="ColumnNormal" style={{ gap: 40, paddingTop: 30 }}>
          <div
            className="ColumnNormal"
            style={{ gap: 10, textAlign: "center" }}
          >
            <span style={styles.headerText}>Review File Details</span>
            <span style={styles.subtext}>
              Choose a file display name and review before continuing.
            </span>
          </div>
          <div
            className="ColumnNormal"
            style={{ gap: 7, width: 547, paddingBottom: 24 }}
          >
            <span style={{ ...styles.bodyMedium, textAlign: "left" }}>
              Display Name
            </span>
            <div className="ColumnNormal" style={{ gap: 4 }}>
              <CustomTextField
                value={displayFile.name}
                placeholder="Display Name..."
                inputMode="text"
                borderRadius={12}
                backgroundColor={Colors.WHITE1}
                onChange={(change: any) => {
                  setDisplayFile({
                    name: change.target.value,
                    isError: false,
                    message: "",
                  });
                }}
                error={displayFile.isError}
                altMarginBottom={0}
              />
              {displayFile.isError ? (
                <span style={{ fontSize: 12, color: Colors.RED2 }}>
                  {displayFile.message}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <UploadSpreadsheetFile
        spreadsheet={spreadsheet}
        setSpreadsheet={setSpreadsheet}
        displayFile={displayFile}
        setDisplayFile={setDisplayFile}
        permissionsChecked={permissionsChecked}
        setPermissionsChecked={setPermissionsChecked}
        currNumImported={currNumImported}
      />
      {!spreadsheet ? (
        <RectangleButton
          buttonLabel={
            <span style={styles.bodyMedium}>How to Format Spreadsheet</span>
          }
          altColor={Colors.GRAY6}
          altTextColor={Colors.BLACK}
          onPress={() => window.open(MARKIT_WEBSITE + "/formatspreadsheet")}
          iconRight={
            <Icon icon="mdi:chevron-right" height={16} color={Colors.BLACK} />
          }
          width={248}
          containerStyles={{ marginTop: 40 }}
        />
      ) : null}
    </div>
  );
};

export default UploadContacts;
