import {
  AccountData,
  Conversation,
  MemberIndicator,
} from "@markit/common.types";
import { useCallback, useMemo } from "react";
import { Colors } from "../../../utils/colors";
import { TimeAgo } from "../../../utils/TimeAgo";
import ProfilePic from "../../ProfilePic";

type ConversationsSidebarPreviewProps = {
  accountData: AccountData;
  conversationItem?: Conversation;
  followingAccountData: AccountData[];
  conversationsMainViewChatUser: AccountData | undefined;
  selectConversationMainView: (conversation: Conversation) => void;
};

export const isUnreadConversation = (
  chatIndicators: MemberIndicator[],
  accountDataId: string
) => {
  if (chatIndicators !== undefined) {
    return chatIndicators.find(
      (member) => member.indicator && member.uid === accountDataId
    );
  } else {
    return false;
  }
};

const ConversationsSidebarPreview = (
  props: ConversationsSidebarPreviewProps
) => {
  const {
    accountData,
    conversationItem,
    followingAccountData,
    conversationsMainViewChatUser,
    selectConversationMainView,
  } = props;
  const userId = accountData.uid;

  const participant = useMemo(
    () =>
      conversationItem &&
      followingAccountData.find(
        (user) => user.uid === conversationItem.participantUids[0]
      ),
    [conversationItem, followingAccountData]
  );

  const isUnread = useCallback(() => {
    return (
      conversationItem &&
      isUnreadConversation(conversationItem.chatIndicator, userId)
    );
  }, [conversationItem, userId]);

  return (
    <div
      className="AlignedRow ConversationSidebarItem"
      style={{
        position: "relative",
        backgroundColor:
          participant === undefined ||
          conversationsMainViewChatUser === undefined ||
          !(participant?.uid === conversationsMainViewChatUser?.uid)
            ? Colors.WHITE
            : Colors.GRAY6,
        pointerEvents:
          (accountData.customer.state === "ACTIVE" ||
            accountData.customer.state === "TRIAL") &&
          conversationItem
            ? "all"
            : "none",
      }}
      onClick={() =>
        conversationItem ? selectConversationMainView(conversationItem) : {}
      }
    >
      <div
        className="AlignedRow"
        style={{ gap: 10, width: "100%", alignItems: "center" }}
      >
        <div>
          <ProfilePic user={participant} size={45} />
        </div>
        <div style={{ width: "100%", marginRight: 20 }}>
          <div className="AlignedRowSpaced">
            <span className="AboutSubtitle">
              {participant ? participant.fullName : "•••"}
            </span>
            {conversationItem &&
            conversationItem.recentTimestamp !== undefined ? (
              <span
                style={{ fontSize: 13, color: Colors.GRAY1, fontWeight: 400 }}
              >
                {TimeAgo(conversationItem.recentTimestamp)}
              </span>
            ) : null}
          </div>
          {conversationItem ? (
            <div style={{ width: "90%" }}>
              <span
                className="TextOverflowEllipsis"
                style={{
                  marginTop: 4,
                  fontSize: 14,
                  color: isUnread() ? Colors.BLACK : Colors.GRAY1,
                  fontWeight: isUnread() ? 600 : 400,
                  width: "260px",
                }}
              >
                {conversationItem.recentMessage.text
                  ? conversationItem.recentMessage.text.trim() === ""
                    ? "Attachment: 1 Media" // assume if no text that it's media
                    : conversationItem.recentMessage.text
                  : "No message responses yet"}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {isUnread() ? (
        <div
          style={{
            position: "absolute",
            top: 40,
            right: 10,
            width: 8,
            height: 8,
            backgroundColor: Colors.PINK1,
            borderRadius: 64,
          }}
        />
      ) : null}
    </div>
  );
};

export default ConversationsSidebarPreview;
