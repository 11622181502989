import { FollowType, SpreadsheetStatus } from "@markit/common.types";
import { getCountFromServer, getDocs, query, where } from "../firebase";
import { getSpreadsheetsRef, getUserFollowersRef } from "./FirebaseUtils";
import Papa from "papaparse";
import * as XLSX from "xlsx";

/**
 * Get total number of spreadsheet followers uploaded
 */
export const getTotalSpreadsheetFollowersUploaded = async (
  uid: string
): Promise<number> => {
  const followersRef = getUserFollowersRef(uid);
  const followersQuery = query(
    followersRef,
    where("spreadsheetData.fileId", "!=", "")
  );

  const snapshot = await getCountFromServer(followersQuery);
  return snapshot.data().count;
};

/**
 * Get total number of accepted spreadsheet followers uploaded
 */
export const getTotalAcceptedSpreadsheetFollowers = async (
  uid: string
): Promise<number> => {
  const followersRef = getUserFollowersRef(uid);
  const followersQuery = query(
    followersRef,
    where("followType", "==", FollowType.UPLOAD),
    where("spreadsheetData.status", "==", SpreadsheetStatus.ACCEPTED)
  );

  const snapshot = await getCountFromServer(followersQuery);
  return snapshot.data().count;
};

// Parses the csv or xlsx file into a string with the valid info
export const parseSpreadsheetFile = (
  fileData: string | ArrayBuffer | null | undefined,
  file: any
): string => {
  let csv: string = "";
  if (file.name.endsWith(".xlsx")) {
    const workbook = XLSX.read(fileData, { type: "binary" });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    let tempCsv = XLSX.utils.sheet_to_csv(sheet);
    // Filter out empty rows
    csv = tempCsv
      .split("\n")
      .filter((row) => row.split(",").some((value) => value.trim() !== ""))
      .join("\n");
  } else if (file.name.endsWith(".csv")) {
    Papa.parse(fileData as any, {
      complete: (result) => {
        const cleanedData = result.data.filter((row: any) =>
          row.some((value: string) => value.trim() !== "")
        );
        csv = Papa.unparse(cleanedData);
      },
    });
  }
  return csv;
};

export const foundExistingSpreadsheetName = async (
  fileName: string,
  userId: string
) => {
  const spreadsheetsRef = getSpreadsheetsRef(userId);
  const spreadsheetsQuery = query(
    spreadsheetsRef,
    where("fileName", "==", fileName)
  );
  const snapshot = await getDocs(spreadsheetsQuery);
  return !snapshot.empty;
};

// Determines if there is a empty array (column), and if it's empty, remove it from the columns data
export const removeEmptyAssignedColumns = (arrays: string[][]) => {
  if (arrays.length === 0) return arrays;

  const numCols = arrays[0].length;
  const numRows = arrays.length;

  // Transpose the array of arrays
  const transposed = [];
  for (let col = 0; col < numCols; col++) {
    const column = [];
    for (let row = 0; row < numRows; row++) {
      column.push(arrays[row][col]);
    }
    transposed.push(column);
  }

  const isEmpty = (value: string) => /^\s*$/.test(value);

  // Filter out columns that are all empty strings
  const filteredTransposed = transposed.filter(
    (column) => !column.every(isEmpty)
  );

  // Transpose back to original form
  const result = [];
  for (let row = 0; row < numRows; row++) {
    const newRow = [];
    for (let col = 0; col < filteredTransposed.length; col++) {
      newRow.push(filteredTransposed[col][row]);
    }
    result.push(newRow);
  }

  return result;
};
