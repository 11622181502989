import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { useTheme } from "../../hooks/useTheme";
import CustomTextField from "../../components/CustomTextField";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { useCallback, useContext, useRef, useState } from "react";
import {
  MEDIA_UPLOAD_CODES,
  errorAlertText,
  errorSubAlertText,
  uploadMedia,
} from "../../utils/photoUtils";
import { AccountData } from "@markit/common.types";
import { isValidUserName } from "../../utils/stringUtils";
import {
  getUserDataByUsername,
  getUsernameSnap,
} from "../../utils/FirebaseUtils";
import { useSelector } from "react-redux";
import {
  changeBio,
  changeFullName,
  changeInstagram,
  changeLinkedin,
  changeProfilePicURL,
  changeSpotify,
  changeTiktok,
  changeTwitter,
  changeUsername,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { useDispatch } from "react-redux";
import {
  INSTAGRAM_DEFAULT,
  LINKEDIN_DEFAULT,
  SPOTIFY_DEFAULT,
  TIKTOK_DEFAULT,
  X_DEFAULT,
  checkValidSocialMediaLink,
  detectedCensored,
} from "@markit/common.utils";
import { MixpanelContext } from "../../context/AnalyticsService";
import AlertContainer from "../../components/Containers/AlertContainer";
import { useOnMount } from "../../utils/useOnMount";

type LoginOnboardingProfileProps = {
  accountSettings: AccountData;
  updateAccountSettings: (accountSettings: Partial<AccountData>) => void;
  image: string;
  setImage: (image: string) => void;
  loading: boolean;
  onFinishSetUp: () => void;
};

const LoginOnboardingProfile = (props: LoginOnboardingProfileProps) => {
  const {
    accountSettings,
    updateAccountSettings,
    image,
    setImage,
    loading,
    onFinishSetUp,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const dispatch = useDispatch();
  const mixpanel = useContext(MixpanelContext);
  const { theme } = useTheme();
  const [showFullNameError, setShowFullNameError] = useState({
    status: false,
    message: "",
  });
  const [showUsernameError, setShowUsernameError] = useState(false);
  const [showProfilePicError, setShowProfilePicError] = useState(false);
  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const styles = {
    labelView: { marginBottom: 7 },
    labelText: { fontSize: 12, fontWeight: "600", color: Colors.BLACK },
    labelTagText: { fontSize: 12, fontWeight: "600", color: Colors.GRAY2 },
    labelTagTextError: {
      fontSize: 12,
      fontWeight: "600",
      color: theme.ErrorBorderColor.borderColor,
    },
  };

  useOnMount(() => {
    // Default the username to empty since it starts as the random generated
    updateAccountSettings({ username: "" });
  });

  const updateAccountState = useCallback(
    (update: Partial<AccountData>) => {
      if (update.fullName !== undefined) {
        updateAccountSettings({ fullName: update.fullName });
        if (detectedCensored(update.fullName)) {
          setShowFullNameError({
            status: true,
            message: "Content Violation: Banned word(s) detected",
          });
          return;
        } else {
          setShowFullNameError({ status: false, message: "" });
        }
      }
      if (update.username !== undefined) {
        setShowUsernameError(false);
        updateAccountSettings({ username: update.username });
      }
    },
    [updateAccountSettings]
  );

  const finishSetupOnPress = useCallback(async () => {
    // check all inputs first
    const usernameLower = accountSettings.username.toLowerCase();

    if (image === "" && accountData.inCreatorMode) {
      setAlertText({
        heading: "You must choose a profile picture",
        subHeading: "",
      });
      setShowProfilePicError(true);
      return;
    }
    if (accountSettings.fullName === "") {
      setShowFullNameError({
        status: true,
        message: "Full Name cannot be empty.",
      });
      return;
    }
    if (usernameLower === "") {
      setShowUsernameError(true);
      return;
    }
    if (!isValidUserName(usernameLower)) {
      setAlertText({
        heading:
          "*Username can only consist of alphanumeric characters, 3-30 characters in length, no spaces",
        subHeading: "",
      });
      setShowUsernameError(true);
      return;
    }
    const usernameSnap = await getUsernameSnap(usernameLower);
    if (usernameSnap.exists()) {
      const usernameData = usernameSnap.data();
      const existingUserData = await getUserDataByUsername(
        usernameData.username
      );
      if (existingUserData && existingUserData.uid !== accountData.uid) {
        setAlertText({
          heading: "Username already exists.",
          subHeading: "Try another.",
        });
        setShowUsernameError(true);
        return;
      }
    }

    // check socials
    if (accountSettings.instagram !== "") {
      const valid = checkValidSocialMediaLink(
        "instagram",
        accountSettings.instagram
      );
      if (!valid) {
        setAlertText({ heading: "Invalid instagram url", subHeading: "" });
        return;
      } else if (accountSettings.instagram.length > 200) {
        setAlertText({
          heading: "Instagram is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.linkedin !== "") {
      const valid = checkValidSocialMediaLink(
        "linkedin",
        accountSettings.linkedin
      );
      if (!valid) {
        setAlertText({ heading: "Invalid linkedin url", subHeading: "" });
        return;
      } else if (accountSettings.linkedin.length > 200) {
        setAlertText({
          heading: "Linkedin is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.twitter !== "") {
      const valid = checkValidSocialMediaLink(
        "twitter",
        accountSettings.twitter
      );
      if (!valid) {
        setAlertText({ heading: "Invalid twitter url", subHeading: "" });
        return;
      } else if (accountSettings.twitter.length > 200) {
        setAlertText({
          heading: "Twitter is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.tiktok !== "") {
      const valid = checkValidSocialMediaLink("tiktok", accountSettings.tiktok);
      if (!valid) {
        setAlertText({ heading: "Invalid tiktok url", subHeading: "" });
        return;
      } else if (accountSettings.tiktok.length > 200) {
        setAlertText({
          heading: "Tiktok is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    if (accountSettings.spotify !== "") {
      const valid = checkValidSocialMediaLink(
        "spotify",
        accountSettings.spotify
      );
      if (!valid) {
        setAlertText({ heading: "Invalid spotify url", subHeading: "" });
        return;
      } else if (accountSettings.spotify.length > 200) {
        setAlertText({
          heading: "Spotify is limited to 200 characters",
          subHeading: "",
        });
        return;
      }
    }

    // execute all the set changes
    dispatch(
      changeUsername(
        accountData.uid,
        accountData.username,
        usernameLower.trim()
      )
    );
    dispatch(changeFullName(accountData.uid, accountSettings.fullName.trim()));
    if (image) {
      dispatch(changeProfilePicURL(image, accountData.uid));
    }
    // set bio
    if (accountSettings.bio !== "") {
      dispatch(changeBio(accountData.uid, accountSettings.bio.trim()));
    }

    // Set socials
    if (accountSettings.instagram !== "") {
      dispatch(
        changeInstagram(accountData.uid, accountSettings.instagram.trim())
      );
      mixpanel.track("Set Instagram Account Details", {
        instagram: accountSettings.instagram,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.linkedin !== "") {
      dispatch(
        changeLinkedin(accountData.uid, accountSettings.linkedin.trim())
      );
      mixpanel.track("Set LinkedIn Account Details", {
        linkedin: accountSettings.linkedin,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.twitter !== "") {
      dispatch(changeTwitter(accountData.uid, accountSettings.twitter.trim()));
      mixpanel.track("Set Twitter Account Details", {
        twitter: accountSettings.twitter,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.tiktok !== "") {
      dispatch(changeTiktok(accountData.uid, accountSettings.tiktok.trim()));
      mixpanel.track("Set TikTok Account Details", {
        tiktok: accountSettings.tiktok,
        type: "sign-up",
        from: "webapp",
      });
    }

    if (accountSettings.spotify !== "") {
      dispatch(changeSpotify(accountData.uid, accountSettings.spotify.trim()));
      mixpanel.track("Set Spotify Account Details", {
        spotify: accountSettings.spotify,
        type: "sign-up",
        from: "webapp",
      });
    }

    mixpanel.track("Webapp: Entered Account Details", {
      distinct_id: accountData.uid,
      fullName: accountSettings.fullName,
      username: usernameLower,
    });
    mixpanel.identify(accountData.uid);
    mixpanel.people.set({
      $name: accountSettings.fullName,
    });

    onFinishSetUp();
  }, [
    accountSettings.username,
    accountSettings.fullName,
    accountSettings.instagram,
    accountSettings.linkedin,
    accountSettings.twitter,
    accountSettings.tiktok,
    accountSettings.spotify,
    accountSettings.bio,
    accountData.inCreatorMode,
    image,
    dispatch,
    accountData.uid,
    accountData.username,
    mixpanel,
    onFinishSetUp,
  ]);

  const uploadImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      return new Promise<void>(async (resolve, reject) => {
        const uploadData = await uploadMedia(event, false, true);
        if (uploadData.uploadCode !== MEDIA_UPLOAD_CODES.SUCCESS) {
          setAlertText({
            heading: errorAlertText(uploadData.uploadCode, true),
            subHeading: errorSubAlertText(uploadData.uploadCode),
          });

          reject();
        } else {
          setImage(uploadData.blobUrl);
          setShowProfilePicError(false);
          resolve();
        }
      });
    },
    [setImage]
  );

  return (
    <>
      <div className="ColumnNormal">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Icon
            icon="ion:person-circle-sharp"
            height={35}
            style={{
              color: Colors.BLACK,
              marginBottom: 14,
            }}
          />
          <span
            className="LoginHeaderText"
            style={{
              color: Colors.BLACK,
            }}
          >
            Set up your profile
          </span>
          <div style={{ paddingTop: 7 }}>
            <span className="LoginHeaderTextDescription">
              This helps us tailor your Markit experience to fit you.
            </span>
          </div>
          <hr />
          <div style={styles.labelView}>
            <span style={styles.labelText}>Add a Profile Photo</span>
            {accountData.inCreatorMode ? (
              <span
                style={
                  showProfilePicError
                    ? styles.labelTagTextError
                    : styles.labelTagText
                }
              >
                {" (required)"}
              </span>
            ) : null}
          </div>
          <div
            style={{
              backgroundColor: Colors.WHITE,
              width: 80,
              height: 80,
              borderRadius: 80 / 2,
              borderStyle: "dashed",
              borderColor: showProfilePicError
                ? theme.ErrorBorderColor.borderColor
                : Colors.GRAY2,
              borderWidth: image ? 0 : 1,
              marginBottom: 16,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <label
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: 80,
                height: 80,
                borderRadius: 80 / 2,
              }}
            >
              <input
                type="file"
                accept="image/x-png, image/jpeg"
                onChange={uploadImage}
                style={{
                  color: "transparent",
                  width: 80,
                  height: 80,
                  borderRadius: 80 / 2,
                }}
              />
              {image ? (
                <img
                  style={{
                    width: 80,
                    height: 80,
                    borderRadius: 80 / 2,
                    objectFit: "cover",
                  }}
                  src={image}
                  alt={"profilePicImage"}
                />
              ) : (
                <Icon
                  icon="ion:add"
                  height={42}
                  style={{
                    color: Colors.BLACK,
                    paddingTop: 20,
                  }}
                />
              )}
            </label>
          </div>
          <div style={styles.labelView}>
            <span style={styles.labelText}>Full Name</span>
            <span
              style={
                showFullNameError.status
                  ? styles.labelTagTextError
                  : styles.labelTagText
              }
            >
              {" (required)"}
            </span>
          </div>
          <div className="ColumnNormal" style={{ marginBottom: 16, gap: 4 }}>
            <CustomTextField
              value={accountSettings.fullName}
              placeholder="full name"
              disabled={loading}
              borderRadius={12}
              inputMode="text"
              error={showFullNameError.status}
              onChange={(change: any) => {
                updateAccountState({ fullName: change.target.value });
              }}
            />
            {showFullNameError.message !== "" ? (
              <span style={{ fontSize: 12, color: Colors.RED2 }}>
                {showFullNameError.message}
              </span>
            ) : null}
          </div>
          <div style={styles.labelView}>
            <span style={styles.labelText}>Username</span>
            <span
              style={
                showUsernameError
                  ? styles.labelTagTextError
                  : styles.labelTagText
              }
            >
              {" (required)"}
            </span>
          </div>
          <div style={{ marginBottom: 16 }}>
            <CustomTextField
              value={accountSettings.username}
              placeholder="username"
              disabled={loading}
              borderRadius={12}
              inputMode="text"
              error={showUsernameError}
              onChange={(change: any) => {
                updateAccountState({ username: change.target.value });
              }}
            />
          </div>
          {accountData.inCreatorMode ? (
            <>
              <div style={styles.labelView}>
                <span style={styles.labelText}>Bio</span>
                <span style={styles.labelTagText}>{" (optional)"}</span>
              </div>
              <div style={{ marginBottom: 16 }}>
                <CustomTextField
                  value={accountSettings.bio}
                  placeholder="bio"
                  disabled={loading}
                  borderRadius={12}
                  inputMode="text"
                  multiline
                  minRows={3}
                  maxRows={3}
                  onChange={(change: any) => {
                    updateAccountSettings({ bio: change.target.value });
                  }}
                />
              </div>
            </>
          ) : null}
          <div style={styles.labelView}>
            <span style={styles.labelText}>Link Socials</span>
            <span style={styles.labelTagText}>{" (optional)"}</span>
          </div>
          <CustomTextField
            value={accountSettings.instagram}
            placeholder={INSTAGRAM_DEFAULT}
            borderRadius={12}
            rectangleBottom
            noBottomBorder
            inputMode="text"
            onChange={(change: any) => {
              updateAccountSettings({ instagram: change.target.value });
            }}
            startAdornment={
              <Icon
                icon="mdi:instagram"
                height={22}
                color={
                  accountSettings.instagram === "" ? Colors.GRAY2 : Colors.BLACK
                }
                style={{ marginLeft: 14 }}
              />
            }
          />
          <CustomTextField
            value={accountSettings.linkedin}
            placeholder={LINKEDIN_DEFAULT}
            borderRadius={12}
            rectangleTop
            rectangleBottom
            noBottomBorder
            inputMode="text"
            onChange={(change: any) => {
              updateAccountSettings({ linkedin: change.target.value });
            }}
            startAdornment={
              <Icon
                icon="ri:linkedin-fill"
                height={22}
                color={
                  accountSettings.linkedin === "" ? Colors.GRAY2 : Colors.BLACK
                }
                style={{ marginLeft: 14 }}
              />
            }
          />
          <CustomTextField
            value={accountSettings.twitter}
            placeholder={X_DEFAULT}
            borderRadius={12}
            rectangleTop
            rectangleBottom
            noBottomBorder
            inputMode="text"
            onChange={(change: any) => {
              updateAccountSettings({ twitter: change.target.value });
            }}
            startAdornment={
              <Icon
                icon="ri:twitter-x-fill"
                height={22}
                color={
                  accountSettings.twitter === "" ? Colors.GRAY2 : Colors.BLACK
                }
                style={{ marginLeft: 14 }}
              />
            }
          />
          <CustomTextField
            value={accountSettings.tiktok}
            placeholder={TIKTOK_DEFAULT}
            inputMode="text"
            borderRadius={12}
            rectangleTop
            rectangleBottom
            noBottomBorder
            onChange={(change: any) => {
              updateAccountSettings({ tiktok: change.target.value });
            }}
            startAdornment={
              <Icon
                icon="ic:baseline-tiktok"
                height={22}
                color={
                  accountSettings.tiktok === "" ? Colors.GRAY2 : Colors.BLACK
                }
                style={{ marginLeft: 14 }}
              />
            }
          />
          <CustomTextField
            value={accountSettings.spotify}
            placeholder={SPOTIFY_DEFAULT}
            inputMode="text"
            borderRadius={12}
            rectangleTop
            onChange={(change: any) => {
              updateAccountSettings({ spotify: change.target.value });
            }}
            startAdornment={
              <Icon
                icon="mdi:spotify"
                height={22}
                color={
                  accountSettings.spotify === "" ? Colors.GRAY2 : Colors.BLACK
                }
                style={{ marginLeft: 14 }}
              />
            }
          />
          <div style={{ marginTop: 16 }}>
            <RectangleButton
              buttonLabel={<span>Finish set up</span>}
              onPress={finishSetupOnPress}
              theme={theme}
              altPaddingVert={12}
              disabled={loading || showFullNameError.status}
              loading={loading}
            />
          </div>
        </div>
      </div>
      <AlertContainer
        headerComp={alertText.heading}
        subHeaderComp={
          alertText.subHeading !== "" ? alertText.subHeading : undefined
        }
        theme={theme}
        closeModal={() => {
          setAlertText({ heading: "", subHeading: "" });
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        }}
        hideModal={alertText.heading === "" && alertText.subHeading === ""}
      />
    </>
  );
};

export default LoginOnboardingProfile;
