import { useCallback, useState } from "react";
import { Colors } from "../../../utils/colors";
import {
  MARKIT_FREE_CREATOR_NUMBER,
  isValidPhoneNumber,
} from "@markit/common.utils";
import { API } from "../../../API";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import PopupModalContainer from "../../Containers/PopupModalContainer";
import RectangleButton from "../../Buttons/RectangleButton";
import { fromInternationalCode } from "../../../utils/stringUtils";
import { formatPhoneNumber } from "../../../utils/FormatPhoneNumber";
import AlertButtonWrapper from "../../Containers/AlertButtonWrapper";
import { generate } from "shortid";
import { saveMediaToFirestore } from "../../../utils/photoUtils";

type TestTextModalProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  message: string;
  image?: string;
  save?: boolean; // only save if it's an image not already saved in firebase
};

const TestTextModal = (props: TestTextModalProps) => {
  const { isVisible, setIsVisible, message, image, save } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [testPhoneNumber, setTestPhoneNumber] = useState("");

  const [alertText, setAlertText] = useState({ heading: "", subHeading: "" });
  const [sending, setSending] = useState(false);

  const styles = {
    subtext: {
      fontSize: 14,
      color: Colors.GRAY1,
    },
  };

  const receiveTestMessage = useCallback(
    async (phoneNumber: string, customMessage: string) => {
      let finalMessage: string = customMessage;

      let mediaUrl = "";
      setSending(true);
      if (image && save) {
        const url = await saveMediaToFirestore(
          image,
          accountData.uid + "/textMedia/tests/" + generate()
        );
        mediaUrl = url;
      } else if (image) {
        mediaUrl = image;
      }

      await API.text
        .sendTestMessage({
          userId: accountData.uid,
          message: finalMessage,
          mediaUrl: mediaUrl,
          fromPhoneNumber:
            accountData.customer.phone !== ""
              ? accountData.customer.phone
              : fromInternationalCode(MARKIT_FREE_CREATOR_NUMBER),
          toPhoneNumber: phoneNumber,
        })
        .then((response) => {
          if (response.success) {
            setAlertText({ heading: "Sent!", subHeading: "" });
            setSending(false);
          } else {
            setAlertText({
              heading: "An error occurred.",
              subHeading:
                "Please contact our hotline 617-213-0897 to diagnose the issue before attempting to send again.",
            });
            setSending(false);
          }
        });
    },
    [image, save, accountData.uid, accountData.customer.phone]
  );

  const showInputOrSend = useCallback(
    async (customMessage: string) => {
      if (!isValidPhoneNumber(formatPhoneNumber(testPhoneNumber))) {
        setAlertText({
          heading: "Invalid phone number.",
          subHeading: "Please try again.",
        });
      } else if (accountData.excludedPhone.includes(testPhoneNumber)) {
        setAlertText({
          heading: "The test phone number is opted out of texts.",
          subHeading:
            "Text START to " +
            accountData.customer.phone +
            " and send test message again.",
        });
      } else {
        receiveTestMessage(testPhoneNumber, customMessage);
      }
    },
    [
      accountData.customer.phone,
      accountData.excludedPhone,
      receiveTestMessage,
      testPhoneNumber,
    ]
  );

  return isVisible ? (
    <PopupModalContainer
      headerComp="Test Text"
      subHeaderComp=""
      valueComp={
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <div className="ColumnNormal" style={{ gap: 4 }}>
            <span className="AboutSubtitle">What is a test text?</span>
            <span style={styles.subtext}>
              A test text allows you to preview your text in your native
              operating system, so you can see how it will look to your
              attendees.
            </span>
          </div>
          <div className="ColumnNormal" style={{ gap: 4, marginTop: 7 }}>
            <span className="AboutSubtitle">Select Recipient</span>
            <span style={styles.subtext}>
              This text text will only be sent to one recipient. Enter the phone
              number you want this test text to be sent to below.
            </span>
          </div>
          <div className="MassTextsTestTextSearchContaienr">
            <div className="TextingPanelIconAndInput">
              <input
                className="TextingPanelInput"
                placeholder="Enter Phone Number"
                onChange={(e) => {
                  setTestPhoneNumber(e.target.value);
                }}
                value={testPhoneNumber}
              />
            </div>
          </div>
          <AlertButtonWrapper
            buttonComp={
              <RectangleButton
                buttonLabel="Send Test Text"
                onPress={() => {
                  showInputOrSend(message);
                }}
                altTextColor={Colors.WHITE}
                altColor={Colors.BLACK}
                loading={sending}
              />
            }
            alertTextHeader={alertText.heading}
            alertTextSubHeader={alertText.subHeading}
            clearAlert={() => {
              if (
                alertText.heading === "Sent!" &&
                alertText.subHeading === ""
              ) {
                setIsVisible(false);
              }
              setAlertText({ heading: "", subHeading: "" });
            }}
          />
        </div>
      }
      closeModal={() => {
        setIsVisible(false);
      }}
    />
  ) : null;
};

export default TestTextModal;
