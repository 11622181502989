import { useCallback, useEffect, useMemo, useState } from "react";
import LargePopupModalContainer from "../Containers/LargePopupModalContainer";
import "../../css/GlobalStyles.css";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import { FormInputType, SavedFormQuestion } from "@markit/common.types";
import { ThemeStyle } from "../../hooks/useTheme";
import FormBuilder from "./FormBuilder";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/store";
import { useDispatch } from "react-redux";
import {
  LoginState,
  accountActions,
  updateProfileQuestions,
} from "../../redux/slices/accountSlice";
import ConfirmDeleteModal from "../Containers/ConfirmPopups/ConfirmDeleteModal";
import CustomCheckbox from "../CustomCheckbox";
import {
  filterUndefinedValues,
  sortSavedQuestions,
} from "@markit/common.utils";
import SavedQuestionItem from "../DisplayItem/SavedQuestionItem";
import AlertContainer from "../Containers/AlertContainer";
import { AddNewToListButton } from "../Buttons/AddNewToListButton";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import filter from "lodash.filter";
import LargePopupModalHeader from "../Containers/LargePopupModalHeader";
import RectangleButton from "../Buttons/RectangleButton";
import { useOnMount } from "../../utils/useOnMount";
import { API } from "../../API";
import { CircularProgress } from "@mui/material";
import { BinaryConfirmActions } from "../Containers/ConfirmPopups/ConfirmActionPopup";

export enum FormSavedQuestionsType {
  EVENT = "Event",
  PROFILE = "Profile",
  ASSIGN = "Assign", // For spreadsheet assign columns, diff logic since can only select one question at a time
}

// assignOnPress, selectedAssignedQuestion, newQuestionOptions -> spreadsheet assign columns
type FormSavedQuestionsProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  type: FormSavedQuestionsType;
  assignOnPress?: (question: SavedFormQuestion | undefined) => boolean;
  selectedAssignedQuestion?: SavedFormQuestion;
  newQuestionOptions?: string[];
  setConfirmPopupFormQuestionItem?: (action: BinaryConfirmActions) => void;
  theme?: ThemeStyle;
};

const FormSavedQuestions = (props: FormSavedQuestionsProps) => {
  const {
    isVisible,
    setIsVisible,
    type,
    assignOnPress,
    selectedAssignedQuestion,
    newQuestionOptions,
    theme,
    setConfirmPopupFormQuestionItem,
  } = props;
  const { currentEventFormQuestions, savedQuestions, accountData, loggedIn } =
    useSelector((state: AppState) => state.account);
  const dispatch = useDispatch();
  const [newFormVisible, setNewFormVisible] = useState(false);
  const [confirmCloseVisible, setConfirmCloseVisible] = useState(false);
  const [selectedFormQuestions, setSelectedFormQuestions] = useState<
    SavedFormQuestion[]
  >([]);
  const [formQuestionItem, setFormQuestionItem] = useState<
    SavedFormQuestion | undefined
  >();
  const [savedQuestionsToShow, setSavedQuestionsToShow] = useState<
    SavedFormQuestion[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [alertText, setAlertText] = useState("");
  const [loadingDefaults, setLoadingDefaults] = useState(false);

  useOnMount(() => {
    (async () => {
      // generate email and phone number saved questions if not in user's saved form questions
      if (loggedIn === LoginState.LOGGED_IN) {
        const emailExists = savedQuestions.some(
          (question) => question.formInput.inputType === FormInputType.EMAIL
        );
        const phoneExists = savedQuestions.some(
          (question) => question.formInput.inputType === FormInputType.PHONE
        );
        if (!emailExists || !phoneExists) {
          setLoadingDefaults(true);
          const { presetFormQuestions } =
            await API.user.generatePresetSavedFormQuestions({
              uid: accountData.uid,
            });
          if (presetFormQuestions.length > 0) {
            dispatch(accountActions.addSavedFormQuestions(presetFormQuestions));
          }
          setLoadingDefaults(false);
        }
      }

      // Preset the selected questions if selectedAssignQuestion is defined
      if (
        type === FormSavedQuestionsType.ASSIGN &&
        selectedAssignedQuestion &&
        selectedAssignedQuestion.formInput.label
      ) {
        setSelectedFormQuestions(
          selectedFormQuestions.concat(selectedAssignedQuestion)
        );
      }
    })();
  });

  const profileFormQuestions = useMemo(() => {
    const formQuestions = accountData.formQuestions.map((questionId) =>
      savedQuestions.find((question) => question.id === questionId)
    );
    const formQuestionsDefined: SavedFormQuestion[] =
      filterUndefinedValues(formQuestions);
    return formQuestionsDefined;
  }, [accountData.formQuestions, savedQuestions]);

  const currentFormQuestions = useMemo(
    () =>
      type === FormSavedQuestionsType.PROFILE
        ? profileFormQuestions
        : currentEventFormQuestions,
    [currentEventFormQuestions, profileFormQuestions, type]
  );

  const resetOnPress = useCallback(() => {
    setIsVisible(false);
    setSelectedFormQuestions([]);
    setFormQuestionItem(undefined);
  }, [setIsVisible]);

  const closeOnPress = useCallback(() => {
    if (selectedFormQuestions.length > 0) {
      setConfirmCloseVisible(true);
    } else {
      resetOnPress();
    }
  }, [resetOnPress, selectedFormQuestions.length]);

  const addOnPress = useCallback(() => {
    if (type === FormSavedQuestionsType.PROFILE) {
      // updated the newly selected form questions onProfileFollow to be true
      const updatedSelectedFormQuestions = selectedFormQuestions.map(
        (question) => ({
          ...question,
          onProfileFollow: true,
        })
      );
      // append the new selected form questions to the existing ones and replace any duplicates with the newer selected ones
      let newFormQuestions = profileFormQuestions;
      for (let i = 0; i < updatedSelectedFormQuestions.length; i++) {
        const index = newFormQuestions.findIndex(
          (question) => question.id === updatedSelectedFormQuestions[i].id
        );
        if (index !== -1) {
          newFormQuestions.splice(index, 1, updatedSelectedFormQuestions[i]);
        } else {
          newFormQuestions = newFormQuestions.concat(
            updatedSelectedFormQuestions[i]
          );
        }
      }

      dispatch(updateProfileQuestions(accountData.uid, newFormQuestions));
      resetOnPress();
    } else if (assignOnPress && type === FormSavedQuestionsType.ASSIGN) {
      const validAssign = assignOnPress(
        selectedFormQuestions.length > 0 ? selectedFormQuestions[0] : undefined
      );
      if (!validAssign) {
        setAlertText(
          "This question has already been assigned to another column."
        );
        return;
      }
      resetOnPress();
    } else if (selectedFormQuestions.length > 0) {
      dispatch(
        accountActions.updateCurrentEventFormQuestions(selectedFormQuestions)
      );
      if (setConfirmPopupFormQuestionItem) {
        setConfirmPopupFormQuestionItem(BinaryConfirmActions.AFFIRMATIVE);
      }
      resetOnPress();
    } else {
      setAlertText("Please select a saved question to add first.");
    }
  }, [
    type,
    selectedFormQuestions,
    assignOnPress,
    profileFormQuestions,
    dispatch,
    accountData.uid,
    resetOnPress,
    setConfirmPopupFormQuestionItem,
  ]);

  // we don't use selectedFormQuestions as a dependency so that it doesn't re-order while you're pressing options
  useEffect(() => {
    const finalToShow = sortSavedQuestions(
      savedQuestions,
      currentFormQuestions,
      selectedFormQuestions
    );
    setSavedQuestionsToShow(finalToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFormQuestions, savedQuestions, newFormVisible, isVisible]);

  const contains = useCallback((question: SavedFormQuestion, query: string) => {
    const label = question.formInput.label ?? "";
    return label.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const savedQuestionsToDisplay = useMemo(() => {
    let questions: SavedFormQuestion[] = savedQuestionsToShow;
    if (searchTerm !== "") {
      questions = filter(questions, (question: SavedFormQuestion) => {
        return contains(question, searchTerm);
      });
    }
    return questions;
  }, [contains, savedQuestionsToShow, searchTerm]);

  const selectQuestionOnPress = useCallback(
    (isSelectedOption: boolean, question: SavedFormQuestion) => {
      if (isSelectedOption) {
        setSelectedFormQuestions(
          type === FormSavedQuestionsType.ASSIGN
            ? [question]
            : selectedFormQuestions.filter((q) => q.id !== question.id)
        );
      } else {
        setSelectedFormQuestions(
          type === FormSavedQuestionsType.ASSIGN &&
            selectedFormQuestions.length > 0
            ? [question]
            : selectedFormQuestions.concat(question)
        );
      }
    },
    [selectedFormQuestions, type]
  );

  const newQuestionOnPress = useCallback(() => {
    setNewFormVisible(true);
  }, []);

  return (
    <>
      <LargePopupModalContainer
        showModal={isVisible}
        headerComp={
          <LargePopupModalHeader
            backOnPress={closeOnPress}
            altHeaderLeft={
              type === FormSavedQuestionsType.PROFILE
                ? "mdi:chevron-left"
                : "mdi:close"
            }
            headerRight={
              <RectangleButton
                buttonLabel={
                  type === FormSavedQuestionsType.ASSIGN &&
                  !selectedAssignedQuestion
                    ? "Assign"
                    : "Save"
                }
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                disabled={
                  selectedFormQuestions.length === 0 &&
                  !selectedAssignedQuestion
                }
                onPress={addOnPress}
                altPaddingHorz={14}
                altPaddingVert={10}
                altBorderRadius={100}
              />
            }
          />
        }
        valueComp={
          <div
            className="HideScrollbar"
            style={{ overflowY: "scroll", height: "calc(100vh - 120px)" }}
          >
            <div className="ColumnNormal" style={{ gap: 7 }}>
              <span
                style={{ fontSize: 18, fontWeight: 500, ...theme?.PrimaryText }}
              >
                {type === FormSavedQuestionsType.ASSIGN
                  ? "Assign Column"
                  : "Saved Questions"}
              </span>
              {type === FormSavedQuestionsType.ASSIGN ? (
                <span style={{ fontSize: 14, color: Colors.GRAY1 }}>
                  Assign this column to an existing question field or create a
                  new one.
                </span>
              ) : null}
            </div>
            <SearchBoxContainer
              placeholder="Search Questions..."
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginBlock: 20 }}
            />
            {!loadingDefaults ? (
              <div className="ColumnNormal">
                {savedQuestionsToDisplay.map((question) => {
                  const currentlySelected = currentFormQuestions.some(
                    (q) => q.id === question.id
                  );
                  const isSelectedOption =
                    selectedFormQuestions.some((q) => q.id === question.id) ||
                    currentlySelected;
                  return (
                    <div>
                      <SavedQuestionItem
                        item={question}
                        onItemPress={() =>
                          selectQuestionOnPress(isSelectedOption, question)
                        }
                        rightIcon={
                          <div className="AlignedRow" style={{ gap: 5 }}>
                            <CustomCheckbox
                              checked={isSelectedOption}
                              onChange={() =>
                                selectQuestionOnPress(
                                  isSelectedOption,
                                  question
                                )
                              }
                              sx={{
                                padding: 0,
                                pointerEvents: currentlySelected
                                  ? "none"
                                  : "all",
                                opacity: currentlySelected ? 0.4 : 1,
                              }}
                            />
                          </div>
                        }
                        disabled={currentlySelected}
                        theme={theme}
                        editOnPress={() => {
                          setNewFormVisible(true);
                          setFormQuestionItem(question);
                        }}
                      />
                      <hr style={{ ...theme?.DividerColor }} />
                    </div>
                  );
                })}
                <AddNewToListButton
                  label="New Question"
                  onPress={newQuestionOnPress}
                />
              </div>
            ) : (
              <div className="Centering">
                <CircularProgress style={{ color: Colors.GRAY1 }} size={24} />
              </div>
            )}
          </div>
        }
        theme={theme}
      />
      {newFormVisible ? (
        <FormBuilder
          type={type}
          selectedQuestions={selectedFormQuestions}
          setSelectedQuestions={(questions: SavedFormQuestion[]) =>
            type === FormSavedQuestionsType.ASSIGN
              ? selectQuestionOnPress(
                  formQuestionItem?.id === questions[0].id,
                  questions[0]
                )
              : setSelectedFormQuestions(questions)
          }
          setIsFormsModalOpen={setNewFormVisible}
          formQuestionItem={formQuestionItem}
          setFormQuestionItem={setFormQuestionItem}
          newQuestionOptions={newQuestionOptions}
          theme={theme}
        />
      ) : null}
      <ConfirmDeleteModal
        heading="Are you sure you want to exit?"
        subtext="Your selections won't be saved. Are you sure you want to exit?"
        deleteButtonText="Exit"
        hideModal={!(confirmCloseVisible && selectedFormQuestions.length > 0)}
        setIsVisible={setConfirmCloseVisible}
        deleteOnPress={() => {
          setConfirmCloseVisible(false);
          resetOnPress();
        }}
        icon={<Icon icon="ion:exit-outline" height={40} />}
        theme={theme}
      />
      <AlertContainer
        headerComp={alertText}
        theme={theme}
        closeModal={() => setAlertText("")}
        hideModal={alertText === ""}
      />
    </>
  );
};

export default FormSavedQuestions;
