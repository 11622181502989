import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../../redux/slices/accountSlice";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import LargePopupModalContainer from "../../../Containers/LargePopupModalContainer";
import { BackButton } from "../../../Buttons/BackButton";
import { Colors } from "../../../../utils/colors";
import { useOnMount } from "../../../../utils/useOnMount";
import { getSpreadsheetsSnap } from "../../../../utils/FirebaseUtils";
import { Spreadsheet } from "@markit/common.types";
import { CircularProgress } from "@mui/material";
import { GetDateWithTime } from "@markit/common.utils";

type ImportHistoryProps = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

const ImportHistory = (props: ImportHistoryProps) => {
  const { isVisible, setIsVisible } = props;
  const { accountData } = useSelector(getAccountState).account;
  const [spreadsheets, setSpreadsheets] = useState<Spreadsheet[]>([]);
  const [loading, setLoading] = useState(true);

  const styles = {
    subtext: { fontSize: 12, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
    headerPosition: {
      right: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      zIndex: -1,
    },
  };

  useOnMount(() => {
    (async () => {
      const spreadsheetsSnaps = await getSpreadsheetsSnap(accountData.uid);
      if (spreadsheetsSnaps.docs.length > 0) {
        const spreadsheetsData = await Promise.all(
          spreadsheetsSnaps.docs.map((doc) => {
            return doc.data();
          })
        );
        setSpreadsheets(spreadsheetsData);
      }
      setLoading(false);
    })();
  });

  return (
    <LargePopupModalContainer
      showModal={isVisible}
      headerComp={
        <div className="AlignedRowSpaced">
          <BackButton
            iconName={"mdi:close"}
            onPress={() => setIsVisible(false)}
          />
          <div style={{ ...styles.headerPosition, position: "absolute" }}>
            <span style={styles.bodyMedium}>Upload History</span>
          </div>
        </div>
      }
      valueComp={
        <div
          className="HideScrollbar"
          style={{
            height: `calc(100vh - 110px)`,
            overflow: "scroll",
            paddingTop: 7,
          }}
        >
          {!loading ? (
            spreadsheets.map((spreadsheet) => (
              <div className="ColumnNormal" style={{ gap: 4 }}>
                <span style={styles.bodyMedium}>{spreadsheet.fileName}</span>
                <span style={styles.subtext}>
                  Upload Date: {GetDateWithTime(spreadsheet.createdAt, true)}
                </span>
                <HorizontalDivider altMargin={16} />
              </div>
            ))
          ) : (
            <div className="Centering">
              <CircularProgress style={{ color: Colors.GRAY1 }} size={18} />
            </div>
          )}
        </div>
      }
    />
  );
};

export default ImportHistory;
