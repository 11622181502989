import "../../css/Subscription.css";
import "../../css/GlobalStyles.css";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { Colors } from "../../utils/colors";
import {
  OVERFLOW_RATE,
  OVERFLOW_RATE_BUSINESS,
  PlanTypeV4,
  SubscriptionPlanType,
} from "@markit/common.types";
import { convertToMoney } from "@markit/common.utils";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import { ReactNode, useMemo } from "react";

type SubscriptionPlanCardProps = {
  cardButton: ReactNode;
  subscriptionPlan: SubscriptionPlanType;
};

const SubscriptionPlanCard = (props: SubscriptionPlanCardProps) => {
  const { cardButton, subscriptionPlan } = props;

  const styles = {
    bodyMediumBold: {
      fontWeight: 500,
      fontSize: 14,
    },
    subtext: {
      fontSize: 12,
      fontWeight: 500,
      color: Colors.GRAY2,
    },
  };

  const previousSubscriptionOption = useMemo(
    () =>
      subscriptionPlan.name === PlanTypeV4.BUSINESS.name
        ? PlanTypeV4.PROFESSIONAL
        : subscriptionPlan.name === PlanTypeV4.PROFESSIONAL.name
        ? PlanTypeV4.ESSENTIALS
        : PlanTypeV4.FREE,
    [subscriptionPlan.name]
  );

  const starterSubscriptionCard = (
    <div className="ColumnNormal" style={{ gap: 14 }}>
      <div className="ColumnNormal" style={{ gap: 4 }}>
        <span style={styles.bodyMediumBold}>
          {PlanTypeV4.FREE.baselineLimit}{" "}
          <span style={styles.subtext}>free text credits/mo</span>
        </span>
        <span style={styles.subtext}>(No overflow)</span>
      </div>
      <span style={styles.bodyMediumBold}>Scheduled texting</span>
      <span style={styles.bodyMediumBold}>Personalization plugins</span>
      <span style={styles.bodyMediumBold}>Text open/conversion tracking</span>
      <span style={styles.bodyMediumBold}>3 Audience lists</span>
      <span style={styles.bodyMediumBold}>3 Link trackers</span>
      <HorizontalDivider altMargin={10} />
      <span style={styles.subtext}>Growth Features</span>
      <span style={styles.bodyMediumBold}>Import contacts</span>
      <span style={styles.bodyMediumBold}>Universal data capture links</span>
      <span style={styles.bodyMediumBold}>Link in bio builder</span>
      <span style={styles.bodyMediumBold}>QR code generation</span>
      <span style={styles.bodyMediumBold}>Unlimited event creation</span>
      <span style={styles.bodyMediumBold}>Paid tickets & promo codes</span>
      <span style={styles.bodyMediumBold}>
        Apple, Google, Cashapp Pay integration
      </span>
    </div>
  );

  const essentialsSubscriptionCard = (
    <div className="ColumnNormal" style={{ gap: 14 }}>
      <div className="ColumnNormal" style={{ gap: 4 }}>
        <span style={styles.bodyMediumBold}>
          {PlanTypeV4.ESSENTIALS.baselineLimit}{" "}
          <span style={styles.subtext}>free text credits/mo</span>
        </span>
        <span style={styles.subtext}>({OVERFLOW_RATE} cents per credit)</span>
      </div>
      <span style={styles.bodyMediumBold}>Two-way audience texting</span>
      <span style={styles.bodyMediumBold}>Dedicated phone number</span>
      <span style={styles.bodyMediumBold}>Branded contact card</span>
    </div>
  );

  const professionalSubscriptionCard = (
    <div className="ColumnNormal" style={{ gap: 14 }}>
      <div className="ColumnNormal" style={{ gap: 4 }}>
        <span style={styles.bodyMediumBold}>
          {PlanTypeV4.PROFESSIONAL.baselineLimit}{" "}
          <span style={styles.subtext}>free text credits/mo</span>
        </span>
        <span style={styles.subtext}>({OVERFLOW_RATE} cents per credit)</span>
      </div>
      <span style={styles.bodyMediumBold}>Unlimited Audience lists</span>
      <span style={styles.bodyMediumBold}>Unlimited link trackers</span>
      <HorizontalDivider altMargin={10} />
      <span style={styles.subtext}>Integrations</span>
      <span style={styles.bodyMediumBold}>Import external data</span>
      <span style={styles.bodyMediumBold}>Export data to CRM</span>
      <HorizontalDivider altMargin={10} />
      <span style={styles.subtext}>Verification</span>
      <span style={styles.bodyMediumBold}>Verified creator check</span>
    </div>
  );

  const businessSubscriptionCard = (
    <div className="ColumnNormal" style={{ gap: 14 }}>
      <div className="ColumnNormal" style={{ gap: 4 }}>
        <span style={styles.bodyMediumBold}>
          {PlanTypeV4.BUSINESS.baselineLimit}{" "}
          <span style={styles.subtext}>free text credits/mo</span>
        </span>
        <span style={styles.subtext}>
          ({OVERFLOW_RATE_BUSINESS} cent per credit)
        </span>
      </div>
      <HorizontalDivider altMargin={10} />
      <span style={styles.subtext}>Support</span>
      <span style={styles.bodyMediumBold}>Dedicated customer support</span>
    </div>
  );

  return (
    <StandardBorderedContainer
      containerStyles={{
        minHeight: 710,
        padding: 14,
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundColor: Colors.WHITE,
      }}
    >
      {/* {referral ? (
        <div
          className="AlignedRow"
          style={{
            marginBottom: 15,
            backgroundColor: Colors.GRAY6,
            borderRadius: 5,
            padding: 10,
          }}
        >
          <Icon
            icon={"ion:checkmark-circle"}
            height={18}
            color={Colors.GREEN2}
          />
          <span style={{ fontSize: 12 }}>
            This plan qualifies for $50 referral bonus
          </span>
        </div>
      ) : null} */}
      <div className="ColumnNormal">
        <div className="ColumnCentering" style={{ gap: 14 }}>
          <div
            style={{
              paddingInline: 7,
              paddingBlock: 4,
              borderRadius: 8,
              backgroundColor: Colors.GRAY6,
            }}
          >
            <span style={styles.bodyMediumBold}>{subscriptionPlan.name}</span>
          </div>
          {subscriptionPlan.price > 0 ? (
            <span style={{ fontSize: 24, fontWeight: 500 }}>
              {convertToMoney(subscriptionPlan.price / 100).slice(0, -3)}
              <span style={styles.subtext}> /mo</span>
            </span>
          ) : (
            <span style={{ fontSize: 24, fontWeight: 500 }}>$0</span>
          )}
          <span style={{ fontSize: 12, fontWeight: 500, textAlign: "center" }}>
            {subscriptionPlan.name === PlanTypeV4.FREE.name
              ? "Send limited texts from a shared number"
              : subscriptionPlan.name === PlanTypeV4.ESSENTIALS.name
              ? "Two way texting & personal phone number"
              : subscriptionPlan.name === PlanTypeV4.PROFESSIONAL.name
              ? "Unlimited feature access"
              : "Discount bulk texting & dedicated customer support"}
          </span>
        </div>
        <HorizontalDivider altMargin={20} />
        {previousSubscriptionOption && subscriptionPlan.price > 0 ? (
          <>
            <span style={styles.bodyMediumBold}>
              All features in {previousSubscriptionOption.name} plus:
            </span>
            <HorizontalDivider altMargin={20} />
          </>
        ) : null}
        <div className="ColumnNormal" style={{ gap: 14 }}>
          <span style={styles.subtext}>Marketing & Texting</span>
          {subscriptionPlan.name === PlanTypeV4.FREE.name
            ? starterSubscriptionCard
            : subscriptionPlan.name === PlanTypeV4.ESSENTIALS.name
            ? essentialsSubscriptionCard
            : subscriptionPlan.name === PlanTypeV4.PROFESSIONAL.name
            ? professionalSubscriptionCard
            : businessSubscriptionCard}
        </div>
      </div>
      <div className="ColumnNormal">
        <HorizontalDivider altMargin={10} />
        {cardButton}
      </div>
    </StandardBorderedContainer>
  );
};

export default SubscriptionPlanCard;
