import { useMemo, useState } from "react";
import {
  AccountData,
  Event,
  MassText,
  SubSelectionItem,
} from "@markit/common.types";
import MassTextsSelectRecipients from "./SelectRecipientsFlow/SelectRecipientsPanel";
import {
  isSubscriptionPaymentFailed,
  roundTimeInterval,
} from "@markit/common.utils";
import LargePopupModalContainer from "../../Containers/LargePopupModalContainer";
import "../../../css/CreatorDashboardMassTextsPanel.css";
import { useMassTextManagement } from "../../../hooks/useMassTextManagement";
import MassTextsMessaging from "./MassTextsMessaging";
import { useOnMount } from "../../../utils/useOnMount";
import { getTotalAttendeeTickets } from "../../../utils/eventUtils/eventUtils";
import {
  getMassTextRecipients,
  massTextDefaultMessage,
} from "../../../utils/textingUtils";
import PaymentFailedButtonModal from "../../DisplayModal/UpdatePaymentInfoModal";
import { useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import FullProfilePreviewModal from "../../FollowerProfile/FullProfilePreviewModal";
import { TextPopupConfirmActions } from "../../../screens/Profile/CreatorPanels/TextingPanel";

export enum MassTextScreenType {
  SELECT_RECIPIENTS = "Select Recipients",
  OVERVIEW = "Overview",
}

export enum MassTextMessagingScreenType {
  SUMMARY = "Mass Text Summary",
  SEND_SUMMARY = "Mass Text Send Summary",
  SEND_SCHEDULE = "Mass Text Send Schedule",
  RECIPIENTS_SEND_SUMMARY = "Recipients Send Summary",
  LINK_ANALYTICS = "Link Analytics",
}

export type MassTextsSharedProps = {
  massTextSettings: MassText;
  updateMassTextSettings: (massTextSettings: Partial<MassText>) => void;
  setMainScreen: (mainScreen: MassTextScreenType) => void;
  setProfileSelected: (profileSelected: AccountData) => void;
};

type MassTextsPopupPanelProps = {
  isVisible: boolean;
  setIsVisible: (massTextsPopupPanelVisible: boolean) => void;
  editingMassText?: MassText | undefined;
  setEditingMassText?: (editingMassText: MassText | undefined) => void;
  event?: Event;
  suggestedAction?: SubSelectionItem;
  defaultScheduled?: boolean; // for event texts scheduled default

  // for text send/schedule success/fail/delete popup
  setConfirmPopupTextConfirmation?: (
    confirmType: TextPopupConfirmActions
  ) => void;
};

const MassTextsPopupPanel = (props: MassTextsPopupPanelProps) => {
  const {
    isVisible,
    setIsVisible,
    editingMassText,
    setEditingMassText,
    event,
    suggestedAction,
    defaultScheduled,
    setConfirmPopupTextConfirmation,
  } = props;
  const { accountData } = useSelector(getAccountState).account;
  const { massTextSettings, updateMassTextSettings, resetMassText } =
    useMassTextManagement({
      massText: editingMassText,
      isEditing: editingMassText !== undefined,
      eventId: event ? event.id : "",
    });

  const [screenType, setScreenType] = useState<MassTextScreenType>(
    MassTextScreenType.OVERVIEW
  );
  const [numEventAttendees, setNumEventAttendees] = useState(0);
  const [textSuggestedAction, setTextSuggestedAction] = useState<
    SubSelectionItem | undefined
  >(!editingMassText ? suggestedAction : undefined);

  // keep track of currently selected recipients
  const [allSelectedPeople, setAllSelectedPeople] = useState<string[]>([]);
  const [allExcludedPeople, setAllExcludedPeople] = useState<string[]>([]);

  const [profileSelected, setProfileSelected] = useState<AccountData>();
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  const netRecipients = useMemo(
    () => allSelectedPeople.filter((item) => !allExcludedPeople.includes(item)),
    [allSelectedPeople, allExcludedPeople]
  );

  useOnMount(() => {
    (async () => {
      // Set up step for some of the mass text / scheduleTextInfo data (not editing case)
      if (event && !editingMassText) {
        updateMassTextSettings({
          message: massTextDefaultMessage(
            event,
            accountData.fullName,
            suggestedAction?.type
          ),
        });
      }
      const roundedSendTime = roundTimeInterval(new Date().toISOString(), 5);
      if (defaultScheduled) {
        updateMassTextSettings({
          scheduled: defaultScheduled,
          sentAt: roundedSendTime.toISOString(),
        });
      }

      if (editingMassText !== undefined) {
        // Whether to display the payment failed popup
        if (isSubscriptionPaymentFailed(accountData.customer.state)) {
          setPaymentFailedVisible(true);
          return;
        }
        // Fetch and set the recipients for the editing existing mass text case
        const textRecipients = await getMassTextRecipients(
          editingMassText.sentBy,
          editingMassText.id
        );
        setAllSelectedPeople(textRecipients);
      }

      // Fetch the number of recipients for an event related text to determine whether to show categories
      if (event) {
        const numEventAttendees = await getTotalAttendeeTickets(event.id);
        setNumEventAttendees(numEventAttendees);
      }
    })();
  });

  const resetState = () => {
    if (setEditingMassText) {
      setEditingMassText(undefined);
    }
    resetMassText();
    setIsVisible(false);
  };

  const sharedPropsPack = {
    massTextSettings: massTextSettings,
    updateMassTextSettings: updateMassTextSettings,
    setProfileSelected: setProfileSelected,
    setMainScreen: setScreenType,
  };

  return (
    <LargePopupModalContainer
      showModal={isVisible}
      containerStyles={{ padding: 0 }}
      valueComp={
        <>
          {screenType === MassTextScreenType.OVERVIEW ? (
            <MassTextsMessaging
              {...sharedPropsPack}
              event={event}
              netSelectedPeople={netRecipients}
              suggestedAction={textSuggestedAction}
              editingMassText={editingMassText}
              resetState={resetState}
              setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
            />
          ) : screenType === MassTextScreenType.SELECT_RECIPIENTS ? (
            <MassTextsSelectRecipients
              {...sharedPropsPack}
              allSelectedPeople={allSelectedPeople}
              setAllSelectedPeople={setAllSelectedPeople}
              allExcludedPeople={allExcludedPeople}
              setAllExcludedPeople={setAllExcludedPeople}
              numEventAttendees={numEventAttendees}
              setSuggestedAction={setTextSuggestedAction}
            />
          ) : null}
          {profileSelected ? (
            <FullProfilePreviewModal
              profileSelected={profileSelected}
              setProfileSelected={setProfileSelected}
            />
          ) : null}
          {paymentFailedVisible ? (
            <PaymentFailedButtonModal
              isVisible={paymentFailedVisible}
              setIsVisible={() => {
                setPaymentFailedVisible(false);
                setIsVisible(false);
              }}
              location="Mass Text"
            />
          ) : null}
        </>
      }
    />
  );
};

export default MassTextsPopupPanel;
