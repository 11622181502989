import { useMemo, useRef } from "react";
import { Colors } from "../utils/colors";

type ListTabsProp = {
  tabLabels: string[];
  tabNumbers: number[];
  selectedValue: number;
  onChange: (index: number) => void;
  containerStyles?: React.CSSProperties;
  tabStyles?: React.CSSProperties;
  altColor?: string;
  newSelectedTextColor?: string;
  newTextColor?: string;
};

const ListTabs = (props: ListTabsProp) => {
  const {
    tabLabels,
    tabNumbers,
    selectedValue,
    onChange,
    containerStyles,
    tabStyles,
    altColor,
    newSelectedTextColor,
    newTextColor,
  } = props;

  const styles = {
    tabContainer: {
      transition: "all 200ms ease",
      borderRadius: 20,
      paddingBlock: 8,
      paddingInline: 10,
      gap: 5,
    },
    tabText: {
      fontSize: 14,
      fontWeight: "500",
    },
  };

  const slider = useRef<HTMLDivElement>(null);

  const textColorToShow = useMemo(
    () => newTextColor ?? Colors.BLACK,
    [newTextColor]
  );
  const selectedTextColorToShow = useMemo(
    () => newSelectedTextColor ?? Colors.WHITE,
    [newSelectedTextColor]
  );

  return (
    <div className="AlignedRow" style={{ gap: 4, ...containerStyles }}>
      {tabLabels.map((label, index) => (
        <div
          ref={slider}
          onClick={() => onChange(index)}
          className="AlignedRowSelect"
          style={{
            ...styles.tabContainer,
            ...tabStyles,
            backgroundColor:
              selectedValue === index ? altColor || Colors.BLUE5 : "",
          }}
        >
          <span
            style={{
              ...styles.tabText,
              color:
                selectedValue === index
                  ? selectedTextColorToShow
                  : textColorToShow,
            }}
          >
            {label}
          </span>
          {tabNumbers[index] !== -1 ? (
            <span
              style={{
                ...styles.tabText,
                color:
                  selectedValue === index
                    ? selectedTextColorToShow
                    : textColorToShow,
              }}
            >
              ({tabNumbers[index]})
            </span>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default ListTabs;
