import { useCallback, useEffect, useMemo, useState } from "react";
import MassTextsPanel from "../../../components/CreatorDashboard/MassTexts/MassTextsPanel";
import TextingPanelUsage from "../../../components/CreatorDashboard/TextingPanelUsage";
import PopupModalContainer from "../../../components/Containers/PopupModalContainer";
import ConversationsPanel from "../../../components/CreatorDashboard/Conversations/ConversationsPanel";
import {
  AccountData,
  MassText,
  OVERFLOW_RATE,
  OVERFLOW_RATE_BUSINESS,
} from "@markit/common.types";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState } from "../../../redux/slices/accountSlice";
import {
  fetchConversations,
  fetchNumTotalConversations,
  fetchNumUnreadConversations,
  getConversationState,
} from "../../../redux/slices/conversationSlice";
import { Colors } from "../../../utils/colors";
import { hasSubscription, isBusinessSubscription } from "@markit/common.utils";
import MassTextsPopupPanel from "../../../components/CreatorDashboard/MassTexts/MassTextsPopupPanel";
import { CreatorPanelTabs } from "../../../components/CreatorDashboard/CreatorPanelTabs";
import { TabPanel } from "../../../components/FullEventSubComponents/TabPanel";
import RectangleButtonCreatorPanel from "../../../components/Buttons/RectangleButtonCreatorPanel";
import ConfirmActionPopup from "../../../components/Containers/ConfirmPopups/ConfirmActionPopup";
import { Icon } from "@iconify/react";
import { MassTextPreviewItem } from "../../../components/DisplayItem/MassTextPreviewItem";
import { LessThanDate } from "@markit/common.utils";
import { useOnMount } from "../../../utils/useOnMount";
import {
  getLatestMassTextQuery,
  getUsersLatestScheduledTextsQuery,
  getUsersLatestSentTexts,
} from "../../../utils/textingUtils";
import { onSnapshot } from "../../../firebase";
import StandardBorderedContainer from "../../../components/Containers/StandardBorderedContainer";
import MarkitPlusActionButton from "../../../components/Subscription/MarkitPlusActionButton";

enum TABS {
  CONVERSATIONS = 0,
  MANAGE_TEXTS = 1,
}

export enum TextPopupConfirmActions {
  SENT_SUCCESS = "Text Sent",
  SENT_FAILURE = "Text Failed to Send",
  SCHEDULED_SUCCESS = "Text Scheduled",
  SCHEDULED_FAILURE = "Text Failed to Schedule",
  TEXT_DELETE = "Text Deleted",
  NONE = "",
}

const TextingPanel = () => {
  const [tabValue, setTabValue] = useState(TABS.CONVERSATIONS);
  const dispatch = useDispatch();

  const [overflowModalVisible, setOverflowModalVisible] =
    useState<boolean>(false);
  const [sentAndScheduledTexts, setSentAndScheduledTexts] = useState<number>(0);
  const { account } = useSelector(getAccountState);
  const { conversations } = useSelector(getConversationState);
  const { accountData, followingAccountData } = account;
  const { userConversations } = conversations;
  const [numTotalConversations, setNumTotalConversations] = useState(0);
  const [conversationsMainUser, setConversationsMainUser] =
    useState<AccountData>();
  const [sentTexts, setSentTexts] = useState<MassText[]>([]);
  const [scheduledTexts, setScheduledTexts] = useState<MassText[]>([]);
  const [massTextsPopupPanelVisible, setMassTextsPopupPanelVisible] =
    useState<boolean>(false);
  const [confirmPopupTextConfirmation, setConfirmPopupTextConfirmation] =
    useState<TextPopupConfirmActions>(TextPopupConfirmActions.NONE);
  const [editingMassText, setEditingMassText] = useState<MassText>();

  const [loadingInitialConversation, setLoadingInitialConversation] =
    useState(true);

  const styles = {
    manageTextsRow: {
      paddingTop: 14,
      display: "flex",
      gap: 10,
      alignItems: "center",
      cursor: "pointer",
    },

    noTextsEmptyState: {
      backgroundColor: Colors.GRAY6,
      borderRadius: 12,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 14,
    },
    textingPanelMainContentContainer: {
      backgroundColor: Colors.WHITE,
      marginLeft: 48,
      marginRight: 48,
      marginTop: 30,
      borderRadius: 20,
    },
  };

  useOnMount(() => {
    (async () => {
      const [
        latestCreatedMassTextQuery,
        latestScheduledTextsQuery,
        totalConversations,
      ] = await Promise.all([
        getLatestMassTextQuery(accountData.uid),
        getUsersLatestScheduledTextsQuery(accountData.uid, 2),
        fetchNumTotalConversations(accountData.uid),
      ]);
      setNumTotalConversations(totalConversations);

      const unsubscribeSentTexts = onSnapshot(
        latestCreatedMassTextQuery,
        (snapshot) => {
          snapshot.docChanges().forEach(async (message) => {
            if (message.type === "added" || message.type === "modified") {
              const latestSentTexts = await getUsersLatestSentTexts(
                accountData.uid,
                2
              );
              setSentTexts(latestSentTexts);
            }
          });
        }
      );

      const unsubscribeScheduledTexts = onSnapshot(
        latestScheduledTextsQuery,
        (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data());
          setScheduledTexts(data);
        }
      );

      return () => {
        unsubscribeScheduledTexts();
        unsubscribeSentTexts();
      };
    })();
  });

  useEffect(() => {
    (async () => {
      const promises = [
        dispatch(fetchConversations(accountData.uid)),
        dispatch(fetchNumUnreadConversations(accountData.uid)),
      ];
      await Promise.all(promises);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize to start with the most recent conversation selected
  useEffect(() => {
    if (userConversations.length > 0 && loadingInitialConversation) {
      const conversationUserData = followingAccountData.find(
        (follower) => follower.uid === userConversations[0].participantUids[0]
      );
      if (conversationUserData) {
        setLoadingInitialConversation(false);
        setConversationsMainUser(conversationUserData);
      }
    }
  }, [followingAccountData, loadingInitialConversation, userConversations]);

  const handleChangeTab = useCallback(
    (newValue: number) => {
      if (newValue === 0 && userConversations.length > 0) {
        const conversationUserData = followingAccountData.find(
          (follower) => follower.uid === userConversations[0].participantUids[0]
        );
        setConversationsMainUser(conversationUserData);
      }
    },
    [followingAccountData, userConversations]
  );

  // render mass text previews based on user's sent vs scheduled texts
  const renderMassTextPreviews = useMemo(() => {
    const isTextScheduled = (text: MassText) =>
      LessThanDate(new Date().toISOString(), text.sentAt);

    // Render mass text previews based on user's sent vs scheduled texts
    const renderTextItem = (text: MassText, isScheduled: boolean) => (
      <div style={{ flex: 1 }}>
        <MassTextPreviewItem
          massTextItem={text}
          isScheduled={isScheduled}
          setMassTextsPopupPanelVisible={setMassTextsPopupPanelVisible}
          setEditingMassText={setEditingMassText}
        />
      </div>
    );
    if (sentTexts.length >= 1 && scheduledTexts.length >= 1) {
      // Case 1: 1 Sent text, 1 scheduled text
      return (
        <div style={styles.manageTextsRow}>
          {renderTextItem(
            scheduledTexts[0],
            isTextScheduled(scheduledTexts[0])
          )}
          {renderTextItem(sentTexts[0], isTextScheduled(sentTexts[0]))}
        </div>
      );
    } else if (scheduledTexts.length === 2) {
      // Case 2: 0 Sent texts, 2 scheduled texts
      return (
        <div style={styles.manageTextsRow}>
          {renderTextItem(
            scheduledTexts[0],
            isTextScheduled(scheduledTexts[0])
          )}
          {renderTextItem(
            scheduledTexts[1],
            isTextScheduled(scheduledTexts[1])
          )}
        </div>
      );
    } else if (sentTexts.length === 2) {
      // Case 3: 2 Sent texts, 0 scheduled texts
      return (
        <div className="AlignedRowSelect" style={styles.manageTextsRow}>
          {renderTextItem(sentTexts[1], isTextScheduled(sentTexts[1]))}
          {renderTextItem(sentTexts[0], isTextScheduled(sentTexts[0]))}
        </div>
      );
    } else if (sentTexts.length === 1) {
      // Case 4: Only one sent text
      return (
        <div style={styles.manageTextsRow}>
          {renderTextItem(sentTexts[0], isTextScheduled(sentTexts[0]))}
        </div>
      );
    } else if (scheduledTexts.length === 1) {
      // Case 5: Only one scheduled text
      return (
        <div style={styles.manageTextsRow}>
          {renderTextItem(
            scheduledTexts[0],
            isTextScheduled(scheduledTexts[0])
          )}
        </div>
      );
    }

    return null; // No texts to display
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentTexts, scheduledTexts]); // update/re-render if text states change

  return (
    <div style={{ backgroundColor: Colors.WHITE }}>
      <div className="CreatorPanelContainer">
        <div className="AlignedRowSpaced">
          <h2>Texting</h2>
          <div className="AlignedRow" style={{ gap: 14 }}>
            <MarkitPlusActionButton />
            <RectangleButtonCreatorPanel
              title="New Text"
              iconName="ion:add-circle"
              onPress={() => {
                setMassTextsPopupPanelVisible(true);
              }}
            />
          </div>
        </div>
        <div className="EventDashboardTextingPanelOverview">
          {/* USAGE BOX */}
          <TextingPanelUsage
            setOverflowModalVisible={setOverflowModalVisible}
          />
          {/* MANAGE TEXTS BOX */}
          <StandardBorderedContainer
            onPress={() => {
              setTabValue(TABS.MANAGE_TEXTS);
            }}
            containerStyles={{ padding: 14, width: "100%", cursor: "pointer" }}
          >
            <div className="AlignedRowSpaced" style={{ cursor: "pointer" }}>
              <span className="AboutSubtitle">Manage Texts</span>
              <Icon icon={"feather:chevron-right"} height={18} />
            </div>
            <div style={{ cursor: "pointer" }}>
              {sentTexts.length === 0 && scheduledTexts.length === 0 ? (
                <div style={styles.noTextsEmptyState}>
                  <span
                    style={{
                      padding: 36,
                      fontSize: 16,
                    }}
                  >
                    No Texts
                  </span>
                </div>
              ) : (
                renderMassTextPreviews
              )}
            </div>
          </StandardBorderedContainer>
        </div>
      </div>
      {/* CONVERSATIONS / MANAGE TEXTS TAB BAR */}
      <div style={{ backgroundColor: Colors.WHITE1, paddingBottom: 30 }}>
        <div
          style={{
            backgroundColor: Colors.WHITE,
            paddingLeft: 48,
            paddingRight: 48,
          }}
        >
          <CreatorPanelTabs
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabNames={[
              hasSubscription(accountData)
                ? `Conversations (${numTotalConversations})`
                : "Conversations",
              sentAndScheduledTexts === 0
                ? "Manage Texts"
                : `Manage Texts (${sentAndScheduledTexts})`,
            ]}
            onChangeTab={(newValue: number) => handleChangeTab(newValue)}
          />
        </div>
        <div style={styles.textingPanelMainContentContainer}>
          <TabPanel value={tabValue} index={0}>
            <ConversationsPanel
              conversationMainUser={conversationsMainUser}
              setConversationMainUser={setConversationsMainUser}
            />
          </TabPanel>
          <MassTextsPanel
            setSentAndScheduledTexts={setSentAndScheduledTexts}
            massTextPreviewOnPress={(massText: MassText) => {
              setMassTextsPopupPanelVisible(true);
              setEditingMassText(massText);
            }}
            setMassTextsPopupPanelVisible={setMassTextsPopupPanelVisible}
            tabValue={tabValue}
          />
        </div>
      </div>
      {overflowModalVisible && (
        <PopupModalContainer
          headerComp={"Overflow Texting"}
          valueComp={
            <div>
              <h4 className="AboutSubtitle">What is overflow texting?</h4>
              <h4 className="OverflowTextSubtitle">
                Every month we give you complimentary texts based on your Markit
                plan. With Markit+, once you use all of your complimentary
                monthly texts you are still able to send texts; these are called
                overflow texts. You can send as many overflow texts as you want
                and will be charged based on your usage.
              </h4>
              <h4 className="AboutSubtitle" style={{ paddingTop: 20 }}>
                Rates
              </h4>
              <h4 className="OverflowTextSubtitle">
                After your complimentary monthly texts we charge you{" "}
                {isBusinessSubscription(accountData)
                  ? OVERFLOW_RATE_BUSINESS + " cent"
                  : OVERFLOW_RATE + " cents"}{" "}
                per credit. Overflow charges will be added to your monthly
                billing alongside your existing subscription billing.
              </h4>
              <h4 className="AboutSubtitle" style={{ paddingTop: 20 }}>
                Refund Policy
              </h4>
              <h4 className="OverflowTextSubtitle">
                Markit does not offer refunds on overflow credits.
              </h4>
            </div>
          }
          closeModal={() => setOverflowModalVisible(false)}
        />
      )}
      {/* Mass Texts Popup Panel */}
      {massTextsPopupPanelVisible ? (
        <MassTextsPopupPanel
          isVisible={massTextsPopupPanelVisible}
          setIsVisible={setMassTextsPopupPanelVisible}
          editingMassText={editingMassText}
          setEditingMassText={setEditingMassText}
          setConfirmPopupTextConfirmation={setConfirmPopupTextConfirmation}
        />
      ) : null}
      {confirmPopupTextConfirmation ? (
        <ConfirmActionPopup
          title={confirmPopupTextConfirmation}
          negativeTitle={confirmPopupTextConfirmation}
          isNegative={
            confirmPopupTextConfirmation ===
              TextPopupConfirmActions.SCHEDULED_FAILURE ||
            confirmPopupTextConfirmation ===
              TextPopupConfirmActions.SENT_FAILURE ||
            confirmPopupTextConfirmation === TextPopupConfirmActions.TEXT_DELETE
          }
          altIcon={
            confirmPopupTextConfirmation === TextPopupConfirmActions.TEXT_DELETE
              ? "ion:trash-outline"
              : undefined
          }
          onDisappear={() =>
            setConfirmPopupTextConfirmation(TextPopupConfirmActions.NONE)
          }
        />
      ) : null}
    </div>
  );
};

export default TextingPanel;
