import "../../css/GlobalStyles.css";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import EmptyViewStateMobile from "../../components/EmptyStates/EmptyViewStateMobile";
import { isDesktop } from "react-device-detect";
import { useNavigate } from "../../hooks/useNavigate";
import RectangleButtonCreatorPanel from "../../components/Buttons/RectangleButtonCreatorPanel";
import HelpModal from "../../components/Containers/HelpModal";
import RectangleButton from "../../components/Buttons/RectangleButton";
import { Icon } from "@iconify/react";
import ConfirmDeleteModal from "../../components/Containers/ConfirmPopups/ConfirmDeleteModal";
import {
  IntegrationPhoneContact,
  IntegrationPhoneContactState,
} from "@markit/common.types";
import ProgressActionModal, {
  ProgressActionStatus,
} from "../../components/Containers/ProgressActionModal";
import MultiStageContainer from "../../components/Containers/MultiStageContainer";
import CreatorModeWrapper from "../../components/CreatorDashboard/CreatorModeWrapper";
import { API } from "../../API";
import IntegrationConfirm from "../../components/Integrations/IntegrationConfirm";
import { hasSubscriptionUnlockedAdvancedData } from "@markit/common.utils";
import ConfirmActionModal from "../../components/Containers/ConfirmPopups/ConfirmActionModal";

export enum EventbriteIntegrationStage {
  LOGGED_IN = "Logged into Eventbrite",
  CONFIRM = "Confirm",
}

const EventbriteIntegration = () => {
  const { accountData, appInitialized } = useSelector(getAccountState).account;
  const navigate = useNavigate();
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [integrationStage, setIntegrationStage] = useState(
    EventbriteIntegrationStage.CONFIRM
  );
  const [loadingConnection, setLoadingConnection] = useState(true);
  const [phoneContacts, setPhoneContacts] = useState<IntegrationPhoneContact[]>(
    []
  );
  const [syncStatusVisible, setSyncStatusVisible] =
    useState<ProgressActionStatus>({
      modalVisible: false,
      state: "completed",
    });
  const [confirmSync, setConfirmSync] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const newValidContacts = useMemo(
    () =>
      phoneContacts.filter(
        (contact) => contact.state === IntegrationPhoneContactState.NEW
      ),
    [phoneContacts]
  );

  const inactiveConnection = useMemo(
    () => newValidContacts.length === 0 && !syncStatusVisible.modalVisible,
    [newValidContacts.length, syncStatusVisible.modalVisible]
  );

  const stageNames = useMemo(() => ["Logged into Eventbrite", "Confirm"], []);

  useEffect(() => {
    (async () => {
      if (!appInitialized) {
        return;
      }
      // Redirect back to integrations tab if user has synced with eventbrite
      if (
        accountData.eventbriteInfo.syncedOn ||
        !hasSubscriptionUnlockedAdvancedData(accountData)
      ) {
        navigate("/home", { state: { initialTabValue: 3 } });
        return;
      }
      const { phoneContacts, success } =
        await API.eventScraper.fetchEventbritePhoneContacts({
          eventbriteInfo: accountData.eventbriteInfo,
          userId: accountData.uid,
          isSyncing: false,
        });
      if (!success) {
        alert("There was an error connecting with Eventbrite at this time.");
        return;
      }
      setPhoneContacts(phoneContacts);
      setLoadingConnection(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData.uid, appInitialized]);

  const syncContactsOnPress = useCallback(async () => {
    setSyncStatusVisible({
      modalVisible: true,
      state: "in progress",
    });
    await API.eventScraper
      .createNewPhoneFollowers({
        userId: accountData.uid,
        phoneContacts: newValidContacts,
      })
      .then((response) => {
        const { success } = response;
        setSyncStatusVisible({
          modalVisible: true,
          state: success ? "completed" : "failed",
        });
      })
      .catch((err: any) => {
        console.log(err.message);
        setSyncStatusVisible({ modalVisible: true, state: "failed" });
      });
  }, [accountData.uid, newValidContacts]);

  const continueOnPress = useCallback(async () => {
    if (inactiveConnection) {
      navigate("/home", { state: { initialTabValue: 3 } });
    } else {
      setConfirmSync(true);
    }
  }, [inactiveConnection, navigate]);

  const backOnPress = useCallback(() => {
    navigate("/home", { state: { initialTabValue: 3 } });
  }, [navigate]);

  if (!isDesktop) {
    return <EmptyViewStateMobile />;
  }

  return (
    <CreatorModeWrapper>
      <div
        className="ColumnNormal"
        style={{ gap: 24, height: "calc(100vh - 60px" }}
      >
        <div className="CreatorPanelContainer">
          <div className="AlignedRowSpaced">
            <h2>Connect Eventbrite</h2>
            <RectangleButtonCreatorPanel
              title="Need Help?"
              onPress={() => setShowHelpModal(true)}
              iconName=""
              addHover
            />
          </div>
        </div>
        <div
          className="CreatorPanelContainer"
          style={{
            backgroundColor: Colors.WHITE1,
            paddingBottom: 40,
            flexGrow: 1,
            height: "100%",
          }}
        >
          <MultiStageContainer
            currentStageIndex={stageNames.findIndex(
              (stage) => stage === integrationStage
            )}
            stageNames={stageNames}
            backOnPress={backOnPress}
            fullLoading={loadingConnection}
            rightButton={
              <RectangleButton
                buttonLabel={
                  <span style={{ fontSize: 14, fontWeight: 500 }}>
                    {inactiveConnection
                      ? "Continue with Inactive Connection"
                      : "Confirm and Sync"}
                  </span>
                }
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                // disabled={loadingConnection}
                altPaddingVert={14}
                altPaddingHorz={40}
                onPress={continueOnPress}
                iconRight={
                  <Icon
                    icon="mdi:chevron-right"
                    height={16}
                    color={loadingConnection ? Colors.GRAY1 : Colors.WHITE}
                  />
                }
              />
            }
          >
            <IntegrationConfirm
              phoneContacts={phoneContacts}
              inactiveConnection={inactiveConnection}
            />
          </MultiStageContainer>
        </div>
      </div>
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
      <ConfirmDeleteModal
        heading="Are you sure want to exit?"
        subtext="Your changes will be discarded."
        deleteButtonText="Exit"
        hideModal={!showConfirmDelete}
        setIsVisible={setShowConfirmDelete}
        deleteOnPress={() => {}}
        icon={<Icon icon="ion:exit" height={40} />}
      />
      <ConfirmActionModal
        heading={"Ready to Sync?"}
        subtext={`You are about to sync ${newValidContacts.length} contacts from Eventbrite. This cannot be undone.`}
        confirmButtonText={"Sync"}
        icon={<Icon icon="ion:cloud-upload" height={40} />}
        hideModal={!confirmSync}
        setIsVisible={setConfirmSync}
        confirmOnPress={syncContactsOnPress}
      />
      <ProgressActionModal
        actionStatus={syncStatusVisible}
        setActionStatus={setSyncStatusVisible}
        failureMessage={
          "An error occurred while syncing. Try again or contact our hotline for help."
        }
        inProgressMessage={"Syncing may take a few minutes."}
        successMessage={`Successfully synced ${
          newValidContacts.length
        } contact${newValidContacts.length !== 1 ? "s" : ""}.`}
        addOnCompletion={() =>
          navigate("/home", { state: { initialTabValue: 3 } })
        }
      />
    </CreatorModeWrapper>
  );
};

export default memo(EventbriteIntegration);
