import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import ContactSupportButton from "../Buttons/ContactSupportButton";
import { HorizontalDivider } from "../Dividers/HorizontalDivider";
import {
  IntegrationPhoneContact,
  IntegrationPhoneContactState,
} from "@markit/common.types";
import EventbriteIcon from "../IntegrationIcons/EventbriteIcon";
import ValidContactsPopupPanel from "../CreatorDashboard/CreatorProfile/ImportContacts/ValidContactsPopupPanel";

type IntegrationConfirmProps = {
  phoneContacts: IntegrationPhoneContact[];
  inactiveConnection: boolean;
};

const IntegrationConfirm = (props: IntegrationConfirmProps) => {
  const { phoneContacts, inactiveConnection } = props;
  const [contactsView, setContactsView] = useState(0);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    container: { paddingBlock: 14, backgroundColor: Colors.WHITE1, width: 547 },
  };

  const phoneContactsGroup = useCallback(
    (state: IntegrationPhoneContactState) =>
      phoneContacts.filter((contact) => contact.state === state),
    [phoneContacts]
  );

  const contactsToShow = useMemo(() => {
    const contacts: { name: string; phoneNumber: string }[] =
      phoneContactsGroup(
        contactsView === 1
          ? IntegrationPhoneContactState.NEW
          : contactsView === 2
          ? IntegrationPhoneContactState.INVALID
          : IntegrationPhoneContactState.ALREADY_EXISTING
      ).map((row) => {
        return { name: row.name, phoneNumber: row.phoneNumber };
      });
    return contacts;
  }, [contactsView, phoneContactsGroup]);

  const contactsInfoRow = useCallback(
    (contactRow: number, rowValue: number) => {
      const rowName =
        contactRow === 1
          ? "New Valid Phone Number Contacts"
          : contactRow === 2
          ? "Contacts with No Phone Numbers"
          : "Already Existing Contacts";
      return (
        <div
          onClick={() => setContactsView(contactRow)}
          className="AlignedRowSpacedSelect"
          style={{
            paddingInline: 14,
            pointerEvents: rowValue === 0 ? "none" : "all",
          }}
        >
          <div className="ColumnNormalSelect" style={{ gap: 7 }}>
            <span
              className="bodySubtext"
              style={{
                fontWeight: 500,
                color:
                  contactRow === 1
                    ? rowValue === 0
                      ? Colors.RED3
                      : Colors.GREEN2
                    : Colors.BLACK,
              }}
            >
              {rowValue} {rowName}
            </span>
          </div>
          <Icon icon="ion:chevron-right" height={14} color={Colors.GRAY1} />
        </div>
      );
    },
    []
  );

  const renderInactiveRow = useCallback(
    (icon: ReactNode, title: string, subtext: string) => (
      <div className="AlignedRow" style={{ gap: 14 }}>
        <div style={{ width: 40 }}>{icon}</div>
        <div className="ColumnNormal" style={{ gap: 7 }}>
          <span className="sectionTitle">{title}</span>
          <span className="bodySubtext">{subtext}</span>
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      <div
        className="ColumnNormal Centering"
        style={{ gap: 40, paddingTop: 30 }}
      >
        <div
          className="ColumnNormal"
          style={{ gap: 10, textAlign: "center", width: 607 }}
        >
          <span style={styles.headerText}>
            {inactiveConnection
              ? "Continue with Inactive Connection"
              : "Confirm your sync details"}
          </span>
          {inactiveConnection ? (
            <span className="bodySubtext">
              You have to start collecting phone numbers through Eventbrite to
              text your imported audience. For now, 0 new valid phone number
              contacts will be added to your audience.
            </span>
          ) : (
            <span className="bodySubtext">
              {phoneContactsGroup(IntegrationPhoneContactState.NEW).length} new
              valid phone number contact
              {phoneContactsGroup(IntegrationPhoneContactState.NEW).length !== 1
                ? "s"
                : ""}{" "}
              will be added to your audience.
            </span>
          )}
        </div>
        {inactiveConnection ? (
          <StandardBorderedContainer
            containerStyles={{ ...styles.container, paddingInline: 14 }}
          >
            <div className="ColumnNormal" style={{ gap: 30 }}>
              {renderInactiveRow(
                <EventbriteIcon size={20} padding={7} />,
                "Step 1: Collect data with questions on Eventbrite",
                "Start asking form questions in Eventbrite to collect data to sync with your Markit account."
              )}
              {renderInactiveRow(
                <Icon icon="ion:checkmark-circle" height={34} />,
                "Step 2: Activate your Markit/Eventbrite integration",
                "Answer questions, chat, and engage your people with two-way texting conversations"
              )}
              {renderInactiveRow(
                <Icon icon="ion:arrow-forward" height={34} />,
                "Step 3: Continue collecting data to keep syncing",
                "Continue asking form questions through Eventbrite and watch your contacts sync effortlessly with Markit."
              )}
            </div>
          </StandardBorderedContainer>
        ) : (
          <StandardBorderedContainer containerStyles={styles.container}>
            <div className="ColumnNormal">
              <div
                className="AlignedRow"
                style={{ paddingInline: 14, gap: 14 }}
              >
                <EventbriteIcon size={34} padding={10} />
                <div className="ColumnNormal" style={{ gap: 4 }}>
                  <div className="AlignedRow" style={{ gap: 10 }}>
                    <span className="sectionTitle">Eventbrite</span>
                    <span className="bodySubtext">Connected</span>
                  </div>
                  <span className="smallBodySubtext">
                    Seamlessly connect all your attendees and contacts to sync
                    with your Markit contacts and start texting.
                  </span>
                </div>
              </div>
              <HorizontalDivider altMargin={14} />
              {contactsInfoRow(
                1,
                phoneContactsGroup(IntegrationPhoneContactState.NEW).length
              )}
              {/* <HorizontalDivider altMargin={14} /> */}
              {/* {contactsInfoRow(
                2,
                phoneContactsGroup(IntegrationPhoneContactState.INVALID).length
              )} */}
              {phoneContactsGroup(IntegrationPhoneContactState.ALREADY_EXISTING)
                .length > 0 ? (
                <>
                  <HorizontalDivider altMargin={14} />
                  {contactsInfoRow(
                    3,
                    phoneContactsGroup(
                      IntegrationPhoneContactState.ALREADY_EXISTING
                    ).length
                  )}
                </>
              ) : null}
            </div>
          </StandardBorderedContainer>
        )}
        <ContactSupportButton />
      </div>
      {contactsView > 0 ? (
        <ValidContactsPopupPanel
          contactsToShow={contactsToShow}
          contactsView={contactsView}
          setContactsView={setContactsView}
        />
      ) : null}
    </>
  );
};

export default IntegrationConfirm;
