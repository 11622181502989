import { Route, Routes } from "react-router-dom";
import { memo } from "react";
import HomePage from "../screens/Home/HomePage";
import TextingPanel from "../screens/Profile/CreatorPanels/TextingPanel";
import TransactionsPanel from "../screens/Profile/CreatorPanels/TransactionsPanel";
import BillingPanel from "../screens/Profile/CreatorPanels/BillingPanel";
import EventsPanel from "../screens/Profile/CreatorPanels/EventsPanel";
import HomePanel from "../screens/Profile/CreatorPanels/HomePanel";
import LinksPanel from "../screens/Profile/CreatorPanels/LinksPanel";

const HomeRoute = memo(function HomeRouteFn() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}>
        <Route index element={<HomePanel />} />
        <Route path="events" element={<EventsPanel />} />
        <Route path="links" element={<LinksPanel />} />
        <Route path="texting" element={<TextingPanel />} />
        <Route path="transactions" element={<TransactionsPanel />} />
        <Route path="billing" element={<BillingPanel />} />
      </Route>
    </Routes>
  );
});

export default HomeRoute;
