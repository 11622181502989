import { useCallback } from "react";
import {
  CustomTab,
  CustomTabs,
  a11yProps,
} from "../FullEventSubComponents/CustomTabs";

type CreatorPanelTabsProps = {
  tabValue: number;
  setTabValue: (tabValue: number) => void;
  tabNames: string[];
  onChangeTab?: (newValue: number) => void;
  containerStyles?: React.CSSProperties;
  fontStyles?: React.CSSProperties;
  spacing?: number;
};

// Used for the panel tabs in creator mode
export const CreatorPanelTabs = (props: CreatorPanelTabsProps) => {
  const {
    tabValue,
    setTabValue,
    tabNames,
    onChangeTab,
    containerStyles,
    fontStyles,
    spacing,
  } = props;

  const handleChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      if (onChangeTab) {
        onChangeTab(newValue);
      }
      setTabValue(newValue);
    },
    [onChangeTab, setTabValue]
  );

  return (
    <div style={{ marginTop: 28, ...containerStyles }}>
      <CustomTabs
        value={tabValue}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons={false}
        sx={{ minHeight: 0 }}
      >
        {tabNames.map((name, index) => (
          <CustomTab
            label={
              <div
                className={
                  tabValue === index
                    ? "EventTabNameRowActive"
                    : "EventTabNameRow"
                }
                style={{
                  marginLeft: index === 0 ? 0 : undefined,
                  marginRight: spacing,
                }}
              >
                <span style={fontStyles} className={"TabName"}>
                  {name}
                </span>
              </div>
            }
            sx={{ minHeight: 0, cursor: "default" }}
            {...a11yProps(index)}
          />
        ))}
      </CustomTabs>
    </div>
  );
};
