import { isDesktop } from "react-device-detect";
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { LoginState, getAccountState } from "../../redux/slices/accountSlice";
import CreatorModeSidebar, {
  homePathTab,
} from "../../components/CreatorDashboard/CreatorModeSidebar";
import CreatorModeTopHeader from "../../components/CreatorDashboard/CreatorModeTopHeader";
import { Icon } from "@iconify/react";
import { Outlet, useLocation } from "react-router-dom";
import { useOnMount } from "../../utils/useOnMount";
import LoadingScreen from "../LoadingScreen";
import WeeklyCommunityUpdatesModal from "../../components/DisplayModal/WeeklyCommunityUpdatesModal";
import { MixpanelContext } from "../../context/AnalyticsService";
import HomePageMobile from "./HomePageMobile";
import HelpModal from "../../components/Containers/HelpModal";
import { isSubscriptionPaymentFailed } from "@markit/common.utils";
import PaymentFailedButtonModal from "../../components/DisplayModal/UpdatePaymentInfoModal";
import { useNavigate } from "../../hooks/useNavigate";
import { NavigationId } from "../../navigation/AppParamList";

const HomePage = () => {
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { accountData, loggedIn, sidebarCondensed, numFollowers } = account;
  const { pathname } = useLocation();
  const mixpanel = useContext(MixpanelContext);

  const [loading, setLoading] = useState(true);

  const [morePanelVisible, setMorePanelVisible] = useState<boolean>(false);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);
  const [communityUpdatesVisible, setCommunityUpdatesVisible] = useState(false);
  const [paymentFailedVisible, setPaymentFailedVisible] = useState(false);

  useOnMount(() => {
    (async () => {
      if (loggedIn !== LoginState.LOGGED_IN) {
        navigate("/welcome");
      } else if (!accountData.inCreatorMode) {
        navigate("/");
      } else {
        setLoading(false);
      }
    })();
  });

  // Logic whether to show PaymentFailedSubscription
  useEffect(() => {
    if (
      pathname === NavigationId.HOME_TEXTING &&
      isSubscriptionPaymentFailed(accountData.customer.state)
    ) {
      setPaymentFailedVisible(true);
    }
  }, [accountData.customer.state, pathname]);

  // Logic whether to show WeeklyCommunityUpdates
  useEffect(() => {
    if (
      numFollowers > 0 &&
      !accountData.showedWeeklyCommunityUpdates &&
      !paymentFailedVisible
    ) {
      setCommunityUpdatesVisible(true);
      mixpanel.track("Webapp: Triggered Weekly Community Updates Modal", {
        desktop: true,
        phoneAccount: accountData.phoneNumber !== "",
        uid: accountData.uid,
      });
    }
  }, [
    accountData.phoneNumber,
    accountData.showedWeeklyCommunityUpdates,
    accountData.uid,
    mixpanel,
    numFollowers,
    paymentFailedVisible,
    setCommunityUpdatesVisible,
  ]);

  if (loading || !account.appInitialized) {
    return <LoadingScreen />;
  }

  return !isDesktop ? (
    <HomePageMobile />
  ) : (
    <div className="AlignedRow" style={{ width: "100vw" }}>
      {communityUpdatesVisible ? (
        <WeeklyCommunityUpdatesModal
          setModalVisible={setCommunityUpdatesVisible}
        />
      ) : null}
      {paymentFailedVisible ? (
        <PaymentFailedButtonModal
          location="Initial Home Page"
          isVisible={paymentFailedVisible}
          setIsVisible={setPaymentFailedVisible}
          addOnClose={() => navigate("/home")}
        />
      ) : null}
      {/* Left Panel */}
      <CreatorModeSidebar
        morePanelVisible={morePanelVisible}
        setMorePanelVisible={setMorePanelVisible}
      />
      {/* Right Panel + Content */}
      <div
        className="CreatorModeRightPanel"
        style={{
          overflow: morePanelVisible ? "hidden" : "auto",
          width: sidebarCondensed ? "calc(100vw - 76px)" : "86vw",
          marginLeft: sidebarCondensed ? "76px" : "14vw",
        }}
      >
        <CreatorModeTopHeader />
        <Outlet />
      </div>
      {/* Floating action button */}
      {homePathTab(pathname) !== "Texting" ? (
        <div
          className="FloatingActionButton"
          onClick={() => setShowHelpModal(true)}
        >
          <Icon icon="ion:help" height={27} />
        </div>
      ) : null}
      <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
    </div>
  );
};

export default HomePage;
