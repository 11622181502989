import {
  MassText,
  MassTextAttachment,
  MassTextType,
  Event,
  SubSelectionType,
} from "@markit/common.types";
import {
  getCountFromServer,
  getDocs,
  query,
  where,
  orderBy,
  limit,
} from "../firebase";
import {
  getMassTextAttachmentsSnap,
  getMassTextRecipientsRef,
  getMassTextRecipientsSnap,
  getTicketsRef,
  getUserMassTextsRef,
} from "./FirebaseUtils";
import { API } from "../API";
import {
  dayAfterEventText,
  defaultExternalEventText,
  isEventExternalLink,
  massTextPlaceholder,
  massTextUpcomingEventPlaceholder,
  pregameEventText,
} from "@markit/common.utils";
import { generate } from "shortid";
import { getUidsFromEventTicketType } from "./eventUtils/eventUtils";
import { GetLongDate } from "./GetLongDate";

/**
 * Get latest sent texts data
 * for Texting Tab (Manage Text previews)
 */
export const getUsersLatestSentTexts = async (
  userId: string,
  numTexts: number
) => {
  const massTextsRef = await getUserMassTextsRef(userId);
  const date = new Date().toISOString();
  const sentTextItemsQuery = query(
    massTextsRef,
    where("sentAt", "<=", date),
    orderBy("sentAt", "desc"),
    limit(numTexts)
  );
  const sentTexts = (await getDocs(sentTextItemsQuery)).docs.map((doc) =>
    doc.data()
  );
  return sentTexts;
};

/**
 * Get latest scheduled texts data
 * for Texting Tab (Manage Text previews)
 */
export const getUsersLatestScheduledTextsQuery = async (
  userId: string,
  numTexts: number
) => {
  const massTextsRef = await getUserMassTextsRef(userId);
  const date = new Date().toISOString();
  const scheduledTextItemsQuery = query(
    massTextsRef,
    where("sentAt", ">", date),
    where('scheduled', '==', true),
    orderBy("sentAt", "asc"),
    limit(numTexts)
  );
  return scheduledTextItemsQuery;
};

/**
 * Get latest mass text info + attachments
 * for Texting Tab
 */
export const getLatestMassTextQuery = async (userId: string) => {
  const massTextsRef = await getUserMassTextsRef(userId);
  const massTextItemsQuery = query(
    massTextsRef,
    orderBy("createdAt", "desc"),
    limit(1)
  );
  return massTextItemsQuery;
};

/**
 * Get the mass text attachments from a specified mass text
 */
export const getMassTextAttachments = async (
  userId: string,
  massText: MassText
): Promise<MassTextAttachment[]> => {
  const massTextAttachmentsSnap = await getMassTextAttachmentsSnap(
    userId,
    massText.id
  );
  if (massTextAttachmentsSnap.empty) {
    return [];
  }
  const massTextAttachments: MassTextAttachment[] = await Promise.all(
    massTextAttachmentsSnap.docs.map((snapshot) => {
      const attachment: MassTextAttachment = snapshot.data();
      return attachment;
    })
  );
  return massTextAttachments;
};

/**
 * Get total number of mass texts (only) of a user
 */
export const getNumMassTexts = async (userId: string): Promise<number> => {
  const massTextsRef = getUserMassTextsRef(userId);
  const massTextsQuery = query(
    massTextsRef,
    where("sentAt", "<=", new Date().toISOString())
  );
  const snapshot = await getCountFromServer(massTextsQuery);

  return snapshot.data().count;
};

export const getNumScheduledMassTexts = async (
  userId: string
): Promise<number> => {
  const massTextsRef = getUserMassTextsRef(userId);
  const massTextsQuery = query(
    massTextsRef,
    where("sentAt", ">", new Date().toISOString())
  );
  const snapshot = await getCountFromServer(massTextsQuery);
  return snapshot.data().count;
};

// get the number of event texts by a user to one specified user
export const getNumEventMassTextsToUser = async (
  userId: string,
  recipientId: string
): Promise<number> => {
  const massTextsRef = getUserMassTextsRef(userId);
  const massTextsQuery = query(
    massTextsRef,
    where("type", "==", MassTextType.EVENTTEXT)
  );
  const massTextsSnap = await getDocs(massTextsQuery);
  const promises = massTextsSnap.docs.map(async (doc) => {
    const massText = doc.data();
    const ticketsRef = getTicketsRef(massText.eventRefId);
    const ticketsQuery = query(
      ticketsRef,
      where("redeemedBy", "==", recipientId)
    );
    const snapshot = await getCountFromServer(ticketsQuery);
    return snapshot.data().count;
  });
  const results = await Promise.all(promises);
  const totalEventTexts = results.reduce((acc, count) => acc + count, 0);
  return totalEventTexts;
};

// get the number of mass texts by a user to one specified user
export const getNumMassTextsToUser = async (
  userId: string,
  recipientId: string
): Promise<number> => {
  const massTextsRef = getUserMassTextsRef(userId);
  const massTextsQuery = query(
    massTextsRef,
    where("type", "==", MassTextType.MASSTEXT)
  );
  const massTextsSnap = await getDocs(massTextsQuery);
  const promises = massTextsSnap.docs.map(async (doc) => {
    const massText = doc.data();
    const recipientsRef = getMassTextRecipientsRef(userId, massText.id);
    const recipientsQuery = query(
      recipientsRef,
      where("uid", "==", recipientId)
    );
    const snapshot = await getCountFromServer(recipientsQuery);
    return snapshot.data().count;
  });
  const results = await Promise.all(promises);
  const totalEventTexts = results.reduce((acc, count) => acc + count, 0);
  return totalEventTexts;
};

/**
 * Get total number of recipients from a specified mass text
 */
export const getNumMassTextRecipients = async (
  userId: string,
  massTextId: string
): Promise<number> => {
  const massTextRecipientsRef = getMassTextRecipientsRef(userId, massTextId);
  const snapshot = await getCountFromServer(massTextRecipientsRef);
  return snapshot.data().count;
};

/**
 * Get the recipients from a specified mass text for a specified attachment
 */
export const getMassTextRecipients = async (
  userId: string,
  massTextId: string
): Promise<string[]> => {
  const textRecipientsSnap = await getMassTextRecipientsSnap(
    userId,
    massTextId
  );
  const textRecipientIds: string[] = await Promise.all(
    textRecipientsSnap.docs.map((snapshot) => {
      const recipientId = snapshot.data().uid;
      return recipientId;
    })
  );
  return textRecipientIds;
};

export const getMassTextSendRate = async (
  massText: MassText
): Promise<number> => {
  const response = await API.tracking.getMassTextSendRate({
    massText: massText,
  });

  if (response) {
    return response.sendRate;
  }

  return 100;
};

export const getMassTextDeliveryAttemptedRate = async (
  massText: MassText
): Promise<number> => {
  const response = await API.tracking.getMassTextDeliveryAttemptedRate({
    massText: massText,
  });

  if (response) {
    return response.deliveryAttemptedRate;
  }

  return 100;
};

export const generateDefaultEventTexts = (userId: string, event: Event) => {
  if (isEventExternalLink(event.eventType)) {
    const externalDefaultText: MassText = defaultExternalEventText(
      generate(),
      userId,
      event.id,
      event.start,
      event.externalLink
    );
    const defaultEventTexts: MassText[] = [externalDefaultText];
    return defaultEventTexts;
  } else {
    const pregameText: MassText = pregameEventText(
      generate(),
      userId,
      event.id,
      event.start,
      event.isVirtual
    );

    const dayAfterText: MassText = dayAfterEventText(
      generate(),
      userId,
      event.id,
      event.end
    );

    const defaultEventTexts: MassText[] = [pregameText, dayAfterText];
    return defaultEventTexts;
  }
};

// fetch the attendees per ticket type and store the recipients that belong within each ticket type in a map
// Used for mass text selecting by event ticket types
export const fetchAttendeesPerTypeMap = async (selectedEvent: Event) => {
  if (selectedEvent) {
    const attendeesPerTicketTypeArray = await Promise.all(
      selectedEvent.customTickets.map(async (ticket) => {
        const ticketAttendees = await getUidsFromEventTicketType(
          selectedEvent.id,
          ticket.id
        );
        return {
          id: ticket.id,
          attendees: ticketAttendees,
        };
      })
    );
    const attendeesPerTicketType = new Map(
      attendeesPerTicketTypeArray.map((ticketItem) => [
        ticketItem.id,
        ticketItem.attendees,
      ])
    );
    return attendeesPerTicketType;
  }
  return new Map<string, string[]>();
};

// Returns the default message we want to start the message with based on the suggestedActionType
export const massTextDefaultMessage = (
  event: Event | undefined,
  fullName: string,
  suggestedActionType: SubSelectionType | undefined
) => {
  return event && suggestedActionType === SubSelectionType.ALL_FOLLOWERS
    ? massTextUpcomingEventPlaceholder(
        fullName,
        GetLongDate(event.start, false, true, true, false),
        event.id
      )
    : massTextPlaceholder(fullName);
};
