import { useCallback, useMemo, useState } from "react";
import OSPills from "../OSPills";
import {
  accountActions,
  getAccountState,
} from "../../redux/slices/accountSlice";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useLocation, useSearchParams } from "react-router-dom";
import { NavigationId } from "../../navigation/AppParamList";
import MorePanel from "../../screens/Profile/CreatorPanels/MorePanel";
import { DarkTheme, ThemeStyle } from "../../hooks/useTheme";
import MarkitHeaderLogo from "../MarkitHeaderLogo";
import { useNavigate } from "../../hooks/useNavigate";
import MarkitPlusNumberModal from "../Subscription/MarkitPlusNumberModal";
import ShareProfilePopupPanel from "./CreatorProfile/ShareProfilePopupPanel";
import { hasSubscription, isExternalGenericLink } from "@markit/common.utils";
import { getEventState } from "../../redux/slices/eventSlice";
import { getUnreadConversationsQuery } from "../../redux/slices/conversationSlice";
import { useOnMount } from "../../utils/useOnMount";
import { onSnapshot } from "../../firebase";

export enum MenuItemLabel {
  HOME = "Home",
  TEXTING = "Texting",
  GROW = "Grow",
  EVENTS = "Events",
  LINKS = "Links",
  SHARE_PROFILE = "Share Profile",
  SHARE_NUMBER = "Share Number",
  MORE = "More",
  BILLING = "Billing",
  PLANS = "Plans",
  TRANSACTIONS = "Transactions",
  TERMSOFUSE = "Terms of Use",
  PRIVACYPOLICY = "Privacy Policy",
}

export const homePathTab = (pathname: string) => {
  switch (pathname) {
    case "/home":
    case NavigationId.IMPORT:
    case NavigationId.INTEGRATIONS:
      return MenuItemLabel.HOME;
    case NavigationId.HOME_EVENTS:
    case NavigationId.CREATE:
      return MenuItemLabel.EVENTS;
    case NavigationId.HOME_LINKS:
    case NavigationId.CREATE_LINK:
      return MenuItemLabel.LINKS;
    case NavigationId.HOME_TEXTING:
      return MenuItemLabel.TEXTING;
    case NavigationId.HOME_TRANSACTIONS:
      return MenuItemLabel.TRANSACTIONS;
    case NavigationId.HOME_BILLING:
      return MenuItemLabel.BILLING;
    default:
      return MenuItemLabel.EVENTS;
  }
};

interface MenuItem {
  label: MenuItemLabel;
  deselectedIcon: string;
  selectedIcon: string;
}

type CreatorModeSidebarProps = {
  morePanelVisible: boolean;
  setMorePanelVisible: (morePanelVisible: boolean) => void;
  theme?: ThemeStyle;
};

const CreatorModeSidebar = (props: CreatorModeSidebarProps) => {
  const { morePanelVisible, setMorePanelVisible, theme } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account } = useSelector(getAccountState);
  const { sidebarCondensed, accountData } = account;
  const { events: eventList } = useSelector(getEventState).events;
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [showGrowItems, setShowGrowItems] = useState(true);
  const [showShareNumber, setShowShareNumber] = useState(false);
  const [shareProfileOpen, setShareProfileOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  const isDarkTheme = useMemo(() => theme && theme === DarkTheme, [theme]);

  // get the number of unread conversation messages
  useOnMount(() => {
    (async () => {
      const unreadQuery = await getUnreadConversationsQuery(accountData.uid);
      const unsubscribe = onSnapshot(unreadQuery, (snapshot) => {
        setUnreadMessages(snapshot.size);
      });
      return () => unsubscribe();
    })();
  });

  const menuItems: MenuItem[] = useMemo(() => {
    const items = [
      {
        label: MenuItemLabel.HOME,
        deselectedIcon: "mdi:home-variant-outline",
        selectedIcon: "mdi:home-variant",
      },
      {
        label: MenuItemLabel.TEXTING,
        deselectedIcon: "ion:chatbubble-ellipses-outline",
        selectedIcon: "ion:chatbubble-ellipses",
      },
      {
        label: MenuItemLabel.GROW,
        deselectedIcon: "ion:trending-up",
        selectedIcon: "ion:trending-up",
      },
    ];

    if (showGrowItems) {
      items.push(
        {
          label: MenuItemLabel.EVENTS,
          deselectedIcon: "ion:calendar-outline",
          selectedIcon: "ion:calendar",
        },
        {
          label: MenuItemLabel.LINKS,
          deselectedIcon: "mdi:link-variant",
          selectedIcon: "mdi:link-variant",
        },
        {
          label: MenuItemLabel.SHARE_PROFILE,
          deselectedIcon: "ion:person-outline",
          selectedIcon: "ion:person",
        },
        {
          label: MenuItemLabel.SHARE_NUMBER,
          deselectedIcon: "ion:call-outline",
          selectedIcon: "ion:call",
        }
      );
    }

    items.push({
      label: MenuItemLabel.MORE,
      deselectedIcon: "mdi:dots-horizontal",
      selectedIcon: "mdi:dots-horizontal",
    });

    return items;
  }, [showGrowItems]);

  const foundHomePathTab = useMemo(() => {
    // check searchParam to check if the createLink path is event
    if (
      pathname.includes(NavigationId.CREATE_LINK) &&
      searchParams.has("event")
    ) {
      return MenuItemLabel.EVENTS;
    }
    // check if on create and if it's a generic link
    if (pathname.includes(NavigationId.CREATE) && searchParams.has("link")) {
      return MenuItemLabel.LINKS;
    }
    // check if on dashboard if it's a generic link or event
    if (pathname.includes("/e/")) {
      const eventId = pathname.split("/")[2];
      const foundEvent = eventList.find((event) => event.id === eventId);
      if (foundEvent) {
        return isExternalGenericLink(foundEvent.eventType)
          ? MenuItemLabel.LINKS
          : MenuItemLabel.EVENTS;
      }
    }
    const foundTab = homePathTab(pathname);
    return foundTab;
  }, [eventList, pathname, searchParams]);

  const isSelectedMenuItem = useCallback(
    (item: MenuItemLabel) => {
      return (
        (foundHomePathTab === item && !morePanelVisible) ||
        (morePanelVisible && item === MenuItemLabel.MORE) ||
        (item === MenuItemLabel.MORE &&
          (foundHomePathTab === MenuItemLabel.BILLING ||
            foundHomePathTab === MenuItemLabel.TRANSACTIONS))
      );
    },
    [foundHomePathTab, morePanelVisible]
  );

  const menuItemOnPress = useCallback(
    (item: MenuItemLabel) => {
      if (item === MenuItemLabel.MORE) {
        setMorePanelVisible(!morePanelVisible);
        return;
      }

      switch (item) {
        case MenuItemLabel.HOME:
          navigate("/home");
          break;
        case MenuItemLabel.TEXTING:
          navigate(NavigationId.HOME_TEXTING);
          break;
        case MenuItemLabel.GROW:
          setShowGrowItems(!showGrowItems);
          break;
        case MenuItemLabel.EVENTS:
          navigate(NavigationId.HOME_EVENTS);
          break;
        case MenuItemLabel.LINKS:
          navigate(NavigationId.HOME_LINKS);
          break;
        case MenuItemLabel.SHARE_PROFILE:
          setShareProfileOpen(true);
          break;
        case MenuItemLabel.SHARE_NUMBER:
          setShowShareNumber(true);
          break;
        case MenuItemLabel.TRANSACTIONS:
          navigate(NavigationId.HOME_TRANSACTIONS);
          break;
        case MenuItemLabel.BILLING:
          navigate(NavigationId.HOME_BILLING);
          break;
        default:
          navigate("/home");
      }
      setMorePanelVisible(false);
    },
    [morePanelVisible, navigate, setMorePanelVisible, showGrowItems]
  );

  const SidebarMenuItems = () => {
    return (
      <LazyMotion features={domAnimation}>
        <div className="CreatorModeMenuItemsContainer HideScrollbar">
          {menuItems.map((item) => {
            return (
              <m.div
                className="AlignedRowSpaced CreatorModeMenuItemContainer HideScrollbar"
                onClick={() => menuItemOnPress(item.label)}
                style={{
                  backgroundColor: isSelectedMenuItem(item.label)
                    ? isDarkTheme
                      ? Colors.GRAY7
                      : morePanelVisible
                      ? Colors.GRAY6
                      : Colors.WHITE
                    : isDarkTheme
                    ? Colors.GRAY10
                    : morePanelVisible
                    ? Colors.WHITE
                    : Colors.GRAY6,
                }}
                whileHover={{
                  backgroundColor: isDarkTheme
                    ? Colors.GRAY7
                    : morePanelVisible
                    ? Colors.GRAY6
                    : Colors.WHITE,
                }}
                transition={{ duration: 0.1 }}
              >
                <div className="AlignedRowSelect" style={{ gap: 14 }}>
                  <Icon
                    icon={
                      isSelectedMenuItem(item.label)
                        ? item.selectedIcon
                        : item.deselectedIcon
                    }
                    height={20}
                    style={{ color: theme?.PrimaryText.color }}
                  />
                  {!sidebarCondensed ? (
                    <div className="ColumnNormalSelect" style={{ gap: 4 }}>
                      <span
                        style={{
                          fontWeight: isSelectedMenuItem(item.label)
                            ? 600
                            : 400,
                          cursor: "pointer",
                          transition: "0.1s ease",
                          userSelect: "none",
                          ...theme?.PrimaryText,
                        }}
                      >
                        {item.label}
                      </span>
                      {item.label === MenuItemLabel.SHARE_NUMBER &&
                      !hasSubscription(accountData) ? (
                        <span style={{ fontSize: 12, color: Colors.GRAY2 }}>
                          Available with Markit
                          <span style={{ color: Colors.BLUE5 }}>+</span>
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                {item.label === MenuItemLabel.GROW && !sidebarCondensed ? (
                  <Icon
                    icon={showGrowItems ? "mdi-chevron-up" : "mdi:chevron-down"}
                    height={19}
                    color={theme?.PrimaryText.color}
                  />
                ) : null}
                {item.label === MenuItemLabel.TEXTING &&
                !sidebarCondensed &&
                unreadMessages > 0 ? (
                  <div
                    className="Centering"
                    style={{
                      paddingInline: 6,
                      paddingBlock: 4,
                      minWidth: 10,
                      borderRadius: 100,
                      backgroundColor: Colors.RED3,
                    }}
                  >
                    <span style={{ fontSize: 11, color: Colors.WHITE }}>
                      {unreadMessages}
                    </span>
                  </div>
                ) : null}
              </m.div>
            );
          })}
        </div>
      </LazyMotion>
    );
  };

  return (
    <>
      <div
        className="CreatorModeLeftPanel"
        style={{
          backgroundColor: isDarkTheme
            ? Colors.GRAY10
            : morePanelVisible
            ? Colors.WHITE
            : Colors.GRAY6,
          borderRight: morePanelVisible
            ? "0px solid black"
            : isDarkTheme
            ? "0.5px solid #3d3d3d"
            : "0.5px solid #B9B9B9",
          width: sidebarCondensed ? "76px" : "14vw",
          minWidth: sidebarCondensed ? "76px" : 226,
        }}
      >
        <div>
          {!sidebarCondensed ? (
            <div style={{ margin: "24px" }}>
              <MarkitHeaderLogo theme={theme} />
            </div>
          ) : null}
          <div style={{ paddingTop: sidebarCondensed ? 84 : 20 }}>
            <SidebarMenuItems />
          </div>
        </div>
        <div>
          {!sidebarCondensed ? (
            <div
              className="ProfilePageCreatorSidebarCard ColumnNormal"
              style={{
                backgroundColor: isDarkTheme
                  ? Colors.GRAY7
                  : morePanelVisible
                  ? Colors.GRAY6
                  : Colors.WHITE,
                gap: 14,
              }}
            >
              <div className="ColumnNormal" style={{ gap: 7 }}>
                <span
                  style={{
                    ...theme?.PrimaryText,
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  Get the app
                </span>
                <h2 className="ProfilePageCreatorSidebarCardSubtext">
                  Download the app to use Markit on the go.
                </h2>
              </div>
              <OSPills alternateTextSize={12} />
            </div>
          ) : null}
          <div
            className="AlignedRow"
            style={{ justifyContent: sidebarCondensed ? "center" : "flex-end" }}
          >
            <Icon
              icon={
                sidebarCondensed
                  ? "mdi:chevron-double-right"
                  : "mdi:chevron-double-left"
              }
              height={24}
              style={{ cursor: "pointer", padding: 14, ...theme?.SmallSubText }}
              onClick={() => {
                dispatch(accountActions.setSidebarCondensed(!sidebarCondensed));
                if (morePanelVisible) {
                  setMorePanelVisible(false);
                }
              }}
            />
          </div>
        </div>
      </div>
      <MorePanel
        morePanelVisible={morePanelVisible}
        setMorePanelVisible={setMorePanelVisible}
        theme={theme}
      />
      <MarkitPlusNumberModal
        isVisible={showShareNumber}
        setIsVisible={setShowShareNumber}
      />
      {shareProfileOpen ? (
        <ShareProfilePopupPanel
          isVisible={shareProfileOpen}
          setIsVisible={setShareProfileOpen}
        />
      ) : null}
    </>
  );
};

export default CreatorModeSidebar;
