import {
  SubSelectionItem,
  SubSelectionStatusId,
  SubSelectionType,
  Event,
} from "@markit/common.types";
import { useMemo } from "react";
import { Colors } from "../../../../utils/colors";
import { MassTextsActionItem } from "../Items/MassTextsActionItem";
import { Icon } from "@iconify/react";
import { HorizontalDivider } from "../../../Dividers/HorizontalDivider";
import { isEventExternalLink } from "@markit/common.utils";

type SelectRecipientsSuggestedCategoriesProps = {
  event: Event;
  excludingMode: boolean;
  selectedCategories: SubSelectionItem[];
  selectOnPress: (
    tabValue: number,
    isAllAttendees?: boolean,
    isAllPeople?: boolean
  ) => void;
  hideAllAttendees: boolean;
};

const SelectRecipientsSuggestedCategories = (
  props: SelectRecipientsSuggestedCategoriesProps
) => {
  const {
    event,
    selectedCategories,
    excludingMode,
    selectOnPress,
    hideAllAttendees,
  } = props;

  const iconColor = useMemo(
    () => (excludingMode ? Colors.RED3 : Colors.PURPLE7),
    [excludingMode]
  );

  const isExternalLink = useMemo(
    () => isEventExternalLink(event.eventType),
    [event.eventType]
  );

  return (
    <>
      <div>
        <div className="AlignedRow" style={{ paddingBottom: 10 }}>
          <span
            className="OneLineText"
            style={{ color: Colors.GRAY1, fontSize: 14, fontWeight: "500" }}
          >
            Categories (From {event.title})
          </span>
        </div>
        {!excludingMode && !hideAllAttendees ? (
          <>
            <MassTextsActionItem
              title={isExternalLink ? "All People" : "All Attendees"}
              icon={<Icon icon="ion:people" height={30} color={iconColor} />}
              onPress={() =>
                isExternalLink
                  ? selectOnPress(0, false, true)
                  : selectOnPress(0, true)
              }
              checkColor={iconColor}
              isCheckSelected={selectedCategories.some((category) =>
                isExternalLink
                  ? category.type === SubSelectionType.ALL_PEOPLE
                  : category.type === SubSelectionType.STATUS &&
                    category.id === SubSelectionStatusId.ATTENDEES
              )}
            />
            <HorizontalDivider altMargin={10} />
          </>
        ) : null}
        {!isExternalLink ? (
          <>
            <MassTextsActionItem
              title={"Status"}
              icon={<Icon icon="ion:scan" height={30} color={iconColor} />}
              onPress={() => selectOnPress(1)}
            />
            <HorizontalDivider altMargin={10} />
          </>
        ) : null}
        <MassTextsActionItem
          title={"Form Responses"}
          icon={<Icon icon="ion:document" height={30} color={iconColor} />}
          onPress={() => selectOnPress(isExternalLink ? 1 : 2)}
        />
        {!isExternalLink ? (
          <>
            <HorizontalDivider altMargin={10} />
            <MassTextsActionItem
              title={"Ticket Type"}
              icon={<Icon icon="ion:ticket" height={30} color={iconColor} />}
              onPress={() => selectOnPress(3)}
            />
          </>
        ) : null}
      </div>
    </>
  );
};

export default SelectRecipientsSuggestedCategories;
