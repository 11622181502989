import { generate } from "shortid";
import { geohashForLocation } from "geofire-common";
import {
  AccountData,
  CustomTicketV2,
  Event,
  EventType,
  ThemeType,
  TicketOptionName,
  TicketType,
  Visibility,
  validExternalLinkUrls,
} from "@markit/common.types";
import {
  deepCopy,
  ticketPurchasePlaceholder,
  defaultExternalSubmissionText,
} from "@markit/common.utils";
import { GoogleLocationData } from "../hooks/useCreateEventManagement";
import axios from "axios";
import {
  firebaseMapsApiKey,
  firebaseStorage,
  getDownloadURL,
  listAll,
  ref,
} from "../firebase";
import { API } from "../API";
import merge from "lodash.merge";
import {
  MEDIA_UPLOAD_CODES,
  compressAndManipulateImage,
  storeEventImageIntoFirebaseStorage,
} from "./photoUtils";

export const DEFAULT_TICKET_PRICE = 5 * 100;
export const DEFAULT_CROWDFUNDING_GOAL = 100 * 100;
export const DEFAULT_GOING_CAPACITY = 100;
export const DEFAULT_CUSTOM_TICKET_GOING_CAPACITY = 10;
export const MIN_TICKET_QUANTITY = 1;
export const MAX_TICKET_QUANTITY = 24;

export const makeDefaultTicket = (
  label: string,
  price: number,
  type: TicketType,
  quantityAvailable?: number,
  minQuantity?: number,
  maxQuantity?: number,
  hideTicket?: boolean
) => {
  return {
    id: generate(),
    label: label,
    type: type,
    price: price,
    quantityAvailable: quantityAvailable ?? 0,
    minQuantity: minQuantity ?? 1,
    maxQuantity: maxQuantity ?? 8,
    hideTicket: hideTicket ?? false,
  } as CustomTicketV2;
};

export const makeEmptyEvent = (
  userId: string,
  fullName: string,
  isAdmin: boolean,
  currentLocation?: GoogleLocationData,
  isGenericLink?: boolean
) => {
  const id = generate();
  const [lat, lng] =
    currentLocation && currentLocation.latitude !== -999
      ? [currentLocation.latitude, currentLocation.longitude]
      : [-1, -1];

  const startDate = new Date();
  startDate.setDate(startDate.getDate());
  startDate.setTime(Math.ceil(startDate.getTime() / 1800000) * 1800000);
  let endDate = new Date();

  if (isGenericLink) {
    // Initialize start as current time, but make expires one week ahead
    endDate.setDate(startDate.getDate() + 7);
  } else {
    // Initializes startDate by setting it 1 week ahead
    startDate.setDate(startDate.getDate() + 7);
    // Initializes endDate by setting it to one hour after startDate
    endDate = new Date(startDate.getTime());
    endDate.setTime(endDate.getTime() + 3600000);
  }
  endDate.setTime(Math.ceil(endDate.getTime() / 1800000) * 1800000);

  const newEvent: Event = {
    id: id,
    geohash: geohashForLocation([lat, lng]),
    googlePlaceId: currentLocation?.googlePlaceId ?? "",
    googleDescription: currentLocation?.googleDescription ?? "",
    createdBy: userId,
    createdAt: new Date().toISOString(),
    creatorDayAfterMessage: "",
    creatorPregameMessage: "",
    creatorOrganizerMessage: false,
    creatorTicketPurchaseMessage: ticketPurchasePlaceholder(fullName),
    creatorTicketPurchaseMediaUrl: "",
    lat: lat,
    lng: lng,
    photoURL: "",
    isDraft: false,
    isFeatured: false,
    isVirtual: false,
    isManualSoldOut: false,
    invited: [],
    invitedCommunities: [],
    wished: [],
    going: [],
    notGoing: [],
    title: "",
    description: "",
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    eventType: EventType.MARKIT,
    externalLink: "",
    link: "",
    categories: [],
    discussion: [],
    visibility: isAdmin ? Visibility.Private : Visibility.Public,
    visibilityCommunity: "",
    universityId: -1,
    stripePaymentCollector: "",
    customTickets: [
      makeDefaultTicket(TicketOptionName.FREE, 0, TicketType.FREE),
    ],
    promoCodes: [],
    canRefund: false,
    canTransfer: false,
    crowdfundingGoal: 0,
    crowdfundingHide: false,
    contributions: [],
    hideResponses: true,
    requestList: [],
    scannedList: [],
    performers: [],
    promoters: [],
    referrers: [],
    cohosts: [],
    scanners: [],
    notifiedList: [],
    notifiedReminderList: [],
    formattedAddress: currentLocation?.formattedAddress ?? "",
    privateLocation: false,
    locationDetails: "",
    formQuestions: [],
    locationBasedRSVP: false,
    theme: ThemeType.Light,
  };

  return newEvent;
};

export const makeDuplicateEvent = (
  userData: AccountData,
  event: Event,
  eventDetailsChecked: boolean
) => {
  const emptyEvent = makeEmptyEvent(
    userData.uid,
    userData.fullName,
    userData.isAdmin
  );

  if (!eventDetailsChecked) {
    return emptyEvent;
  }

  const newEvent = merge(emptyEvent, {
    canRefund: event.canRefund,
    canTransfer: event.canTransfer,
    categories: event.categories,
    crowdfundingGoal: event.crowdfundingGoal,
    crowdfundingHide: event.crowdfundingHide,
    customTickets: event.customTickets,
    description: event.description,
    formattedAddress: event.formattedAddress,
    hideResponses: event.hideResponses,
    lat: event.lat,
    link: event.link,
    lng: event.lng,
    locationDetails: event.locationDetails,
    geohash: event.geohash,
    googleDescription: event.googleDescription,
    googlePlaceId: event.googlePlaceId,
    photoURL: event.photoURL,
    privateLocation: event.privateLocation,
    isDraft: event.isDraft,
    isVirtual: event.isVirtual,
    isManualSoldOut: event.isManualSoldOut,
    title: event.title,
    universityId: event.universityId,
    visibility: event.visibility,
    theme: event.theme,
    formQuestions: event.formQuestions,
  });

  return newEvent;
};

export const invalidEndDate = (event: Event) => {
  return new Date(event.end).getTime() <= new Date(event.start).getTime();
};

// Uploads the blob to firebase storage and sets the photoURL to new url if changed
export const uploadBlobToFirebase = async (
  event: Event,
  isCreated: boolean
): Promise<Event> => {
  // some extra checks to mutate final event object
  const finalEvent = finalizeEvent(event, isCreated);
  const eventCopy: Event = deepCopy(finalEvent);

  // save event photo if non-sample photo
  if (event.photoURL) {
    try {
      const photosRef = ref(firebaseStorage, "sampleEventPhotos/");
      await listAll(photosRef).then(async (listResult) => {
        const defaultImages = await Promise.all(
          listResult.items.map(async (item) => {
            const url = await getDownloadURL(item);
            return url;
          })
        );

        if (!defaultImages.includes(event.photoURL)) {
          try {
            const newPhotoUrl = await storeEventImageIntoFirebaseStorage(event);
            eventCopy.photoURL = newPhotoUrl;
          } catch (err: any) {
            console.log(err.message);
          }
        }
      });
    } catch (err: any) {
      console.log("Error saving photo for event: " + err.message);
    }
  }
  return eventCopy;
};

export const finalizeEvent = (event: Event, isCreated?: boolean) => {
  return {
    ...event,
    ...(isCreated && { createdAt: new Date().toISOString() }),
    title: event.title !== "" ? event.title : "",
    link:
      event.link !== "" && !/^(https|http):\/\/.*/.test(event.link)
        ? `https://${event.link}`
        : event.link,
  } as Event;
};

export const getFormattedAddress = async (googlePlaceId: string) => {
  try {
    const url =
      "https://maps.googleapis.com/maps/api/place/details/json?place_id=" +
      googlePlaceId +
      "&key=" +
      firebaseMapsApiKey;
    const response = await axios.get(url);
    return response.data.result?.formatted_address as string;
  } catch (err: any) {
    console.error(err.message);
  }
};

export const getDescriptionFromPlaceId = async (googlePlaceId: string) => {
  try {
    const response = await API.tracking.getEventGoogleDescription({
      placeId: googlePlaceId,
    });
    if (response) {
      return response.googleDescription;
    }
  } catch (err: any) {
    console.error(err.message);
  }
};

export const createExternalEventLink = async (
  eventLink: string,
  userData: AccountData,
  isGenericLink: boolean,
  eventToConvert?: Event // If converting to markit event, pass this in
): Promise<{ event: Event | undefined; dataScraped: boolean }> => {
  const trimmedLink = eventLink.trim();
  const newEvent: Event = {
    ...makeEmptyEvent(
      userData.uid,
      userData.fullName,
      userData.isAdmin,
      undefined,
      isGenericLink
    ),
    creatorTicketPurchaseMessage: defaultExternalSubmissionText(
      userData.fullName
    ),
    externalLink: trimmedLink,
    eventType: isGenericLink ? EventType.GENERIC_LINK : EventType.EXTERNAL_DATA,
  };
  // Supported external link (scrape details)
  const isSupported = validExternalLinkUrls.some((validUrl) =>
    trimmedLink.includes(validUrl.url)
  );
  if (isSupported) {
    const fetchExternalEvent = trimmedLink.includes(
      validExternalLinkUrls[0].url
    )
      ? API.eventScraper.fetchEventbriteEvent
      : trimmedLink.includes(validExternalLinkUrls[1].url)
      ? API.eventScraper.fetchPoshEvent
      : trimmedLink.includes(validExternalLinkUrls[2].url)
      ? API.eventScraper.fetchLumaEvent
      : trimmedLink.includes(validExternalLinkUrls[3].url)
      ? API.eventScraper.fetchPartifulEvent
      : API.eventScraper.fetchMarkitEvent;
    const response = await fetchExternalEvent({
      url: trimmedLink,
      userId: userData.uid,
      isGenericLink: isGenericLink,
      eventDataToConvert: eventToConvert,
    });
    const { eventDetails, success } = response;
    if (!success) {
      return { event: newEvent, dataScraped: false };
    }

    return { event: eventDetails, dataScraped: true };
  } else {
    // Don't scrape details
    return { event: newEvent, dataScraped: false };
  }
};
