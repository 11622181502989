import { AccountData, Event } from "@markit/common.types";
import {
  LessThanDate,
  isEventExternalLink,
  isExternalGenericLink,
} from "@markit/common.utils";
import { Colors } from "../../../utils/colors";
import { GetTime } from "../../../utils/GetTime";
import ProfileEventTimelineSubItem from "./ProfileEventTimelineSubItem";
import EventOrganizerSubItem from "../EventItem/EventOrganizerSubItem";

type ProfileEventTimelineGridItemProps = {
  event: Event;
  organizers: AccountData[];
  url: string;
  isMyAccount: boolean;
};

// To display the event details on the profile page event timeline for the grid (condensed) view
const ProfileEventTimelineGridItem = (
  props: ProfileEventTimelineGridItemProps
) => {
  const { event, organizers, url, isMyAccount } = props;

  const styles = {
    title: {
      color: Colors.BLACK,
      fontSize: 14,
      fontWeight: "500",
    },
    description: {
      fontSize: 12,
      color: Colors.GRAY1,
    },
  };

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="AlignedRowSpacedSelect"
      style={{ alignItems: "flex-start" }}
    >
      <div className="AlignedRowSelect" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            borderRadius: 8,
            height: "100%",
            width: 2,
            backgroundColor: LessThanDate(event.end, new Date().toISOString())
              ? Colors.GRAY1
              : Colors.BLUE5,
          }}
        />
        <div className="ColumnNormalSelect" style={{ marginLeft: 14, gap: 4 }}>
          <div className="ColumnNormalSelect" style={{ gap: 4 }}>
            <span
              className="TwoLineText"
              style={{ ...styles.title, paddingRight: 20 }}
            >
              {event.title}
            </span>
            {!isExternalGenericLink(event.eventType) ? (
              <>
                <span style={styles.description}>
                  {event.isVirtual
                    ? "Virtual Location"
                    : event.privateLocation && !isMyAccount
                    ? "Hidden Location"
                    : event.googleDescription}
                </span>
                {organizers.length > 0 &&
                !isEventExternalLink(event.eventType) ? (
                  <EventOrganizerSubItem organizers={organizers} />
                ) : null}
              </>
            ) : null}
          </div>
          <ProfileEventTimelineSubItem event={event} condensedView />
        </div>
      </div>
      {!isExternalGenericLink(event.eventType) ? (
        <span
          style={{ ...styles.description, whiteSpace: "nowrap", fontSize: 14 }}
        >
          {GetTime(event.start)}
        </span>
      ) : null}
    </a>
  );
};

export default ProfileEventTimelineGridItem;
