import { useCallback, useMemo, useState } from "react";
import {
  getDocs,
  onSnapshot,
  limit,
  orderBy,
  query,
  where,
  startAfter,
} from "../firebase";
import { uniqueVals } from "@markit/common.utils";
import {
  AccountData,
  OrganizerType,
  RequestStatus,
  TicketV2,
} from "@markit/common.types";
import { getTicketsRef, getUserData } from "../utils/FirebaseUtils";
import { loadAlgoliaUsersSearchResults } from "../utils/algoliaUtils";

type useLoadUserTicketListProps = {
  eventId?: string;
  windowSize: number;
  ticketType: string; // empty string if none specified and want to not filter by a ticket type
};
export const useLoadUserTicketList = (props: useLoadUserTicketListProps) => {
  const { eventId, windowSize, ticketType } = props;

  const [fetchedUserData, setFetchedUserData] = useState<AccountData[]>([]);
  const [fetchedTicketData, setFetchedTicketData] = useState<TicketV2[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [timeoutEvent, setTimeoutEvent] = useState<
    NodeJS.Timeout | undefined
  >();

  const [lastVisible, setLastVisible] = useState<string>(
    new Date().toISOString()
  );
  const [loadedAllMessages, setLoadedAllMessages] = useState(false);

  const addUserData = useCallback((newUserData: AccountData[]) => {
    setFetchedUserData((fetchedUserData) =>
      uniqueVals(
        fetchedUserData.concat(newUserData),
        (userData) => userData.uid
      )
    );
  }, []);

  const addTicketData = useCallback((newTicketData: TicketV2[]) => {
    setFetchedTicketData((fetchedTicketData) => {
      for (let i = 0; i < newTicketData.length; i++) {
        const index = fetchedTicketData.findIndex(
          (fetched) => fetched.id === newTicketData[i].id
        );
        if (index !== -1) {
          fetchedTicketData.splice(index, 1, newTicketData[i]);
          // fetchedTicketData[i] = newTicketData[i];
        } else {
          fetchedTicketData = fetchedTicketData.concat(newTicketData);
        }
      }
      return uniqueVals(
        fetchedTicketData,
        (fetchedTicketData) => fetchedTicketData.id
      );
    });
  }, []);

  const removeTicketData = useCallback((newTicketData: TicketV2[]) => {
    setFetchedTicketData((fetchedTicketData) => {
      for (let i = 0; i < newTicketData.length; i++) {
        const index = fetchedTicketData.findIndex(
          (fetched) => fetched.id === newTicketData[i].id
        );
        if (index !== -1) {
          fetchedTicketData.splice(index, 1);
          // fetchedTicketData[i] = newTicketData[i];
        }
      }
      return uniqueVals(
        fetchedTicketData,
        (fetchedTicketData) => fetchedTicketData.id
      );
    });
  }, []);

  const isFinished = useMemo(() => loadedAllMessages, [loadedAllMessages]);

  // todo (jonathan): code duplication between loadTicketUsers and loadMoreTicketUsers. Reduce this
  const loadTicketUsers = useCallback(async () => {
    if (!isFinished && !isLoading) {
      if (!eventId) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
        return;
      }
      setIsLoading(true);

      const ticketsRef = getTicketsRef(eventId);
      const ticketUsersQuery =
        ticketType === "organizers"
          ? query(
              ticketsRef,
              where("customTicketId", "==", ""),
              orderBy("createdAt", "desc"),
              limit(windowSize)
            )
          : ticketType !== ""
          ? query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              where("requestStatus", "==", ticketType),
              orderBy("createdAt", "desc"),
              limit(windowSize)
            )
          : query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              orderBy("createdAt", "desc"),
              limit(windowSize)
            );

      const uploadedTimes = (await getDocs(ticketUsersQuery)).docs.map(
        (document) => document.data().createdAt
      );

      setLastVisible(uploadedTimes[uploadedTimes.length - 1]);
      if (uploadedTimes.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(ticketUsersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const ticketUserData = message.doc.data();
            if (ticketType === "organizers") {
              addTicketData([ticketUserData]);
              if (ticketUserData.uid !== "") {
                const userData = await getUserData(ticketUserData.uid);
                if (userData) {
                  addUserData([userData]);
                }
              }
            } else {
              if (
                ticketUserData &&
                ticketUserData.customTicketId !== "" &&
                ticketUserData.uid !== ""
              ) {
                addTicketData([ticketUserData]);

                if (ticketUserData.redeemedBy !== "") {
                  const userData = await getUserData(ticketUserData.redeemedBy);

                  if (userData) {
                    addUserData([userData]);
                  }
                } else if (
                  !fetchedUserData.find(
                    (fetched) => fetched.uid === ticketUserData.uid
                  )
                ) {
                  const userData = await getUserData(ticketUserData.uid);
                  if (userData) {
                    addUserData([userData]);
                  }
                }
              }
            }
          } else if (message.type === "removed") {
            const ticketUserData = message.doc.data();
            removeTicketData([ticketUserData]);
          }
        });
      });

      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      return unsubscribe;
    }
  }, [
    addTicketData,
    addUserData,
    eventId,
    fetchedUserData,
    isFinished,
    isLoading,
    removeTicketData,
    ticketType,
    windowSize,
  ]);

  const loadMoreTicketUsers = useCallback(async () => {
    if (!isFinished) {
      if (!eventId) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
        return;
      }
      setIsLoading(true);
      const ticketsRef = getTicketsRef(eventId);
      const ticketUsersQuery =
        ticketType === "organizers"
          ? query(
              ticketsRef,
              where("customTicketId", "==", ""),
              orderBy("createdAt", "desc"),
              startAfter(lastVisible),
              limit(windowSize)
            )
          : ticketType !== ""
          ? query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              where("requestStatus", "==", ticketType),
              orderBy("createdAt", "desc"),
              startAfter(lastVisible),
              limit(windowSize)
            )
          : query(
              ticketsRef,
              where("role.type", "==", OrganizerType.ATTENDEE),
              orderBy("createdAt", "desc"),
              startAfter(lastVisible),
              limit(windowSize)
            );

      const uploadedTimes = (await getDocs(ticketUsersQuery)).docs.map(
        (document) => document.data().createdAt
      );

      setLastVisible(uploadedTimes[uploadedTimes.length - 1]);
      if (uploadedTimes.length < windowSize) {
        setLoadedAllMessages(true);
      }

      const unsubscribe = onSnapshot(ticketUsersQuery, (snapshot) => {
        snapshot.docChanges().forEach(async (message, index) => {
          if (message.type === "added" || message.type === "modified") {
            const ticketUserData = message.doc.data();

            if (ticketType === "organizers") {
              addTicketData([ticketUserData]);
              if (ticketUserData.uid !== "") {
                const userData = await getUserData(ticketUserData.uid);
                if (userData) {
                  addUserData([userData]);
                }
              }
            } else {
              if (
                ticketUserData &&
                ticketUserData.customTicketId !== "" &&
                ticketUserData.uid !== ""
              ) {
                addTicketData([ticketUserData]);

                if (ticketUserData.redeemedBy !== "") {
                  const userData = await getUserData(ticketUserData.redeemedBy);
                  if (userData) {
                    addUserData([userData]);
                  }
                } else if (
                  !fetchedUserData.find(
                    (fetched) => fetched.uid === ticketUserData.uid
                  )
                ) {
                  const userData = await getUserData(ticketUserData.uid);
                  if (userData) {
                    addUserData([userData]);
                  }
                }
              }
            }
          } else if (message.type === "removed") {
            const ticketUserData = message.doc.data();
            removeTicketData([ticketUserData]);
          }
        });
      });
      // TODO (Peter): hacky way to wait for data to load before setting isLoading to false.
      // Better solution is to set isLoading to false when we know the data has actually been loaded
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
      return unsubscribe;
    }
  }, [
    addTicketData,
    addUserData,
    eventId,
    fetchedUserData,
    isFinished,
    lastVisible,
    removeTicketData,
    ticketType,
    windowSize,
  ]);

  const loadSearchResults = useCallback(
    async (searchTerm: string) => {
      if (!eventId) {
        setFetchedUserData([]);
        setFetchedTicketData([]);
      } else {
        const fetchEventUsers = async (userIds: string[]) => {
          setIsLoading(true);
          await Promise.all(
            userIds.map(async (uid) => {
              const ticketsRef = getTicketsRef(eventId);
              const query_ =
                ticketType !== ""
                  ? query(
                      ticketsRef,
                      where("requestStatus", "==", ticketType),
                      where("uid", "==", uid)
                    )
                  : query(ticketsRef, where("uid", "==", uid));

              const unsubscribe = onSnapshot(query_, (snapshot) => {
                snapshot.docChanges().forEach(async (message) => {
                  if (message.type === "added" || message.type === "modified") {
                    const ticketUserData = message.doc.data();
                    if (
                      ticketUserData &&
                      ((ticketUserData.customTicketId !== "" &&
                        ticketUserData.uid !== "") ||
                        ticketType === "organizers")
                    ) {
                      addTicketData([ticketUserData]);
                      if (
                        ticketType === "organizers" ||
                        ticketUserData.requestStatus === RequestStatus.PENDING
                          ? ticketUserData.uid !== ""
                          : ticketUserData.redeemedBy !== "" &&
                            ticketUserData.redeemedBy !== "manual redeemed" &&
                            ticketUserData.redeemedBy !==
                              "manual redeemed scanned"
                      ) {
                        const userData = await getUserData(
                          ticketUserData.requestStatus === RequestStatus.PENDING
                            ? ticketUserData.uid
                            : ticketUserData.redeemedBy
                        );
                        if (userData) {
                          addUserData([userData]);
                        }
                      }
                    }
                  } else if (message.type === "removed") {
                    const ticketUserData = message.doc.data();
                    removeTicketData([ticketUserData]);
                  }
                });
              });
              return unsubscribe;
            })
          );
          setIsLoading(false);
        };
        await loadAlgoliaUsersSearchResults(
          searchTerm,
          `eventIds:${eventId}`,
          (userIds: string[]) => fetchEventUsers(userIds)
        );
      }
    },
    [addTicketData, addUserData, eventId, removeTicketData, ticketType]
  );

  const loadSearchResultsThrottled = useCallback(
    (searchTerm: string) => {
      if (timeoutEvent) {
        clearTimeout(timeoutEvent);
      }
      setTimeoutEvent(
        setTimeout(() => {
          if (searchTerm.length > 1) {
            loadSearchResults(searchTerm);
          }
        }, 500)
      );
    },
    [loadSearchResults, timeoutEvent]
  );

  return {
    isFinished,
    isLoading,
    fetchedUserData,
    fetchedTicketData,
    loadTicketUsers,
    loadMoreTicketUsers,
    loadSearchResultsThrottled,
  };
};
