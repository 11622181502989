import { memo, useCallback, useContext, useMemo, useState } from "react";
import { AccountData, Event, VerificationState } from "@markit/common.types";
import { Colors } from "../../utils/colors";
import { Icon } from "@iconify/react";
import { useTheme } from "../../hooks/useTheme";
import { MixpanelContext } from "../../context/AnalyticsService";
import { isDesktop } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { getAccountState, logoutUser } from "../../redux/slices/accountSlice";
import { formattedVerificationPhoneNumber } from "../../utils/FormatPhoneNumber";
import Phone from "../../utils/Phone";
import VerificationCodeButton from "../VerificationCodeButton";
import { CheckoutEmailAccountNotice } from "../FullEventSubComponents/CheckoutEmailAccountNotice";
import CustomTextField from "../../components/CustomTextField";
import { detectedCensored, isEventExternalLink } from "@markit/common.utils";
import ProfilePic from "../ProfilePic";
import MarkitSecuredContainer from "./MarkitSecuredContainer";
import { DataLoaders } from "../../redux/slices/dataSlice";
import { useNavigate } from "../../hooks/useNavigate";

type NameAndPhoneNumberInputProps = {
  host: AccountData;
  verificationState: VerificationState;
  setVerificationState: (verificationState: VerificationState) => void;
  tempFullName: string;
  setTempFullName: (tempFullName: string) => void;
  tempPhoneNumber: string;
  setTempPhoneNumber: (tempPhoneNumber: string) => void;
  setUid: (uid: string) => void;
  processing: boolean;
  setIsOrganizer?: (isOrganizer: boolean) => void;
  event?: Event;
  callbackOnVerify?: () => void;
};

export const NameAndPhoneNumberInput = memo(function NameAndPhoneNumberInputFn(
  props: NameAndPhoneNumberInputProps
) {
  const {
    host,
    verificationState,
    setVerificationState,
    tempFullName,
    setTempFullName,
    tempPhoneNumber,
    setTempPhoneNumber,
    setUid,
    setIsOrganizer,
    processing,
    event,
    callbackOnVerify,
  } = props;
  const { theme } = useTheme();
  const mixpanel = useContext(MixpanelContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { account } = useSelector(getAccountState);
  const { accountData } = account;

  const [showFullNameError, setShowFullNameError] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false);
  const [showCodeInputError, setShowCodeInputError] = useState(false);

  const isEmailBasedAccount = useMemo(
    () => accountData.email !== "",
    [accountData.email]
  );

  const isDisabledButton = useMemo(
    () => formattedVerificationPhoneNumber(tempPhoneNumber).length !== 10,
    [tempPhoneNumber]
  );

  const logoutEmailOnPress = useCallback(() => {
    mixpanel.track("Webapp: User Logout", {
      distinct_id: accountData.uid,
    });
    dispatch(logoutUser());
    setUid("");
    if (setIsOrganizer) {
      setIsOrganizer(false);
    }
    setVerificationState(VerificationState.UNVERIFIED);
  }, [
    accountData.uid,
    dispatch,
    mixpanel,
    setIsOrganizer,
    setUid,
    setVerificationState,
  ]);

  const showError = useCallback(() => {
    let error = false;
    if (!Phone.isValid(formattedVerificationPhoneNumber(tempPhoneNumber))) {
      setShowPhoneNumberError(true);
      error = true;
    }

    if (tempFullName === "" || detectedCensored(tempFullName)) {
      if (detectedCensored(tempFullName)) {
        alert("Full name contains banned words and must be removed.");
      }
      setShowFullNameError(true);
      error = true;
    }

    if (verificationState === VerificationState.VERIFYING) {
      setShowCodeInputError(true);
    }

    return error;
  }, [tempFullName, tempPhoneNumber, verificationState]);

  const navigateProfileOnPress = useCallback(() => {
    dispatch(DataLoaders.user(host.uid));
    navigate(`/u/${host.username}`, {
      state: { username: host.username },
    });
  }, [host.uid, host.username, dispatch, navigate]);

  return (
    <div
      className={isDesktop ? "YourInfoContainerDesktop" : "YourInfoContainer"}
      style={theme.SecondaryBG}
    >
      {event && isEventExternalLink(event.eventType) ? (
        <div
          className="AlignedRowSpaced"
          style={{ gap: 14, paddingBottom: 14, paddingInline: 14 }}
        >
          <div>
            <ProfilePic
              user={host}
              size={49}
              onPress={navigateProfileOnPress}
            />
          </div>
          <span
            style={{ fontWeight: "500", fontSize: 14, ...theme.PrimaryText }}
          >
            {host.fullName} uses Markit to send updates and reminders
          </span>
        </div>
      ) : (
        <div style={{ paddingBottom: 14 }}>
          <span
            style={{
              ...theme.PrimaryText,
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            Your Info
          </span>
          {verificationState !== VerificationState.VERIFIED ? (
            <div>
              <span style={{ color: Colors.GRAY2, fontSize: 12 }}>
                Just for updates. We hide your number.
              </span>
            </div>
          ) : null}
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: "14px" }}>
        <CustomTextField
          value={tempFullName}
          placeholder="Your name"
          disabled={
            processing || verificationState === VerificationState.VERIFIED
          }
          inputMode="text"
          borderRadius={12}
          onChange={(name: any) => {
            setShowFullNameError(false);
            setTempFullName(name.target.value);
          }}
          error={showFullNameError}
        />
        {isEmailBasedAccount ? (
          <CheckoutEmailAccountNotice
            email={accountData.email}
            logoutEmailOnPress={logoutEmailOnPress}
          />
        ) : (
          <CustomTextField
            value={tempPhoneNumber}
            inputMode="tel"
            placeholder="Phone number"
            borderRadius={12}
            onChange={(phone: any) => {
              const parsedPhoneNumber = phone.target.value.replace(/-/g, "");
              setShowPhoneNumberError(false);
              setTempPhoneNumber(parsedPhoneNumber);
            }}
            disabled={
              processing ||
              verificationState === VerificationState.VERIFIED ||
              verificationState === VerificationState.VERIFYING
            }
            error={showPhoneNumberError}
            endAdornment={
              verificationState === VerificationState.VERIFIED ? (
                <div
                  className="AlignedRow"
                  style={{
                    gap: "5px",
                    color: Colors.GREEN2,
                  }}
                >
                  <span style={{ paddingTop: "2.5px" }}>Verified</span>
                  <Icon
                    icon="ion:checkmark-circle"
                    height={20}
                    style={{
                      marginRight: 10,
                      paddingBottom: 1,
                      color: Colors.GREEN2,
                    }}
                  />
                </div>
              ) : verificationState === VerificationState.UNVERIFIED ? (
                showPhoneNumberError ? (
                  <Icon
                    icon="ion:close-circle-outline"
                    height={20}
                    style={{
                      paddingTop: 1,
                      marginRight: 10,
                      color: Colors.RED2,
                    }}
                  />
                ) : !isDisabledButton && tempPhoneNumber.length > 0 ? (
                  <Icon
                    icon="ion:checkmark-circle-outline"
                    height={24}
                    style={{
                      paddingTop: 1,
                      marginRight: 10,
                      color: Colors.GREEN2,
                    }}
                  />
                ) : undefined
              ) : undefined
            }
          />
        )}
        <VerificationCodeButton
          event={event}
          signIn={false}
          buttonText="Verify Number"
          tempPhoneNumber={tempPhoneNumber}
          setNewUid={setUid}
          verificationState={verificationState}
          setVerificationState={setVerificationState}
          showCodeInputError={showCodeInputError}
          setShowCodeInputError={setShowCodeInputError}
          showError={showError}
          hostFullName={host.fullName}
          callbackOnVerify={callbackOnVerify}
          processing={processing}
        />
        {/** For when an acount is being converted to subaccount and phone number gets moved */}
        {/* {event?.createdBy === "qB9dhzxgPYf62LHiMQh7yhSifaX2" ||
        host.fullName === "Glamhospitality" ? (
          <p
            style={{
              fontSize: 12,
              fontWeight: "500",
              textAlign: "center",
              marginInline: 20,
              color: "#929292",
            }}
          >
            <span
              style={{ color: Colors.RED1, fontWeight: "600", fontSize: 14 }}
            >
              Attention!
            </span>{" "}
            There are some maintenance changes currently being done. Please
            check back in half an hour. Thank you.
          </p>
        ) : null} */}
        {!isEmailBasedAccount &&
        event &&
        isEventExternalLink(event.eventType) ? (
          <MarkitSecuredContainer />
        ) : null}
      </div>
    </div>
  );
});
