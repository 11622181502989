import React, { useState, useCallback } from "react";
import { Colors } from "../../../../utils/colors";
import StandardBorderedContainer from "../../../Containers/StandardBorderedContainer";
import RectangleButton from "../../../Buttons/RectangleButton";
import AddToListModal from "../AudienceLists/AddToListModal";
import { AudienceList, SpreadsheetInfo } from "@markit/common.types";
import SwitchToggleItem from "../../../SwitchToggleItem";
import AudienceListItemsContainer from "../AudienceLists/AudienceListItemsContainer";

type SegmentContactsProps = {
  spreadsheet: SpreadsheetInfo | undefined;
  selectedLists: AudienceList[];
  setSelectedLists: (selectedLists: AudienceList[]) => void;
  includeExistingContacts: boolean;
  setIncludeExistingContacts: (includeExistingContacts: boolean) => void;
};

const SegmentContacts = (props: SegmentContactsProps) => {
  const {
    spreadsheet,
    selectedLists,
    setSelectedLists,
    includeExistingContacts,
    setIncludeExistingContacts,
  } = props;
  const [listModalVisible, setListModalVisible] = useState(false);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    bodyMedium: { fontSize: 14, fontWeight: 500 },
  };

  const addListsOnPress = useCallback(
    (lists: AudienceList[]) => {
      setSelectedLists(lists);
    },
    [setSelectedLists]
  );

  return (
    <>
      <div
        className="ColumnNormal"
        style={{ gap: 40, paddingBlock: 40, alignItems: "center" }}
      >
        <div className="ColumnNormal" style={{ gap: 10 }}>
          <span style={{ ...styles.headerText, textAlign: "center" }}>
            Segment uploaded contacts by adding to lists
          </span>
          <span style={styles.subtext}>
            Optionally organize your contacts into existing lists, or create a
            new one.
          </span>
        </div>
        {selectedLists.length === 0 ? (
          <StandardBorderedContainer
            containerStyles={{
              border: "1.5px dashed #b9b9b9",
              paddingInline: 40,
              paddingBlock: 60,
              width: 547,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <RectangleButton
                buttonLabel="Add to Lists"
                onPress={() => setListModalVisible(true)}
                altColor={Colors.BLACK}
                altTextColor={Colors.WHITE}
                altPaddingHorz={24}
                altPaddingVert={14}
              />
            </div>
          </StandardBorderedContainer>
        ) : (
          <div className="ColumnNormal" style={{ gap: 24, width: 547 }}>
            <AudienceListItemsContainer
              lists={selectedLists}
              itemOnPress={(item: AudienceList) =>
                setSelectedLists(
                  selectedLists.filter((list) => list.id !== item.id)
                )
              }
              newListOnPress={() => setListModalVisible(true)}
            />
            {spreadsheet && spreadsheet.existingContactRows.length > 0 ? (
              <StandardBorderedContainer
                containerStyles={{
                  padding: 14,
                  backgroundColor: Colors.WHITE1,
                }}
              >
                <SwitchToggleItem
                  title={`Include Already Existing Contacts (${spreadsheet.existingContactRows.length})`}
                  description={
                    "Include any duplicate contacts from your spreadsheet that had been previously uploaded who were already in your audience."
                  }
                  toggleValue={includeExistingContacts}
                  onChange={() =>
                    setIncludeExistingContacts(!includeExistingContacts)
                  }
                />
              </StandardBorderedContainer>
            ) : null}
          </div>
        )}
      </div>
      {listModalVisible ? (
        <AddToListModal
          isVisible={listModalVisible}
          closeOnPress={() => setListModalVisible(false)}
          addOnPress={(lists: AudienceList[]) => addListsOnPress(lists)}
          preselectedLists={selectedLists}
        />
      ) : null}
    </>
  );
};

export default SegmentContacts;
