import React, { useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  createSingleSavedQuestion,
  getAccountState,
} from "../../../../redux/slices/accountSlice";
import { SavedFormQuestion, SpreadsheetInfo } from "@markit/common.types";
import { Colors } from "../../../../utils/colors";
import { Icon } from "@iconify/react";
import { useOnMount } from "../../../../utils/useOnMount";
import FormSavedQuestions, {
  FormSavedQuestionsType,
} from "../../../Form/FormSavedQuestions";
import "../../../../css/Table.css";
import { deepCopy, uniqueVals } from "@markit/common.utils";
import { useDispatch } from "react-redux";

type AssignImportedContactsProps = {
  spreadsheet: SpreadsheetInfo | undefined;
  assignedColumns: (SavedFormQuestion | undefined)[];
  setAssignedColumns: (
    assignedColumns: (SavedFormQuestion | undefined)[]
  ) => void;
};

const AssignImportedContacts = (props: AssignImportedContactsProps) => {
  const { spreadsheet, assignedColumns, setAssignedColumns } = props;
  const dispatch = useDispatch();
  const { accountData } = useSelector(getAccountState).account;
  const [questionsVisible, setQuestionsVisible] = useState(false);
  const [formQuestionItem, setFormQuestionItem] = useState<SavedFormQuestion>();
  const [columnIndex, setColumnIndex] = useState(-1);
  const [newQuestionOptions, setNewQuestionOptions] = useState<string[]>([]);

  const styles = {
    headerText: { fontSize: 20, fontWeight: 500 },
    subtext: { fontSize: 14, color: Colors.GRAY1 },
    leftCellContainer: {
      paddingInline: 24,
      paddingBlock: 20,
      whiteSpace: "nowrap",
    },
    cellContainer: {
      paddingInline: 40,
      paddingBlock: 20,
      whiteSpace: "nowrap",
    },
  };

  useOnMount(() => {
    if (
      spreadsheet &&
      spreadsheet.validRows[0].dataColumns &&
      assignedColumns.length === 0
    ) {
      setAssignedColumns(
        new Array(spreadsheet.validRows[0].dataColumns.length).fill(undefined)
      );
    }
  });

  const assignOnPress = useCallback(
    (selectedQuestion: SavedFormQuestion | undefined) => {
      if (
        assignedColumns.some(
          (column) => column && column.id === selectedQuestion?.id
        )
      ) {
        return false;
      }
      const assignedColumnsCopy = deepCopy(assignedColumns);
      assignedColumnsCopy.splice(columnIndex, 1, selectedQuestion);
      setAssignedColumns(assignedColumnsCopy);
      setColumnIndex(0);
      setNewQuestionOptions([]);
      setFormQuestionItem(undefined);
      if (selectedQuestion) {
        dispatch(createSingleSavedQuestion(accountData.uid, selectedQuestion));
      }
      return true;
    },
    [
      accountData.uid,
      assignedColumns,
      columnIndex,
      dispatch,
      setAssignedColumns,
    ]
  );

  const columnOnPress = useCallback(
    (question: SavedFormQuestion | undefined, index: number) => {
      setColumnIndex(index);
      if (question) {
        setFormQuestionItem(question);
      }
      if (spreadsheet) {
        // Auto generate the options for the question
        const questionOptions = spreadsheet.validRows
          .map((row) => (row.dataColumns ? row.dataColumns[index] : ""))
          .filter((option) => option !== "");
        setNewQuestionOptions(uniqueVals(questionOptions));
      }
      setQuestionsVisible(true);
    },
    [spreadsheet]
  );

  const hasDataColumns = useMemo(
    () =>
      spreadsheet &&
      spreadsheet.validRows[0].dataColumns &&
      spreadsheet.validRows[0].dataColumns.length > 0,
    [spreadsheet]
  );

  return (
    <>
      <div
        className="ColumnNormal Centering"
        style={{ gap: 40, paddingBlock: 30 }}
      >
        <div className="ColumnNormal" style={{ gap: 10, textAlign: "center" }}>
          <span style={styles.headerText}>
            Assign imported columns to existing data
          </span>
          <span style={styles.subtext}>
            Assign columns to existing data or create new column types. Review
            before continuing.
          </span>
        </div>
        <div
          className="ColumnNormal"
          style={{
            overflowX: "auto",
            width: hasDataColumns ? "100%" : "auto",
          }}
        >
          <table style={{ marginInline: 40, paddingBottom: 20 }}>
            <thead>
              <tr>
                <th style={styles.leftCellContainer}></th>
                <th style={{ ...styles.cellContainer, textAlign: "center" }}>
                  Full Name
                </th>
                <th style={{ ...styles.cellContainer, textAlign: "center" }}>
                  Phone Number
                </th>
                {assignedColumns.map((question, index) => {
                  return (
                    <th
                      style={{
                        ...styles.cellContainer,
                        color: question ? Colors.BLACK : Colors.ORANGE1,
                        cursor: "pointer",
                      }}
                      onClick={() => columnOnPress(question, index)}
                    >
                      <span>
                        {question ? question.formInput.label : "Assign Column"}
                      </span>
                      <Icon
                        icon="mdi:chevron-down-box"
                        height={17}
                        style={{ marginBottom: -3, marginLeft: 7 }}
                      />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {spreadsheet?.validRows.slice(0, 10).map((row, index) => {
                const rowItems = [
                  row.fullName,
                  row.phoneNumber,
                  ...(row.dataColumns ?? []),
                ];
                return (
                  <tr>
                    <td
                      style={{
                        ...styles.leftCellContainer,
                        textAlign: "center",
                        backgroundColor: Colors.WHITE1,
                      }}
                    >
                      {index + 1}
                    </td>
                    {rowItems.map((item) => (
                      <td
                        style={{
                          ...styles.cellContainer,
                          textAlign: "center",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 500,
                        }}
                      >
                        {item}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {spreadsheet && spreadsheet.validRows.length > 7 ? (
            <div className="Centering" style={{ paddingTop: 20 }}>
              <span style={{ color: Colors.GRAY1 }}>
                {spreadsheet.validRows.length - 10} More Rows...
              </span>
            </div>
          ) : null}
        </div>
      </div>
      {questionsVisible ? (
        <FormSavedQuestions
          type={FormSavedQuestionsType.ASSIGN}
          isVisible={questionsVisible}
          setIsVisible={(isVisible: boolean) => {
            setQuestionsVisible(isVisible);
            setFormQuestionItem(undefined);
          }}
          assignOnPress={(question: SavedFormQuestion | undefined) =>
            assignOnPress(question)
          }
          selectedAssignedQuestion={formQuestionItem}
          newQuestionOptions={newQuestionOptions}
        />
      ) : null}
    </>
  );
};

export default AssignImportedContacts;
