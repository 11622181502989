import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import {
  mostRecentSubscription,
  formatDate,
  prettifyPhone,
  hasSubscription,
  mostRecentSubscriptionPlan,
  isBusinessSubscription,
} from "@markit/common.utils";
import { Icon } from "@iconify/react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useCallback, useMemo, useState } from "react";
import { GetLongDate } from "../../utils/GetLongDate";
import { Colors } from "../../utils/colors";
import { IoWarningOutline } from "react-icons/io5";
import StandardBorderedContainer from "../Containers/StandardBorderedContainer";
import { MarkitPlusModal } from "../Subscription/MarkitPlusModal";
import {
  FREE_TIER_MESSAGE_LIMIT,
  OVERFLOW_RATE,
  OVERFLOW_RATE_BUSINESS,
} from "@markit/common.types";

const TextingPanelUsage = (props: {
  setOverflowModalVisible: (visible: boolean) => void;
}) => {
  const { setOverflowModalVisible } = props;
  const { account } = useSelector(getAccountState);
  const { accountData } = account;
  const [markitPlusModalVisible, setMarkitPlusModalVisible] = useState(false);

  const closeMarkitPlusModal = useCallback(() => {
    setMarkitPlusModalVisible(false);
  }, []);

  const trialSubscription = useMemo(
    () => accountData.customer.state === "TRIAL",
    [accountData.customer.state]
  );

  const frozenSubscription = useMemo(
    () => accountData.customer.state === "FROZEN",
    [accountData.customer.state]
  );

  const subscription = useMemo(
    () =>
      hasSubscription(accountData)
        ? mostRecentSubscription(accountData)
        : undefined,
    [accountData]
  );

  const subscriptionPlan = useMemo(
    () => mostRecentSubscriptionPlan(accountData),
    [accountData]
  );

  const freeTierFirstDayBilling = useMemo(() => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return formatDate(firstDay, false, true, false);
  }, []);

  const freeTierLastDayBilling = useMemo(() => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return formatDate(lastDay, false, true, false);
  }, []);

  const freeTierMessages = useMemo(
    () =>
      accountData.customer.freeTierMessages
        ? accountData.customer.freeTierMessages
        : 0,
    [accountData.customer.freeTierMessages]
  );

  const progressTickerLabels: number[] = useMemo(() => {
    const labels: number[] = [0];
    if (subscription) {
      for (let i = 1; i <= 4; i++) {
        labels.push(subscription.price[0].limit * (i / 4));
      }
    } else {
      for (let i = 1; i <= 4; i++) {
        labels.push(FREE_TIER_MESSAGE_LIMIT * (i / 4));
      }
    }
    return labels;
  }, [subscription]);

  const styles = {
    smallNoticeButton: {
      backgroundColor: Colors.GRAY6,
      alignItems: "center",
      padding: 10,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 20,
      fontSize: 14,
      marginBottom: 14,
      cursor: "pointer",
    },
  };

  const textUsagePercent = useMemo(() => {
    return (
      <span
        style={{
          fontSize: 14,
          paddingTop: 5,
          marginLeft: 14,
          color: Colors.GRAY1,
        }}
      >
        {subscription
          ? `${Math.round(
              (subscription.message / subscription.price[0].limit) * 100
            )}% • ${subscription.message.toLocaleString()}/${subscription.price[0].limit.toLocaleString()} `
          : `${Math.round(
              (freeTierMessages / FREE_TIER_MESSAGE_LIMIT) * 100
            )}%• ${freeTierMessages}/${FREE_TIER_MESSAGE_LIMIT}`}
      </span>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeTierMessages]);

  const noticeButton = useMemo(() => {
    if (subscriptionPlan) {
      if (frozenSubscription) {
        return (
          <div style={styles.smallNoticeButton}>
            <p>Account Frozen</p>
            <IoWarningOutline
              size={14}
              style={{ color: Colors.ORANGE1, marginLeft: 4 }}
            />
          </div>
        );
      } else if (trialSubscription) {
        return (
          <div style={{ display: "flex" }}>
            <div style={styles.smallNoticeButton}>Free Trial</div>
            {textUsagePercent}
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex" }}>
            <div className="AlignedRow" style={styles.smallNoticeButton}>
              Markit
              <p style={{ color: Colors.BLUE5 }}>+</p>
            </div>
            {textUsagePercent}
          </div>
        );
      }
    } else {
      return (
        <div style={{ display: "flex" }}>
          <div
            style={styles.smallNoticeButton}
            onClick={() => {
              setMarkitPlusModalVisible(true);
            }}
          >
            Free Plan
          </div>
          {textUsagePercent}
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subscriptionPlan,
    accountData.customer.state,
    frozenSubscription,
    trialSubscription,
  ]);

  return (
    <StandardBorderedContainer
      containerStyles={{ padding: 14, width: "100%", cursor: "pointer" }}
    >
      <div className="AlignedRowSpaced">
        {noticeButton}
        {hasSubscription(accountData) && !frozenSubscription ? (
          <div className="AlignedRow" style={{ gap: 7, marginTop: -18 }}>
            <span style={{ color: Colors.BLUE5 }}>
              {prettifyPhone(accountData.customer.phone)}
            </span>
            <Icon
              icon="ion:checkmark-circle"
              height={16}
              color={Colors.BLUE5}
            />
          </div>
        ) : null}
      </div>
      <div>
        <ProgressBar
          completed={subscription ? subscription.message : freeTierMessages}
          maxCompleted={
            subscription ? subscription.price[0].limit : FREE_TIER_MESSAGE_LIMIT
          }
          isLabelVisible={false}
          bgColor={Colors.BLUE5}
          baseBgColor="rgba(133,134,145,0.25)"
          height="4px"
          borderRadius="4px"
        />
        <div className="AlignedRowSpaced">
          {progressTickerLabels.map((_, index) => (
            <span
              key={index}
              style={{
                fontSize: 8,
                color: Colors.GRAY2,
                userSelect: "none",
              }}
            >
              |
            </span>
          ))}
        </div>
        <div className="AlignedRowSpaced">
          {progressTickerLabels.map((label, index) => (
            <span
              key={index}
              style={{
                fontSize: 12,
                color: Colors.GRAY2,
                userSelect: "none",
              }}
            >
              {label}
            </span>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <span style={{ fontSize: 12, color: Colors.GRAY2, marginTop: 14 }}>
          This {subscriptionPlan ? "billing" : "monthly"} period (
          {subscription
            ? GetLongDate(subscription.start, false, true, false)
            : freeTierFirstDayBilling}{" "}
          -{" "}
          {subscription
            ? GetLongDate(subscription.end, false, true, false)
            : freeTierLastDayBilling}
          )
        </span>
        <div
          onClick={() => {
            setOverflowModalVisible(true);
          }}
          className="AlignedRow"
          style={{
            cursor: "pointer",
            pointerEvents: hasSubscription(accountData) ? "all" : "none",
          }}
        >
          <span
            style={{ fontSize: 12, color: Colors.GRAY2, cursor: "pointer" }}
          >
            {subscriptionPlan
              ? `Overflow Rate (${
                  isBusinessSubscription(accountData)
                    ? OVERFLOW_RATE_BUSINESS + " cent"
                    : OVERFLOW_RATE + " cents"
                } per credit)`
              : `Free tier is capped at ${FREE_TIER_MESSAGE_LIMIT} texts`}
          </span>
          {hasSubscription(accountData) ? (
            <Icon
              icon="mdi:information-outline"
              height={16}
              color={Colors.BLACK}
              style={{ marginLeft: 4, cursor: "pointer" }}
            />
          ) : null}
        </div>
      </div>
      {markitPlusModalVisible ? (
        <MarkitPlusModal closeModal={closeMarkitPlusModal} />
      ) : null}
    </StandardBorderedContainer>
  );
};

export default TextingPanelUsage;
