import { transform, isEqual, isArray, isObject } from "lodash";
const CryptoJS = require("crypto-js");

export const encryptPhoneNumber = (phone: string) => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(phone));
};

export const decryptData = (data: any) => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8);
};

export function truncateString(str: string, length: number, ending: string) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str) {
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }
}

// capitalize the first letter in a string
export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
export function difference(origObj: object, newObj: object): object {
  function changes(newObj: object, origObj: object) {
    let arrayIndexCounter = 0;
    return transform(newObj, function (result: any, value, key) {
      if (!isEqual(value, origObj[key])) {
        const resultKey: any = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }
  return changes(newObj, origObj);
}

export const isValidUserName = (username: string): boolean => {
  const re = /^[a-zA-Z0-9_.]{3,30}$/;
  return re.test(username);
};

export const fromInternationalCode = (phone: string): string => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  return cleaned
    .replace(/[^\d]+/g, "")
    .replace(/(?:\+1|1)?(\d{3})(\d{3})(\d{4})/, "$1$2$3");
};
