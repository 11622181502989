import React, { useCallback, useMemo, useState } from "react";
import { TabPanel } from "../FullEventSubComponents/TabPanel";
import StandardListContainer from "../Containers/StandardListContainer";
import SearchBoxContainer from "../Containers/SearchBoxContainer";
import { useSelector } from "react-redux";
import { getAccountState } from "../../redux/slices/accountSlice";
import { AudienceList } from "@markit/common.types";
import BlackHoverButton from "../Buttons/BlackHoverButton";
import GridTableHeader from "../Headers/GridTableHeader";
import FlatList from "flatlist-react/lib";
import EmptyStateButton from "../Buttons/EmptyStateButton";
import { Icon } from "@iconify/react";
import { Colors } from "../../utils/colors";
import filter from "lodash.filter";
import AudienceListItem from "../DisplayItem/AudienceListItem";
import AudienceListPopupPanel from "../CreatorDashboard/CreatorProfile/AudienceLists/AudienceListPopupPanel";
import { sortAudienceListsDisplay } from "../../utils/userUtils";
import { EmptyStateFlatlist } from "../EmptyStates/EmptyStateFlatlist";

type ListsPanelProps = { tabValue: number };

export const ListsPanel = (props: ListsPanelProps) => {
  const { tabValue } = props;
  const { audienceLists, appInitialized } =
    useSelector(getAccountState).account;
  const [searchTerm, setSearchTerm] = useState("");
  const [listPopupVisible, setListPopupVisible] = useState(false);
  const [selectedList, setSelectedList] = useState<AudienceList>();

  const contains = useCallback((list: AudienceList, query: string) => {
    return list.name.toLowerCase().includes(query);
  }, []);

  const handleSearch = (text: string) => {
    setSearchTerm(text.toLowerCase());
  };

  const audienceListsToShow = useMemo(() => {
    if (!appInitialized) {
      return [];
    }
    let userAudienceLists: AudienceList[] =
      sortAudienceListsDisplay(audienceLists);
    if (searchTerm !== "") {
      userAudienceLists = filter(userAudienceLists, (list: AudienceList) => {
        return contains(list, searchTerm);
      });
    }
    return userAudienceLists;
  }, [appInitialized, audienceLists, contains, searchTerm]);

  const newListOnPress = useCallback(() => {
    setListPopupVisible(true);
  }, []);

  const existingListOnPress = useCallback((item: AudienceList) => {
    setSelectedList(item);
    setListPopupVisible(true);
  }, []);

  const renderEmptyView = useMemo(() => {
    return (
      <EmptyStateFlatlist
        searchTerm={searchTerm}
        isLoading={false}
        containerStyles={{ paddingTop: 150 }}
        nonSearchEmptyView={<></>}
      />
    );
  }, [searchTerm]);

  return (
    <TabPanel value={tabValue} index={1}>
      <div className="ColumnNormal" style={{ gap: 30, paddingBottom: 48 }}>
        <StandardListContainer
          searchComp={
            <SearchBoxContainer
              placeholder="Search Lists..."
              onChange={(e) => handleSearch(e.target.value)}
              containerStyles={{ marginTop: 0 }}
            />
          }
          headerRightComp={
            <BlackHoverButton
              title="New&nbsp;List"
              iconName="ion:add-circle"
              permanentHover
              iconSize={14}
              onPress={newListOnPress}
              containerStyles={{ paddingInline: 14, paddingBlock: 12 }}
            />
          }
          gridHeaderComp={
            <GridTableHeader
              flexSections={[4, 1, 1, 2]}
              sectionTitles={["Name", "Visibility", "People", "Description"]}
            />
          }
          listComp={
            <div className="ColumnNormal" style={{ paddingTop: 20 }}>
              <FlatList
                list={audienceListsToShow}
                renderItem={(item: AudienceList) => {
                  return (
                    <AudienceListItem
                      item={item}
                      selectOnPress={() => existingListOnPress(item)}
                    />
                  );
                }}
                renderWhenEmpty={() => renderEmptyView}
              />
              {audienceLists.length === 1 && !searchTerm ? (
                <EmptyStateButton
                  title="Grow with Lists"
                  description="Segment and build your audience with lists."
                  icon={
                    <Icon icon="ion:list" height={64} color={Colors.GRAY1} />
                  }
                  iconBox={84}
                  btnText="New List"
                  onPress={newListOnPress}
                  containerStyles={{ paddingTop: 100 }}
                />
              ) : null}
            </div>
          }
          isLoading={!appInitialized}
        />
      </div>
      {listPopupVisible ? (
        <AudienceListPopupPanel
          isVisible={listPopupVisible}
          setIsVisible={setListPopupVisible}
          existingList={selectedList}
          setExistingList={setSelectedList}
        />
      ) : null}
    </TabPanel>
  );
};
